import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { RequestViewForm } from '../request-view-form';

export function RequestView() {
  return (
    <DashboardContent>
      <RequestViewForm />
    </DashboardContent>
  );
}
