import { z } from 'zod';

export const requestStatusType = z.enum([
  'DRAFT',
  'ACTIVE',
  'COMPLETED',
  'CANCELLED',
]);

export type RequestStatusType = z.infer<typeof requestStatusType>;

export const requestStatusFilterType = z.enum([
  'all',
  'DRAFT',
  'ACTIVE',
  'COMPLETED',
  'CANCELLED',
]);

export type RequestStatusFilterType = z.infer<typeof requestStatusFilterType>;
