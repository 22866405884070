import Chip from '@mui/material/Chip';
import type { SxProps, Theme } from '@mui/material/styles';
import { useCallback } from 'react';
import {
  chipProps,
  FiltersBlock,
  FiltersResult,
} from 'src/minimal-theme/components/filters-result';
import type { UseSetStateReturn } from 'src/minimal-theme/hooks/use-set-state';
import type { LocationTableFilters } from 'src/types/location';

// ----------------------------------------------------------------------

type Props = {
  totalResults: number;
  sx?: SxProps<Theme>;
  onResetPage: () => void;
  filters: UseSetStateReturn<LocationTableFilters>;
};

export function LocationTableFiltersResult({
  filters,
  onResetPage,
  totalResults,
  sx,
}: Props) {
  const handleRemoveKeyword = useCallback(() => {
    onResetPage();
    filters.setState({ search: '' });
  }, [filters, onResetPage]);

  const handleRemoveStatus = useCallback(() => {
    onResetPage();
    filters.setState({ isNetworkMember: false });
  }, [filters, onResetPage]);

  const handleReset = useCallback(() => {
    onResetPage();
    filters.onResetState();
  }, [filters, onResetPage]);

  return (
    <FiltersResult totalResults={totalResults} onReset={handleReset} sx={sx}>
      <FiltersBlock
        label="Status:"
        isShow={filters.state.isNetworkMember !== false}
      >
        <Chip
          {...chipProps}
          label={
            filters.state.isNetworkMember
              ? 'myMechanic Network'
              : 'All Locations'
          }
          onDelete={handleRemoveStatus}
          sx={{ textTransform: 'capitalize' }}
        />
      </FiltersBlock>

      <FiltersBlock label="Keyword:" isShow={!!filters.state.search}>
        <Chip
          {...chipProps}
          label={filters.state.search}
          onDelete={handleRemoveKeyword}
        />
      </FiltersBlock>
    </FiltersResult>
  );
}
