import { Alert, AlertTitle, Typography } from '@mui/material';
import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import type { ContainerProps } from '@mui/material/Container';
import Container from '@mui/material/Container';
import type { Breakpoint } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { useConvexAuth, useQuery } from 'convex/react';
import { api } from 'src/convex/_generated/api';
import { getStepTypeDisplay } from 'src/convex/schema/entities/requests/requests';
import { MuiTypeSafeLink } from 'src/minimal-theme/components/routes/components/mui-link';
import { useSettingsContext } from 'src/minimal-theme/components/settings';
import { layoutClasses } from 'src/minimal-theme/layouts/classes';

type MainProps = BoxProps & {
  isNavHorizontal: boolean;
};

export function Main({ children, isNavHorizontal, sx, ...other }: MainProps) {
  return (
    <Box
      component="main"
      className={layoutClasses.main}
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        ...(isNavHorizontal && {
          '--layout-dashboard-content-pt': '40px',
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}

// ----------------------------------------------------------------------

type DashboardContentProps = ContainerProps & {
  disablePadding?: boolean;
};

export function DashboardContent({
  sx,
  children,
  disablePadding,
  maxWidth = 'lg',
  ...other
}: DashboardContentProps) {
  const theme = useTheme();

  const settings = useSettingsContext();

  const layoutQuery: Breakpoint = 'lg';

  const { isAuthenticated, isLoading } = useConvexAuth();

  const user = useQuery(
    api.functions.users.getMe,
    isAuthenticated ? {} : 'skip'
  );

  const activeRequestsAll = useQuery(api.functions.requests.getActiveRequests);

  const activeAssignments = activeRequestsAll?.filter(
    r => r.currentAssignedToId === user?._id
  );

  console.log(activeAssignments);

  return (
    <Container
      className={layoutClasses.content}
      maxWidth={settings.compactLayout ? maxWidth : false}
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        pt: 'var(--layout-dashboard-content-pt)',
        pb: 'var(--layout-dashboard-content-pb)',
        [theme.breakpoints.up(layoutQuery)]: {
          px: 'var(--layout-dashboard-content-px)',
        },
        ...(disablePadding && {
          p: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 0,
            xl: 0,
          },
        }),
        ...sx,
      }}
      {...other}
    >
      {activeAssignments && activeAssignments?.length > 0 && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Alert severity="warning" sx={{ flex: 1 }}>
            <AlertTitle sx={{ textTransform: 'capitalize' }}>
              You have {activeAssignments?.length} active{' '}
              {activeAssignments?.length === 1 ? 'assignment' : 'assignments'}
            </AlertTitle>
            {activeAssignments?.map(assignment => (
              <Box
                key={assignment._id}
                display="flex"
                gap={1}
                alignItems="flex-end"
              >
                <Typography>
                  {assignment.caseNumber} —{' '}
                  {getStepTypeDisplay(assignment.currentStepType)}
                </Typography>
                <MuiTypeSafeLink
                  to={`/dashboard/requests/$requestId`}
                  params={{ requestId: assignment._id }}
                  color="inherit"
                  sx={{ typography: 'subtitle2' }}
                >
                  View
                </MuiTypeSafeLink>
              </Box>
            ))}
          </Alert>
        </Box>
      )}

      {children}
    </Container>
  );
}
