import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { DriverNewEditForm } from '../driver-new-edit-form';

export function DriverEditView() {
  return (
    <DashboardContent>
      <DriverNewEditForm />
    </DashboardContent>
  );
}
