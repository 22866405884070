import LoadingButton from '@mui/lab/LoadingButton';
import { Button, CardHeader, FormControlLabel, Switch } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useForm } from '@tanstack/react-form';
import { useLoaderData, useNavigate } from '@tanstack/react-router';
import { useMutation, useQuery } from 'convex/react';
import { toast } from 'sonner';
import { api } from 'src/convex/_generated/api';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { PhoneInput } from 'src/minimal-theme/components/phone-input';
import { RouterLink } from 'src/minimal-theme/components/routes/components';

export function MechanicNewEditForm() {
  const navigate = useNavigate();

  const selectedMechanic = (() => {
    try {
      return useLoaderData({
        from: '/_auth/dashboard/mechanics/$mechanicId',
      });
    } catch {
      return null;
    }
  })();

  const me = useQuery(api.functions.users.getMe);

  const isAFleetCompany = me?.primaryRoleType === 'FLEET_DISPATCHER';

  const inviteUser = useMutation(api.functions.invitations.inviteUser);

  const { Field, Subscribe, handleSubmit, reset } = useForm({
    defaultValues: {
      firstName: selectedMechanic?.clerkUser.firstName ?? '',
      lastName: selectedMechanic?.clerkUser.lastName ?? '',
      phone: selectedMechanic?.clerkUser.primaryPhoneNumber?.phoneNumber ?? '',
      email:
        selectedMechanic?.clerkUser.primaryEmailAddress?.emailAddress ?? '',
      skipInvitationEmail: true,
      // imageUrl: selectedMechanic?.clerkUser.imageUrl ?? '',
    },
    onSubmit: async ({ value }) => {
      const response = await inviteUser({
        inviteInfo: {
          ...value,
          role: isAFleetCompany ? 'TECHNICIAN_FLEET' : 'TECHNICIAN_PROVIDER',
        },
      });

      if (response.success) {
        toast.success(response.message);
        navigate({ to: '/dashboard/mechanics' });
      } else {
        toast.error(response.message);
      }
    },
  });

  return (
    <>
      <Button
        component={RouterLink}
        href={'/dashboard/mechanics'}
        startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
        sx={{ mr: 'auto', mb: 2 }}
      >
        Back
      </Button>

      <Card>
        <CardHeader
          title={!selectedMechanic ? 'Add Mechanic' : 'Edit Mechanic'}
          subheader={selectedMechanic?.clerkUser.fullName}
          sx={{ mb: 3 }}
        />
        <Stack spacing={3} sx={{ p: 3 }}>
          <form
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit();
            }}
            style={{ width: '100%' }}
          >
            {/* <Box sx={{ mb: 5 }}>
            <Field
              name="imageUrl"
              children={({ state, ...other }) => (
                <UploadAvatar
                  value={state.value}
                  maxSize={3145728}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 3,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.disabled',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of {fData(3145728)}
                    </Typography>
                  }
                  {...other}
                />
              )}
            />
          </Box> */}
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <Field
                name="firstName"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="lastName"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="phone"
                children={({ state, handleChange, handleBlur }) => (
                  <PhoneInput
                    required
                    label="Phone Number"
                    fullWidth
                    value={state.value}
                    onChange={newValue => handleChange(newValue || '')}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
              <Field
                name="email"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field name="skipInvitationEmail">
                {({ state, handleChange, handleBlur }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        id="skipInvitationEmail"
                        checked={state.value}
                        onChange={e => handleChange(e.target.checked)}
                      />
                    }
                    label="Skip Invitation Email"
                  />
                )}
              </Field>
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <Subscribe
                selector={state => [state.canSubmit, state.isSubmitting]}
                children={([canSubmit, isSubmitting]) => {
                  return (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={!canSubmit}
                    >
                      {!selectedMechanic ? 'Add' : 'Save changes'}
                    </LoadingButton>
                  );
                }}
              />
            </Stack>
          </form>
        </Stack>
      </Card>
    </>
  );
}
