import { api } from '@api';
import { useClerk, useUser } from '@clerk/clerk-react';
import { RouterProvider } from '@tanstack/react-router';
import { useConvexAuth, useMutation, useQuery } from 'convex/react';
import { useEffect } from 'react';
import { useImpersonationInfo } from 'src/hooks/useImpersonationInfo';
import { createRouter } from '../../router';

export const router = createRouter();

export function RouterProviderWithContext() {
  const { isAuthenticated, isLoading } = useConvexAuth();
  const { user } = useUser();
  const clerk = useClerk();

  const superAdminCheckResponse = useQuery(
    api.functions.users.checkSuperAdminInit,
    isAuthenticated && !!user ? {} : 'skip'
  );

  const needsSuperAdminInit = superAdminCheckResponse?.needsSuperAdminInit;

  const me = useQuery(
    api.functions.users.getMe,
    isAuthenticated && needsSuperAdminInit === false ? {} : 'skip'
  );

  const { impersonatingServiceProvider, impersonatingFleet } =
    useImpersonationInfo();

  const initSuperAdmin = useMutation(api.functions.users.initSuperAdminUser);

  // Handle superadmin initialization
  useEffect(() => {
    if (needsSuperAdminInit) {
      initSuperAdmin({}).catch(error => {
        console.error('Failed to initialize superadmin:', error);
      });
    }
  }, [needsSuperAdminInit, initSuperAdmin]);

  if (isLoading) {
    return null;
  }

  return (
    <RouterProvider
      router={router}
      context={{
        isAuthenticated,
        user,
        clerk,
        me,
        impersonatingServiceProvider,
        impersonatingFleet,
      }}
    />
  );
}
