import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'src/config-global';
import { SignInView } from 'src/sections/sign-in/view/sign-in-view';

const metadata = { title: `myMechanic | Sign In - ${CONFIG.appName}` };

export default function Page() {
  return (
    <>
      <Helmet>
        <title> {metadata.title}</title>
      </Helmet>
      <SignInView />
    </>
  );
}
