import { api } from '@api';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useForm } from '@tanstack/react-form';
import { useParams } from '@tanstack/react-router';
import { useMutation, useQuery } from 'convex/react';
import { useEffect, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input/input';
import { toast } from 'sonner';
import { Id } from 'src/convex/_generated/dataModel';
import { ServiceProviderResult } from 'src/convex/functions/providers';
import { PhoneInput } from 'src/minimal-theme/components/phone-input';
import { schemaHelper } from 'src/minimal-theme/utils/schema-helper';
import zod from 'zod';

export type InvitedUserSchema = zod.infer<typeof InvitedUserSchema>;

export const InvitedUserSchema = zod.object({
  email: zod
    .string()
    .email('Invalid email address')
    .optional()
    .or(zod.literal('')),
  phone: schemaHelper.phoneNumber({ isValidPhoneNumber }),
  firstName: zod.string().min(2, 'First name must be at least 2 characters'),
  lastName: zod.string().min(2, 'Last name must be at least 2 characters'),
});

export function InviteUserViaRequestDialog({
  open,
  onClose,
  selectedServiceProvider,
}: {
  open: boolean;
  onClose: () => void;
  selectedServiceProvider: ServiceProviderResult;
}) {
  const params = useParams({ from: '/_auth/dashboard/requests/$requestId' });

  const inviteUser = useMutation(api.functions.invitations.inviteUser);

  const [scheduledInvitationId, setScheduledInvitationId] =
    useState<Id<'_scheduled_functions'> | null>(null);

  const scheduledInvitation = useQuery(
    api.functions.schedule.getScheduledMessage,
    scheduledInvitationId ? { id: scheduledInvitationId } : 'skip'
  );

  useEffect(() => {
    if (scheduledInvitation) {
      if (scheduledInvitation.state.kind === 'success') {
        toast.dismiss();
        toast.success('Invitation sent successfully');
        onClose();
      } else if (
        scheduledInvitation.state.kind === 'failed' ||
        scheduledInvitation.state.kind === 'canceled'
      ) {
        toast.dismiss();
        toast.error('Something went wrong when inviting user');
      }
    }
  }, [scheduledInvitation]);

  const { Field, Subscribe, handleSubmit, reset } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      knownLocationInvitationId: '',
    },
    onSubmit: async ({ value }) => {
      try {
        const res = await inviteUser({
          inviteInfo: {
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            phone: value.phone,
            role: 'SERVICE_DISPATCHER',
            requestId: params.requestId as Id<'requests'>,
            knownLocationInvitationId:
              selectedServiceProvider.locationGroup._id,
            // skipInvitationEmail: value.skipInvitationEmail,
          },
        });
        if (res.success) {
          toast.loading(res.message);
          if (res.scheduleId) {
            setScheduledInvitationId(res.scheduleId);
          }
          // onClose();
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        console.error('Failed to save location:', error);
        toast.error('Failed to save location. Please try again.');
      }
    },
  });

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: 'md' } }}
    >
      <DialogTitle>Invite And Assign User</DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Divider />
        <Stack spacing={3} sx={{ p: 3 }}>
          <form
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit();
            }}
            style={{ width: '100%' }}
          >
            <Typography variant="h6">Contact Details</Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{ my: 2 }}
            >
              <Field
                name="firstName"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="First Name"
                    variant="outlined"
                    autoFocus
                    fullWidth
                    required
                    value={state.value}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
              <Field
                name="lastName"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
              <Field
                name="phone"
                children={({ state, handleChange, handleBlur }) => (
                  <PhoneInput
                    required
                    label="Phone Number"
                    fullWidth
                    value={state.value}
                    onChange={newValue => handleChange(newValue || '')}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
              <Field
                name="email"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={state.value}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <Subscribe
                selector={state => [state.canSubmit, state.isSubmitting]}
                children={([canSubmit, isSubmitting]) => {
                  return (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={!canSubmit}
                    >
                      Invite User
                    </LoadingButton>
                  );
                }}
              />
            </Stack>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
