import { zid, zodToConvex } from 'convex-helpers/server/zod';
import { defineTable } from 'convex/server';
import { z } from 'zod';

export const subscriptionPlanSchema = z
  .enum(['basic', 'premium', 'enterprise', 'none'])
  .default('none');

export const subscriptionStatusSchema = z
  .enum(['active', 'inactive', 'trial', 'expired', 'cancelled', 'none'])
  .default('none');

export const subscriptionInfoSchema = z.object({
  subscriptionPlan: subscriptionPlanSchema.optional(),
  subscriptionStatus: subscriptionStatusSchema.optional(), // TODO: I don't want to make these optional, but it solves the type discrepency where the zod schema says they are one of the values but the return from use query says otherwise...
  subscriptionStartDate: z.string().optional(),
  subscriptionEndDate: z.string().optional(),
});

/** Current subscription is stored directly on the company
 *  when they change/add subscriptions, we move them here so we can check their subscription history
 */
const subscriptionHistorySchema = subscriptionInfoSchema.extend({
  companyId: zid('companies'),
});

export const subscriptionsHistory = defineTable(
  zodToConvex(subscriptionHistorySchema).fields
).index('by_companyId', ['companyId']);
