import * as Sentry from '@sentry/react';
import { router } from 'src/components/routing/RouterProviderWithContext';
import { viteEnvironment, viteMode } from 'src/viteEnv';

export function initSentry() {
  Sentry.init({
    enabled: viteMode !== 'development',
    environment: process.env.NODE_ENV || 'development',
    dsn: viteEnvironment.VITE_SENTRY_DSN,
    integrations: [
      Sentry.tanstackRouterBrowserTracingIntegration(router),
      Sentry.browserTracingIntegration(),
      // TODO: RF - revisit options
      // Sentry.replayIntegration({
      //   maskAllText: true,
      //   maskAllInputs: true,
      // }),
      Sentry.feedbackIntegration({
        // Customize the widget
        colorScheme: 'system',
        buttonLabel: 'Send Feedback',
        submitButtonLabel: 'Send',
        formTitle: 'Send Feedback',
        messagePlaceholder: 'What would you like to tell us?',
        // Position bottom-right
        positioning: {
          side: 'right',
          offset: '20px',
        },
        showBranding: false,
      }),
    ],
    // Tracing
    // Setting a sample rate is required for sending performance data.
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control.
    tracesSampleRate: 1.0, // Capture 100% of the transactions (adjust as needed)
    tracePropagationTargets: ['localhost'], // TODO: RF - need to add other envs here
    // Session Replay
    replaysSessionSampleRate: 0.1, // 10% session replay
    replaysOnErrorSampleRate: 1.0, // 100% on error sessions
    // Environment
    // TODO: RF - add these
    // environment: viteEnv.VITE_APP_ENV, // e.g., 'development', 'production'
    // Release
    // release: viteEnv.VITE_APP_VERSION, // Ensure this is set during build
  });
}
