import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { LocationNewEditForm } from '../location-new-edit-form';

export function LocationCreateView() {
  return (
    <DashboardContent>
      <LocationNewEditForm />
    </DashboardContent>
  );
}
