import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { CompanyEditForm } from '../company-edit-form';

export function SettingsView() {
  return (
    <DashboardContent>
      <CompanyEditForm />
    </DashboardContent>
  );
}
