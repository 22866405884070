import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { CompanyNewEditForm } from '../company-new-edit-form';

export function CompanyCreateView() {
  return (
    <DashboardContent>
      <CompanyNewEditForm />
    </DashboardContent>
  );
}
