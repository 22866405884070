import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { forwardRef } from 'react';
import { RouterLink } from 'src/minimal-theme/components/routes/components/router-link';
import { FileRoutesByFullPath } from 'src/routeTree.gen';

// ----------------------------------------------------------------------

type ValidPaths = keyof FileRoutesByFullPath;

type TypeSafeLinkProps = Omit<MuiLinkProps, 'href' | 'component' | 'params'> & {
  to: ValidPaths;
  params?: Parameters<typeof RouterLink>[0]['params'];
};

/** Used as a reverse router-link, when we need to use the Mui <Link component but want our inferred routes on href */
export function MuiTypeSafeLink({
  to,
  params,
  ...muiLinkProps
}: TypeSafeLinkProps): React.ReactElement {
  const LinkComponent = forwardRef<HTMLAnchorElement, any>((props, ref) => (
    <RouterLink ref={ref} href={to} params={params} {...props} />
  ));

  return <MuiLink component={LinkComponent} {...muiLinkProps} />;
}
