import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useParams } from '@tanstack/react-router';
import { useMutation, useQuery } from 'convex/react';
import { useState } from 'react';
import { toast } from 'sonner';

import { api } from 'src/convex/_generated/api';
import { Id } from 'src/convex/_generated/dataModel';
import { ServiceProviderResult } from 'src/convex/functions/providers';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Scrollbar } from 'src/minimal-theme/components/scrollbar';
import { InvitingUser } from 'src/sections/request/view/request-step-information';

type RequestFindServiceProviderProps = {
  open: boolean;
  needToFindAServiceProvider?: boolean;
  onClose: () => void;
  inviteLocation: () => void;
  setUserInvitation: (invite: InvitingUser) => void;
};

const PAGE_SIZE = 10;

export function RequestFindServiceProvider({
  open,
  onClose,
  inviteLocation,
  needToFindAServiceProvider,
  setUserInvitation,
}: RequestFindServiceProviderProps) {
  const params = useParams({ from: '/_auth/dashboard/requests/$requestId' });

  const request = useQuery(api.functions.requests.getRequest, {
    requestId: params.requestId as Id<'requests'>,
  });

  const [selectedServiceProvider, setSelectedServiceProvider] =
    useState<ServiceProviderResult | null>(null);

  const [howManyToShow, setHowManyToShow] = useState<number>(PAGE_SIZE);

  const vehicleLat = request?.vehicle?.location?.latitude;
  const vehicleLong = request?.vehicle?.location?.longitude;

  // TODO: This should be on the request...
  const requiredServices: Id<'services'>[] = [];
  // Query with current cursor
  const searchResults = useQuery(
    api.functions.providers.searchServiceProviders,
    !!vehicleLat && !!vehicleLong && needToFindAServiceProvider
      ? {
          latitude: vehicleLat,
          longitude: vehicleLong,
          requiredServiceIds: requiredServices,
          maxDistanceMiles: 50000,
        }
      : 'skip'
  );

  // TODO: client side pagination for now
  const handleLoadMore = async () => {
    setHowManyToShow(prev => prev + PAGE_SIZE);
  };

  const assignServiceProvider = useMutation(
    api.functions.requests.assignToServiceProviderDispatch
  );

  const [expandedProvider, setExpandedProvider] = useState<Id<'groups'> | null>(
    null
  );

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: 500 } }}
    >
      <DialogTitle>Find A Service Provider</DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        {searchResults && (
          <Scrollbar sx={{ height: 200, px: 2, mx: 2 }}>
            <List
              sx={{
                // TODO: This should be a theme variable
                background: '#F1F3F8',
                px: 2,
                borderRadius: 1,
              }}
            >
              {searchResults.slice(0, howManyToShow).map(locationGroup => {
                const labelId = `checkbox-list-secondary-label-${locationGroup.locationGroup._id}`;
                return (
                  <ListItem
                    key={locationGroup.locationGroup._id}
                    sx={theme => ({
                      background: theme.palette.background.paper,
                      // padding: theme.spacing(2),
                      // marginBottom: theme.spacing(2),
                      borderRadius: 1,
                      border: '1px solid',
                      borderColor: theme.palette.divider,
                      display: 'flex',
                      flexDirection: 'column',
                      my: 1,
                    })}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <ListItemText
                        id={labelId}
                        primary={locationGroup.locationGroup.name}
                        secondary={`${locationGroup.distance.toFixed(1)} miles out`}
                      />
                      <IconButton
                        onClick={() =>
                          setExpandedProvider(
                            expandedProvider === locationGroup.locationGroup._id
                              ? null
                              : locationGroup.locationGroup._id
                          )
                        }
                        sx={{ mr: 2 }}
                      >
                        <Iconify
                          icon="eva:arrow-ios-downward-fill"
                          sx={{
                            transform:
                              expandedProvider ===
                              locationGroup.locationGroup._id
                                ? 'rotate(180deg)'
                                : 'none',
                            transition: 'transform 0.2s',
                          }}
                        />
                      </IconButton>
                      <Button
                        onClick={() =>
                          setSelectedServiceProvider(
                            selectedServiceProvider?.locationGroup._id ===
                              locationGroup.locationGroup._id
                              ? null
                              : locationGroup
                          )
                        }
                        variant={
                          selectedServiceProvider?.locationGroup._id ===
                          locationGroup.locationGroup._id
                            ? 'contained'
                            : 'outlined'
                        }
                        color="primary"
                        sx={{ width: 100 }}
                        disableRipple
                      >
                        {selectedServiceProvider?.locationGroup._id ===
                        locationGroup.locationGroup._id ? (
                          <Iconify icon="carbon:checkmark" />
                        ) : (
                          'Select'
                        )}
                      </Button>
                    </Box>
                    {expandedProvider === locationGroup.locationGroup._id && (
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          Address :{' '}
                          {locationGroup.locationGroup.location.address}
                        </Typography>
                      </Box>
                    )}
                  </ListItem>
                );
              })}
            </List>
            <Button
              sx={{ mt: 2 }}
              variant="outlined"
              onClick={handleLoadMore}
              fullWidth
              disabled={howManyToShow >= searchResults.length}
            >
              Load More
            </Button>
          </Scrollbar>
        )}
        {/* {searchResults?.unverifiedProviders.map(v => (
            <Box key={v.locationGroup._id}>
              <Typography>{v.locationGroup.name}</Typography>
            </Box>
          ))} */}
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mt: 2, textAlign: 'center' }}
        >
          Can't find the service provider you're looking for?
          <Link
            onClick={() => {
              onClose();
              inviteLocation();
            }}
            sx={{
              cursor: 'pointer',
              textDecoration: 'none !important',
              ml: 1,
            }}
          >
            Invite a vendor
          </Link>
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>

        {selectedServiceProvider && (
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={!selectedServiceProvider}
            onClick={async () => {
              if (selectedServiceProvider.verified) {
                const response = await assignServiceProvider({
                  requestId: params.requestId as Id<'requests'>,
                  locationGroupId: selectedServiceProvider.locationGroup._id,
                });
                if (response.success) {
                  toast.success(response.message);
                  onClose();
                } else {
                  toast.error(response.message);
                }
              } else {
                onClose();
                setUserInvitation({
                  dialogOpen: true,
                  selectedServiceProvider,
                });
              }
            }}
          >
            {selectedServiceProvider.verified ? 'Assign' : 'Call And Invite'}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
