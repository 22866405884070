import { getManyVia } from 'convex-helpers/server/relationships';
import { ConvexError } from 'convex/values';
import { QueryCtx } from '../_generated/server';
import { getUser } from '../functions/users';
import { getCustomUserIdentity } from './getCustomUserIdentity';

export async function getUserContext(ctx: QueryCtx) {
  const identity = await getCustomUserIdentity(ctx);
  if (!identity) throw new Error('User not authenticated');

  const { isSuperAdmin, companyOwner, subject } = identity;

  const user = await getUser(ctx, { subject });

  if (!user) throw new ConvexError('User not found');

  let companyId = user.companyId!;
  let primaryLocationGroupId = user.primaryLocationGroupId!;

  if (isSuperAdmin) {
    if (user.impersonatingCompanyId) {
      companyId = user.impersonatingCompanyId;
    }
    if (user.impersonatingLocationId) {
      primaryLocationGroupId = user.impersonatingLocationId;
    }
  }

  const [roles, company, primaryLocation] = await Promise.all([
    getManyVia(ctx.db, 'userRoles', 'roleDefinitionId', 'by_userId', user._id),
    ctx.db.get(companyId),
    ctx.db.get(primaryLocationGroupId),
  ]);

  if (!company || !primaryLocation) {
    throw new ConvexError('User must belong to a company and primary location');
  }

  return {
    user,
    primaryLocation,
    company,
    roles: roles.filter((s): s is NonNullable<typeof s> => s !== null),
    isSuperAdmin,
    companyOwner,
  };
}
