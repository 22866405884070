import LoadingButton from '@mui/lab/LoadingButton';
import { FormHelperText, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useParams } from '@tanstack/react-router';
import { useMutation, useQuery } from 'convex/react';
import debounce from 'lodash/debounce';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Map, { Layer, Source, ViewState } from 'react-map-gl';
import { CONFIG } from 'src/config-global';
import { api } from 'src/convex/_generated/api';
import { Id } from 'src/convex/_generated/dataModel';
import { Iconify } from 'src/minimal-theme/components/iconify/iconify';
import { MapControl } from '../../minimal-theme/components/map/map-control';
import { MapMarker } from '../../minimal-theme/components/map/map-marker';

interface SearchResult {
  place_name: string;
  center: [number, number];
}

interface DirectionsResponse {
  routes: {
    duration: number;
    geometry: {
      coordinates: [number, number][];
    };
  }[];
}

const routeLayer = {
  id: 'route',
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#3887be',
    'line-width': 5,
    'line-opacity': 0.75,
  },
};

export function RequestMap({
  address,
  handleChange,
  disabled,
  editing,
  error,
  helperText,
  draftSelectedVehicleId,
}: {
  address?: string;
  handleChange: (value: string) => void;
  disabled?: boolean;
  editing?: (value: boolean) => void;
  error?: boolean;
  helperText?: string;
  draftSelectedVehicleId?: Id<'vehicles'> | null;
}) {
  const params = useParams({ from: '/_auth/dashboard/requests/$requestId' });

  const request = useQuery(api.functions.requests.getRequest, {
    requestId: params.requestId as Id<'requests'>,
  });

  const technician = useQuery(
    api.functions.users.getUserById,
    request?.activeTechnicianId ? { id: request.activeTechnicianId } : 'skip'
  );

  const actualVehicleId = request?.vehicleId ?? draftSelectedVehicleId;

  const driverVehicle = useQuery(
    api.functions.vehicles.getVehicleById,
    actualVehicleId ? { vehicleId: actualVehicleId } : 'skip'
  );

  const actualAddress = driverVehicle?.location?.address ?? address;

  const [coordinates, setCoordinates] = useState<[number, number] | null>(
    actualAddress ? [0, 0] : null
  );

  const me = useQuery(api.functions.users.getMe);

  const [viewState, setViewState] = useState<Partial<ViewState>>({
    latitude: 0,
    longitude: 0,
    zoom: 14,
  });
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [inputValue, setInputValue] = useState(actualAddress || '');
  const [selectedValue, setSelectedValue] = useState<SearchResult | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [routeGeometry, setRouteGeometry] = useState<any>(null);
  const [travelTime, setTravelTime] = useState<number | null>(null);

  const updateVehicleLocation = useMutation(
    api.functions.vehicles.updateVehicleLocation
  );
  const updateRequestLocation = useMutation(
    api.functions.requests.updateRequestLocation
  );

  useEffect(() => {
    if (actualAddress) {
      setInputValue(actualAddress);
      setSelectedValue(
        actualAddress ? { place_name: actualAddress, center: [0, 0] } : null
      );
    }
  }, [actualAddress]);

  // Memoize the geocoding function to prevent recreating on every render
  const geocodeAddress = useMemo(
    () => async (query: string) => {
      if (!query) return null;

      try {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            query
          )}.json?access_token=${CONFIG.mapboxApiKey}&types=address`
        );
        const data = await response.json();
        return data.features?.[0] || null;
      } catch (error) {
        console.error('Geocoding error:', error);
        return null;
      }
    },
    []
  );

  // Debounced search function
  const searchAddress = useMemo(
    () =>
      debounce(async (query: string) => {
        if (!query) return;

        try {
          const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
              query
            )}.json?access_token=${CONFIG.mapboxApiKey}&types=address`
          );
          const data = await response.json();
          setSearchResults(
            data.features.map((feature: any) => ({
              place_name: feature.place_name,
              center: feature.center,
            }))
          );
        } catch (error) {
          console.error('Search error:', error);
          setSearchResults([]);
        }
      }),
    []
  );

  const reverseGeocode = async (latitude: number, longitude: number) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${CONFIG.mapboxApiKey}&types=address`
      );
      const data = await response.json();
      return data.features?.[0] || null;
    } catch (error) {
      console.error('Reverse geocoding error:', error);
      return null;
    }
  };

  const handleGetLocation = async () => {
    setIsLoading(true);
    if (!navigator.geolocation) {
      alert('Geolocation is not supported by your browser');
      return;
    }

    try {
      const position = await new Promise<GeolocationPosition>(
        (resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        }
      );

      const { latitude, longitude } = position.coords;
      const feature = await reverseGeocode(latitude, longitude);

      console.log('About to update location with:', {
        request,
        actualVehicleId,
      });

      if (request && actualVehicleId) {
        console.log('Updating vehicle:', actualVehicleId);
        await Promise.all([
          updateVehicleLocation({
            vehicleId: actualVehicleId,
            location: { longitude, latitude, address: feature?.place_name },
          }),
          updateRequestLocation({
            requestId: request._id,
            location: { longitude, latitude, address: feature?.place_name },
          }),
        ]);
      }

      if (!feature) {
        alert('Could not find address for this location');
        return;
      }

      const result = {
        place_name: feature.place_name,
        center: feature.center,
      };

      setCoordinates([latitude, longitude]);
      setInputValue(feature.place_name);
      setSearchResults([result]);
      setSelectedValue(result);
      handleChange(feature.place_name);
    } catch (error) {
      console.error('Error getting location:', error);
      alert('Error getting your location. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Initialize address on component mount
  useEffect(() => {
    const initializeAddress = async () => {
      // Set initial input value from prop
      setInputValue(address || '');

      if (!address) {
        return;
      }

      try {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            address
          )}.json?access_token=${CONFIG.mapboxApiKey}&types=address`
        );
        const data = await response.json();
        const feature = data.features?.[0];

        if (!feature) {
          console.error('Could not geocode address:', address);
          return;
        }

        const [long, lat] = feature.center;

        // Update all state at once
        setCoordinates([lat, long]);
        setViewState({
          latitude: lat,
          longitude: long,
          zoom: 14,
        });
        setSearchResults([
          {
            place_name: feature.place_name,
            center: feature.center,
          },
        ]);
        setSelectedValue({
          place_name: feature.place_name,
          center: feature.center,
        });
      } catch (error) {
        console.error('Error initializing address:', error);
      }
    };

    initializeAddress();
  }, [address]); // Add address as dependency

  const handleAddressChange = (_event: any, value: SearchResult | null) => {
    if (!value) return;

    const [long, lat] = value.center;
    setCoordinates([lat, long]);
    setInputValue(value.place_name);
    setSelectedValue(value);
    handleChange(value.place_name);
  };

  // Update viewState when coordinates change
  useEffect(() => {
    if (coordinates) {
      setViewState({
        latitude: coordinates[0],
        longitude: coordinates[1],
        zoom: 14,
      });
    }
  }, [coordinates]);

  const handleViewStateChange = useCallback(
    ({ viewState }: { viewState: ViewState }) => {
      setViewState(viewState);
    },
    []
  );

  const getDirections = async (
    start: [number, number],
    end: [number, number]
  ) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${start[0]},${start[1]};${end[0]},${end[1]}?geometries=geojson&access_token=${CONFIG.mapboxApiKey}`
      );
      const data: DirectionsResponse = await response.json();

      if (data.routes && data.routes[0]) {
        setRouteGeometry({
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: data.routes[0].geometry.coordinates,
          },
        });
        setTravelTime(Math.round(data.routes[0].duration / 60)); // Convert seconds to minutes
      }
    } catch (error) {
      console.error('Error getting directions:', error);
    }
  };

  // Add effect to handle technician route
  useEffect(() => {
    if (
      // user?.primaryRoleType === 'TECHNICIAN_PROVIDER' &&
      technician?.location &&
      coordinates &&
      technician?.location.longitude &&
      technician?.location.latitude
    ) {
      getDirections(
        [technician?.location.longitude, technician?.location.latitude],
        [coordinates[1], coordinates[0]]
      );
    }
  }, [technician, coordinates]);

  const getValidCoordinates = () => {
    if (driverVehicle?.location) {
      const { latitude, longitude } = driverVehicle.location;
      if (latitude != null && longitude != null) {
        return { latitude, longitude };
      }
    }

    if (
      Array.isArray(coordinates) &&
      coordinates.length >= 2 &&
      coordinates[0] != null &&
      coordinates[1] != null
    ) {
      return {
        latitude: coordinates[0],
        longitude: coordinates[1],
      };
    }

    return null;
  };

  const validCoordinates = getValidCoordinates();

  useEffect(() => {
    const validCoords = getValidCoordinates();
    if (validCoords) {
      setViewState({
        latitude: validCoords.latitude,
        longitude: validCoords.longitude,
        zoom: 14,
      });
    }
  }, [driverVehicle?.location, coordinates]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Autocomplete
          disabled={disabled}
          fullWidth
          options={searchResults}
          value={selectedValue}
          getOptionLabel={option => option.place_name}
          inputValue={inputValue}
          onInputChange={(_event, newValue) => {
            setInputValue(newValue);
            searchAddress(newValue);
          }}
          onChange={handleAddressChange}
          renderInput={params => (
            <TextField
              {...params}
              label="Address"
              variant="outlined"
              fullWidth
              error={error}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: error ? 'error.main' : undefined,
                  },
                },
                '& .MuiInputLabel-root': {
                  color: error ? 'error.main' : undefined,
                },
                '& .MuiInputBase-input': {
                  color: error ? 'error.main' : undefined,
                },
              }}
            />
          )}
          isOptionEqualToValue={(option, value) =>
            option.place_name === value.place_name
          }
        />
        {error && (
          <FormHelperText error sx={{ mt: 1, mx: 1.5, alignSelf: 'start' }}>
            {helperText}
          </FormHelperText>
        )}
        {(me?.primaryRoleType === 'DRIVER' ||
          me?.primaryRoleType === 'DRIVER_FLEET') && (
          <LoadingButton
            sx={{ width: 'fit-content', mt: 1, alignSelf: 'start' }}
            variant="text"
            color="primary"
            onClick={handleGetLocation}
            loading={isLoading}
            startIcon={<Iconify icon="f7:location-fill" />}
            loadingPosition="start"
          >
            Use Current Location
          </LoadingButton>
        )}
      </Box>
      {validCoordinates && (
        <Box sx={{ borderRadius: 2 }}>
          <Map
            mapboxAccessToken={CONFIG.mapboxApiKey}
            mapStyle="mapbox://styles/mapbox/light-v10"
            {...viewState}
            onMove={handleViewStateChange}
            style={{ width: '100%', height: 400, borderRadius: '16px' }}
          >
            <MapControl />
            <MapMarker
              latitude={validCoordinates.latitude}
              longitude={validCoordinates.longitude}
              onClick={() => {
                // Handle marker click
                if (editing) {
                  editing(true);
                }
              }}
            />

            {/* Add technician marker and route */}
            {technician?.location && (
              <>
                <MapMarker
                  latitude={technician.location.latitude}
                  longitude={technician.location.longitude}
                  color="success"
                />
                {routeGeometry && (
                  <Source type="geojson" data={routeGeometry}>
                    <Layer {...(routeLayer as any)} />
                  </Source>
                )}
              </>
            )}
          </Map>
        </Box>
      )}

      {travelTime !== null && (
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Estimated travel time: {travelTime} minutes
          </Typography>
        </Box>
      )}
    </>
  );
}
