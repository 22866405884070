import { createFileRoute } from '@tanstack/react-router';
import { api } from 'src/convex/_generated/api';
import { MyParticipationsView } from 'src/sections/request/view/MyParticipationsView';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute(
  '/_auth/dashboard/requests/my-participations'
)({
  beforeLoad: ({ context }) =>
    checkRouteAccess(context, routeAccessConfigs.allRolesExceptSuperAdmin),
  loader: async ({ context }) => {
    return await context.convex.query(
      api.functions.requests.getRequestsParticipatingIn,
      {}
    );
  },
  component: MyParticipationsView,
});
