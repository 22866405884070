import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Link,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useParams } from '@tanstack/react-router';
import { useMutation, useQuery } from 'convex/react';
import { useState } from 'react';
import { toast } from 'sonner';
import { api } from 'src/convex/_generated/api';

import { Doc, Id } from 'src/convex/_generated/dataModel';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Label } from 'src/minimal-theme/components/label';

export function RequestParticipants() {
  const params = useParams({ from: '/_auth/dashboard/requests/$requestId' });
  const request = useQuery(api.functions.requests.getRequest, {
    requestId: params.requestId as Id<'requests'>,
  });

  const me = useQuery(api.functions.users.getMe);

  if (!request) return null;

  const sections = [
    {
      title: 'Driver',
      participant: request.activeDriver,
    },
    {
      title: 'Fleet Dispatcher',
      participant: request.fleetDispatcher,
    },
    {
      title: 'Service Dispatcher',
      participant: request.serviceDispatcher,
    },
    {
      title: 'Technician',
      participant: request.technician,
    },
  ].filter(section => section.participant); // Only keep sections with participants

  if (sections.length === 0) return null;

  const canPing =
    me?._id === request.activeFleetDispatcherId ||
    me?._id === request.activeServiceDispatcherId;

  return (
    <Card>
      <CardHeader title="Participants" />
      <Stack spacing={3} sx={{ p: 3 }}>
        {sections.map(({ title, participant }) => (
          <Participant
            key={title}
            participant={participant!}
            title={title}
            isCurrentAssignee={participant?._id === request.currentAssignedToId}
            canPing={canPing}
            requestId={request._id}
          />
        ))}
      </Stack>
    </Card>
  );
}

type ParticipantProps = {
  participant: Doc<'users'>;
  title: string;
  isCurrentAssignee: boolean;
  canPing: boolean;
  requestId: Id<'requests'>;
};

function Participant({
  participant,
  title,
  isCurrentAssignee,
  canPing,
  requestId,
}: ParticipantProps) {
  const theme = useTheme();

  const sendPing = useMutation(
    api.functions.requests.sendPingReminderToAssignee
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const locationGroup = useQuery(api.functions.companies.getLocationById, {
    id: participant?.primaryLocationGroupId as Id<'groups'>,
  });

  const handleSendPing = async () => {
    try {
      const resp = await sendPing({ requestId });

      if (resp.success) {
        toast.success(resp.message);
      }
    } catch (e) {
      toast.error('Something went wrong with your ping request');
    }
    handleClose();
  };

  const id = `participant-menu-button-${participant._id}`;
  return (
    <>
      <ListItemButton
        disableRipple
        sx={{
          cursor: 'default',
          '&:hover': { backgroundColor: 'transparent' },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              border: isCurrentAssignee
                ? `4px solid ${theme.vars.palette.primary.main}`
                : 'none',
              borderRadius: '50%',
            }}
          >
            <Avatar src={participant?.clerkUser.imageUrl}>
              {participant?.clerkUser.firstName?.[0]}
            </Avatar>
          </Box>
          <Box sx={{ flexGrow: 1, minWidth: 0 }}>
            {isCurrentAssignee && <Label color="primary">Assigned</Label>}
            <Box sx={{ display: 'flex' }}>
              <Typography variant="subtitle2" noWrap>
                {title} — {locationGroup?.name}
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {participant?.clerkUser.fullName}
            </Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              <Link
                href={`tel:${participant?.clerkUser.primaryPhoneNumber?.phoneNumber}`}
              >
                {participant?.clerkUser.primaryPhoneNumber?.phoneNumber}
              </Link>
            </Typography>
          </Box>
          {isCurrentAssignee && canPing && (
            <Box>
              <ListItemButton
                onClick={handleClick}
                id={id}
                sx={{
                  borderRadius: 1,
                  minWidth: 'auto',
                  padding: 1,
                }}
              >
                <Iconify icon="mdi:bell-ring" />
              </ListItemButton>
            </Box>
          )}
        </Stack>
      </ListItemButton>
      <Menu
        id={`participant-${participant._id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': id,
        }}
      >
        <MenuItem onClick={handleSendPing}>
          <ListItemText>Send Reminder</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
