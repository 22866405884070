import { Box, CardHeader, Fab, InputBase } from '@mui/material';
import { useParams } from '@tanstack/react-router';
import { useMutation, useQuery } from 'convex/react';
import { useMemo, useState } from 'react';
import { toast } from 'sonner';
import { api } from 'src/convex/_generated/api';
import { Doc, Id } from 'src/convex/_generated/dataModel';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { useCollapseNav } from 'src/sections/chat/hooks/use-collapse-nav';
import {
  IChatAttachment,
  IChatMessage,
  IChatParticipant,
} from 'src/types/chat';
import { ChatMessageList } from '../chat-message-list';
import { Layout } from '../layout';

export function RequestChat() {
  const params = useParams({ from: '/_auth/dashboard/requests/$requestId' });
  const request = useQuery(api.functions.requests.getRequest, {
    requestId: params.requestId as Id<'requests'>,
  });
  const me = useQuery(api.functions.users.getMe);

  const messages = useQuery(
    api.functions.requestChats.getRequestMessages,
    request
      ? {
          requestId: request._id,
        }
      : 'skip'
  );

  const participants = useMemo(() => {
    if (!request) return [];

    return [
      mapUserToChatParticipant(request.activeDriver, 'Driver'),
      mapUserToChatParticipant(request.fleetDispatcher, 'Fleet Dispatcher'),
      mapUserToChatParticipant(request.serviceDispatcher, 'Service Dispatcher'),
      mapUserToChatParticipant(request.technician, 'Technician'),
    ].filter((p): p is IChatParticipant => p !== null);
  }, [request]);

  const sendMessage = useMutation(api.functions.requestChats.sendMessage);
  const [message, setMessage] = useState('');
  // const messagesEndRef = useRef<HTMLDivElement>(null);

  // Scroll to bottom when new messages arrive
  // useEffect(() => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  // }, [messages]);

  const handleSend = async () => {
    if (!message.trim() || !request) return;

    try {
      await sendMessage({
        requestId: request._id,
        content: message,
        type: 'GENERAL',
      });
      setMessage('');
    } catch (error) {
      console.error('Failed to send message:', error);
      toast.error('Problem occurred when sending message, please try again');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const roomNav = useCollapseNav();

  const formattedMessages = formatChatMessages(messages ?? []);

  return (
    <Layout
      sx={{
        // minHeight: 500,
        borderRadius: 2,
        position: 'relative',
        bgcolor: 'background.paper',
        boxShadow: theme => theme.customShadows.card,
      }}
      slots={{
        // header: (
        //   <ChatHeaderDetail
        //     collapseNav={roomNav}
        //     participants={participants}
        //     loading={!messages}
        //   />
        // ),
        header: (
          <CardHeader
            title="Request Chat"
            sx={{ pl: '4px', pt: '4px', pb: 0, pr: 0 }}
          />
        ),
        nav: null,
        main: (
          <>
            <ChatMessageList
              messages={formattedMessages}
              participants={participants}
              loading={!messages}
            />
            {/* <ChatMessageInput
              recipients={participants}
              onAddRecipients={() => {}}
              selectedConversationId={request?._id ?? ''}
              disabled={!participants.length}
            /> */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
                borderTop: theme => `solid 1px ${theme.vars.palette.divider}`,
                px: 3,
                py: 2,
              }}
            >
              <InputBase
                name="chat-message"
                id="chat-message-input"
                value={message}
                onKeyUp={handleKeyPress}
                onChange={handleChangeMessage}
                placeholder="Type a message"
                multiline
                disabled={false}
                fullWidth
                // fullWidth
                // startAdornment={
                //   <IconButton>
                //     <Iconify icon="eva:smiling-face-fill" />
                //   </IconButton>
                // }
                // endAdornment={
                //   <Stack direction="row" sx={{ flexShrink: 0 }}>
                //     <IconButton onClick={handleAttach}>
                //       <Iconify icon="solar:gallery-add-bold" />
                //     </IconButton>
                //     <IconButton onClick={handleAttach}>
                //       <Iconify icon="eva:attach-2-fill" />
                //     </IconButton>
                //     <IconButton>
                //       <Iconify icon="solar:microphone-bold" />
                //     </IconButton>
                //   </Stack>
                // }
              />

              <Fab size="small" sx={{ width: 45 }} onClick={handleSend}>
                <Iconify icon="mingcute:arrow-up-fill" />
              </Fab>

              {/* <input type="file" ref={fileRef} style={{ display: 'none' }} /> */}
            </Box>
          </>
        ),
        // details: (
        //   <ChatRoom
        //     collapseNav={roomNav}
        //     participants={participants}
        //     loading={!messages}
        //     messages={formattedMessages}
        //   />
        // ),
        details: null,
      }}
    />
  );
}

function mapUserToChatParticipant(
  user: Doc<'users'> | null,
  role: string
): IChatParticipant | null {
  if (!user) return null;

  return {
    id: user._id,
    name: user.clerkUser.fullName,
    role,
    email: user.clerkUser.primaryEmailAddress?.emailAddress ?? '',
    phoneNumber: user.clerkUser.primaryPhoneNumber?.phoneNumber ?? '',
    avatarUrl: user.clerkUser.imageUrl ?? '',
    lastActivity: new Date().toISOString(),
    status: 'offline', // Default to offline since we're not tracking presence yet
  };
}

/**
 * Formats raw request chat messages into standardized chat message format
 */
export function formatChatMessages(
  messages: Array<
    Doc<'requestChats'> & {
      user?: {
        name: string;
        imageUrl: string;
      };
    }
  >
): IChatMessage[] {
  return messages.map(message => {
    // Extract creation time components
    const creationDate = new Date(message._creationTime);

    // const attachments: IChatAttachment[] = message.attachments?.map(attachment => ({
    //   name: `File-${attachment.fileId}`, // Generate a name for the file
    //   size: 0, // Would need actual file size from storage
    //   type: attachment.type,
    //   path: `/api/files/${attachment.fileId}`,
    //   preview: `/api/files/${attachment.fileId}`,
    //   createdAt: message._creationTime,
    //   modifiedAt: message._creationTime
    // })) || [];

    const attachments: IChatAttachment[] = [];

    return {
      id: message._id,
      body: message.content,
      senderId: message.userId,
      contentType: message.type,
      createdAt: message._creationTime,
      attachments,
    };
  });
}
