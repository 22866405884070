import { zid } from 'convex-helpers/server/zod';
import { internal } from '../_generated/api';
import { zInternalAction } from '../functions/helpers/zodHelpers';

export const handleServiceProviderTimeout = zInternalAction({
  args: {
    requestId: zid('requests'),
    originalDispatcherId: zid('users'),
    locationGroupId: zid('groups'),
  },
  handler: async (
    ctx,
    { requestId, originalDispatcherId, locationGroupId }
  ) => {
    // Get current request state
    const request = await ctx.runQuery(
      internal.functions.requests.getRequestInternally,
      {
        requestId,
      }
    );

    if (!request) {
      console.warn(`Request ${requestId} not found during timeout check`);
      return;
    }

    // Only proceed if request is still in queued state for this service provider
    if (
      request.status === 'ACTIVE' &&
      request.currentPhase === 'MECHANIC_DISPATCH' &&
      request.currentStepType === 'WITH_SERVICE_PROVIDER_DISPATCH' &&
      request.currentStepState === 'QUEUED' &&
      request.mechanicDispatchGroupId === locationGroupId
    ) {
      // Revert request back to fleet dispatch
      await ctx.runMutation(
        internal.functions.requests.timeoutServiceProviderAssignment,
        {
          requestId,
          originalDispatcherId,
          locationGroupId,
        }
      );
    }
  },
});

import { z } from 'zod';

// Time constants
export const LOCATION_CHECK_INTERVAL = 15 * 60 * 1000; // 15 minutes
const LOCATION_STALENESS_THRESHOLD = 10 * 60 * 1000; // 10 minutes
const MAX_MONITORING_DURATION = 4 * 60 * 60 * 1000; // 4 hours - prevent infinite monitoring

export const monitorTechnicianLocation = zInternalAction({
  args: {
    requestId: zid('requests'),
    technicianId: zid('users'),
    monitoringStartTime: z.number().optional(),
  },
  handler: async (ctx, { requestId, technicianId, monitoringStartTime }) => {
    // Get current request and technician state
    const [request, technician] = await Promise.all([
      ctx.runQuery(internal.functions.requests.getRequestInternally, {
        requestId,
      }),
      ctx.runQuery(internal.functions.users.getUserByIdInternally, {
        id: technicianId,
      }),
    ]);

    if (!request || !technician) {
      console.warn('Request or technician not found during location check');
      return;
    }

    // Stop monitoring if request is no longer active or technician is no longer assigned
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'MECHANIC_SERVICE' ||
      request.currentStepType !== 'TECHNICIAN_ACCEPTED' ||
      request.activeTechnicianId !== technicianId ||
      request.currentAssignedToId !== technicianId
    ) {
      return;
    }

    // Check if we've been monitoring too long
    const startTime = monitoringStartTime || Date.now();
    if (Date.now() - startTime > MAX_MONITORING_DURATION) {
      console.warn(
        `Stopping location monitoring for request ${requestId} - max duration exceeded`
      );
      return;
    }

    // Check if location is stale
    const locationIsStale = isLocationStale(
      technician.location?.lastUpdated,
      LOCATION_STALENESS_THRESHOLD
    );

    if (locationIsStale) {
      // Send notification TODO: Do we send email or just SMS?

      if (technician.clerkUser.primaryPhoneNumber?.phoneNumber) {
        await ctx.runAction(internal.actions.sendSms.sendSmsAction, {
          phoneNumber: technician.clerkUser.primaryPhoneNumber?.phoneNumber,
          message: `Please share location for active job: ${request.caseNumber}`,
          requestId: request._id,
        });
      } else {
        throw new Error(
          `Technician ${technician._id} does not have a phone number`
        );
      }
    }

    console.log(
      'Sent SMS location sharing reminder to technician and scheduling another'
    );

    // Schedule next check
    await ctx.scheduler.runAfter(
      LOCATION_CHECK_INTERVAL,
      internal.nonnodeactions.requests.monitorTechnicianLocation,
      {
        requestId,
        technicianId,
        monitoringStartTime: startTime,
      }
    );
  },
});

function isLocationStale(
  lastUpdated: string | undefined,
  threshold: number
): boolean {
  if (!lastUpdated) return true;

  const lastUpdateTime = new Date(lastUpdated).getTime();
  return Date.now() - lastUpdateTime > threshold;
}
