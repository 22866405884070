import { createLazyFileRoute, linkOptions } from '@tanstack/react-router';
import Page from 'src/pages/sign-up/sign-up-final';

export const Route = createLazyFileRoute('/_incomplete-sign-up/sign-up-final')({
  component: Page,
});

export const signUpFinalLinkOptions = linkOptions({
  to: '/sign-up-final',
});
