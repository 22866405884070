import Chip from '@mui/material/Chip';
import type { SxProps, Theme } from '@mui/material/styles';
import { useCallback } from 'react';
import {
  chipProps,
  FiltersBlock,
  FiltersResult,
} from 'src/minimal-theme/components/filters-result';
import type { UseSetStateReturn } from 'src/minimal-theme/hooks/use-set-state';
import type { RequestTableFilters } from 'src/types/request';
import { fDateRangeShortLabel } from 'src/utils/format-time';
import { getStatusColor, getStatusLabel } from 'src/utils/helper';

type RequestTableFiltersResultProps = {
  totalResults: number;
  sx?: SxProps<Theme>;
  onResetPage: () => void;
  filters: UseSetStateReturn<RequestTableFilters>;
};

export function RequestTableFiltersResult({
  filters,
  totalResults,
  onResetPage,
  sx,
}: RequestTableFiltersResultProps) {
  const handleRemoveKeyword = useCallback(() => {
    onResetPage();
    filters.setState({ name: '' });
  }, [filters, onResetPage]);

  const handleRemoveStatus = useCallback(() => {
    onResetPage();
    filters.setState({ status: 'all' });
  }, [filters, onResetPage]);

  const handleRemoveDate = useCallback(() => {
    onResetPage();
    filters.setState({ startDate: null, endDate: null });
  }, [filters, onResetPage]);

  const handleReset = useCallback(() => {
    onResetPage();
    filters.onResetState();
  }, [filters, onResetPage]);

  return (
    <FiltersResult totalResults={totalResults} onReset={handleReset} sx={sx}>
      <FiltersBlock label="Status:" isShow={filters.state.status !== 'all'}>
        <Chip
          color={getStatusColor(filters.state.status)}
          {...chipProps}
          label={getStatusLabel(filters.state.status)}
          onDelete={handleRemoveStatus}
          sx={{ textTransform: 'capitalize' }}
        />
      </FiltersBlock>

      <FiltersBlock
        label="Date:"
        isShow={Boolean(filters.state.startDate && filters.state.endDate)}
      >
        <Chip
          {...chipProps}
          label={fDateRangeShortLabel(
            filters.state.startDate,
            filters.state.endDate
          )}
          onDelete={handleRemoveDate}
        />
      </FiltersBlock>

      <FiltersBlock label="Keyword:" isShow={!!filters.state.name}>
        <Chip
          {...chipProps}
          label={filters.state.name}
          onDelete={handleRemoveKeyword}
        />
      </FiltersBlock>
    </FiltersResult>
  );
}
