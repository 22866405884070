/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SignUpImport } from './routes/sign-up'
import { Route as SignOutImport } from './routes/sign-out'
import { Route as SignInImport } from './routes/sign-in'
import { Route as PendingVerificationImport } from './routes/_pending-verification'
import { Route as IncompleteSignUpImport } from './routes/_incomplete-sign-up'
import { Route as AuthImport } from './routes/_auth'
import { Route as IndexImport } from './routes/index'
import { Route as Auth403Import } from './routes/_auth.403'
import { Route as AuthDashboardIndexImport } from './routes/_auth.dashboard.index'
import { Route as AuthDashboardMapImport } from './routes/_auth.dashboard.map'
import { Route as AuthDashboardInvoicesImport } from './routes/_auth.dashboard.invoices'
import { Route as AuthDashboardTrucksIndexImport } from './routes/_auth.dashboard.trucks.index'
import { Route as AuthDashboardSettingsIndexImport } from './routes/_auth.dashboard.settings.index'
import { Route as AuthDashboardRequestsIndexImport } from './routes/_auth.dashboard.requests.index'
import { Route as AuthDashboardMechanicsIndexImport } from './routes/_auth.dashboard.mechanics.index'
import { Route as AuthDashboardLocationsIndexImport } from './routes/_auth.dashboard.locations.index'
import { Route as AuthDashboardDriversIndexImport } from './routes/_auth.dashboard.drivers.index'
import { Route as AuthDashboardDispatchersIndexImport } from './routes/_auth.dashboard.dispatchers.index'
import { Route as AuthDashboardCompaniesIndexImport } from './routes/_auth.dashboard.companies.index'
import { Route as AuthDashboardTrucksNewImport } from './routes/_auth.dashboard.trucks.new'
import { Route as AuthDashboardTrucksTruckIdImport } from './routes/_auth.dashboard.trucks.$truckId'
import { Route as AuthDashboardRequestsQueueImport } from './routes/_auth.dashboard.requests.queue'
import { Route as AuthDashboardRequestsMyParticipationsImport } from './routes/_auth.dashboard.requests.my-participations'
import { Route as AuthDashboardRequestsMyAssignmentsImport } from './routes/_auth.dashboard.requests.my-assignments'
import { Route as AuthDashboardRequestsRequestIdImport } from './routes/_auth.dashboard.requests.$requestId'
import { Route as AuthDashboardMechanicsNewImport } from './routes/_auth.dashboard.mechanics.new'
import { Route as AuthDashboardMechanicsMechanicIdImport } from './routes/_auth.dashboard.mechanics.$mechanicId'
import { Route as AuthDashboardLocationsNewImport } from './routes/_auth.dashboard.locations.new'
import { Route as AuthDashboardLocationsLocationIdImport } from './routes/_auth.dashboard.locations.$locationId'
import { Route as AuthDashboardInvoicesStripeReauthImport } from './routes/_auth.dashboard.invoices.stripe-reauth'
import { Route as AuthDashboardInvoicesStripeOnboardingCompleteImport } from './routes/_auth.dashboard.invoices.stripe-onboarding-complete'
import { Route as AuthDashboardDriversNewImport } from './routes/_auth.dashboard.drivers.new'
import { Route as AuthDashboardDriversDriverIdImport } from './routes/_auth.dashboard.drivers.$driverId'
import { Route as AuthDashboardDispatchersNewImport } from './routes/_auth.dashboard.dispatchers.new'
import { Route as AuthDashboardDispatchersDispatcherIdImport } from './routes/_auth.dashboard.dispatchers.$dispatcherId'
import { Route as AuthDashboardCompaniesNewImport } from './routes/_auth.dashboard.companies.new'
import { Route as AuthDashboardCompaniesCompanyIdImport } from './routes/_auth.dashboard.companies.$companyId'

// Create Virtual Routes

const PendingVerificationCompanyVerificationLazyImport = createFileRoute(
  '/_pending-verification/company-verification',
)()
const IncompleteSignUpSignUpFinalLazyImport = createFileRoute(
  '/_incomplete-sign-up/sign-up-final',
)()
const AuthDashboardPartsLazyImport = createFileRoute('/_auth/dashboard/parts')()
const AuthDashboardChatLazyImport = createFileRoute('/_auth/dashboard/chat')()

// Create/Update Routes

const SignUpRoute = SignUpImport.update({
  id: '/sign-up',
  path: '/sign-up',
  getParentRoute: () => rootRoute,
} as any)

const SignOutRoute = SignOutImport.update({
  id: '/sign-out',
  path: '/sign-out',
  getParentRoute: () => rootRoute,
} as any)

const SignInRoute = SignInImport.update({
  id: '/sign-in',
  path: '/sign-in',
  getParentRoute: () => rootRoute,
} as any)

const PendingVerificationRoute = PendingVerificationImport.update({
  id: '/_pending-verification',
  getParentRoute: () => rootRoute,
} as any)

const IncompleteSignUpRoute = IncompleteSignUpImport.update({
  id: '/_incomplete-sign-up',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const PendingVerificationCompanyVerificationLazyRoute =
  PendingVerificationCompanyVerificationLazyImport.update({
    id: '/company-verification',
    path: '/company-verification',
    getParentRoute: () => PendingVerificationRoute,
  } as any).lazy(() =>
    import('./routes/_pending-verification.company-verification.lazy').then(
      (d) => d.Route,
    ),
  )

const IncompleteSignUpSignUpFinalLazyRoute =
  IncompleteSignUpSignUpFinalLazyImport.update({
    id: '/sign-up-final',
    path: '/sign-up-final',
    getParentRoute: () => IncompleteSignUpRoute,
  } as any).lazy(() =>
    import('./routes/_incomplete-sign-up.sign-up-final.lazy').then(
      (d) => d.Route,
    ),
  )

const Auth403Route = Auth403Import.update({
  id: '/403',
  path: '/403',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDashboardIndexRoute = AuthDashboardIndexImport.update({
  id: '/dashboard/',
  path: '/dashboard/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDashboardPartsLazyRoute = AuthDashboardPartsLazyImport.update({
  id: '/dashboard/parts',
  path: '/dashboard/parts',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth.dashboard.parts.lazy').then((d) => d.Route),
)

const AuthDashboardChatLazyRoute = AuthDashboardChatLazyImport.update({
  id: '/dashboard/chat',
  path: '/dashboard/chat',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth.dashboard.chat.lazy').then((d) => d.Route),
)

const AuthDashboardMapRoute = AuthDashboardMapImport.update({
  id: '/dashboard/map',
  path: '/dashboard/map',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDashboardInvoicesRoute = AuthDashboardInvoicesImport.update({
  id: '/dashboard/invoices',
  path: '/dashboard/invoices',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDashboardTrucksIndexRoute = AuthDashboardTrucksIndexImport.update({
  id: '/dashboard/trucks/',
  path: '/dashboard/trucks/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDashboardSettingsIndexRoute = AuthDashboardSettingsIndexImport.update(
  {
    id: '/dashboard/settings/',
    path: '/dashboard/settings/',
    getParentRoute: () => AuthRoute,
  } as any,
)

const AuthDashboardRequestsIndexRoute = AuthDashboardRequestsIndexImport.update(
  {
    id: '/dashboard/requests/',
    path: '/dashboard/requests/',
    getParentRoute: () => AuthRoute,
  } as any,
)

const AuthDashboardMechanicsIndexRoute =
  AuthDashboardMechanicsIndexImport.update({
    id: '/dashboard/mechanics/',
    path: '/dashboard/mechanics/',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthDashboardLocationsIndexRoute =
  AuthDashboardLocationsIndexImport.update({
    id: '/dashboard/locations/',
    path: '/dashboard/locations/',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthDashboardDriversIndexRoute = AuthDashboardDriversIndexImport.update({
  id: '/dashboard/drivers/',
  path: '/dashboard/drivers/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDashboardDispatchersIndexRoute =
  AuthDashboardDispatchersIndexImport.update({
    id: '/dashboard/dispatchers/',
    path: '/dashboard/dispatchers/',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthDashboardCompaniesIndexRoute =
  AuthDashboardCompaniesIndexImport.update({
    id: '/dashboard/companies/',
    path: '/dashboard/companies/',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthDashboardTrucksNewRoute = AuthDashboardTrucksNewImport.update({
  id: '/dashboard/trucks/new',
  path: '/dashboard/trucks/new',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDashboardTrucksTruckIdRoute = AuthDashboardTrucksTruckIdImport.update(
  {
    id: '/dashboard/trucks/$truckId',
    path: '/dashboard/trucks/$truckId',
    getParentRoute: () => AuthRoute,
  } as any,
)

const AuthDashboardRequestsQueueRoute = AuthDashboardRequestsQueueImport.update(
  {
    id: '/dashboard/requests/queue',
    path: '/dashboard/requests/queue',
    getParentRoute: () => AuthRoute,
  } as any,
)

const AuthDashboardRequestsMyParticipationsRoute =
  AuthDashboardRequestsMyParticipationsImport.update({
    id: '/dashboard/requests/my-participations',
    path: '/dashboard/requests/my-participations',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthDashboardRequestsMyAssignmentsRoute =
  AuthDashboardRequestsMyAssignmentsImport.update({
    id: '/dashboard/requests/my-assignments',
    path: '/dashboard/requests/my-assignments',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthDashboardRequestsRequestIdRoute =
  AuthDashboardRequestsRequestIdImport.update({
    id: '/dashboard/requests/$requestId',
    path: '/dashboard/requests/$requestId',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthDashboardMechanicsNewRoute = AuthDashboardMechanicsNewImport.update({
  id: '/dashboard/mechanics/new',
  path: '/dashboard/mechanics/new',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDashboardMechanicsMechanicIdRoute =
  AuthDashboardMechanicsMechanicIdImport.update({
    id: '/dashboard/mechanics/$mechanicId',
    path: '/dashboard/mechanics/$mechanicId',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthDashboardLocationsNewRoute = AuthDashboardLocationsNewImport.update({
  id: '/dashboard/locations/new',
  path: '/dashboard/locations/new',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDashboardLocationsLocationIdRoute =
  AuthDashboardLocationsLocationIdImport.update({
    id: '/dashboard/locations/$locationId',
    path: '/dashboard/locations/$locationId',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthDashboardInvoicesStripeReauthRoute =
  AuthDashboardInvoicesStripeReauthImport.update({
    id: '/stripe-reauth',
    path: '/stripe-reauth',
    getParentRoute: () => AuthDashboardInvoicesRoute,
  } as any)

const AuthDashboardInvoicesStripeOnboardingCompleteRoute =
  AuthDashboardInvoicesStripeOnboardingCompleteImport.update({
    id: '/stripe-onboarding-complete',
    path: '/stripe-onboarding-complete',
    getParentRoute: () => AuthDashboardInvoicesRoute,
  } as any)

const AuthDashboardDriversNewRoute = AuthDashboardDriversNewImport.update({
  id: '/dashboard/drivers/new',
  path: '/dashboard/drivers/new',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDashboardDriversDriverIdRoute =
  AuthDashboardDriversDriverIdImport.update({
    id: '/dashboard/drivers/$driverId',
    path: '/dashboard/drivers/$driverId',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthDashboardDispatchersNewRoute =
  AuthDashboardDispatchersNewImport.update({
    id: '/dashboard/dispatchers/new',
    path: '/dashboard/dispatchers/new',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthDashboardDispatchersDispatcherIdRoute =
  AuthDashboardDispatchersDispatcherIdImport.update({
    id: '/dashboard/dispatchers/$dispatcherId',
    path: '/dashboard/dispatchers/$dispatcherId',
    getParentRoute: () => AuthRoute,
  } as any)

const AuthDashboardCompaniesNewRoute = AuthDashboardCompaniesNewImport.update({
  id: '/dashboard/companies/new',
  path: '/dashboard/companies/new',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDashboardCompaniesCompanyIdRoute =
  AuthDashboardCompaniesCompanyIdImport.update({
    id: '/dashboard/companies/$companyId',
    path: '/dashboard/companies/$companyId',
    getParentRoute: () => AuthRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_incomplete-sign-up': {
      id: '/_incomplete-sign-up'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof IncompleteSignUpImport
      parentRoute: typeof rootRoute
    }
    '/_pending-verification': {
      id: '/_pending-verification'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof PendingVerificationImport
      parentRoute: typeof rootRoute
    }
    '/sign-in': {
      id: '/sign-in'
      path: '/sign-in'
      fullPath: '/sign-in'
      preLoaderRoute: typeof SignInImport
      parentRoute: typeof rootRoute
    }
    '/sign-out': {
      id: '/sign-out'
      path: '/sign-out'
      fullPath: '/sign-out'
      preLoaderRoute: typeof SignOutImport
      parentRoute: typeof rootRoute
    }
    '/sign-up': {
      id: '/sign-up'
      path: '/sign-up'
      fullPath: '/sign-up'
      preLoaderRoute: typeof SignUpImport
      parentRoute: typeof rootRoute
    }
    '/_auth/403': {
      id: '/_auth/403'
      path: '/403'
      fullPath: '/403'
      preLoaderRoute: typeof Auth403Import
      parentRoute: typeof AuthImport
    }
    '/_incomplete-sign-up/sign-up-final': {
      id: '/_incomplete-sign-up/sign-up-final'
      path: '/sign-up-final'
      fullPath: '/sign-up-final'
      preLoaderRoute: typeof IncompleteSignUpSignUpFinalLazyImport
      parentRoute: typeof IncompleteSignUpImport
    }
    '/_pending-verification/company-verification': {
      id: '/_pending-verification/company-verification'
      path: '/company-verification'
      fullPath: '/company-verification'
      preLoaderRoute: typeof PendingVerificationCompanyVerificationLazyImport
      parentRoute: typeof PendingVerificationImport
    }
    '/_auth/dashboard/invoices': {
      id: '/_auth/dashboard/invoices'
      path: '/dashboard/invoices'
      fullPath: '/dashboard/invoices'
      preLoaderRoute: typeof AuthDashboardInvoicesImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/map': {
      id: '/_auth/dashboard/map'
      path: '/dashboard/map'
      fullPath: '/dashboard/map'
      preLoaderRoute: typeof AuthDashboardMapImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/chat': {
      id: '/_auth/dashboard/chat'
      path: '/dashboard/chat'
      fullPath: '/dashboard/chat'
      preLoaderRoute: typeof AuthDashboardChatLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/parts': {
      id: '/_auth/dashboard/parts'
      path: '/dashboard/parts'
      fullPath: '/dashboard/parts'
      preLoaderRoute: typeof AuthDashboardPartsLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/': {
      id: '/_auth/dashboard/'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof AuthDashboardIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/companies/$companyId': {
      id: '/_auth/dashboard/companies/$companyId'
      path: '/dashboard/companies/$companyId'
      fullPath: '/dashboard/companies/$companyId'
      preLoaderRoute: typeof AuthDashboardCompaniesCompanyIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/companies/new': {
      id: '/_auth/dashboard/companies/new'
      path: '/dashboard/companies/new'
      fullPath: '/dashboard/companies/new'
      preLoaderRoute: typeof AuthDashboardCompaniesNewImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/dispatchers/$dispatcherId': {
      id: '/_auth/dashboard/dispatchers/$dispatcherId'
      path: '/dashboard/dispatchers/$dispatcherId'
      fullPath: '/dashboard/dispatchers/$dispatcherId'
      preLoaderRoute: typeof AuthDashboardDispatchersDispatcherIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/dispatchers/new': {
      id: '/_auth/dashboard/dispatchers/new'
      path: '/dashboard/dispatchers/new'
      fullPath: '/dashboard/dispatchers/new'
      preLoaderRoute: typeof AuthDashboardDispatchersNewImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/drivers/$driverId': {
      id: '/_auth/dashboard/drivers/$driverId'
      path: '/dashboard/drivers/$driverId'
      fullPath: '/dashboard/drivers/$driverId'
      preLoaderRoute: typeof AuthDashboardDriversDriverIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/drivers/new': {
      id: '/_auth/dashboard/drivers/new'
      path: '/dashboard/drivers/new'
      fullPath: '/dashboard/drivers/new'
      preLoaderRoute: typeof AuthDashboardDriversNewImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/invoices/stripe-onboarding-complete': {
      id: '/_auth/dashboard/invoices/stripe-onboarding-complete'
      path: '/stripe-onboarding-complete'
      fullPath: '/dashboard/invoices/stripe-onboarding-complete'
      preLoaderRoute: typeof AuthDashboardInvoicesStripeOnboardingCompleteImport
      parentRoute: typeof AuthDashboardInvoicesImport
    }
    '/_auth/dashboard/invoices/stripe-reauth': {
      id: '/_auth/dashboard/invoices/stripe-reauth'
      path: '/stripe-reauth'
      fullPath: '/dashboard/invoices/stripe-reauth'
      preLoaderRoute: typeof AuthDashboardInvoicesStripeReauthImport
      parentRoute: typeof AuthDashboardInvoicesImport
    }
    '/_auth/dashboard/locations/$locationId': {
      id: '/_auth/dashboard/locations/$locationId'
      path: '/dashboard/locations/$locationId'
      fullPath: '/dashboard/locations/$locationId'
      preLoaderRoute: typeof AuthDashboardLocationsLocationIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/locations/new': {
      id: '/_auth/dashboard/locations/new'
      path: '/dashboard/locations/new'
      fullPath: '/dashboard/locations/new'
      preLoaderRoute: typeof AuthDashboardLocationsNewImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/mechanics/$mechanicId': {
      id: '/_auth/dashboard/mechanics/$mechanicId'
      path: '/dashboard/mechanics/$mechanicId'
      fullPath: '/dashboard/mechanics/$mechanicId'
      preLoaderRoute: typeof AuthDashboardMechanicsMechanicIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/mechanics/new': {
      id: '/_auth/dashboard/mechanics/new'
      path: '/dashboard/mechanics/new'
      fullPath: '/dashboard/mechanics/new'
      preLoaderRoute: typeof AuthDashboardMechanicsNewImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/requests/$requestId': {
      id: '/_auth/dashboard/requests/$requestId'
      path: '/dashboard/requests/$requestId'
      fullPath: '/dashboard/requests/$requestId'
      preLoaderRoute: typeof AuthDashboardRequestsRequestIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/requests/my-assignments': {
      id: '/_auth/dashboard/requests/my-assignments'
      path: '/dashboard/requests/my-assignments'
      fullPath: '/dashboard/requests/my-assignments'
      preLoaderRoute: typeof AuthDashboardRequestsMyAssignmentsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/requests/my-participations': {
      id: '/_auth/dashboard/requests/my-participations'
      path: '/dashboard/requests/my-participations'
      fullPath: '/dashboard/requests/my-participations'
      preLoaderRoute: typeof AuthDashboardRequestsMyParticipationsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/requests/queue': {
      id: '/_auth/dashboard/requests/queue'
      path: '/dashboard/requests/queue'
      fullPath: '/dashboard/requests/queue'
      preLoaderRoute: typeof AuthDashboardRequestsQueueImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/trucks/$truckId': {
      id: '/_auth/dashboard/trucks/$truckId'
      path: '/dashboard/trucks/$truckId'
      fullPath: '/dashboard/trucks/$truckId'
      preLoaderRoute: typeof AuthDashboardTrucksTruckIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/trucks/new': {
      id: '/_auth/dashboard/trucks/new'
      path: '/dashboard/trucks/new'
      fullPath: '/dashboard/trucks/new'
      preLoaderRoute: typeof AuthDashboardTrucksNewImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/companies/': {
      id: '/_auth/dashboard/companies/'
      path: '/dashboard/companies'
      fullPath: '/dashboard/companies'
      preLoaderRoute: typeof AuthDashboardCompaniesIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/dispatchers/': {
      id: '/_auth/dashboard/dispatchers/'
      path: '/dashboard/dispatchers'
      fullPath: '/dashboard/dispatchers'
      preLoaderRoute: typeof AuthDashboardDispatchersIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/drivers/': {
      id: '/_auth/dashboard/drivers/'
      path: '/dashboard/drivers'
      fullPath: '/dashboard/drivers'
      preLoaderRoute: typeof AuthDashboardDriversIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/locations/': {
      id: '/_auth/dashboard/locations/'
      path: '/dashboard/locations'
      fullPath: '/dashboard/locations'
      preLoaderRoute: typeof AuthDashboardLocationsIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/mechanics/': {
      id: '/_auth/dashboard/mechanics/'
      path: '/dashboard/mechanics'
      fullPath: '/dashboard/mechanics'
      preLoaderRoute: typeof AuthDashboardMechanicsIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/requests/': {
      id: '/_auth/dashboard/requests/'
      path: '/dashboard/requests'
      fullPath: '/dashboard/requests'
      preLoaderRoute: typeof AuthDashboardRequestsIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/settings/': {
      id: '/_auth/dashboard/settings/'
      path: '/dashboard/settings'
      fullPath: '/dashboard/settings'
      preLoaderRoute: typeof AuthDashboardSettingsIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/dashboard/trucks/': {
      id: '/_auth/dashboard/trucks/'
      path: '/dashboard/trucks'
      fullPath: '/dashboard/trucks'
      preLoaderRoute: typeof AuthDashboardTrucksIndexImport
      parentRoute: typeof AuthImport
    }
  }
}

// Create and export the route tree

interface AuthDashboardInvoicesRouteChildren {
  AuthDashboardInvoicesStripeOnboardingCompleteRoute: typeof AuthDashboardInvoicesStripeOnboardingCompleteRoute
  AuthDashboardInvoicesStripeReauthRoute: typeof AuthDashboardInvoicesStripeReauthRoute
}

const AuthDashboardInvoicesRouteChildren: AuthDashboardInvoicesRouteChildren = {
  AuthDashboardInvoicesStripeOnboardingCompleteRoute:
    AuthDashboardInvoicesStripeOnboardingCompleteRoute,
  AuthDashboardInvoicesStripeReauthRoute:
    AuthDashboardInvoicesStripeReauthRoute,
}

const AuthDashboardInvoicesRouteWithChildren =
  AuthDashboardInvoicesRoute._addFileChildren(
    AuthDashboardInvoicesRouteChildren,
  )

interface AuthRouteChildren {
  Auth403Route: typeof Auth403Route
  AuthDashboardInvoicesRoute: typeof AuthDashboardInvoicesRouteWithChildren
  AuthDashboardMapRoute: typeof AuthDashboardMapRoute
  AuthDashboardChatLazyRoute: typeof AuthDashboardChatLazyRoute
  AuthDashboardPartsLazyRoute: typeof AuthDashboardPartsLazyRoute
  AuthDashboardIndexRoute: typeof AuthDashboardIndexRoute
  AuthDashboardCompaniesCompanyIdRoute: typeof AuthDashboardCompaniesCompanyIdRoute
  AuthDashboardCompaniesNewRoute: typeof AuthDashboardCompaniesNewRoute
  AuthDashboardDispatchersDispatcherIdRoute: typeof AuthDashboardDispatchersDispatcherIdRoute
  AuthDashboardDispatchersNewRoute: typeof AuthDashboardDispatchersNewRoute
  AuthDashboardDriversDriverIdRoute: typeof AuthDashboardDriversDriverIdRoute
  AuthDashboardDriversNewRoute: typeof AuthDashboardDriversNewRoute
  AuthDashboardLocationsLocationIdRoute: typeof AuthDashboardLocationsLocationIdRoute
  AuthDashboardLocationsNewRoute: typeof AuthDashboardLocationsNewRoute
  AuthDashboardMechanicsMechanicIdRoute: typeof AuthDashboardMechanicsMechanicIdRoute
  AuthDashboardMechanicsNewRoute: typeof AuthDashboardMechanicsNewRoute
  AuthDashboardRequestsRequestIdRoute: typeof AuthDashboardRequestsRequestIdRoute
  AuthDashboardRequestsMyAssignmentsRoute: typeof AuthDashboardRequestsMyAssignmentsRoute
  AuthDashboardRequestsMyParticipationsRoute: typeof AuthDashboardRequestsMyParticipationsRoute
  AuthDashboardRequestsQueueRoute: typeof AuthDashboardRequestsQueueRoute
  AuthDashboardTrucksTruckIdRoute: typeof AuthDashboardTrucksTruckIdRoute
  AuthDashboardTrucksNewRoute: typeof AuthDashboardTrucksNewRoute
  AuthDashboardCompaniesIndexRoute: typeof AuthDashboardCompaniesIndexRoute
  AuthDashboardDispatchersIndexRoute: typeof AuthDashboardDispatchersIndexRoute
  AuthDashboardDriversIndexRoute: typeof AuthDashboardDriversIndexRoute
  AuthDashboardLocationsIndexRoute: typeof AuthDashboardLocationsIndexRoute
  AuthDashboardMechanicsIndexRoute: typeof AuthDashboardMechanicsIndexRoute
  AuthDashboardRequestsIndexRoute: typeof AuthDashboardRequestsIndexRoute
  AuthDashboardSettingsIndexRoute: typeof AuthDashboardSettingsIndexRoute
  AuthDashboardTrucksIndexRoute: typeof AuthDashboardTrucksIndexRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  Auth403Route: Auth403Route,
  AuthDashboardInvoicesRoute: AuthDashboardInvoicesRouteWithChildren,
  AuthDashboardMapRoute: AuthDashboardMapRoute,
  AuthDashboardChatLazyRoute: AuthDashboardChatLazyRoute,
  AuthDashboardPartsLazyRoute: AuthDashboardPartsLazyRoute,
  AuthDashboardIndexRoute: AuthDashboardIndexRoute,
  AuthDashboardCompaniesCompanyIdRoute: AuthDashboardCompaniesCompanyIdRoute,
  AuthDashboardCompaniesNewRoute: AuthDashboardCompaniesNewRoute,
  AuthDashboardDispatchersDispatcherIdRoute:
    AuthDashboardDispatchersDispatcherIdRoute,
  AuthDashboardDispatchersNewRoute: AuthDashboardDispatchersNewRoute,
  AuthDashboardDriversDriverIdRoute: AuthDashboardDriversDriverIdRoute,
  AuthDashboardDriversNewRoute: AuthDashboardDriversNewRoute,
  AuthDashboardLocationsLocationIdRoute: AuthDashboardLocationsLocationIdRoute,
  AuthDashboardLocationsNewRoute: AuthDashboardLocationsNewRoute,
  AuthDashboardMechanicsMechanicIdRoute: AuthDashboardMechanicsMechanicIdRoute,
  AuthDashboardMechanicsNewRoute: AuthDashboardMechanicsNewRoute,
  AuthDashboardRequestsRequestIdRoute: AuthDashboardRequestsRequestIdRoute,
  AuthDashboardRequestsMyAssignmentsRoute:
    AuthDashboardRequestsMyAssignmentsRoute,
  AuthDashboardRequestsMyParticipationsRoute:
    AuthDashboardRequestsMyParticipationsRoute,
  AuthDashboardRequestsQueueRoute: AuthDashboardRequestsQueueRoute,
  AuthDashboardTrucksTruckIdRoute: AuthDashboardTrucksTruckIdRoute,
  AuthDashboardTrucksNewRoute: AuthDashboardTrucksNewRoute,
  AuthDashboardCompaniesIndexRoute: AuthDashboardCompaniesIndexRoute,
  AuthDashboardDispatchersIndexRoute: AuthDashboardDispatchersIndexRoute,
  AuthDashboardDriversIndexRoute: AuthDashboardDriversIndexRoute,
  AuthDashboardLocationsIndexRoute: AuthDashboardLocationsIndexRoute,
  AuthDashboardMechanicsIndexRoute: AuthDashboardMechanicsIndexRoute,
  AuthDashboardRequestsIndexRoute: AuthDashboardRequestsIndexRoute,
  AuthDashboardSettingsIndexRoute: AuthDashboardSettingsIndexRoute,
  AuthDashboardTrucksIndexRoute: AuthDashboardTrucksIndexRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

interface IncompleteSignUpRouteChildren {
  IncompleteSignUpSignUpFinalLazyRoute: typeof IncompleteSignUpSignUpFinalLazyRoute
}

const IncompleteSignUpRouteChildren: IncompleteSignUpRouteChildren = {
  IncompleteSignUpSignUpFinalLazyRoute: IncompleteSignUpSignUpFinalLazyRoute,
}

const IncompleteSignUpRouteWithChildren =
  IncompleteSignUpRoute._addFileChildren(IncompleteSignUpRouteChildren)

interface PendingVerificationRouteChildren {
  PendingVerificationCompanyVerificationLazyRoute: typeof PendingVerificationCompanyVerificationLazyRoute
}

const PendingVerificationRouteChildren: PendingVerificationRouteChildren = {
  PendingVerificationCompanyVerificationLazyRoute:
    PendingVerificationCompanyVerificationLazyRoute,
}

const PendingVerificationRouteWithChildren =
  PendingVerificationRoute._addFileChildren(PendingVerificationRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof PendingVerificationRouteWithChildren
  '/sign-in': typeof SignInRoute
  '/sign-out': typeof SignOutRoute
  '/sign-up': typeof SignUpRoute
  '/403': typeof Auth403Route
  '/sign-up-final': typeof IncompleteSignUpSignUpFinalLazyRoute
  '/company-verification': typeof PendingVerificationCompanyVerificationLazyRoute
  '/dashboard/invoices': typeof AuthDashboardInvoicesRouteWithChildren
  '/dashboard/map': typeof AuthDashboardMapRoute
  '/dashboard/chat': typeof AuthDashboardChatLazyRoute
  '/dashboard/parts': typeof AuthDashboardPartsLazyRoute
  '/dashboard': typeof AuthDashboardIndexRoute
  '/dashboard/companies/$companyId': typeof AuthDashboardCompaniesCompanyIdRoute
  '/dashboard/companies/new': typeof AuthDashboardCompaniesNewRoute
  '/dashboard/dispatchers/$dispatcherId': typeof AuthDashboardDispatchersDispatcherIdRoute
  '/dashboard/dispatchers/new': typeof AuthDashboardDispatchersNewRoute
  '/dashboard/drivers/$driverId': typeof AuthDashboardDriversDriverIdRoute
  '/dashboard/drivers/new': typeof AuthDashboardDriversNewRoute
  '/dashboard/invoices/stripe-onboarding-complete': typeof AuthDashboardInvoicesStripeOnboardingCompleteRoute
  '/dashboard/invoices/stripe-reauth': typeof AuthDashboardInvoicesStripeReauthRoute
  '/dashboard/locations/$locationId': typeof AuthDashboardLocationsLocationIdRoute
  '/dashboard/locations/new': typeof AuthDashboardLocationsNewRoute
  '/dashboard/mechanics/$mechanicId': typeof AuthDashboardMechanicsMechanicIdRoute
  '/dashboard/mechanics/new': typeof AuthDashboardMechanicsNewRoute
  '/dashboard/requests/$requestId': typeof AuthDashboardRequestsRequestIdRoute
  '/dashboard/requests/my-assignments': typeof AuthDashboardRequestsMyAssignmentsRoute
  '/dashboard/requests/my-participations': typeof AuthDashboardRequestsMyParticipationsRoute
  '/dashboard/requests/queue': typeof AuthDashboardRequestsQueueRoute
  '/dashboard/trucks/$truckId': typeof AuthDashboardTrucksTruckIdRoute
  '/dashboard/trucks/new': typeof AuthDashboardTrucksNewRoute
  '/dashboard/companies': typeof AuthDashboardCompaniesIndexRoute
  '/dashboard/dispatchers': typeof AuthDashboardDispatchersIndexRoute
  '/dashboard/drivers': typeof AuthDashboardDriversIndexRoute
  '/dashboard/locations': typeof AuthDashboardLocationsIndexRoute
  '/dashboard/mechanics': typeof AuthDashboardMechanicsIndexRoute
  '/dashboard/requests': typeof AuthDashboardRequestsIndexRoute
  '/dashboard/settings': typeof AuthDashboardSettingsIndexRoute
  '/dashboard/trucks': typeof AuthDashboardTrucksIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof PendingVerificationRouteWithChildren
  '/sign-in': typeof SignInRoute
  '/sign-out': typeof SignOutRoute
  '/sign-up': typeof SignUpRoute
  '/403': typeof Auth403Route
  '/sign-up-final': typeof IncompleteSignUpSignUpFinalLazyRoute
  '/company-verification': typeof PendingVerificationCompanyVerificationLazyRoute
  '/dashboard/invoices': typeof AuthDashboardInvoicesRouteWithChildren
  '/dashboard/map': typeof AuthDashboardMapRoute
  '/dashboard/chat': typeof AuthDashboardChatLazyRoute
  '/dashboard/parts': typeof AuthDashboardPartsLazyRoute
  '/dashboard': typeof AuthDashboardIndexRoute
  '/dashboard/companies/$companyId': typeof AuthDashboardCompaniesCompanyIdRoute
  '/dashboard/companies/new': typeof AuthDashboardCompaniesNewRoute
  '/dashboard/dispatchers/$dispatcherId': typeof AuthDashboardDispatchersDispatcherIdRoute
  '/dashboard/dispatchers/new': typeof AuthDashboardDispatchersNewRoute
  '/dashboard/drivers/$driverId': typeof AuthDashboardDriversDriverIdRoute
  '/dashboard/drivers/new': typeof AuthDashboardDriversNewRoute
  '/dashboard/invoices/stripe-onboarding-complete': typeof AuthDashboardInvoicesStripeOnboardingCompleteRoute
  '/dashboard/invoices/stripe-reauth': typeof AuthDashboardInvoicesStripeReauthRoute
  '/dashboard/locations/$locationId': typeof AuthDashboardLocationsLocationIdRoute
  '/dashboard/locations/new': typeof AuthDashboardLocationsNewRoute
  '/dashboard/mechanics/$mechanicId': typeof AuthDashboardMechanicsMechanicIdRoute
  '/dashboard/mechanics/new': typeof AuthDashboardMechanicsNewRoute
  '/dashboard/requests/$requestId': typeof AuthDashboardRequestsRequestIdRoute
  '/dashboard/requests/my-assignments': typeof AuthDashboardRequestsMyAssignmentsRoute
  '/dashboard/requests/my-participations': typeof AuthDashboardRequestsMyParticipationsRoute
  '/dashboard/requests/queue': typeof AuthDashboardRequestsQueueRoute
  '/dashboard/trucks/$truckId': typeof AuthDashboardTrucksTruckIdRoute
  '/dashboard/trucks/new': typeof AuthDashboardTrucksNewRoute
  '/dashboard/companies': typeof AuthDashboardCompaniesIndexRoute
  '/dashboard/dispatchers': typeof AuthDashboardDispatchersIndexRoute
  '/dashboard/drivers': typeof AuthDashboardDriversIndexRoute
  '/dashboard/locations': typeof AuthDashboardLocationsIndexRoute
  '/dashboard/mechanics': typeof AuthDashboardMechanicsIndexRoute
  '/dashboard/requests': typeof AuthDashboardRequestsIndexRoute
  '/dashboard/settings': typeof AuthDashboardSettingsIndexRoute
  '/dashboard/trucks': typeof AuthDashboardTrucksIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_incomplete-sign-up': typeof IncompleteSignUpRouteWithChildren
  '/_pending-verification': typeof PendingVerificationRouteWithChildren
  '/sign-in': typeof SignInRoute
  '/sign-out': typeof SignOutRoute
  '/sign-up': typeof SignUpRoute
  '/_auth/403': typeof Auth403Route
  '/_incomplete-sign-up/sign-up-final': typeof IncompleteSignUpSignUpFinalLazyRoute
  '/_pending-verification/company-verification': typeof PendingVerificationCompanyVerificationLazyRoute
  '/_auth/dashboard/invoices': typeof AuthDashboardInvoicesRouteWithChildren
  '/_auth/dashboard/map': typeof AuthDashboardMapRoute
  '/_auth/dashboard/chat': typeof AuthDashboardChatLazyRoute
  '/_auth/dashboard/parts': typeof AuthDashboardPartsLazyRoute
  '/_auth/dashboard/': typeof AuthDashboardIndexRoute
  '/_auth/dashboard/companies/$companyId': typeof AuthDashboardCompaniesCompanyIdRoute
  '/_auth/dashboard/companies/new': typeof AuthDashboardCompaniesNewRoute
  '/_auth/dashboard/dispatchers/$dispatcherId': typeof AuthDashboardDispatchersDispatcherIdRoute
  '/_auth/dashboard/dispatchers/new': typeof AuthDashboardDispatchersNewRoute
  '/_auth/dashboard/drivers/$driverId': typeof AuthDashboardDriversDriverIdRoute
  '/_auth/dashboard/drivers/new': typeof AuthDashboardDriversNewRoute
  '/_auth/dashboard/invoices/stripe-onboarding-complete': typeof AuthDashboardInvoicesStripeOnboardingCompleteRoute
  '/_auth/dashboard/invoices/stripe-reauth': typeof AuthDashboardInvoicesStripeReauthRoute
  '/_auth/dashboard/locations/$locationId': typeof AuthDashboardLocationsLocationIdRoute
  '/_auth/dashboard/locations/new': typeof AuthDashboardLocationsNewRoute
  '/_auth/dashboard/mechanics/$mechanicId': typeof AuthDashboardMechanicsMechanicIdRoute
  '/_auth/dashboard/mechanics/new': typeof AuthDashboardMechanicsNewRoute
  '/_auth/dashboard/requests/$requestId': typeof AuthDashboardRequestsRequestIdRoute
  '/_auth/dashboard/requests/my-assignments': typeof AuthDashboardRequestsMyAssignmentsRoute
  '/_auth/dashboard/requests/my-participations': typeof AuthDashboardRequestsMyParticipationsRoute
  '/_auth/dashboard/requests/queue': typeof AuthDashboardRequestsQueueRoute
  '/_auth/dashboard/trucks/$truckId': typeof AuthDashboardTrucksTruckIdRoute
  '/_auth/dashboard/trucks/new': typeof AuthDashboardTrucksNewRoute
  '/_auth/dashboard/companies/': typeof AuthDashboardCompaniesIndexRoute
  '/_auth/dashboard/dispatchers/': typeof AuthDashboardDispatchersIndexRoute
  '/_auth/dashboard/drivers/': typeof AuthDashboardDriversIndexRoute
  '/_auth/dashboard/locations/': typeof AuthDashboardLocationsIndexRoute
  '/_auth/dashboard/mechanics/': typeof AuthDashboardMechanicsIndexRoute
  '/_auth/dashboard/requests/': typeof AuthDashboardRequestsIndexRoute
  '/_auth/dashboard/settings/': typeof AuthDashboardSettingsIndexRoute
  '/_auth/dashboard/trucks/': typeof AuthDashboardTrucksIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/sign-in'
    | '/sign-out'
    | '/sign-up'
    | '/403'
    | '/sign-up-final'
    | '/company-verification'
    | '/dashboard/invoices'
    | '/dashboard/map'
    | '/dashboard/chat'
    | '/dashboard/parts'
    | '/dashboard'
    | '/dashboard/companies/$companyId'
    | '/dashboard/companies/new'
    | '/dashboard/dispatchers/$dispatcherId'
    | '/dashboard/dispatchers/new'
    | '/dashboard/drivers/$driverId'
    | '/dashboard/drivers/new'
    | '/dashboard/invoices/stripe-onboarding-complete'
    | '/dashboard/invoices/stripe-reauth'
    | '/dashboard/locations/$locationId'
    | '/dashboard/locations/new'
    | '/dashboard/mechanics/$mechanicId'
    | '/dashboard/mechanics/new'
    | '/dashboard/requests/$requestId'
    | '/dashboard/requests/my-assignments'
    | '/dashboard/requests/my-participations'
    | '/dashboard/requests/queue'
    | '/dashboard/trucks/$truckId'
    | '/dashboard/trucks/new'
    | '/dashboard/companies'
    | '/dashboard/dispatchers'
    | '/dashboard/drivers'
    | '/dashboard/locations'
    | '/dashboard/mechanics'
    | '/dashboard/requests'
    | '/dashboard/settings'
    | '/dashboard/trucks'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/sign-in'
    | '/sign-out'
    | '/sign-up'
    | '/403'
    | '/sign-up-final'
    | '/company-verification'
    | '/dashboard/invoices'
    | '/dashboard/map'
    | '/dashboard/chat'
    | '/dashboard/parts'
    | '/dashboard'
    | '/dashboard/companies/$companyId'
    | '/dashboard/companies/new'
    | '/dashboard/dispatchers/$dispatcherId'
    | '/dashboard/dispatchers/new'
    | '/dashboard/drivers/$driverId'
    | '/dashboard/drivers/new'
    | '/dashboard/invoices/stripe-onboarding-complete'
    | '/dashboard/invoices/stripe-reauth'
    | '/dashboard/locations/$locationId'
    | '/dashboard/locations/new'
    | '/dashboard/mechanics/$mechanicId'
    | '/dashboard/mechanics/new'
    | '/dashboard/requests/$requestId'
    | '/dashboard/requests/my-assignments'
    | '/dashboard/requests/my-participations'
    | '/dashboard/requests/queue'
    | '/dashboard/trucks/$truckId'
    | '/dashboard/trucks/new'
    | '/dashboard/companies'
    | '/dashboard/dispatchers'
    | '/dashboard/drivers'
    | '/dashboard/locations'
    | '/dashboard/mechanics'
    | '/dashboard/requests'
    | '/dashboard/settings'
    | '/dashboard/trucks'
  id:
    | '__root__'
    | '/'
    | '/_auth'
    | '/_incomplete-sign-up'
    | '/_pending-verification'
    | '/sign-in'
    | '/sign-out'
    | '/sign-up'
    | '/_auth/403'
    | '/_incomplete-sign-up/sign-up-final'
    | '/_pending-verification/company-verification'
    | '/_auth/dashboard/invoices'
    | '/_auth/dashboard/map'
    | '/_auth/dashboard/chat'
    | '/_auth/dashboard/parts'
    | '/_auth/dashboard/'
    | '/_auth/dashboard/companies/$companyId'
    | '/_auth/dashboard/companies/new'
    | '/_auth/dashboard/dispatchers/$dispatcherId'
    | '/_auth/dashboard/dispatchers/new'
    | '/_auth/dashboard/drivers/$driverId'
    | '/_auth/dashboard/drivers/new'
    | '/_auth/dashboard/invoices/stripe-onboarding-complete'
    | '/_auth/dashboard/invoices/stripe-reauth'
    | '/_auth/dashboard/locations/$locationId'
    | '/_auth/dashboard/locations/new'
    | '/_auth/dashboard/mechanics/$mechanicId'
    | '/_auth/dashboard/mechanics/new'
    | '/_auth/dashboard/requests/$requestId'
    | '/_auth/dashboard/requests/my-assignments'
    | '/_auth/dashboard/requests/my-participations'
    | '/_auth/dashboard/requests/queue'
    | '/_auth/dashboard/trucks/$truckId'
    | '/_auth/dashboard/trucks/new'
    | '/_auth/dashboard/companies/'
    | '/_auth/dashboard/dispatchers/'
    | '/_auth/dashboard/drivers/'
    | '/_auth/dashboard/locations/'
    | '/_auth/dashboard/mechanics/'
    | '/_auth/dashboard/requests/'
    | '/_auth/dashboard/settings/'
    | '/_auth/dashboard/trucks/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthRoute: typeof AuthRouteWithChildren
  IncompleteSignUpRoute: typeof IncompleteSignUpRouteWithChildren
  PendingVerificationRoute: typeof PendingVerificationRouteWithChildren
  SignInRoute: typeof SignInRoute
  SignOutRoute: typeof SignOutRoute
  SignUpRoute: typeof SignUpRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthRoute: AuthRouteWithChildren,
  IncompleteSignUpRoute: IncompleteSignUpRouteWithChildren,
  PendingVerificationRoute: PendingVerificationRouteWithChildren,
  SignInRoute: SignInRoute,
  SignOutRoute: SignOutRoute,
  SignUpRoute: SignUpRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_auth",
        "/_incomplete-sign-up",
        "/_pending-verification",
        "/sign-in",
        "/sign-out",
        "/sign-up"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/403",
        "/_auth/dashboard/invoices",
        "/_auth/dashboard/map",
        "/_auth/dashboard/chat",
        "/_auth/dashboard/parts",
        "/_auth/dashboard/",
        "/_auth/dashboard/companies/$companyId",
        "/_auth/dashboard/companies/new",
        "/_auth/dashboard/dispatchers/$dispatcherId",
        "/_auth/dashboard/dispatchers/new",
        "/_auth/dashboard/drivers/$driverId",
        "/_auth/dashboard/drivers/new",
        "/_auth/dashboard/locations/$locationId",
        "/_auth/dashboard/locations/new",
        "/_auth/dashboard/mechanics/$mechanicId",
        "/_auth/dashboard/mechanics/new",
        "/_auth/dashboard/requests/$requestId",
        "/_auth/dashboard/requests/my-assignments",
        "/_auth/dashboard/requests/my-participations",
        "/_auth/dashboard/requests/queue",
        "/_auth/dashboard/trucks/$truckId",
        "/_auth/dashboard/trucks/new",
        "/_auth/dashboard/companies/",
        "/_auth/dashboard/dispatchers/",
        "/_auth/dashboard/drivers/",
        "/_auth/dashboard/locations/",
        "/_auth/dashboard/mechanics/",
        "/_auth/dashboard/requests/",
        "/_auth/dashboard/settings/",
        "/_auth/dashboard/trucks/"
      ]
    },
    "/_incomplete-sign-up": {
      "filePath": "_incomplete-sign-up.tsx",
      "children": [
        "/_incomplete-sign-up/sign-up-final"
      ]
    },
    "/_pending-verification": {
      "filePath": "_pending-verification.tsx",
      "children": [
        "/_pending-verification/company-verification"
      ]
    },
    "/sign-in": {
      "filePath": "sign-in.tsx"
    },
    "/sign-out": {
      "filePath": "sign-out.tsx"
    },
    "/sign-up": {
      "filePath": "sign-up.tsx"
    },
    "/_auth/403": {
      "filePath": "_auth.403.tsx",
      "parent": "/_auth"
    },
    "/_incomplete-sign-up/sign-up-final": {
      "filePath": "_incomplete-sign-up.sign-up-final.lazy.tsx",
      "parent": "/_incomplete-sign-up"
    },
    "/_pending-verification/company-verification": {
      "filePath": "_pending-verification.company-verification.lazy.tsx",
      "parent": "/_pending-verification"
    },
    "/_auth/dashboard/invoices": {
      "filePath": "_auth.dashboard.invoices.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/dashboard/invoices/stripe-onboarding-complete",
        "/_auth/dashboard/invoices/stripe-reauth"
      ]
    },
    "/_auth/dashboard/map": {
      "filePath": "_auth.dashboard.map.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/chat": {
      "filePath": "_auth.dashboard.chat.lazy.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/parts": {
      "filePath": "_auth.dashboard.parts.lazy.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/": {
      "filePath": "_auth.dashboard.index.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/companies/$companyId": {
      "filePath": "_auth.dashboard.companies.$companyId.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/companies/new": {
      "filePath": "_auth.dashboard.companies.new.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/dispatchers/$dispatcherId": {
      "filePath": "_auth.dashboard.dispatchers.$dispatcherId.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/dispatchers/new": {
      "filePath": "_auth.dashboard.dispatchers.new.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/drivers/$driverId": {
      "filePath": "_auth.dashboard.drivers.$driverId.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/drivers/new": {
      "filePath": "_auth.dashboard.drivers.new.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/invoices/stripe-onboarding-complete": {
      "filePath": "_auth.dashboard.invoices.stripe-onboarding-complete.tsx",
      "parent": "/_auth/dashboard/invoices"
    },
    "/_auth/dashboard/invoices/stripe-reauth": {
      "filePath": "_auth.dashboard.invoices.stripe-reauth.tsx",
      "parent": "/_auth/dashboard/invoices"
    },
    "/_auth/dashboard/locations/$locationId": {
      "filePath": "_auth.dashboard.locations.$locationId.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/locations/new": {
      "filePath": "_auth.dashboard.locations.new.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/mechanics/$mechanicId": {
      "filePath": "_auth.dashboard.mechanics.$mechanicId.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/mechanics/new": {
      "filePath": "_auth.dashboard.mechanics.new.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/requests/$requestId": {
      "filePath": "_auth.dashboard.requests.$requestId.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/requests/my-assignments": {
      "filePath": "_auth.dashboard.requests.my-assignments.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/requests/my-participations": {
      "filePath": "_auth.dashboard.requests.my-participations.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/requests/queue": {
      "filePath": "_auth.dashboard.requests.queue.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/trucks/$truckId": {
      "filePath": "_auth.dashboard.trucks.$truckId.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/trucks/new": {
      "filePath": "_auth.dashboard.trucks.new.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/companies/": {
      "filePath": "_auth.dashboard.companies.index.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/dispatchers/": {
      "filePath": "_auth.dashboard.dispatchers.index.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/drivers/": {
      "filePath": "_auth.dashboard.drivers.index.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/locations/": {
      "filePath": "_auth.dashboard.locations.index.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/mechanics/": {
      "filePath": "_auth.dashboard.mechanics.index.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/requests/": {
      "filePath": "_auth.dashboard.requests.index.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/settings/": {
      "filePath": "_auth.dashboard.settings.index.tsx",
      "parent": "/_auth"
    },
    "/_auth/dashboard/trucks/": {
      "filePath": "_auth.dashboard.trucks.index.tsx",
      "parent": "/_auth"
    }
  }
}
ROUTE_MANIFEST_END */
