import { DashboardContent } from '../../../minimal-theme/layouts/dashboard';

import { DriverNewEditForm } from '../driver-new-edit-form';

export function DriverCreateView() {
  return (
    <DashboardContent>
      <DriverNewEditForm />
    </DashboardContent>
  );
}
