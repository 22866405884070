import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { z } from 'zod';

export const phoneSchema = z
  .string()
  .refine(value => isValidPhoneNumber(value), {
    message: 'Please provide a valid phone number',
  })
  .transform(value => {
    const phoneNumber = parsePhoneNumber(value);
    return phoneNumber.format('E.164'); // Normalize to E.164 format
  });
