import { Box } from '@mui/material';

type HighlightTextProps = {
  text: string;
  matches: { start: number; end: number }[];
  color?: string;
};
export function HighlightText({ text, matches, color }: HighlightTextProps) {
  if (!matches.length) return <span>{text}</span>;

  // Sort and merge overlapping matches
  const sortedMatches = matches
    .sort((a, b) => a.start - b.start)
    .reduce(
      (acc, match) => {
        if (!acc.length || acc[acc.length - 1].end < match.start) {
          acc.push(match);
        } else {
          acc[acc.length - 1].end = Math.max(
            acc[acc.length - 1].end,
            match.end
          );
        }
        return acc;
      },
      [] as typeof matches
    );

  const parts: { text: string; highlight: boolean }[] = [];
  let lastEnd = 0;

  sortedMatches.forEach(match => {
    if (match.start > lastEnd) {
      parts.push({ text: text.slice(lastEnd, match.start), highlight: false });
    }
    parts.push({ text: text.slice(match.start, match.end), highlight: true });
    lastEnd = match.end;
  });

  if (lastEnd < text.length) {
    parts.push({ text: text.slice(lastEnd), highlight: false });
  }

  return (
    <span>
      {parts.map((part, i) =>
        part.highlight ? (
          <Box component="mark" sx={{ bgcolor: color ?? '#cff6dd' }} key={i}>
            {part.text}
          </Box>
        ) : (
          <span key={i}>{part.text}</span>
        )
      )}
    </span>
  );
}
