import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'src/config-global';
import { DispatcherEditView } from 'src/sections/dispatcher/view/dispatcher-edit-view';
const metadata = {
  title: `Dispatcher edit | Dashboard - ${CONFIG.appName}`,
};

export default function Page() {
  return (
    <>
      <Helmet>
        <title> {metadata.title}</title>
      </Helmet>
      <DispatcherEditView />
    </>
  );
}
