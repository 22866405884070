import { createFileRoute } from '@tanstack/react-router';
import { api } from 'src/convex/_generated/api';
import { QueueView } from 'src/sections/request/view/QueueView';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/requests/queue')({
  beforeLoad: ({ context }) =>
    checkRouteAccess(context, routeAccessConfigs.allRolesExceptSuperAdmin),
  loader: async ({ context }) => {
    return await context.convex.query(
      api.functions.requests.getQueuedRequests,
      {}
    );
  },
  component: QueueView,
});
