import Chip from '@mui/material/Chip';
import type { SxProps, Theme } from '@mui/material/styles';
import { useCallback } from 'react';
import type { DispatcherTableFilters } from 'src/types/user';
import { getStatusColor, getStatusLabel } from 'src/utils/helper';
import type { UseSetStateReturn } from '../../minimal-theme/hooks/use-set-state';

import {
  chipProps,
  FiltersBlock,
  FiltersResult,
} from '../../minimal-theme/components/filters-result';

type DispatcherTableFiltersResultProps = {
  totalResults: number;
  sx?: SxProps<Theme>;
  onResetPage: () => void;
  filters: UseSetStateReturn<DispatcherTableFilters>;
};

export function DispatcherTableFiltersResult({
  filters,
  onResetPage,
  totalResults,
  sx,
}: DispatcherTableFiltersResultProps) {
  const handleRemoveKeyword = useCallback(() => {
    onResetPage();
    filters.setState({ name: '' });
  }, [filters, onResetPage]);

  const handleRemoveStatus = useCallback(() => {
    onResetPage();
    filters.setState({ status: 'all' });
  }, [filters, onResetPage]);

  const handleReset = useCallback(() => {
    onResetPage();
    filters.onResetState();
  }, [filters, onResetPage]);

  return (
    <FiltersResult totalResults={totalResults} onReset={handleReset} sx={sx}>
      <FiltersBlock label="Status:" isShow={filters.state.status !== 'all'}>
        <Chip
          {...chipProps}
          color={getStatusColor(filters.state.status)}
          label={getStatusLabel(filters.state.status)}
          onDelete={handleRemoveStatus}
          sx={{ textTransform: 'capitalize' }}
        />
      </FiltersBlock>

      <FiltersBlock label="Keyword:" isShow={!!filters.state.name}>
        <Chip
          {...chipProps}
          label={filters.state.name}
          onDelete={handleRemoveKeyword}
        />
      </FiltersBlock>
    </FiltersResult>
  );
}
