import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useQuery } from 'convex/react';
import { api } from 'src/convex/_generated/api';
import { Doc, Id } from 'src/convex/_generated/dataModel';
import { getCompanyTypeDisplay } from 'src/convex/schema/enums/companyType';
import { usePopover } from 'src/minimal-theme/components/custom-popover';
import { MuiTypeSafeLink } from 'src/minimal-theme/components/routes/components/mui-link';

type CompanyTableRowProps = {
  row: Doc<'companies'>;
  selected: boolean;
  onEditRow: () => void;
  onSelectRow: () => void;
  onDeleteRow: () => void;
};

export function CompanyTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: CompanyTableRowProps) {
  const me = useQuery(api.functions.users.getMe);

  const popover = usePopover();

  const owner = useQuery(
    api.functions.users.getUserById,
    row.ownerId ? { id: row.ownerId as Id<'users'> } : 'skip'
  );

  return (
    <>
      <TableRow hover selected={selected} aria-checked={selected} tabIndex={-1}>
        <TableCell padding="checkbox">
          <Checkbox id={row._id} checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <MuiTypeSafeLink
            to="/dashboard/companies/$companyId"
            params={{ companyId: row._id }}
            color="inherit"
            sx={{ cursor: 'pointer' }}
          >
            {row.name}
          </MuiTypeSafeLink>
        </TableCell>

        <TableCell>{getCompanyTypeDisplay(row.companyType)}</TableCell>

        <TableCell>{row.isSignUpFormCompleted ? 'Yes' : 'No'}</TableCell>

        <TableCell>
          {owner && (
            <Stack spacing={1} direction="row" alignItems="center">
              <Avatar
                alt={owner.clerkUser.fullName}
                src={owner.clerkUser.imageUrl}
                sx={{ width: 24, height: 24 }}
              />

              <Stack
                sx={{
                  flex: '1 1 auto',
                  alignItems: 'flex-start',
                }}
              >
                <Typography variant="body2">
                  {owner.clerkUser.fullName}
                </Typography>
              </Stack>
            </Stack>
          )}
        </TableCell>
        <TableCell></TableCell>
        {/* 
        <TableCell>
          <Link href={`mailto:${row.contactEmail}`}>{row.contactEmail}</Link>
        </TableCell>

        <TableCell>
          <Link href={`tel:${row.contactPhoneNo}`}>{row.contactPhoneNo}</Link>
        </TableCell> */}

        {/* <TableCell>
          <Label
            variant="soft"
            color={(row.isNetworkMember === true && 'success') || 'default'}
          >
            {row.isNetworkMember ? 'Yes' : 'No'}
          </Label>
        </TableCell> */}
      </TableRow>
    </>
  );
}
