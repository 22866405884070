import { api } from '@api';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  CardHeader,
  Checkbox,
  debounce,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useForm } from '@tanstack/react-form';
import { useParams } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { zid } from 'convex-helpers/server/zod';
import { useMutation, useQuery } from 'convex/react';
import { useEffect, useMemo, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input/input';
import { toast } from 'sonner';
import { CONFIG } from 'src/config-global';
import { Doc, Id } from 'src/convex/_generated/dataModel';
import {
  FLEET_LOCATION_TYPES,
  getPhysicalLocationTypeDisplay,
  isFleetType,
  isServiceType,
  limitedLocationTypes,
  PhysicalLocationType,
  REPAIR_SHOP_LOCATION_TYPES,
} from 'src/convex/schema/entities/groups/groups';
import { PhoneInput } from 'src/minimal-theme/components/phone-input';
import { useCompanySearch } from 'src/minimal-theme/hooks/useCompanySearch';
import { useGroupSearch } from 'src/minimal-theme/hooks/useGroupSearch';
import { schemaHelper } from 'src/minimal-theme/utils/schema-helper';
import zod, { z } from 'zod';

// https://tanstack.com/form/latest/docs/framework/react/guides/async-initial-values
// TODO: We shouldn't have to keep creating new zod schema for forms, should be able to use the same ones on the front/backend which are extended from the base schema eventually
export type NewVendorSchemaType = zod.infer<typeof NewVendorSchema>;

export const NewVendorSchema = zod.object({
  email: zod
    .string()
    .email('Invalid email address')
    .optional()
    .or(zod.literal('')),
  phone: schemaHelper.phoneNumber({ isValidPhoneNumber }),
  dispatchPhone: schemaHelper.phoneNumber({ isValidPhoneNumber }),
  address: zod.string().refine(val => !!val, 'Address is required'),
  locationType: zod.string().refine(val => !!val, 'Location type is required'),
  companyId: zid('companies').refine(
    val => !!val,
    'Parent company is required'
  ),
  name: zod.string().min(2, 'Name is required'),
  serviceIds: z.array(zid('services')),
  simpleServiceAreaByMilesOut: z.number().optional(),
});

export function LocationNewEditForm() {
  // TODO: need a better solution for this because we lose type safety
  const params = useParams({ strict: false });

  const currentLocation = useQuery(
    api.functions.companies.getLocationById,
    params.locationId
      ? {
          id: params.locationId as Id<'groups'>,
        }
      : 'skip'
  );

  const currentParentCompany = useQuery(
    api.functions.companies.getCompany,
    params.locationId && currentLocation
      ? {
          companyId: currentLocation.companyId! as Id<'companies'>,
        }
      : 'skip'
  );

  const initialDispatchLocation = useQuery(
    api.functions.companies.getLocationById,
    currentLocation
      ? {
          id: currentLocation.defaultDispatchGroupId! as Id<'groups'>,
        }
      : 'skip'
  );

  const existingLocationServices = useQuery(
    api.functions.serviceCategories.getLocationServices,
    params.locationId
      ? {
          locationId: params.locationId as Id<'groups'>,
        }
      : 'skip'
  );

  const {
    setDebouncedTerm,
    companies,
    selectedCompany,
    setSelectedCompany,
    debouncedTerm,
  } = useCompanySearch();

  const {
    setDebouncedTerm: setDebouncedLocationTerm,
    groups: locations,
    setSelectedGroup: setSelectedLocation,
    selectedGroup: selectedLocation,
    debouncedTerm: debouncedLocationTerm,
  } = useGroupSearch(selectedCompany?._id);

  // Set initial data for autocompletes when available
  useEffect(() => {
    if (currentParentCompany) {
      setSelectedCompany(currentParentCompany);
    }
  }, [currentParentCompany]);

  useEffect(() => {
    if (initialDispatchLocation) {
      setSelectedLocation(initialDispatchLocation);
    }
  }, [initialDispatchLocation]);

  const debouncedSearch = useMemo(
    () =>
      debounce(async (query: string) => {
        if (!query) return;

        setDebouncedTerm(query);
      }, 200),
    []
  );

  const debouncedLocationSearch = useMemo(
    () =>
      debounce(async (query: string) => {
        if (!query) return;

        setDebouncedLocationTerm(query);
      }, 200),
    []
  );

  const createLocation = useMutation(api.functions.companies.createLocation);
  const updateLocation = useMutation(api.functions.companies.updateLocation);

  type LocationTypeEnum =
    | typeof limitedLocationTypes
    | typeof FLEET_LOCATION_TYPES
    | typeof REPAIR_SHOP_LOCATION_TYPES;

  let locationTypeList: LocationTypeEnum = limitedLocationTypes;

  if (selectedCompany?.companyType === 'FLEET') {
    locationTypeList = FLEET_LOCATION_TYPES;
  } else if (selectedCompany?.companyType === 'REPAIR_SHOP') {
    locationTypeList = REPAIR_SHOP_LOCATION_TYPES;
  }

  const locationTypeOptions = locationTypeList.options;

  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [coordinates, setCoordinates] = useState<{
    long: number;
    lat: number;
  } | null>(null);

  // Debounced search function
  const searchAddress = useMemo(
    () =>
      debounce(async (query: string) => {
        if (!query) return;

        try {
          const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
              query
            )}.json?access_token=${CONFIG.mapboxApiKey}&types=address`
          );
          const data = await response.json();
          setSearchResults(
            data.features.map((feature: any) => ({
              place_name: feature.place_name,
              center: feature.center,
            }))
          );
        } catch (error) {
          setSearchResults([]);
        }
      }),
    []
  );

  // Memoize the geocoding function to prevent recreating on every render
  const geocodeAddress = useMemo(
    () => async (query: string) => {
      if (!query) return null;

      try {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            query
          )}.json?access_token=${CONFIG.mapboxApiKey}&types=address`
        );
        const data = await response.json();
        return data.features?.[0] || null;
      } catch (error) {
        return null;
      }
    },
    []
  );

  console.log('currentLocation', currentLocation);

  const { Field, Subscribe, handleSubmit, reset, useStore } = useForm({
    defaultValues: {
      name: currentLocation?.name || '',
      companyId: currentLocation?.companyId || '',
      defaultDispatchGroupId: currentLocation?.defaultDispatchGroupId || '',
      description: currentLocation?.description || '',
      locationType: currentLocation?.locationType || '',
      address: currentLocation?.location?.address || '',
      showInProviderDirectory:
        currentLocation?.showInProviderDirectory || false,
      isRetail: currentLocation?.isRetail || false,
      phone: currentLocation?.contactInfo?.phone || '',
      email: currentLocation?.contactInfo?.email || '',
      dispatchPhone: currentLocation?.contactInfo?.dispatchPhone || '',
      emergencyPhone: currentLocation?.contactInfo?.emergencyPhone || '',
      notes: currentLocation?.contactInfo?.notes || '',
      simpleServiceAreaByMilesOut:
        currentLocation?.simpleServiceAreaByMilesOut || undefined,
      serviceIds: existingLocationServices
        ? existingLocationServices.map(s => s.serviceId)
        : [],
    },
    validatorAdapter: zodValidator(),
    validators: {
      onSubmit: NewVendorSchema,
    },
    onSubmit: async ({ value }) => {
      try {
        if (currentLocation) {
          const result = await updateLocation({
            input: {
              locationId: currentLocation._id,
              name: value.name,
              location: {
                latitude:
                  coordinates?.lat ?? currentLocation.location?.latitude ?? 0,
                longitude:
                  coordinates?.long ?? currentLocation.location?.longitude ?? 0,
                address: value.address,
                lastUpdated: new Date().toISOString(),
              },
              isRetail: value.isRetail,
              simpleServiceAreaByMilesOut: value.simpleServiceAreaByMilesOut,
              description: value.description,
              representsPhysicalLocation: true,
              showInProviderDirectory: value.showInProviderDirectory,
              contactInfo: {
                phone: value.phone,
                email: value.email === '' ? undefined : value.email,
                dispatchPhone: value.dispatchPhone,
                emergencyPhone: value.emergencyPhone,
                notes: value.notes,
              },
            },
            serviceIds: value.serviceIds,
          });
          if (result.success) {
            toast.success(result.message);
          } else {
            toast.error(result.message);
          }
        } else {
          const result = await createLocation({
            input: {
              name: value.name,
              companyId: value.companyId as Id<'companies'>,
              location: {
                latitude: coordinates?.lat ?? 0,
                longitude: coordinates?.long ?? 0,
                address: value.address,
                lastUpdated: new Date().toISOString(),
              },
              isRetail: value.isRetail,
              description: value.description,
              locationType: value.locationType as any,
              representsPhysicalLocation: true,
              showInProviderDirectory: value.showInProviderDirectory,
              simpleServiceAreaByMilesOut: value.simpleServiceAreaByMilesOut,
              defaultDispatchGroupId:
                value.defaultDispatchGroupId === ''
                  ? undefined
                  : (value.defaultDispatchGroupId as Id<'groups'>),
              contactInfo: {
                phone: value.phone,
                email: value.email === '' ? undefined : value.email,
                dispatchPhone: value.dispatchPhone,
                emergencyPhone: value.emergencyPhone,
                notes: value.notes,
              },
            },
            serviceIds: value.serviceIds,
          });

          if (result.success) {
            toast.success(result.message);
            reset();
            // TODO: Gotta figure out a way to not have to do this
            setSelectedCompany(null);
            setSelectedLocation(null);
            setSearchResults([]);
            setCoordinates(null);
          } else {
            toast.error(result.message);
          }
        }
      } catch (error) {
        console.error('Failed to save location:', error);
        toast.error('Failed to save location. Please try again.');
      }
    },
  });

  const locationTypeString = useStore(s => s.values.locationType);

  const locationType =
    locationTypeString === ''
      ? undefined
      : (locationTypeString as PhysicalLocationType);

  const fleetType = isFleetType(locationType);
  const serviceType = isServiceType(locationType);

  if (
    params.locationId &&
    (!currentLocation ||
      !currentParentCompany ||
      !initialDispatchLocation ||
      existingLocationServices === undefined)
  ) {
    return null;
  }

  // TODO: find a better way to do all of this
  // If there's a search term or search results, use those. Otherwise, fall back to current item if it exists
  const companyOptions =
    debouncedTerm || companies.length > 0
      ? companies
      : currentParentCompany
        ? [currentParentCompany]
        : [];

  const dispatchOptions =
    debouncedLocationTerm || locations.length > 0
      ? locations
      : initialDispatchLocation
        ? [initialDispatchLocation]
        : [];

  return (
    <>
      <Card>
        <CardHeader
          title={currentLocation ? 'Edit Location' : 'Create Location'}
          subheader={
            currentLocation
              ? 'Edit the details of the location'
              : "Enter the details of the location you'd like to create"
          }
          sx={{ mb: 3 }}
        />
        <Divider />
        <Stack spacing={3} sx={{ p: 3 }}>
          <form
            autoComplete="off" // TODO: need to revisit but autocomplete can blow things up for some reason
            noValidate
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit();
            }}
            style={{ width: '100%' }}
          >
            <Typography variant="h6">Location Details</Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{ my: 2 }}
            >
              <Field
                name="companyId"
                validators={{
                  onChange: ({ fieldApi }) => {
                    fieldApi.form.setFieldValue('defaultDispatchGroupId', '');
                    return undefined;
                  },
                }}
              >
                {({ state, handleChange }) => (
                  <FormAutocomplete
                    options={companyOptions}
                    value={selectedCompany}
                    disabled={!!currentParentCompany}
                    getOptionLabel={(option: Doc<'companies'>) =>
                      option?.name || ''
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?._id === value?._id
                    }
                    onInputChange={newValue => {
                      debouncedSearch(newValue);
                    }}
                    onChange={newValue => {
                      handleChange(newValue?._id || '');
                      setSelectedCompany(newValue);
                    }}
                    label="Parent Company"
                    required
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0] as string}
                  />
                )}
              </Field>
              <Field
                name="name"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Location Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="locationType"
                validators={{
                  onChange: ({ value, fieldApi }) => {
                    // We want to ensure showInProviderDirectory is cleared if they choose a fleet type
                    if (isFleetType(value as PhysicalLocationType)) {
                      fieldApi.form.setFieldValue(
                        'showInProviderDirectory',
                        false
                      );
                    }
                    return undefined;
                  },
                }}
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    select
                    label="Location Type"
                    variant="outlined"
                    disabled={!!currentLocation}
                    fullWidth
                    required
                    value={state.value}
                    onChange={e =>
                      handleChange(e.target.value as typeof state.value)
                    }
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  >
                    {locationTypeOptions.map(type => (
                      <MenuItem key={type} value={type}>
                        {getPhysicalLocationTypeDisplay(
                          type as PhysicalLocationType
                        )}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Field
                name="description"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    multiline
                    label="About"
                    variant="outlined"
                    fullWidth
                    value={state.value}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />

              <Field
                name="address"
                children={({ state, handleChange, handleBlur }) => (
                  <Autocomplete
                    options={searchResults}
                    getOptionLabel={option => option?.place_name || ''}
                    isOptionEqualToValue={(option, value) =>
                      option.place_name === value.place_name
                    }
                    inputValue={state.value || ''}
                    value={state.value ? { place_name: state.value } : null}
                    onInputChange={(_event, newValue) => {
                      setSearchResults([]);
                      handleChange(newValue);
                      searchAddress(newValue);
                    }}
                    onChange={async (_event, newValue) => {
                      if (typeof newValue === 'string') {
                        handleChange(newValue);
                      } else {
                        handleChange(newValue?.place_name || '');

                        const geocoded = await geocodeAddress(
                          newValue?.place_name || ''
                        );

                        // TODO: create a reusable/testable function for this... and find the typings... cuz it was backwards I think
                        if (geocoded?.center) {
                          const [long, lat] = geocoded.center;
                          setCoordinates({ long, lat });
                        } else {
                          setCoordinates(null);
                        }
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Address"
                        required
                        error={state.meta.errors.length > 0}
                        helperText={state.meta.errors[0]}
                      />
                    )}
                  />
                )}
              />
              <Field
                name="defaultDispatchGroupId"
                children={({ state, handleChange }) => (
                  <FormAutocomplete
                    options={dispatchOptions}
                    value={selectedLocation}
                    disabled={!selectedCompany || !!initialDispatchLocation}
                    getOptionLabel={(option: Doc<'groups'>) =>
                      option?.name
                        ? `${option?.name} (${getPhysicalLocationTypeDisplay(option.locationType!)})`
                        : ''
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?._id === value?._id
                    }
                    onInputChange={newValue => {
                      debouncedLocationSearch(newValue);
                    }}
                    onChange={newValue => {
                      handleChange(newValue?._id || '');
                      setSelectedLocation(newValue);
                    }}
                    label="Default Dispatch Location"
                    error={state.meta.errors.length > 0}
                    helperText={
                      !selectedCompany
                        ? 'Must select a parent company first'
                        : state.meta.errors[0] || undefined
                    }
                  />
                )}
              />
              {serviceType && (
                <Field
                  name="serviceIds"
                  children={({ state, handleChange }) => (
                    <ServiceSelection
                      value={state.value}
                      onChange={handleChange}
                      error={state.meta.errors.length > 0}
                      helperText={state.meta.errors[0] as string}
                    />
                  )}
                />
              )}
              {serviceType && (
                <Field
                  name="simpleServiceAreaByMilesOut"
                  children={({ state, handleChange, handleBlur }) => (
                    <TextField
                      label="Service Radius (Miles Out)"
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={state.value}
                      error={state.meta.errors.length > 0}
                      helperText={state.meta.errors[0]}
                      onChange={e => {
                        const value = e.target.value;
                        handleChange(value === '' ? undefined : Number(value));
                      }}
                      onBlur={handleBlur}
                    />
                  )}
                />
              )}
            </Box>
            <Box display="flex" sx={{ mb: 2 }}>
              {serviceType && (
                <Field name="showInProviderDirectory">
                  {({ state, handleChange, handleBlur, store }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          id="showInProviderDirectory"
                          checked={state.value}
                          onChange={e => handleChange(e.target.checked)}
                        />
                      }
                      label="Show in Provider Directory"
                    />
                  )}
                </Field>
              )}
              <Field name="isRetail">
                {({ state, handleChange, handleBlur }) => (
                  <FormControlLabel
                    sx={{ ml: 2 }}
                    control={
                      <Switch
                        id="isRetail"
                        checked={state.value}
                        onChange={e => handleChange(e.target.checked)}
                      />
                    }
                    label="Has Retail Component"
                  />
                )}
              </Field>
            </Box>

            <Typography variant="h6">Location Contact Details</Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{ my: 2 }}
            >
              <Field
                name="email"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Contact Email"
                    variant="outlined"
                    fullWidth
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="phone"
                children={({ state, handleChange, handleBlur }) => (
                  <PhoneInput
                    required
                    label="Contact Phone Number"
                    fullWidth
                    value={state.value}
                    onChange={newValue => handleChange(newValue || '')}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
              <Field
                name="dispatchPhone"
                children={({ state, handleChange, handleBlur }) => (
                  <PhoneInput
                    label="Dispatch Phone Number"
                    fullWidth
                    value={state.value}
                    onChange={newValue => handleChange(newValue || '')}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />

              <Field
                name="emergencyPhone"
                children={({ state, handleChange, handleBlur }) => (
                  <PhoneInput
                    label="Emergency Phone Number"
                    fullWidth
                    value={state.value}
                    onChange={newValue => handleChange(newValue || '')}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
            </Box>

            <Typography variant="h6">Addtional Information</Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{ my: 2 }}
            >
              <Field
                name="notes"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    multiline
                    rows={4}
                    label="Additional Information"
                    variant="outlined"
                    fullWidth
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <Subscribe
                selector={state => [state.canSubmit, state.isSubmitting]}
                children={([canSubmit, isSubmitting]) => {
                  return (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={!canSubmit}
                    >
                      {!currentLocation ? 'Create location' : 'Save changes'}
                    </LoadingButton>
                  );
                }}
              />
            </Stack>
          </form>
        </Stack>
      </Card>
    </>
  );
}

interface FormAutocompleteProps<T> {
  options: T[];
  value: T | null;
  getOptionLabel: (option: T) => string;
  isOptionEqualToValue: (option: T, value: T) => boolean;
  onInputChange?: (value: string) => void;
  onChange: (value: T | null) => void;
  label: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string | null;
}

function FormAutocomplete<T>({
  options,
  value,
  getOptionLabel,
  isOptionEqualToValue,
  onInputChange,
  onChange,
  label,
  required,
  disabled,
  error,
  helperText,
}: FormAutocompleteProps<T>) {
  // Track the input value separately from the selected value
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      options={options}
      value={value}
      inputValue={inputValue}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
        onInputChange?.(newInputValue);
      }}
      onChange={(_event, newValue) => {
        onChange(newValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          required={required}
          disabled={disabled}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
}

const ServiceSelection = ({
  value,
  onChange,
  error,
  helperText,
}: {
  value: Id<'services'>[];
  onChange: (value: Id<'services'>[]) => void;
  error?: boolean;
  helperText?: string;
}) => {
  const services = useQuery(
    api.functions.serviceCategories.getServicesGroupedByCategory,
    {}
  );

  if (!services) return null;

  return (
    <FormControl fullWidth error={error}>
      <InputLabel htmlFor="services-select">Services Offered</InputLabel>
      <Select
        labelId="services-select"
        multiple
        value={value}
        onChange={e => onChange(e.target.value as Id<'services'>[])}
        label="Services Offered"
        renderValue={selected => {
          const selectedServices = services
            .flatMap(group => group.services)
            .filter(service => selected.includes(service._id))
            .map(service => service.name)
            .join(', ');
          return selectedServices;
        }}
      >
        {services.flatMap(category => [
          <ListSubheader key={`header-${category.category._id}`}>
            {category.category.name}
          </ListSubheader>,
          ...category.services.map(service => (
            <MenuItem key={`service-${service._id}`} value={service._id}>
              <Checkbox checked={value.includes(service._id)} />
              <ListItemText primary={service.name} />
            </MenuItem>
          )),
        ])}
      </Select>
    </FormControl>
  );
};
