import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material';
import { useMutation, useQuery } from 'convex/react';
import { useState } from 'react';
import { toast } from 'sonner';
import { api } from 'src/convex/_generated/api';
import { Doc, Id } from 'src/convex/_generated/dataModel';

type DriverQuickAssignFormProps = {
  open: boolean;
  onClose: () => void;
  driverId: Id<'users'>;
  currentVehicle?: Doc<'vehicles'> | null;
};

export function DriverQuickAssignForm({
  open,
  onClose,
  driverId,
  currentVehicle,
}: DriverQuickAssignFormProps) {
  const [selectedVehicle, setSelectedVehicle] =
    useState<Doc<'vehicles'> | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const vehicles = useQuery(api.functions.vehicles.getVehiclesForLocation);
  const assignVehicleToDriver = useMutation(
    api.functions.vehicles.assignVehicleToDriver
  );
  const unassignVehicleFromDriver = useMutation(
    api.functions.vehicles.unassignVehicleFromDriver
  );

  const handleUnassign = async () => {
    if (!currentVehicle) return;
    setIsLoading(true);
    try {
      const res = await unassignVehicleFromDriver({
        vehicleId: currentVehicle._id,
        driverIds: [driverId],
      });
      if (res.success) {
        toast.success(res.message);
        onClose();
      } else {
        toast.error(res.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAssign = async () => {
    if (!selectedVehicle) return;
    setIsLoading(true);
    try {
      const res = await assignVehicleToDriver({
        driverId,
        vehicleId: selectedVehicle._id,
      });

      if (res.success) {
        toast.success(res.message);
        onClose();
      } else {
        toast.error(res.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: 500 } }}
    >
      {currentVehicle ? (
        <>
          <DialogTitle>Unassign Vehicle</DialogTitle>
          <DialogContent>
            <p>
              Are you sure you want to unassign this driver from their current
              vehicle?
            </p>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              onClick={handleUnassign}
              color="primary"
              variant="contained"
              loading={isLoading}
            >
              Unassign
            </LoadingButton>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Assign Vehicle</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                options={vehicles || []}
                getOptionLabel={option =>
                  `${option.maker} ${option.model} ${option.modelYear} / Unit ${option.unitNumber}`
                }
                value={selectedVehicle}
                onChange={(event, newValue) => {
                  setSelectedVehicle(newValue);
                }}
                renderInput={params => (
                  <TextField {...params} label="Vehicle" required />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.maker} {option.model} {option.modelYear} / Unit{' '}
                    {option.unitNumber}
                  </li>
                )}
              />
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              onClick={handleAssign}
              color="primary"
              variant="contained"
              loading={isLoading}
              disabled={!selectedVehicle}
            >
              Assign
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
