import { createFileRoute } from '@tanstack/react-router';
import { api } from 'src/convex/_generated/api';
import { Id } from 'src/convex/_generated/dataModel';
import Page from 'src/pages/dashboard/truck/edit';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/trucks/$truckId')({
  beforeLoad: ({ context }) =>
    checkRouteAccess(context, routeAccessConfigs.fleetOnly),
  loader: async ({ context, params }) =>
    await context.convex.query(api.functions.vehicles.getVehicleById, {
      vehicleId: params.truckId as Id<'vehicles'>,
    }),
  component: Page,
});
