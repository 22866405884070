import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'src/config-global';
import { LocationListView } from 'src/sections/location/view';
const metadata = { title: `Locations | Dashboard - ${CONFIG.appName}` };

export default function LocationList() {
  return (
    <>
      <Helmet>
        <title> {metadata.title}</title>
      </Helmet>

      <LocationListView />
    </>
  );
}
