import { zid, zodToConvex } from 'convex-helpers/server/zod';
import { defineTable } from 'convex/server';
import { z } from 'zod';
import { Doc } from '../../_generated/dataModel';
import { locationSchema } from '../../types/locationSchema';
import { vehicleType } from '../enums/vehicleType';

export const vehicleTypeStatus = z.enum([
  'FLEET_OWNED', // Regular fleet vehicle
  'RENTAL', // Temporary rental vehicle
  'LEASED', // Long-term leased vehicle
  'BORROWED', // Borrowed from another fleet/company
]);

export const vehicleStatus = z
  .enum([
    'ACTIVE', // In regular service
    'OUT_OF_SERVICE', // Temporarily removed from service (non-mechanical)
    'RETIRED', // Permanently removed from service
    'SOLD', // No longer part of fleet
  ])
  .default('ACTIVE');

export const vehicleSchema = z.object({
  // vin existing means it has passed our verification against 3rd party API
  vin: z
    .string()
    .min(8, { message: 'VIN must be at least 8 characters long' })
    .max(17, { message: 'VIN must be at most 17 characters long' })
    .regex(/^[A-HJ-NPR-Z0-9]{8,17}$/, {
      message: 'VIN must contain only valid characters (A-H, J-N, P-R, Z, 0-9)',
    }),

  type: vehicleType.default('TRUCK').optional(),
  typeStatus: vehicleTypeStatus,
  // Operational status is derived from active requests
  status: vehicleStatus,

  // TODO: RF: Can these not be made mandatory... and can we actually hit an API or store these?
  maker: z.string().optional(),
  model: z.string().optional(),

  color: z.string().optional(),

  location: locationSchema.optional(), // Optional on add but should quickly find way to update

  bodyClass: z.string().optional(),
  brakeSystemType: z.string().optional(),
  displacementInLiters: z.string().optional(),
  driveType: z.string().optional(),
  engineConfiguration: z.string().optional(),
  engineCylinders: z.string().optional(),
  engineHP: z.string().optional(),
  engineModel: z.string().optional(),
  engineManufacturer: z.string().optional(),
  fuelTypePrimary: z.string().optional(),
  gvwr: z.string().optional(),
  manufacturer: z.string().optional(),
  modelId: z.string().optional(),
  modelYear: z.string().optional(),
  trim: z.string().optional(),
  unitNumber: z.string().optional(),
  isVinDecoded: z.boolean().default(false),

  primaryLocationGroupId: zid('groups').optional(),

  // Maps to started when driver changes and driver history record is created
  currentDriverStarted: z.string().optional(),
  updatedAt: z.string().optional(),
  deletedAt: z.string().optional(),

  // Relationships
  addedById: zid('users'),
  updatedById: zid('users'),
  companyId: zid('companies'),
});

export const vehicles = defineTable(zodToConvex(vehicleSchema).fields)
  .index('by_companyId', ['companyId'])
  .index('by_typeStatus', ['typeStatus'])
  .index('by_status', ['status'])
  .index('by_primaryLocationGroupId', ['primaryLocationGroupId'])
  .index('by_vin', ['vin']);

export type Vehicle = Doc<'vehicles'>;

export const partialVehicleInput = z.object({
  vin: z.string(),
  unitNumber: z.string().optional(),
  type: vehicleType.optional(),
  maker: z.string().optional(),
  model: z.string().optional(),
  color: z.string().optional(),
  addedById: zid('users').optional(),
  companyId: zid('companies').optional(),
  updatedById: zid('users').optional(),
  status: vehicleStatus,
  modelYear: z.string().optional(),
  primaryLocationGroupId: zid('groups').optional(),
});

export const decodeVinInput = z.object({
  vin: z.string(),
  vehicleId: zid('vehicles'),
});

// export const createVehicleInput = z.array(partialVehicleInput);

export const createVehicleInput = z.object({
  vehicles: z.array(partialVehicleInput),
});

export const partialVehicleSchema = vehicleSchema.partial();

export const createVehicleSchema = vehicleSchema.merge(partialVehicleSchema);

export const createVehicleActionOtherArgs = z.object({
  user: z.object({
    _id: zid('users'),
  }),
  companyId: zid('companies'),
  roles: z.array(z.string()),
});

export const updateVehicleInput = z.object({
  vehicleId: zid('vehicles'),
  vin: z.string(),
  type: vehicleType.optional(),
  maker: z.string().optional(),
  model: z.string().optional(),
  color: z.string().optional(),
  bodyClass: z.string().optional(),
  brakeSystemType: z.string().optional(),
  displacementInLiters: z.string().optional(),
  driveType: z.string().optional(),
  engineConfiguration: z.string().optional(),
  engineCylinders: z.string().optional(),
  engineHP: z.string().optional(),
  engineModel: z.string().optional(),
  engineManufacturer: z.string().optional(),
  fuelTypePrimary: z.string().optional(),
  gvwr: z.string().optional(),
  manufacturer: z.string().optional(),
  modelId: z.string().optional(),
  modelYear: z.string().optional(),
  trim: z.string().optional(),
  isVinDecoded: z.boolean().optional(),
  status: vehicleStatus,
  unitNumber: z.string().optional(),
});

export const getVehicleByIdInput = z.object({
  vehicleId: zid('vehicles'),
});

export const addPhotosToVehicleMutationInput = z.object({
  vehicleId: z.string(),
  photoUrls: z.array(z.string()),
});

export const assignVehicleToDriverSchema = z.object({
  vehicleId: zid('vehicles'),
  driverId: zid('users'),
});

export const unassignVehicleFromDriverSchema = z.object({
  vehicleId: zid('vehicles'),
  driverIds: z.array(zid('users')),
});
