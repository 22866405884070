import { DashboardContent } from '../../../minimal-theme/layouts/dashboard';

import { MechanicNewEditForm } from '../mechanic-new-edit-form';

export function MechanicCreateView() {
  return (
    <DashboardContent>
      <MechanicNewEditForm />
    </DashboardContent>
  );
}
