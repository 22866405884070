import { z } from 'zod';

export const serviceAreaShapes = z.enum([
  'radius', // Simple radius around a point
  'polygon', // Custom polygon for complex areas
  'rectangle', // Rectangular boundary (TOOD: only one that will work with convex geospatial component right now I think)
]);

export const radiusServiceArea = z.object({
  type: z.literal('radius'),
  radiusMiles: z.number(),
});

export const polygonServiceArea = z.object({
  type: z.literal('polygon'),
  coordinates: z.array(
    z.object({
      latitude: z.number(),
      longitude: z.number(),
    })
  ),
});

export const rectangleServiceArea = z.object({
  type: z.literal('rectangle'),
  bounds: z.object({
    north: z.number(),
    south: z.number(),
    east: z.number(),
    west: z.number(),
  }),
});
