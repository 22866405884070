import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from '@tanstack/react-router';
import { useMutation, useQuery } from 'convex/react';
import dayjs from 'dayjs';
import { capitalize } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { api } from 'src/convex/_generated/api';
import { Doc } from 'src/convex/_generated/dataModel';
import { RequestSearchComponent } from 'src/convex/schema/entities/requests/requests';
import {
  requestStatusFilterType,
  RequestStatusFilterType,
} from 'src/convex/schema/enums/requestStatusType';
import { ConfirmDialog } from 'src/minimal-theme/components/custom-dialog';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Label } from 'src/minimal-theme/components/label';
import { Scrollbar } from 'src/minimal-theme/components/scrollbar';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/minimal-theme/components/table';
import { useBoolean } from 'src/minimal-theme/hooks/use-boolean';
import { useSetState } from 'src/minimal-theme/hooks/use-set-state';
import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { varAlpha } from 'src/minimal-theme/theme/styles';
import type { RequestTableFilters } from 'src/types/request';
import { fIsAfter } from 'src/utils/format-time';
import { getStatusColor } from 'src/utils/helper';
import { RequestTableFiltersResult } from '../request-table-filters-result';
import { RequestTableRow } from '../request-table-row';
import { RequestTableToolbar } from '../request-table-toolbar';

export type RequestSearchHit = RequestSearchComponent & {
  matches: { start: number; end: number }[];
};

type RequestWithSearchHits = {
  filteredData: Doc<'requests'>[];
  searchHits: Map<string, RequestSearchHit[]>;
};

function findSearchHits(
  request: Doc<'requests'>,
  searchInput: string
): RequestSearchHit[] {
  if (!searchInput.trim() || !request.searchComponents) return [];

  const searchTerms = searchInput.toLowerCase().split(/\s+/).filter(Boolean);
  const hits: RequestSearchHit[] = [];

  request.searchComponents.forEach(source => {
    const matches: { start: number; end: number }[] = [];
    const text = source.text.toLowerCase();

    searchTerms.forEach(term => {
      let pos = text.indexOf(term);
      while (pos !== -1) {
        matches.push({ start: pos, end: pos + term.length });
        pos = text.indexOf(term, pos + 1);
      }
    });

    if (matches.length > 0) {
      hits.push({
        type: source.type,
        text: source.text,
        sourceId: source.sourceId,
        matches,
      });
    }
  });

  return hits;
}

function applyRequestTableFilter({
  inputData,
  filters,
  orderBy,
  order,
}: {
  inputData: Doc<'requests'>[];
  filters: RequestTableFilters;
  orderBy: string;
  order: 'asc' | 'desc';
}): RequestWithSearchHits {
  let filteredData = [...inputData];
  const searchHits = new Map<string, RequestSearchHit[]>();

  if (filters.status !== 'all') {
    filteredData = filteredData.filter(
      request => request.status === filters.status
    );
  }

  if (filters.name) {
    filteredData = filteredData.filter(request => {
      const hits = findSearchHits(request, filters.name);
      if (hits.length > 0) {
        searchHits.set(request._id, hits);
        return true;
      }
      return false;
    });
  }

  if (filters.startDate && filters.endDate) {
    const start = dayjs(filters.startDate);
    const end = dayjs(filters.endDate);
    filteredData = filteredData.filter(request => {
      const requestDate = dayjs(request.createdAt);
      return requestDate.isAfter(start) && requestDate.isBefore(end);
    });
  }

  filteredData.sort((a, b) => {
    const aVal = a[orderBy as keyof Doc<'requests'>];
    const bVal = b[orderBy as keyof Doc<'requests'>];
    if (typeof aVal === 'string' && typeof bVal === 'string') {
      return order === 'desc'
        ? -aVal.localeCompare(bVal)
        : aVal.localeCompare(bVal);
    }
    return 0;
  });

  return { filteredData, searchHits };
}

const STATUS_OPTIONS = [
  ...requestStatusFilterType.options.map(option => ({
    value: option,
    label: capitalize(option),
  })),
];

const TABLE_HEAD = [
  { id: '_id', label: 'Order' },
  {
    id: 'address',
    label: 'Address',
  },
  { id: 'status', label: 'Status' },
  { id: 'createdAt', label: 'Created At' },
  { id: '', width: 88 },
];

type RequestListViewProps = {
  requests: Doc<'requests'>[];
  title: string;
};

export function RequestListView({ requests, title }: RequestListViewProps) {
  const createDraftRequest = useMutation(
    api.functions.requests.createDraftRequest
  );

  const [initialized, setInitialized] = useState<boolean>(false);

  const navigate = useNavigate();

  const myDraftRequest = useQuery(api.functions.requests.getMyDraftRequest);

  const table = useTable({ defaultOrderBy: 'createdAt', defaultOrder: 'desc' });
  const confirm = useBoolean();

  // TODO: For dispatchers, should also be able to pass different location ids... or some solution whereby they can see requests associated with all the locations they're responsible for
  // const queuedRequests = useQuery(
  //   api.functions.requests.getQueuedRequests,
  //   user?.primaryRoleId && user?.primaryLocationGroupId
  //     ? {
  //         roleDefinitionId: user?.primaryRoleId,
  //         locationGroupId: user?.primaryLocationGroupId,
  //       }
  //     : 'skip'
  // );

  const filters = useSetState<RequestTableFilters>({
    name: '',
    status: 'all',
    startDate: null,
    endDate: null,
  });

  const hasActiveRequests = requests.some(r => r.status === 'ACTIVE');

  // On initial load, if there are active requests, we most likely want to see those as a priority, if eventually all active requests are completed/etc. we can switch back to all
  useEffect(() => {
    if (!initialized) {
      if (hasActiveRequests) {
        filters.setState({ status: 'ACTIVE' });
      }
      setInitialized(true);
    } else {
      if (!hasActiveRequests) {
        filters.setState({ status: 'all' });
      }
    }
  }, [initialized, hasActiveRequests]);

  const dateError = fIsAfter(filters.state.startDate, filters.state.endDate);

  const { filteredData, searchHits } = applyRequestTableFilter({
    inputData: requests,
    filters: filters.state,
    orderBy: table.orderBy,
    order: table.order,
  });

  const canReset =
    !!filters.state.name ||
    filters.state.status !== 'all' ||
    (!!filters.state.startDate && !!filters.state.endDate);

  const notFound = (!filteredData.length && canReset) || !filteredData.length;

  const handleDeleteRow = () => console.log('delete row');

  const handleDeleteRows = () => console.log('delete rows');

  const handleViewRow = (id: string) => {
    console.log(id);
  };

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: RequestStatusFilterType) => {
      table.onResetPage();
      filters.setState({ status: newValue });
    },
    [filters, table]
  );

  // This function filters out tabs with 0 items
  const getFilteredStatusOptions = useCallback(() => {
    return STATUS_OPTIONS.filter(tab => {
      if (tab.value === 'all') return true;
      if (tab.value === 'ACTIVE') {
        const count =
          requests.filter(request => request.status === 'ACTIVE').length ?? 0;
        return count > 0;
      }
      const count =
        requests.filter(request => request.status === tab.value).length ?? 0;
      return count > 0;
    });
  }, [requests]);

  if (!requests) return null;

  return (
    <>
      <DashboardContent>
        <Button
          color="primary"
          variant="contained"
          startIcon={<Iconify icon="mingcute:add-line" />}
          onClick={async () => {
            if (myDraftRequest) {
              navigate({
                to: '/dashboard/requests/$requestId',
                params: { requestId: myDraftRequest._id },
              });
            } else {
              const requestId = await createDraftRequest();

              navigate({
                to: '/dashboard/requests/$requestId',
                params: { requestId },
              });
            }
          }}
          sx={{ width: 'fit-content', mb: 2, ml: 'auto' }}
        >
          Add Request
        </Button>
        <Card>
          <Tabs
            value={filters.state.status}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: theme =>
                `inset 0 -2px 0 0 ${varAlpha(theme.vars.palette.grey['500Channel'], 0.08)}`,
            }}
          >
            {getFilteredStatusOptions().map(tab => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' ||
                        tab.value === filters.state.status) &&
                        'filled') ||
                      'soft'
                    }
                    color={getStatusColor(tab.value)}
                  >
                    {tab.value === 'all'
                      ? requests.length
                      : tab.value === 'ACTIVE'
                        ? requests.filter(
                            request => request.status === 'ACTIVE'
                          ).length
                        : requests.filter(
                            request => request.status === tab.value
                          ).length}
                  </Label>
                }
              />
            ))}
          </Tabs>

          <RequestTableToolbar
            filters={filters}
            onResetPage={table.onResetPage}
            dateError={dateError}
          />

          {canReset && (
            <RequestTableFiltersResult
              filters={filters}
              totalResults={filteredData.length}
              onResetPage={table.onResetPage}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <Box sx={{ position: 'relative' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={filteredData.length}
              onSelectAllRows={checked =>
                table.onSelectAllRows(
                  checked,
                  filteredData.map(row => row._id.toString())
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar sx={{ minHeight: 444 }}>
              <Table
                size={table.dense ? 'small' : 'medium'}
                sx={{ minWidth: 960 }}
              >
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={checked =>
                    table.onSelectAllRows(
                      checked,
                      filteredData.map(row => row._id.toString())
                    )
                  }
                />

                <TableBody>
                  {filteredData
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map(row => (
                      <RequestTableRow
                        key={row._id}
                        row={row}
                        selected={table.selected.includes(row._id)}
                        onSelectRow={() => table.onSelectRow(row._id)}
                        onDeleteRow={() => handleDeleteRow()}
                        onViewRow={() => handleViewRow(row._id)}
                        searchHits={searchHits}
                      />
                    ))}

                  <TableEmptyRows
                    height={table.dense ? 56 : 56 + 20}
                    emptyRows={emptyRows(
                      table.page,
                      table.rowsPerPage,
                      filteredData.length
                    )}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </Box>

          <TablePaginationCustom
            page={table.page}
            dense={table.dense}
            count={filteredData.length}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onChangeDense={table.onChangeDense}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>
      </DashboardContent>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete
            <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />

      {/* <Dialog
        fullWidth
        maxWidth="xs"
        open={addRequest.value}
        onClose={addRequest.onFalse}
      >
        <Card>
          <CardHeader
            title="Create New Request"
            subheader="Select a service workflow to create a new request"
          />
          <CardContent>
            <SelectWorkflowForm />
          </CardContent>
        </Card>
      </Dialog> */}
    </>
  );
}
