import { createFileRoute } from '@tanstack/react-router';
import Page from 'src/pages/dashboard/map/';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/map')({
  beforeLoad: ({ context }) =>
    checkRouteAccess(context, routeAccessConfigs.dispatcherGeneral),
  component: () => <Page />,
});
