import {
  getOneFrom,
  getOneFromOrThrow,
} from 'convex-helpers/server/relationships';
import { zid } from 'convex-helpers/server/zod';
import { paginationOptsValidator } from 'convex/server';
import { v } from 'convex/values';
import dayjs from 'dayjs';
import { z } from 'zod';
import { internal } from '../_generated/api';
import { Doc, Id } from '../_generated/dataModel';
import { DatabaseReader, MutationCtx, query } from '../_generated/server';
import { LOCATION_CHECK_INTERVAL } from '../nonnodeactions/requests';
import { calculateDistance } from '../schema/entities/groups/groups';
import {
  createHistoryEntry,
  formatHistoryEntry,
  messageTemplates,
} from '../schema/entities/requests/requestHistory';
import {
  generateCaseNumber,
  submitRepairDetailsInput,
} from '../schema/entities/requests/requests';
import { requestStatusType } from '../schema/enums/requestStatusType';
import { getUserRoleDisplay } from '../schema/enums/userRole';
import { getUserContext } from '../utils/getUserContext';
import { EmailTemplateNames } from '../utils/mail/repository/template.repository';
import {
  zInternalMutation,
  zInternalQuery,
  zMutation,
  zQuery,
} from './helpers/zodHelpers';
import { updateTechnicianStatusHelper } from './users';
import { updateDriverAndVehicleLocation } from './vehicles';

// Queries TODO: For now this basic getRequests is for all user roles to get everything and client side filter... will be made more robust
export const getRequestsParticipatingIn = zQuery({
  args: { status: requestStatusType.optional() },
  handler: async (ctx, { status }) => {
    const { user } = await getUserContext(ctx);

    if (user.primaryRoleType === 'FLEET_DISPATCHER') {
      return ctx.db
        .query('requests')
        .withIndex('by_activeFleetDispatcherId_and_status', q => {
          const baseQuery = q.eq('activeFleetDispatcherId', user._id);
          return status ? baseQuery.eq('status', status) : baseQuery;
        })
        .order('desc')
        .filter(q => q.neq(q.field('status'), 'DRAFT')) // TODO: Should be able to CONDITIONALLY run this... asked a question in the discord about it
        .collect();
    }

    if (user.primaryRoleType === 'SERVICE_DISPATCHER') {
      return ctx.db
        .query('requests')
        .withIndex('by_activeServiceDispatcherId_and_status', q => {
          const baseQuery = q.eq('activeServiceDispatcherId', user._id);
          return status ? baseQuery.eq('status', status) : baseQuery;
        })
        .order('desc')
        .filter(q => q.neq(q.field('status'), 'DRAFT'))
        .collect();
    }

    return [];
  },
});

export const getAllRequests = query({
  handler: async ctx => {
    const { user } = await getUserContext(ctx);

    switch (user.primaryRoleType) {
      // Driver gets requests where they are the active driver
      case 'DRIVER_FLEET':
        return await ctx.db
          .query('requests')
          .withIndex('by_activeDriverId_and_status', q =>
            q.eq('activeDriverId', user._id)
          )
          .filter(q => q.neq(q.field('status'), 'DRAFT'))
          .order('desc')
          .collect();

      // Fleet dispatcher gets requests where they are the fleet group
      case 'FLEET_DISPATCHER':
        return await ctx.db
          .query('requests')
          .withIndex('by_fleetDispatchGroupId_and_status', q =>
            q.eq('fleetDispatchGroupId', user.primaryLocationGroupId)
          )
          .filter(q => q.neq(q.field('status'), 'DRAFT'))
          .order('desc')
          .collect();

      // Service dispatcher gets requests where they are the mechanic dispatch group
      case 'SERVICE_DISPATCHER':
        return await ctx.db
          .query('requests')
          .withIndex('by_mechanicDispatchGroupId_and_status', q =>
            q.eq('mechanicDispatchGroupId', user.primaryLocationGroupId)
          )
          .order('desc')
          .filter(q => q.neq(q.field('status'), 'DRAFT'))
          .collect();

      // Technician gets requests where they are the active technician
      case 'TECHNICIAN_PROVIDER':
        return await ctx.db
          .query('requests')
          .withIndex('by_activeTechnicianId_and_status', q =>
            q.eq('activeTechnicianId', user._id)
          )
          .order('desc')
          .filter(q => q.neq(q.field('status'), 'DRAFT'))
          .collect();

      default:
        return [];
    }
  },
});

/**
 * Get a single request by ID, with permission checking
 */
export const getRequest = zQuery({
  args: {
    requestId: zid('requests'),
  },
  handler: async (ctx, { requestId }) => {
    // Get user context
    const { user, company, roles } = await getUserContext(ctx);

    // Get request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Check permissions
    const hasAccess = hasRequestAccess(request, {
      userId: user._id,
      companyId: company._id,
      roles: roles,
      locationGroupId: user.primaryLocationGroupId,
    });

    if (!hasAccess) {
      throw new Error('Not authorized to view this request');
    }

    // Load related data
    const [
      vehicle,
      activeDriver,
      fleetDispatcher,
      serviceDispatcher,
      technician,
      towOperator,
    ] = await Promise.all([
      request.vehicleId ? ctx.db.get(request.vehicleId) : null,
      request.activeDriverId ? ctx.db.get(request.activeDriverId) : null,
      request.activeFleetDispatcherId
        ? ctx.db.get(request.activeFleetDispatcherId)
        : null,
      request.activeServiceDispatcherId
        ? ctx.db.get(request.activeServiceDispatcherId)
        : null,
      request.activeTechnicianId
        ? ctx.db.get(request.activeTechnicianId)
        : null,
      request.activeTowOperatorId
        ? ctx.db.get(request.activeTowOperatorId)
        : null,
    ]);

    // Return request with related data
    return {
      ...request,
      vehicle,
      activeDriver,
      fleetDispatcher,
      serviceDispatcher,
      technician,
      towOperator,
    };
  },
});

export const getRequestInternally = zInternalQuery({
  args: {
    requestId: zid('requests'),
  },
  handler: async (ctx, { requestId }) => ctx.db.get(requestId),
});

const getDraftRequest = (db: DatabaseReader, userId: Id<'users'>) =>
  db
    .query('requests')
    .withIndex('by_createdById_and_status', q =>
      q.eq('createdById', userId).eq('status', 'DRAFT')
    )
    .first();

export const getMyDraftRequest = query({
  handler: async ctx => {
    const { user } = await getUserContext(ctx);

    return getDraftRequest(ctx.db, user._id);
  },
});

// TODO: Will have to tweak for dispatcher teams that look after multiple location groups
/**
 * Get requests in queue for a specific role
 */
export const getQueuedRequests = zQuery({
  handler: async ctx => {
    const { user, roles } = await getUserContext(ctx);

    const fleetDispatcherRole = roles.find(r => r?.type === 'FLEET_DISPATCHER');
    const serviceDispatcherRole = roles.find(
      r => r?.type === 'SERVICE_DISPATCHER'
    );

    if (!fleetDispatcherRole && !serviceDispatcherRole) {
      throw new Error('Must be a dispatcher to view queued requests');
    }

    if (fleetDispatcherRole) {
      return ctx.db
        .query('requests')
        .withIndex('by_fleetQueue', q =>
          q
            .eq('currentRequiredRoleId', fleetDispatcherRole._id)
            .eq('fleetDispatchGroupId', user.primaryLocationGroupId)
            .eq('status', 'ACTIVE')
            .eq('currentStepState', 'QUEUED')
        )
        .order('desc')
        .collect();
    } else if (serviceDispatcherRole) {
      return ctx.db
        .query('requests')
        .withIndex('by_serviceQueue', q =>
          q
            .eq('currentRequiredRoleId', serviceDispatcherRole._id)
            .eq('mechanicDispatchGroupId', user.primaryLocationGroupId)
            .eq('status', 'ACTIVE')
            .eq('currentStepState', 'QUEUED')
        )
        .order('desc')
        .collect();
    } else {
      return [];
    }
  },
});

/**
 * Get requests currently assigned to the user
 */
export const getAssignedRequests = zQuery({
  handler: async ctx => {
    const { user } = await getUserContext(ctx);
    if (!user) throw new Error('Not authenticated');

    return await ctx.db
      .query('requests')
      .withIndex('by_currentAssignedToId_and_status', q =>
        q.eq('currentAssignedToId', user._id).eq('status', 'ACTIVE')
      )
      .order('desc')
      .collect();
  },
});

// TODO: Enable passing in a status to make this more flexible.. possibly merge with some other query
export const getActiveRequests = query({
  handler: async ctx => {
    const { user } = await getUserContext(ctx);
    if (!user) throw new Error('Not authenticated');

    switch (user.primaryRoleType) {
      case 'DRIVER_FLEET':
        return ctx.db
          .query('requests')
          .withIndex('by_activeDriverId_and_status', q =>
            q.eq('activeDriverId', user._id).eq('status', 'ACTIVE')
          )
          .order('desc')
          .collect();

      case 'FLEET_DISPATCHER':
        return ctx.db
          .query('requests')
          .withIndex('by_fleetDispatchGroupId_and_status', q =>
            q
              .eq('fleetDispatchGroupId', user.primaryLocationGroupId)
              .eq('status', 'ACTIVE')
          )
          .order('desc')
          .collect();

      case 'SERVICE_DISPATCHER':
        return ctx.db
          .query('requests')
          .withIndex('by_mechanicDispatchGroupId_and_status', q =>
            q
              .eq('mechanicDispatchGroupId', user.primaryLocationGroupId)
              .eq('status', 'ACTIVE')
          )
          .order('desc')
          .collect();

      case 'TECHNICIAN_PROVIDER':
        return ctx.db
          .query('requests')
          .withIndex('by_activeTechnicianId_and_status', q =>
            q.eq('activeTechnicianId', user._id).eq('status', 'ACTIVE')
          )
          .order('desc')
          .collect();

      default:
        return [];
    }
  },
});

/**
 * Create a draft request with minimal information
 * This allows users to save partial progress when filling out the form
 */
export const createDraftRequest = zMutation({
  args: {},
  handler: async ctx => {
    const { user, roles, company, primaryLocation } = await getUserContext(ctx);

    const existingDraft = await getDraftRequest(ctx.db, user._id);

    if (existingDraft) {
      return existingDraft._id;
    }

    // Check if user is either a fleet dispatcher or driver
    const isDispatcher = roles.some(r => r?.type === 'FLEET_DISPATCHER');
    const isDriver = roles.some(r => r?.type === 'DRIVER_FLEET');

    if (!isDispatcher && !isDriver) {
      throw new Error(
        'Must be a fleet dispatcher or driver to create requests'
      );
    }

    let driver = isDriver ? user : undefined;
    let driverGroup = isDriver ? primaryLocation : undefined;
    let fleetDispatchGroup = isDispatcher ? primaryLocation : undefined;

    if (driver && driverGroup && driverGroup.defaultDispatchGroupId) {
      fleetDispatchGroup =
        (await ctx.db.get(driverGroup.defaultDispatchGroupId)) ?? undefined;
    }

    // TODO: service dispatchers can create too...
    // TODO: independent drivers with no dispatch can create...

    // Create draft request with minimal required info
    return await ctx.db.insert('requests', {
      status: 'DRAFT',
      createdAt: new Date().toISOString(),
      createdById: user._id,
      requesterCompanyId: company._id,
      caseNumber: generateCaseNumber(),

      driverGroupId: driverGroup ? driverGroup._id : undefined, // Undefined until submission when a driver will have been selected
      fleetDispatchGroupId: fleetDispatchGroup?._id,
      activeFleetDispatcherId: isDispatcher ? user._id : undefined,
      activeDriverId: isDriver ? user._id : undefined,
    });
  },
});

// Input schemas
const requestInput = z.object({
  description: z.string(),
  vehicleId: zid('vehicles'),
  address: z.string(),
  timezone: z.string(),
  activeDriverId: zid('users'),
  requestId: zid('requests'),
});

export const updateRequestLocation = zMutation({
  args: {
    requestId: zid('requests'),
    location: z.object({
      latitude: z.number(),
      longitude: z.number(),
      address: z.string(),
    }),
  },
  handler: async (
    ctx,
    { location: { latitude, longitude, address }, requestId }
  ) => {
    const { user } = await getUserContext(ctx);

    const request = await ctx.db.get(requestId);

    if (request && user._id === request.activeDriverId) {
      await ctx.db.patch(requestId, { address, longitude, latitude });
    }
  },
});

// TODO: Need to handle authz cases for ... ensuring if it's fleet dispatch creating, they are authorized to attach the driver/vehicle to a case
// TODO: This would include borrowed/rented temp vehicles, which we haven't fully worked out
// TODO: It would also have to allow for a SP who is being called directly by a driver... to attach them to a ticket (which negates the previous authz, but... what other measures can be in place here?)
export const submitRequest = zMutation({
  args: {
    input: requestInput,
  },
  handler: async (ctx, { input }) => {
    const { user, company, roles, primaryLocation } = await getUserContext(ctx);

    // Check if user is either a fleet dispatcher or driver
    const dispatcherRole = roles.find(r => r?.type === 'FLEET_DISPATCHER');
    const driverRole = roles.find(r => r?.type === 'DRIVER_FLEET');

    if (!dispatcherRole && !driverRole) {
      throw new Error(
        'Must be a fleet dispatcher or driver to create requests'
      );
    }

    const dispatcherRoleDef = await getOneFrom(
      ctx.db,
      'roleDefinitions',
      'by_type',
      'FLEET_DISPATCHER',
      'type'
    );

    if (!dispatcherRoleDef) {
      throw new Error('Dispatcher role not found');
    }

    const dispatcherRoleDefinitionId = dispatcherRoleDef._id;

    const { requestId, ...requestData } = input;

    if (dispatcherRole) {
      const [vehicle, request, driver] = await Promise.all([
        ctx.db.get(requestData.vehicleId),
        ctx.db.get(requestId),
        ctx.db.get(requestData.activeDriverId),
      ]);

      if (!request) {
        throw new Error('Request not found');
      }

      const haveRecentVehicleLocationData = isLocationRecentlyUpdated(vehicle);

      // We need to first request that the driver share their location, before dispatcher can find service provider
      if (!haveRecentVehicleLocationData) {
        if (
          driver &&
          driver.clerkUser.primaryEmailAddress?.emailAddress &&
          driver.clerkUser.primaryPhoneNumber?.phoneNumber
        ) {
          ctx.scheduler.runAfter(
            0,
            internal.actions.sendEmail.sendEmailUsingLocalTemplates,
            {
              to: driver.clerkUser.primaryEmailAddress?.emailAddress ?? '',
              emailType: 'NOTIFICATION',
              data: {
                userName: user.clerkUser.firstName,
                requestId,
                caseNumber: request.caseNumber,
                subject: 'Share Vehicle Location',
                additionalContext:
                  'Dispatch has assigned you the task of sharing your vehicle location',
              },
            }
          );

          if (driver.clerkUser.primaryPhoneNumber?.phoneNumber) {
            ctx.scheduler.runAfter(0, internal.actions.sendSms.sendSmsAction, {
              phoneNumber: driver.clerkUser.primaryPhoneNumber?.phoneNumber,
              message: `${user.clerkUser.fullName} [${getUserRoleDisplay(user.primaryRoleType)}] needs you to confirm location for: ${request.caseNumber}`,
              requestId: request._id,
            });
          } else {
            throw new Error(
              `Driver ${driver._id} does not have a phone number`
            );
          }
        }

        if (driver) {
          await Promise.all([
            ctx.db.patch(requestId, {
              ...requestData,
              status: 'ACTIVE',
              createdAt: new Date().toISOString(),
              currentStepStartedAt: new Date().toISOString(),
              createdById: user._id,
              requesterCompanyId: company._id,
              currentPhase: 'FLEET_DISPATCH',
              currentStepType: 'DRIVER_CONFIRM_LOCATION',
              currentStepState: 'ASSIGNED',
              currentRequiredRoleId: undefined,
              currentAssignedToId: requestData.activeDriverId,
              activeFleetDispatcherId: user._id,
              driverGroupId: driver?.primaryLocationGroupId,
              fleetDispatchGroupId: user.primaryLocationGroupId,
              requiresVehicleLocation: true,
            }),
            createHistoryEntry(ctx.db, {
              requestId,
              type: 'WORKFLOW_TRANSITION',
              userId: user._id,
              userRole: dispatcherRole._id,
              messageComponents:
                messageTemplates.fleetDispatcherAssignedToDriverToConfirmLocation(
                  {
                    dispatcherName: user.clerkUser.fullName,
                    dispatcherRole: dispatcherRole.type,
                    driverName: driver.clerkUser.fullName,
                    driverRole: driver.primaryRoleType!,
                  }
                ),
              visibleToRoles: [],
            }),
          ]);

          return true;
        }
      } else {
        // Dispatch submitting when we already have a valid driver location, so will be assigned to dispatch
        await Promise.all([
          ctx.db.patch(requestId, {
            ...requestData,
            status: 'ACTIVE',
            createdAt: new Date().toISOString(),
            currentStepStartedAt: new Date().toISOString(),
            createdById: user._id,
            requesterCompanyId: company._id,
            currentPhase: 'FLEET_DISPATCH',
            currentStepType: 'DISPATCH_TRIAGE',
            currentStepState: 'ASSIGNED',
            currentRequiredRoleId: undefined,
            currentAssignedToId: user._id,
            activeFleetDispatcherId: user._id,
            driverGroupId: driver?.primaryLocationGroupId,
            fleetDispatchGroupId: user.primaryLocationGroupId,
            requiresVehicleLocation: false,
            address: vehicle?.location?.address,
            longitude: vehicle?.location?.longitude,
            latitude: vehicle?.location?.latitude,
          }),
          createHistoryEntry(ctx.db, {
            requestId,
            type: 'REQUEST_CREATED',
            userId: user._id,
            userRole: dispatcherRole._id,
            messageComponents: messageTemplates.requestCreated({
              creatorName: user.clerkUser.fullName,
              creatorRole: dispatcherRole!.type,
            }),
            visibleToRoles: [],
          }),
        ]);

        if (
          driver &&
          driver.clerkUser.primaryEmailAddress?.emailAddress &&
          driver.clerkUser.primaryPhoneNumber?.phoneNumber
        ) {
          ctx.scheduler.runAfter(
            0,
            internal.actions.sendEmail.sendEmailUsingLocalTemplates,
            {
              to: driver.clerkUser.primaryEmailAddress?.emailAddress ?? '',
              emailType: 'NOTIFICATION',
              data: {
                userName: user.clerkUser.firstName,
                requestId,
                caseNumber: request.caseNumber,
                subject: 'Request Created',
                additionalContext:
                  'Fleet dispatch has created your request and is working on finding a service provider',
              },
            }
          );

          if (driver.clerkUser.primaryPhoneNumber?.phoneNumber) {
            ctx.scheduler.runAfter(0, internal.actions.sendSms.sendSmsAction, {
              phoneNumber: driver.clerkUser.primaryPhoneNumber?.phoneNumber,
              message: `${user.clerkUser.fullName} [${getUserRoleDisplay(user.primaryRoleType)}] has created your request and is working on finding a service provider: ${request.caseNumber}`,
              requestId: request._id,
            });
          } else {
            throw new Error(
              `Driver ${driver._id} does not have a phone number`
            );
          }
        }

        return true;
      }
    } else {
      const vehicle = await ctx.db.get(requestData.vehicleId);

      const haveRecentVehicleLocationData = isLocationRecentlyUpdated(vehicle);

      if (!haveRecentVehicleLocationData) {
        throw new Error(
          'Driver must confirm current location before submitting request'
        );
      }

      // Driver submitting TODO: probably eventually split these up into separate functions... they're distinct enough and zod validator will technically be different... i.e. driver MUST provide valid location when submitting themselves
      const updateRequestPromise = ctx.db.patch(requestId, {
        ...requestData,
        status: 'ACTIVE',
        createdAt: new Date().toISOString(),
        currentStepStartedAt: new Date().toISOString(),
        createdById: user._id,
        requesterCompanyId: company._id,
        currentPhase: 'FLEET_DISPATCH',
        currentStepType: 'DISPATCH_TRIAGE',
        currentStepState: 'QUEUED',
        currentRequiredRoleId: dispatcherRoleDefinitionId,
        currentAssignedToId: undefined,
        activeFleetDispatcherId: undefined,
        fleetDispatchGroupId: primaryLocation.defaultDispatchGroupId,
        address: vehicle?.location?.address,
        longitude: vehicle?.location?.longitude,
        latitude: vehicle?.location?.latitude,
      });

      await createHistoryEntry(ctx.db, {
        requestId,
        type: 'REQUEST_CREATED',
        userId: user._id,
        userRole: driverRole!._id,
        messageComponents: messageTemplates.requestCreated({
          creatorName: user.clerkUser.fullName,
          creatorRole: driverRole!.type,
        }),
        visibleToRoles: [],
      });

      await updateRequestPromise;

      return true;
    }
  },
});

export const driverConfirmLocationAndSendBackToDispatch = zMutation({
  args: {
    requestId: zid('requests'),
    location: z.object({
      latitude: z.number(),
      longitude: z.number(),
      address: z.string(),
    }),
  },
  handler: async (ctx, { requestId, location }) => {
    const { user, company, roles } = await getUserContext(ctx);

    const driverRole = roles.find(r => r?.type === 'DRIVER_FLEET');

    if (!driverRole) {
      throw new Error('Must be a driver');
    }

    // Get current request state
    const request = await ctx.db.get(requestId);

    if (!request) {
      throw new Error('Request not found');
    }

    // Validate request is in correct state to be claimed
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'FLEET_DISPATCH' ||
      request.currentStepState !== 'ASSIGNED' ||
      request.currentStepType !== 'DRIVER_CONFIRM_LOCATION' ||
      !request.vehicleId ||
      !request.activeFleetDispatcherId
    ) {
      throw new Error(
        'Request is not in the correct state for confirming location and sending back to fleet dispatcher'
      );
    }

    const fleetDispatcher = await ctx.db.get(request.activeFleetDispatcherId);

    // TODO: Need to change this to a new template for this purpose
    const emailTemplateName =
      EmailTemplateNames.ServiceRequestAssignedToServiceDispatcherNotification;

    await Promise.all([
      ctx.db.patch(requestId, {
        currentStepStartedAt: new Date().toISOString(),
        currentStepType: 'DISPATCH_TRIAGE',
        currentStepState: 'ASSIGNED',
        currentAssignedToId: request.activeFleetDispatcherId,
        longitude: location.longitude,
        latitude: location.latitude,
        address: location.address,
      }),
      updateDriverAndVehicleLocation(
        ctx,
        { vehicleId: request.vehicleId, location },
        user._id
      ),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'WORKFLOW_TRANSITION',
        userId: user._id,
        userRole: driverRole._id,
        messageComponents: messageTemplates.driverConfirmsLocation({
          driverName: user.clerkUser.fullName,
          driverRole: driverRole.type,
          dispatcherName: fleetDispatcher?.clerkUser.fullName ?? '',
          dispatcherRole:
            fleetDispatcher?.primaryRoleType ?? 'FLEET_DISPATCHER',
        }),
        visibleToRoles: [],
      }),
    ]);

    if (fleetDispatcher) {
      ctx.scheduler.runAfter(
        0,
        internal.actions.sendEmail.sendEmailUsingLocalTemplates,
        {
          to: fleetDispatcher.clerkUser.primaryEmailAddress?.emailAddress ?? '',
          emailType: 'NOTIFICATION',
          data: {
            userName: fleetDispatcher.clerkUser.firstName,
            requestId,
            caseNumber: request.caseNumber,
            subject: 'Driver Has Shared Location',
            additionalContext:
              'Driver has shared location and assigned the request back to you',
          },
        }
      );
    }

    return true;
  },
});

/**
 * Allow fleet dispatcher to claim a request from the fleet dispatch queue
 */
export const claimFleetDispatchRequest = zMutation({
  args: {
    requestId: zid('requests'),
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles } = await getUserContext(ctx);

    // TODO: more authorization checks needed... i.e. is the fleet dispatcher in the correct group to handle requests stemming from the relevant fleet driver group
    // Verify user is a fleet dispatcher
    const dispatcherRole = roles.find(r => r?.type === 'FLEET_DISPATCHER');
    if (!dispatcherRole) {
      throw new Error('Must be a fleet dispatcher to claim requests');
    }

    // Get current request state
    const request = await ctx.db.get(requestId);
    if (!request) {
      throw new Error('Request not found');
    }

    // Validate request is in correct state to be claimed
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'FLEET_DISPATCH' ||
      request.currentStepState !== 'QUEUED' ||
      !request.currentRequiredRoleId
    ) {
      throw new Error('Request is not available for claiming');
    }

    // Verify the required role matches
    if (request.currentRequiredRoleId !== dispatcherRole._id) {
      throw new Error('User does not have required role to claim this request');
    }

    // Update request - this will fail if another dispatcher claimed it first (TODO: Add tests... should fail because it will rerun our above validations if document version differs)
    try {
      await Promise.all([
        ctx.db.patch(requestId, {
          currentStepState: 'ASSIGNED',
          currentAssignedToId: user._id,
          activeFleetDispatcherId: user._id,
          currentRequiredRoleId: undefined,
        }),
        createHistoryEntry(ctx.db, {
          requestId,
          type: 'WORKFLOW_TRANSITION',
          userId: user._id,
          userRole: dispatcherRole._id,
          messageComponents: messageTemplates.fleetDispatcherClaimed({
            dispatcherName: user.clerkUser.fullName,
            dispatcherRole: user.primaryRoleType ?? 'FLEET_DISPATCHER',
          }),
          visibleToRoles: [],
        }),
      ]);
    } catch (error) {
      throw new Error('Failed to claim request');
    }

    //driver should be notified

    return {
      success: true,
      message: 'You have successfully claimed this request',
    };
  },
});

// TODO: Needs lots of changes and enhancements, very basic...
/**
 * Check if a user has permission to access a request based on their role and involvement
 */
export function hasRequestAccess(
  request: Doc<'requests'>,
  {
    userId,
    companyId,
    roles,
    locationGroupId,
  }: {
    userId: Id<'users'>;
    companyId: Id<'companies'>;
    roles: Array<{ type: string; _id: string } | null>;
    locationGroupId?: Id<'groups'>;
  }
): boolean {
  if (!locationGroupId) {
    return false;
  }
  // Check if user is directly involved in request
  const isDirectParticipant = [
    request.activeDriverId,
    request.activeFleetDispatcherId,
    request.activeServiceDispatcherId,
    request.activeTechnicianId,
    request.activeTowOperatorId,
    request.currentAssignedToId,
    request.createdById,
  ].includes(userId);

  if (isDirectParticipant) return true;

  // Check if user belongs to one of the involved companies
  const isInvolvedCompany = [
    request.requesterCompanyId,
    request.serviceProviderCompanyId,
    request.towingCompanyId,
  ].includes(companyId);

  const hasLocationAccess = [
    request.driverGroupId,
    request.fleetDispatchGroupId,
    request.towDispatchGroupId,
    request.towServiceGroupId,
    request.mechanicDispatchGroupId,
    request.mechanicServiceGroupId,
  ].includes(locationGroupId);

  // TODO: For dispatchers, need to check dispatchCoverageGroupIds or whatever equivalent we create so dispatchers can cover multiple locations

  if (!isInvolvedCompany || !hasLocationAccess) return false;

  // Check role-based permissions
  const hasPermittedRole = roles.some(role => {
    if (!role) return false;

    switch (role.type) {
      // Fleet company roles
      case 'FLEET_MANAGER':
      case 'FLEET_ADMIN':
      case 'FLEET_LOCATION_MANAGER':
        return request.requesterCompanyId === companyId;

      // Service provider roles
      case 'SERVICE_MANAGER':
      case 'SERVICE_ADMIN':
      case 'SERVICE_LOCATION_MANAGER':
        return request.serviceProviderCompanyId === companyId;

      // Dispatch roles - can see requests in their queue/company
      case 'FLEET_DISPATCHER':
      case 'FLEET_DISPATCH_SUPERVISOR':
      case 'FLEET_DISPATCH_LOCATION_MANAGER':
        return request.requesterCompanyId === companyId;

      case 'SERVICE_DISPATCHER':
      case 'SERVICE_DISPATCH_SUPERVISOR':
      case 'SERVICE_DISPATCH_LOCATION_MANAGER':
        return request.serviceProviderCompanyId === companyId;

      default:
        return false;
    }
  });

  return hasPermittedRole;
}

// export const claimRequestFromFleetDispatchQueue = zMutation({
//   args: {
//     requestId: zid('requests'),
//   },
//   handler: async (ctx, { requestId }) => {
//     const { user, roles } = await getUserContext(ctx);
//     if (!user) throw new Error('Not authenticated');

//     // TODO: More authz needed here...

//     // Verify user is a fleet dispatcher
//     const dispatcherRole = roles.find(r => r?.type === 'FLEET_DISPATCHER');
//     if (!dispatcherRole) {
//       return {
//         success: false,
//         message: 'Must be a fleet dispatcher to claim requests',
//       };
//     }

//     // Get current request state
//     const request = await ctx.db.get(requestId);
//     if (!request) {
//       return {
//         success: false,
//         message: 'Request not found',
//       };
//     }

//     // Validate request state
//     if (
//       request.status !== 'ACTIVE' ||
//       request.currentPhase !== 'FLEET_DISPATCH' ||
//       request.currentStepState !== 'QUEUED' ||
//       !request.currentRequiredRoleId
//     ) {
//       return {
//         success: false,
//         message: 'Request is not available for claiming',
//       };
//     }

//     // Verify role matches
//     if (request.currentRequiredRoleId !== dispatcherRole._id) {
//       return {
//         success: false,
//         message: 'User does not have required role to claim this request',
//       };
//     }

//     try {
//       await Promise.all([
//         ctx.db.patch(requestId, {
//           currentStepState: 'ASSIGNED',
//           currentAssignedToId: user._id,
//           activeFleetDispatcherId: user._id,
//           currentRequiredRoleId: undefined,
//         }),
//         createHistoryEntry(ctx.db, {
//           requestId,
//           type: 'FLEET_DISPATCHER_CLAIMED',
//           userId: user._id,
//           userRole: dispatcherRole._id,
//           messageComponents: [
//             { type: 'USER_NAME', value: user.clerkUser.fullName },
//             { type: 'ACTION_TEXT', value: ' [as ' },
//             { type: 'USER_ROLE', value: 'Fleet Dispatcher' },
//             { type: 'ACTION_TEXT', value: '] claimed the request from queue' },
//           ],
//           visibleToRoles: [],
//         }),
//       ]);

//       return {
//         success: true,
//         message: 'Successfully claimed request',
//       };
//     } catch (error) {
//       return {
//         success: false,
//         message: 'Failed to claim request',
//       };
//     }
//   },
// });

const assignToServiceProviderInput = z.object({
  requestId: zid('requests'),
  locationGroupId: zid('groups'), // The service provider location group
  estimatedTimeOfArrival: z.string().optional(),
});

export const assignToServiceProviderDispatch = zMutation({
  args: assignToServiceProviderInput.shape,
  handler: async (
    ctx,
    { requestId, locationGroupId, estimatedTimeOfArrival }
  ) => {
    const { user, roles, primaryLocation } = await getUserContext(ctx);

    // Verify user is fleet dispatcher
    const isFleetDispatcher = roles.some(r => r?.type === 'FLEET_DISPATCHER');
    if (!isFleetDispatcher) {
      throw new Error('Only fleet dispatchers can assign to service providers');
    }

    const [request, locationGroup, dispatcherRole] = await Promise.all([
      ctx.db.get(requestId),
      ctx.db.get(locationGroupId),
      getOneFrom(
        ctx.db,
        'roleDefinitions',
        'by_type',
        'SERVICE_DISPATCHER',
        'type'
      ),
    ]);

    if (!request) throw new Error('Request not found');

    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'FLEET_DISPATCH' ||
      request.currentStepState !== 'ASSIGNED' ||
      request.activeFleetDispatcherId !== user._id
    ) {
      throw new Error('Request is not in valid state for assignment');
    }

    if (!locationGroup || !locationGroup.companyId) {
      throw new Error('Service provider location not found');
    }

    if (!dispatcherRole) {
      throw new Error('Service dispatcher role not found');
    }

    if (
      !user.primaryLocationGroupId
      // TODO: removing this because it is WRONG, but we still need to have some sort of check here... just can't think of what it is now
      // (user.primaryLocationGroupId !== request.driverGroupId &&
      //   primaryLocation?.dispatchCoverageGroupIds?.some(
      //     g => g === request.driverGroupId
      //   ))
    ) {
      throw new Error('Not authorized to assign requests from this location');
    }

    await Promise.all([
      ctx.db.patch(requestId, {
        currentPhase: 'MECHANIC_DISPATCH',
        currentStepType: 'WITH_SERVICE_PROVIDER_DISPATCH',
        currentStepState: 'QUEUED',
        serviceProviderCompanyId: locationGroup.companyId,
        mechanicDispatchGroupId: locationGroup.defaultDispatchGroupId,
        mechanicServiceGroupId: locationGroup._id,
        currentRequiredRoleId: dispatcherRole._id,
        currentAssignedToId: undefined,
        estimatedTimeOfArrival,
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'WORKFLOW_TRANSITION',
        userRole: user.primaryRoleId!,
        userId: user._id,
        visibleToRoles: [],
        messageComponents:
          messageTemplates.fleetDispatcherAssignedToServiceProvider({
            dispatcherName: user.clerkUser.fullName,
            dispatcherRole: user.primaryRoleType ?? 'FLEET_DISPATCHER',
            serviceProviderName: locationGroup.name,
          }),
      }),
    ]);

    // Schedule timeout check for 5 minutes from now
    const TIMEOUT_MS = 5 * 60 * 1000; // 5 minutes

    ctx.scheduler.runAfter(
      TIMEOUT_MS,
      internal.nonnodeactions.requests.handleServiceProviderTimeout,
      {
        requestId,
        originalDispatcherId: user._id,
        locationGroupId,
      }
    );

    return {
      success: true,
      message: 'Request assigned to service provider dispatch queue',
    };
  },
});

const assignDirectlyToInvitedServiceDispatcherInput = z.object({
  requestId: zid('requests'),
  locationGroupId: zid('groups'),
  companyId: zid('companies'),
  serviceDispatcherId: zid('users'),
  roleOfInviterId: zid('roleDefinitions'),
  userInvitingId: zid('users'),
  userBeingInvitedId: zid('users'),
});

export const assignDirectlyToInvitedServiceDispatcher = zInternalMutation({
  args: assignDirectlyToInvitedServiceDispatcherInput.shape,
  handler: async (
    ctx,
    {
      requestId,
      locationGroupId,
      serviceDispatcherId,
      companyId,
      roleOfInviterId,
      userInvitingId,
      userBeingInvitedId,
    }
  ) => {
    try {
      const [userInviting, userBeingInvited, locationGroup] = await Promise.all(
        [
          ctx.db.get(userInvitingId),
          ctx.db.get(userBeingInvitedId),
          ctx.db.get(locationGroupId),
        ]
      );

      if (!userInviting || !userBeingInvited || !locationGroup) {
        throw new Error(
          `Failed to update request: inviter or invitee user could not be found`
        );
      } else {
        await Promise.all([
          ctx.db.patch(requestId, {
            currentPhase: 'MECHANIC_DISPATCH',
            currentStepType: 'WITH_SERVICE_PROVIDER_DISPATCH',
            currentStepState: 'ASSIGNED',
            serviceProviderCompanyId: companyId,
            mechanicDispatchGroupId: locationGroup.defaultDispatchGroupId,
            mechanicServiceGroupId: locationGroup._id,
            currentRequiredRoleId: undefined,
            currentAssignedToId: serviceDispatcherId,
            activeServiceDispatcherId: serviceDispatcherId,
          }),
          createHistoryEntry(ctx.db, {
            requestId,
            type: 'WORKFLOW_TRANSITION',
            userId: userInviting._id,
            userRole: roleOfInviterId,
            visibleToRoles: [],
            messageComponents:
              messageTemplates.fleetDispatcherAssignedToServiceProvider({
                dispatcherName: userInviting.clerkUser.fullName,
                dispatcherRole:
                  userInviting.primaryRoleType ?? 'FLEET_DISPATCHER',
                serviceProviderName: locationGroup.name,
                serviceDispatcherName: userBeingInvited.clerkUser.fullName,
                serviceDispatcherRole:
                  userBeingInvited.primaryRoleType ?? 'SERVICE_DISPATCHER',
              }),
          }),
        ]);
        return {
          success: true,
          message: 'Request assigned to service provider dispatch queue',
        };
      }
    } catch (error) {
      // Log error for debugging
      console.error('Failed to assign to service dispatcher:', error);
      // Throw technical error for caller to handle
      throw new Error(
        `Failed to update request ${requestId} or create history entry: ${error instanceof Error ? error.message : 'Unknown error'}`
      );
    }
  },
});

const assignDirectlyToInvitedProviderTechnicianInput = z.object({
  requestId: zid('requests'),
  technicianId: zid('users'),
  serviceDispatcherId: zid('users'),
  dispatcherRoleId: zid('roleDefinitions'),
  dispatcherName: z.string(),
  estimatedTimeOfArrival: z.string().optional(),
});

export const assignDirectlyToInvitedProviderTechnician = zInternalMutation({
  args: assignDirectlyToInvitedProviderTechnicianInput.shape,
  handler: async (ctx, args) => {
    await assignTechnicianToRequest(ctx, args);

    return {
      success: true,
      message: 'Request assigned to provider technician',
    };
  },
});

// For service dispatchers to claim requests from their queue
export const claimServiceDispatchRequest = zMutation({
  args: {
    requestId: zid('requests'),
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles } = await getUserContext(ctx);

    // Verify user is service dispatcher
    const dispatcherRole = roles.find(r => r?.type === 'SERVICE_DISPATCHER');
    if (!dispatcherRole) {
      throw new Error('Must be a service dispatcher to claim requests');
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'MECHANIC_DISPATCH' ||
      request.currentStepState !== 'QUEUED' ||
      !request.currentRequiredRoleId ||
      request.currentRequiredRoleId !== dispatcherRole._id
    ) {
      throw new Error('Request is not available for claiming');
    }

    // Update request
    await ctx.db.patch(requestId, {
      currentStepState: 'ASSIGNED',
      currentAssignedToId: user._id,
      activeServiceDispatcherId: user._id,
      currentRequiredRoleId: undefined,
    });

    // Record assignment
    // await ctx.db.insert('requestHistory', {
    //   requestId,
    //   timestamp: new Date().toISOString(),
    //   type: 'ASSIGNMENT_CREATED',
    //   userId: user._id,
    //   visibleToRoles: [],
    // });

    // for each of the users that need to be notified run the send email action
    const [driver, fleet, serviceDispatcher] = await Promise.all([
      request.activeDriverId
        ? ctx.db.get(request.activeDriverId)
        : Promise.resolve(null),
      request.activeFleetDispatcherId
        ? ctx.db.get(request.activeFleetDispatcherId)
        : Promise.resolve(null),
      request.activeServiceDispatcherId
        ? ctx.db.get(request.activeServiceDispatcherId)
        : Promise.resolve(null),
    ]);

    for (const user of [driver, fleet]) {
      if (user) {
        ctx.scheduler.runAfter(
          0,
          internal.actions.sendEmail.sendEmailUsingLocalTemplates,
          {
            to: user.clerkUser.primaryEmailAddress?.emailAddress ?? '',
            emailType: 'NOTIFICATION',
            data: {
              userName: user.clerkUser.firstName,
              requestId,
              caseNumber: request.caseNumber,
              subject: 'Service Dispatch Accepted Request',
              additionalContext: `Service dispatch has accepted the request`,
            },
          }
        );
      }
    }

    return {
      success: true,
      message: 'Successfully claimed request',
    };
  },
});

export const getAvailableTechnicians = zQuery({
  args: {
    requestId: zid('requests'),
  },
  handler: async (ctx, { requestId }) => {
    const { user, company } = await getUserContext(ctx);

    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Verify dispatcher belongs to service provider company
    if (request.serviceProviderCompanyId !== company._id) {
      throw new Error('Not authorized to view technicians for this request');
    }

    const technicians = await ctx.db
      .query('users')
      .withIndex('by_primaryLocationGroupId_and_primaryRoleType', q =>
        q
          .eq('primaryLocationGroupId', request.mechanicServiceGroupId)
          .eq('primaryRoleType', 'TECHNICIAN_PROVIDER')
      )
      .collect();

    // Get vehicle location from request TODO: might need to get rid of this if we can't ensure vehicle has location for demo
    const vehicle = request.vehicleId
      ? await ctx.db.get(request.vehicleId)
      : null;
    if (!vehicle?.location) {
      throw new Error('Vehicle location not found');
    }

    // Calculate distances and filter by service radius TODO: use more geospatial indexing here
    const techniciansWithDistance = technicians
      .map(tech => {
        if (
          !tech.location?.latitude ||
          !tech.location?.longitude ||
          !vehicle.location?.latitude ||
          !vehicle.location?.longitude
        )
          return null;
        const distance = calculateDistance(
          vehicle.location!.latitude,
          vehicle.location!.longitude,
          tech.location.latitude,
          tech.location.longitude
        );

        return {
          ...tech,
          distance,
          estimatedMinutes: Math.round((distance / 45) * 60),
          withinServiceArea: distance <= (tech.activityRadius || 50),
        };
      })
      .filter(
        (tech): tech is NonNullable<typeof tech> =>
          tech !== null && tech.withinServiceArea
      )
      .sort((a, b) => a.distance - b.distance);

    //TODO : if no technicians within service area, show all technicians
    return {
      techniciansWithDistance,
      technicians,
    };
  },
});

// Assign technician to request
export const assignTechnician = zMutation({
  args: {
    requestId: zid('requests'),
    technicianId: zid('users'),
    estimatedTimeOfArrival: z.string().optional(),
  },
  handler: async (ctx, args) => {
    const { user, roles } = await getUserContext(ctx);

    // Verify user is service dispatcher
    const serviceDispatcherRole = roles.find(
      r => r?.type === 'SERVICE_DISPATCHER'
    );

    const isServiceDispatcher = !!serviceDispatcherRole;

    if (!isServiceDispatcher) {
      throw new Error('Must be a service dispatcher to assign technicians');
    }

    await assignTechnicianToRequest(ctx, {
      ...args,
      serviceDispatcherId: user._id,
      dispatcherRoleId: serviceDispatcherRole._id,
      dispatcherName: user.clerkUser.fullName,
    });

    return {
      success: true,
      message: 'Technician successfully assigned',
    };
  },
});

async function assignTechnicianToRequest(
  ctx: MutationCtx,
  params: {
    requestId: Id<'requests'>;
    technicianId: Id<'users'>;
    serviceDispatcherId: Id<'users'>;
    dispatcherName: string;
    dispatcherRoleId: Id<'roleDefinitions'>;
    estimatedTimeOfArrival?: string;
  }
) {
  const [request, technician, technicianRole] = await Promise.all([
    ctx.db.get(params.requestId),
    ctx.db.get(params.technicianId),
    getOneFrom(
      ctx.db,
      'roleDefinitions',
      'by_type',
      'TECHNICIAN_PROVIDER',
      'type'
    ),
  ]);

  if (!request || !technician || !technicianRole) {
    throw new Error('Request, technician or technician role not found');
  }

  await Promise.all([
    ctx.db.patch(params.requestId, {
      currentPhase: 'MECHANIC_SERVICE',
      currentStepType: 'TECHNICIAN_ASSIGNED',
      currentStepState: 'ASSIGNED',
      currentAssignedToId: params.technicianId,
      currentRequiredRoleId: technicianRole._id,
      activeTechnicianId: params.technicianId,
      mechanicServiceGroupId: technician.primaryLocationGroupId,
      estimatedTimeOfArrival: params.estimatedTimeOfArrival,
    }),
    updateTechnicianStatusHelper(ctx, params.technicianId, 'BUSY'),
    createHistoryEntry(ctx.db, {
      requestId: params.requestId,
      type: 'WORKFLOW_TRANSITION',
      userId: params.serviceDispatcherId,
      userRole: params.dispatcherRoleId,
      messageComponents: messageTemplates.technicianAssignedToRequest({
        dispatcherName: params.dispatcherName,
        dispatcherRole: 'SERVICE_DISPATCHER',
        technicianName: technician.clerkUser.fullName,
        technicianRole: technicianRole.type,
      }),
      visibleToRoles: [],
      details: {
        targetUserId: params.technicianId,
        targetRoleId: technicianRole._id,
      },
    }),
  ]);

  ctx.scheduler.runAfter(
    0,
    internal.actions.sendEmail.sendEmailUsingLocalTemplates,
    {
      to: technician.clerkUser.primaryEmailAddress?.emailAddress ?? '',
      emailType: 'NOTIFICATION',
      data: {
        userName: technician.clerkUser.firstName,
        requestId: request._id,
        caseNumber: request.caseNumber,
        subject: 'You Have Been Assigned a Case',
        additionalContext: `Service dispatch has assigned you a case`,
      },
    }
  );

  return { technician, request };
}

// For technicians to accept assignments
export const acceptTechnicianAssignment = zMutation({
  args: {
    requestId: zid('requests'),
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles } = await getUserContext(ctx);

    // Verify user is technician
    const technicianRole = roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');
    if (!technicianRole) {
      throw new Error('Must be a technician to accept assignments');
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'MECHANIC_SERVICE' ||
      request.currentStepState !== 'ASSIGNED' ||
      request.activeTechnicianId !== user._id
    ) {
      throw new Error('Request is not available for acceptance');
    }

    await Promise.all([
      ctx.db.patch(requestId, {
        currentStepType: 'TECHNICIAN_ACCEPTED',
        currentAssignedToId: user._id,
        currentRequiredRoleId: undefined,
      }),
      ctx.scheduler.runAfter(
        LOCATION_CHECK_INTERVAL,
        internal.nonnodeactions.requests.monitorTechnicianLocation,
        {
          requestId,
          technicianId: user._id,
        }
      ),
    ]);

    // Notify everyone
    // for each of the users that need to be notified run the send email action
    const [driver, fleet, serviceDispatcher] = await Promise.all([
      request.activeDriverId
        ? ctx.db.get(request.activeDriverId)
        : Promise.resolve(null),
      request.activeFleetDispatcherId
        ? ctx.db.get(request.activeFleetDispatcherId)
        : Promise.resolve(null),
      request.activeServiceDispatcherId
        ? ctx.db.get(request.activeServiceDispatcherId)
        : Promise.resolve(null),
    ]);

    for (const user of [driver, fleet, serviceDispatcher]) {
      if (user) {
        ctx.scheduler.runAfter(
          0,
          internal.actions.sendEmail.sendEmailUsingLocalTemplates,
          {
            to: user.clerkUser.primaryEmailAddress?.emailAddress ?? '',
            emailType: 'NOTIFICATION',
            data: {
              userName: user.clerkUser.firstName,
              requestId: request._id,
              caseNumber: request.caseNumber,
              subject: 'Technician Has Accepted Case',
              additionalContext: `Service technician has accepted the case and is on the way`,
            },
          }
        );
      }
    }

    return {
      success: true,
      message: 'Assignment accepted successfully',
    };
  },
});

// Mark technician as arrived at location
export const technicianArrivedAtLocation = zMutation({
  args: {
    requestId: zid('requests'),
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles } = await getUserContext(ctx);

    // Verify user is technician
    const technicianRole = roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');
    if (!technicianRole) {
      throw new Error('Must be a technician to update arrival status');
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Validate request state
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'MECHANIC_SERVICE' ||
      request.currentStepType !== 'TECHNICIAN_ACCEPTED' ||
      request.activeTechnicianId !== user._id
    ) {
      throw new Error('Request is not in valid state for arrival update');
    }

    await Promise.all([
      ctx.db.patch(requestId, {
        currentStepType: 'TECHNICIAN_ARRIVED',
        currentStepStartedAt: new Date().toISOString(),
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'WORKFLOW_TRANSITION',
        userId: user._id,
        userRole: technicianRole._id,
        messageComponents: messageTemplates.technicianAction({
          technicianName: user.clerkUser.fullName,
          technicianRole: user.primaryRoleType!,
          action: ' has arrived on site',
        }),
        visibleToRoles: [],
      }),
    ]);

    // Notify driver and service dispatcher
    // for each of the users that need to be notified run the send email action
    const [driver, serviceDispatcher] = await Promise.all([
      request.activeDriverId
        ? ctx.db.get(request.activeDriverId)
        : Promise.resolve(null),
      request.activeServiceDispatcherId
        ? ctx.db.get(request.activeServiceDispatcherId)
        : Promise.resolve(null),
    ]);

    for (const user of [driver, serviceDispatcher]) {
      if (user) {
        ctx.scheduler.runAfter(
          0,
          internal.actions.sendEmail.sendEmailUsingLocalTemplates,
          {
            to: user.clerkUser.primaryEmailAddress?.emailAddress ?? '',
            emailType: 'NOTIFICATION',
            data: {
              userName: user.clerkUser.firstName,
              requestId: request._id,
              caseNumber: request.caseNumber,
              subject: 'Technician Has Arrived At Job Site',
              additionalContext: `Service technician has arrived at the job site`,
            },
          }
        );
      }
    }

    return {
      success: true,
      message: 'Arrival status updated successfully',
    };
  },
});

// Start work on request
export const startWorkOnRequest = zMutation({
  args: {
    requestId: zid('requests'),
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles } = await getUserContext(ctx);

    // Verify user is technician
    const technicianRole = roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');
    if (!technicianRole) {
      throw new Error('Must be a technician to start work');
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Validate request state
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'MECHANIC_SERVICE' ||
      request.currentStepType !== 'TECHNICIAN_ARRIVED' ||
      request.activeTechnicianId !== user._id
    ) {
      throw new Error('Request is not in valid state to start work');
    }

    // Update request state to work started
    await ctx.db.patch(requestId, {
      currentStepType: 'TECHNICIAN_STARTED_WORK',
      currentStepStartedAt: new Date().toISOString(),
    });

    // Record in history
    await Promise.all([
      ctx.db.patch(requestId, {
        currentStepType: 'TECHNICIAN_STARTED_WORK',
        currentStepStartedAt: new Date().toISOString(),
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'WORKFLOW_TRANSITION',
        userId: user._id,
        userRole: technicianRole._id,
        messageComponents: messageTemplates.technicianAction({
          technicianName: user.clerkUser.fullName,
          technicianRole: user.primaryRoleType!,
          action: ' started work on the repair',
        }),
        visibleToRoles: [],
      }),
    ]);

    // notify service dispatcher
    const [technician, serviceDispatcher] = await Promise.all([
      request.activeTechnicianId
        ? ctx.db.get(request.activeTechnicianId)
        : Promise.resolve(null),
      request.activeServiceDispatcherId
        ? ctx.db.get(request.activeServiceDispatcherId)
        : Promise.resolve(null),
    ]);

    ctx.scheduler.runAfter(
      0,
      internal.actions.sendEmail.sendEmailUsingLocalTemplates,
      {
        to:
          serviceDispatcher?.clerkUser.primaryEmailAddress?.emailAddress ?? '',
        emailType: 'NOTIFICATION',
        data: {
          userName: serviceDispatcher?.clerkUser.firstName ?? '',
          requestId: request._id,
          caseNumber: request.caseNumber,
          subject: 'Technician Has Started Work',
          additionalContext: `Service technician (${technician?.clerkUser.firstName}) has started work`,
        },
      }
    );

    return {
      success: true,
      message: 'Work started successfully',
    };
  },
});

// Complete work on request
export const completeWorkOnRequest = zMutation({
  args: {
    requestId: zid('requests'),
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles } = await getUserContext(ctx);

    // Verify user is technician
    const technicianRole = roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');
    if (!technicianRole) {
      throw new Error('Must be a technician to complete work');
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Get fleet dispatcher role for reassignment
    const fleetDispatcherRole = await getOneFromOrThrow(
      ctx.db,
      'roleDefinitions',
      'by_type',
      'FLEET_DISPATCHER',
      'type'
    );

    // Validate request state
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'MECHANIC_SERVICE' ||
      request.currentStepType !== 'TECHNICIAN_STARTED_WORK' ||
      request.activeTechnicianId !== user._id
    ) {
      throw new Error('Request is not in valid state to complete work');
    }

    // Update request state - assign back to original fleet dispatcher for verification
    await Promise.all([
      ctx.db.patch(requestId, {
        currentStepType: 'COMPLETION_VERIFICATION',
        currentPhase: 'FLEET_DISPATCH',
        currentStepState: 'ASSIGNED',
        currentAssignedToId: request.activeFleetDispatcherId,
        currentRequiredRoleId: fleetDispatcherRole._id,
        currentStepStartedAt: new Date().toISOString(),
      }),
      updateTechnicianStatusHelper(ctx, user._id, 'AVAILABLE'),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'WORKFLOW_TRANSITION',
        userId: user._id,
        userRole: technicianRole._id,
        messageComponents: messageTemplates.technicianAction({
          technicianName: user.clerkUser.fullName,
          technicianRole: user.primaryRoleType!,
          action: ' completed work and submitted for verification',
        }),
        visibleToRoles: [],
        details: {
          targetRoleId: fleetDispatcherRole._id,
          targetUserId: request.activeFleetDispatcherId,
        },
      }),
    ]);

    // notify everyone

    if (
      request.activeDriverId &&
      request.activeFleetDispatcherId &&
      request.activeServiceDispatcherId
    ) {
      const [driver, fleet, serviceDispatcher] = await Promise.all([
        ctx.db.get(request.activeDriverId),
        ctx.db.get(request.activeFleetDispatcherId),
        ctx.db.get(request.activeServiceDispatcherId),
      ]);
      for (const user of [driver, fleet, serviceDispatcher]) {
        if (user) {
          ctx.scheduler.runAfter(
            0,
            internal.actions.sendEmail.sendEmailUsingLocalTemplates,
            {
              to: user?.clerkUser.primaryEmailAddress?.emailAddress ?? '',
              emailType: 'NOTIFICATION',
              data: {
                userName: user?.clerkUser.firstName ?? '',
                requestId: request._id,
                caseNumber: request.caseNumber,
                subject: 'Technician Has Completed The Job',
                additionalContext: `Service technician has completed their work on the job, fleet dispatch must now verify the work`,
              },
            }
          );
        }
      }

      return {
        success: true,
        message: 'Work completed successfully',
      };
    }
    return {
      success: false,
      message: 'Something went wrong',
    };
  },
});

// Fleet dispatcher verifies completion
export const verifyRequestCompletion = zMutation({
  args: {
    requestId: zid('requests'),
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles } = await getUserContext(ctx);

    // Verify user is fleet dispatcher
    const dispatcherRole = roles.find(r => r?.type === 'FLEET_DISPATCHER');
    if (!dispatcherRole) {
      throw new Error('Must be a fleet dispatcher to verify completion');
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Validate request state
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'FLEET_DISPATCH' ||
      request.currentStepType !== 'COMPLETION_VERIFICATION' ||
      request.currentAssignedToId !== user._id
    ) {
      throw new Error(
        'Request is not in valid state for completion verification'
      );
    }

    // Update request state to completed
    await Promise.all([
      ctx.db.patch(requestId, {
        status: 'COMPLETED',
        currentStepType: 'COMPLETED',
        completedAt: new Date().toISOString(),
        currentStepStartedAt: new Date().toISOString(),
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'REQUEST_COMPLETED',
        userId: user._id,
        userRole: dispatcherRole._id,
        messageComponents: messageTemplates.requestCompleted({
          completedByName: user.clerkUser.fullName,
          completedByRole: user.primaryRoleType!,
        }),
        visibleToRoles: [],
      }),
    ]);

    // notify driver, technician and dispatcher
    const [driver, technician, serviceDispatcher] = await Promise.all([
      request.activeDriverId
        ? ctx.db.get(request.activeDriverId)
        : Promise.resolve(null),
      request.activeTechnicianId
        ? ctx.db.get(request.activeTechnicianId)
        : Promise.resolve(null),
      request.activeServiceDispatcherId
        ? ctx.db.get(request.activeServiceDispatcherId)
        : Promise.resolve(null),
    ]);

    for (const user of [driver, technician, serviceDispatcher]) {
      if (user) {
        ctx.scheduler.runAfter(
          0,
          internal.actions.sendEmail.sendEmailUsingLocalTemplates,
          {
            to: user.clerkUser.primaryEmailAddress?.emailAddress ?? '',
            emailType: 'NOTIFICATION',
            data: {
              userName: user.clerkUser.firstName ?? '',
              requestId: request._id,
              caseNumber: request.caseNumber,
              subject: 'Case Completed And Closed Out',
              additionalContext: `Dispatch has verified completion of the work and the case has been closed out`,
            },
          }
        );
      }
    }
    return {
      success: true,
      message: 'Request completed successfully',
    };
  },
});

// what happens when a repair is not verified as done well (disputes)

export const submitRepairDetails = zMutation({
  args: submitRepairDetailsInput.shape,
  handler: async (
    ctx,
    { requestId, cause, correction, notes, wasATemporaryFix }
  ) => {
    const { user, roles } = await getUserContext(ctx);

    // Verify user is technician
    const technicianRole = roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');
    if (!technicianRole) {
      throw new Error('Must be a technician to submit repair details');
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Validate request state
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'MECHANIC_SERVICE' ||
      request.currentStepType !== 'TECHNICIAN_STARTED_WORK' ||
      request.activeTechnicianId !== user._id
    ) {
      throw new Error('Request is not in valid state to submit repair details');
    }

    await Promise.all([
      ctx.db.patch(requestId, {
        repairDetails: {
          cause,
          correction,
          wasATemporaryFix,
          notes,
          completedAt: new Date().toISOString(),
          technicianId: user._id,
        },
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'UPDATED_DETAILS',
        userId: user._id,
        userRole: technicianRole._id,
        messageComponents: messageTemplates.updatedDetails({
          name: user.clerkUser.fullName,
          role: user.primaryRoleType!,
          action: ' updated repair details',
        }),
        visibleToRoles: [],
      }),
    ]);

    return {
      success: true,
      message: 'Repair details submitted successfully',
    };
  },
});

export const declineRequestAssignmentAsServiceDispatcher = zMutation({
  args: { requestId: zid('requests') },
  handler: async (ctx, { requestId }) => {
    const { user, roles } = await getUserContext(ctx);

    // Verify user is technician
    const dispatcherRole = roles.find(r => r?.type === 'SERVICE_DISPATCHER');
    if (!dispatcherRole) {
      throw new Error('Must be a service dispatcher to decline');
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Validate request state
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'MECHANIC_DISPATCH' ||
      request.currentStepType !== 'WITH_SERVICE_PROVIDER_DISPATCH' ||
      request.currentAssignedToId !== undefined
    ) {
      throw new Error('Request is not in valid state to decline');
    }

    const [fleetDispatchUser, _, __] = await Promise.all([
      ctx.db.get(request.activeFleetDispatcherId!),
      ctx.db.patch(requestId, {
        currentStepStartedAt: new Date().toISOString(),
        currentStepType: 'DISPATCH_TRIAGE',
        currentStepState: 'ASSIGNED',
        currentAssignedToId: request.activeFleetDispatcherId,
        currentRequiredRoleId: undefined,
        mechanicDispatchGroupId: undefined,
        mechanicServiceGroupId: undefined,
        serviceProviderCompanyId: undefined,
        estimatedTimeOfArrival: undefined,
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'WORKFLOW_TRANSITION',
        userId: user._id,
        userRole: dispatcherRole._id,
        messageComponents: messageTemplates.assignmentRejected({
          rejecterName: user.clerkUser.fullName,
          rejecterRole: user.primaryRoleType!,
        }),
        visibleToRoles: [],
      }),
    ]);

    if (fleetDispatchUser) {
      ctx.scheduler.runAfter(
        0,
        internal.actions.sendEmail.sendEmailUsingLocalTemplates,
        {
          to:
            fleetDispatchUser.clerkUser.primaryEmailAddress?.emailAddress ?? '',
          emailType: 'NOTIFICATION',
          data: {
            userName: fleetDispatchUser.clerkUser.firstName ?? '',
            requestId: request._id,
            caseNumber: request.caseNumber,
            subject: 'Service Provider Declined Case',
            additionalContext: `Service provider has declined the request and it has been send back to fleet dispatch`,
          },
        }
      );
    }

    return {
      success: true,
      message: 'Successfully declined request',
    };
  },
});

export const timeoutServiceProviderAssignment = zInternalMutation({
  args: {
    requestId: zid('requests'),
    originalDispatcherId: zid('users'),
    locationGroupId: zid('groups'),
  },
  handler: async (
    ctx,
    { requestId, originalDispatcherId, locationGroupId }
  ) => {
    const [request, locationGroup] = await Promise.all([
      ctx.db.get(requestId),
      ctx.db.get(locationGroupId),
    ]);

    if (!request || !locationGroup) {
      throw new Error('Request or location not found');
    }

    // Update request state - revert back to fleet dispatch
    const [fleetDispatchUser, _, __] = await Promise.all([
      ctx.db.get(request.activeFleetDispatcherId!),
      ctx.db.patch(requestId, {
        currentPhase: 'FLEET_DISPATCH',
        currentStepType: 'DISPATCH_TRIAGE',
        currentStepState: 'ASSIGNED',
        currentAssignedToId: originalDispatcherId,
        currentRequiredRoleId: undefined,
        mechanicDispatchGroupId: undefined,
        mechanicServiceGroupId: undefined,
        serviceProviderCompanyId: undefined,
        estimatedTimeOfArrival: undefined,
        currentStepStartedAt: new Date().toISOString(),
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'SYSTEM_TIMEOUT',
        userId: originalDispatcherId,
        visibleToRoles: [],
        userRole: request.currentRequiredRoleId!,
        messageComponents: messageTemplates.systemTimeout({
          entityName: locationGroup.name,
          timeoutDescription:
            ' did not respond within the required timeframe. Request has been returned to fleet dispatch',
        }),
      }),
    ]);

    if (fleetDispatchUser) {
      ctx.scheduler.runAfter(
        0,
        internal.actions.sendEmail.sendEmailUsingLocalTemplates,
        {
          to:
            fleetDispatchUser.clerkUser.primaryEmailAddress?.emailAddress ?? '',
          emailType: 'NOTIFICATION',
          data: {
            userName: fleetDispatchUser.clerkUser.firstName ?? '',
            requestId: request._id,
            caseNumber: request.caseNumber,
            subject: 'Case Returned Due To Timeout',
            additionalContext: `Service provider did not respond within the required timeframe. Request has been returned to fleet dispatch.`,
          },
        }
      );
    }
  },
});

export const sendPingReminderToAssignee = zMutation({
  args: {
    requestId: zid('requests'),
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles } = await getUserContext(ctx);
    // TODO: Confirm user has permissions to send ping
    const request = await ctx.db.get(requestId);

    if (
      user?._id !== request?.activeFleetDispatcherId &&
      user?._id !== request?.activeServiceDispatcherId
    ) {
      throw new Error('Not authorized to send ping');
    }

    if (request && request?.currentAssignedToId) {
      const assignee = await ctx.db.get(request?.currentAssignedToId);

      if (assignee) {
        if (assignee.clerkUser.primaryPhoneNumber?.phoneNumber) {
          await ctx.scheduler.runAfter(
            0,
            internal.actions.sendSms.sendSmsAction,
            {
              phoneNumber: assignee.clerkUser.primaryPhoneNumber?.phoneNumber,
              message: `${user.clerkUser.fullName} [${getUserRoleDisplay(user.primaryRoleType)}] sent reminder re: ${request.caseNumber}`,
              requestId: request._id,
            }
          );
        } else {
          throw new Error(
            `Assignee ${assignee._id} does not have a phone number`
          );
        }
        ctx.scheduler.runAfter(
          0,
          internal.actions.sendEmail.sendEmailUsingLocalTemplates,
          {
            to: assignee.clerkUser.primaryEmailAddress?.emailAddress ?? '',
            emailType: 'NOTIFICATION',
            data: {
              userName: assignee.clerkUser.firstName,
              requestId,
              caseNumber: request.caseNumber,
              subject: 'Case Assignment Reminder',
              additionalContext: `You have been sent a reminder regarding your current assignment`,
            },
          }
        );
      }

      return {
        success: true,
        message: 'Sent reminder ping to current assignee',
      };
    } else {
      throw new Error('Not currently assigned, cannot send ping');
    }
  },
});

export const getRequestHistory = query({
  args: {
    requestId: v.id('requests'),
    // Allow filtering by type if needed
    type: v.optional(v.string()),
    paginationOpts: paginationOptsValidator,
  },
  handler: async (ctx, args) => {
    const { requestId, type, paginationOpts } = args;

    // Get request and verify exists
    const request = await ctx.db.get(requestId);
    if (!request) {
      throw new Error('Request not found');
    }

    // Build query
    let historyQuery = ctx.db
      .query('requestHistory')
      .withIndex('by_requestId', q => q.eq('requestId', requestId));

    // Add type filter if specified
    if (type) {
      historyQuery = historyQuery.filter(q => q.eq(q.field('type'), type));
    }

    // Get paginated history entries
    const historyEntries = await historyQuery
      .order('desc')
      .paginate(paginationOpts);

    // Group by day for display
    const groupedByDay = historyEntries.page.reduce<
      Record<
        string,
        {
          date: string;
          formattedDate: string;
          entries: ReturnType<typeof formatHistoryEntry>[];
        }
      >
    >((acc, entry) => {
      const day = dayjs(entry._creationTime).format('YYYY-MM-DD');

      if (!acc[day]) {
        acc[day] = {
          date: day,
          formattedDate: dayjs(day).format('MMM D, YYYY'),
          entries: [],
        };
      }

      acc[day].entries.push(formatHistoryEntry(entry));
      return acc;
    }, {});

    return {
      ...historyEntries,
      page: Object.values(groupedByDay),
    };
  },
});

export function isLocationRecentlyUpdated(vehicle?: Doc<'vehicles'> | null) {
  console.log('Checking vehicle:', vehicle);

  if (!vehicle?.location) {
    console.log('No vehicle or location data');
    return false;
  }

  const { latitude, longitude, lastUpdated } = vehicle.location;
  if (!latitude || !longitude || !lastUpdated) {
    console.log('Missing required location data:', {
      latitude,
      longitude,
      lastUpdated,
    });
    return false;
  }

  const locationTime = new Date(vehicle.location.lastUpdated).getTime();
  const fifteenMinutesAgo = Date.now() - 15 * 60 * 1000;

  return locationTime > fifteenMinutesAgo;
}
