import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'src/config-global';
import { DispatcherCreateView } from 'src/sections/dispatcher/view/dispatcher-create-view';

const metadata = {
  title: `Create a new dispatcher | Dashboard - ${CONFIG.appName}`,
};

export default function Page() {
  return (
    <>
      <Helmet>
        <title> {metadata.title}</title>
      </Helmet>

      <DispatcherCreateView />
    </>
  );
}
