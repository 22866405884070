import { z } from 'zod';

export const ServiceCategory = z.enum([
  'MOBILE_SERVICES',
  'SHOP_SERVICES',
  'SPECIALIZED_CENTERS',
  'ENGINE_SYSTEMS',
  'DRIVETRAIN',
  'BRAKE_SYSTEMS',
  'ELECTRICAL_SYSTEMS',
  'CHASSIS_SUSPENSION',
  'AUXILIARY_SYSTEMS',
  'TRAILER_SPECIFIC',
]);

export const ServiceSubcategory = z.enum([
  // Mobile Services
  'EMERGENCY_MOBILE',
  'ONSITE_MAINTENANCE',
  'MOBILE_DIAGNOSTIC',
  'JOBSITE_REPAIR',
  'PREVENTIVE_MAINTENANCE',
  'MOBILE_WELDING',
  'MOBILE_TIRE',
  'MOBILE_HYDRAULIC',

  // Shop Services
  'FULL_TRUCK_REPAIR',
  'TRAILER_REPAIR',
  'BODY_SHOP',
  'PAINT_SERVICES',
  'MACHINE_SHOP',
  'FABRICATION',
  'DYNO_TESTING',
  'WARRANTY_CENTER',

  // Specialized Centers
  'TRANSMISSION_SPECIALTY',
  'ENGINE_REBUILD',
  'COLLISION_REPAIR',
  'ALT_FUEL_CENTER',
  'BRAKE_SUSPENSION',
  'ELECTRICAL_SPECIALIST',
  'HYDRAULIC_SPECIALIST',
  'REFRIGERATION_SPECIALIST',

  // Engine Systems
  'DIESEL_REPAIR',
  'ENGINE_DIAGNOSTICS',
  'ENGINE_OVERHAUL',
  'FUEL_INJECTION',
  'TURBOCHARGER',
  'EMISSIONS',
  'ENGINE_BRAKE',
  'ALT_FUEL_SYSTEMS',

  // Drivetrain
  'MANUAL_TRANS',
  'AUTO_TRANS',
  'DIFFERENTIAL',
  'CLUTCH',
  'DRIVELINE',
  'PTO',
  'TRANSFER_CASE',
  'AXLE',

  // Brake Systems
  'AIR_BRAKES',
  'HYDRAULIC_BRAKES',
  'ABS',
  'BRAKE_COMPONENTS',
  'AIR_SYSTEM',
  'FOUNDATION_BRAKE',
  'ELECTRONIC_BRAKE',
  'BRAKE_TESTING',

  // Electrical Systems
  'STARTING_CHARGING',
  'LIGHTING',
  'CONTROL_MODULES',
  'WIRING_HARNESS',
  'BATTERY',
  'ALTERNATOR',
  'ELECTRONIC_DIAGNOSTICS',
  'TELEMATICS',

  // Chassis & Suspension
  'ALIGNMENT',
  'SUSPENSION',
  'FRAME',
  'STEERING',
  'FIFTH_WHEEL',
  'AIR_SUSPENSION',
  'WHEEL_END',
  'CHASSIS_MOD',

  // Auxiliary Systems
  'HVAC',
  'APU',
  'CAB_COMFORT',
  'LIFT_GATE',
  'HYDRAULIC',
  'PTO_AUX',
  'WELDING',
  'FABRICATION_SKILLS',

  // Trailer Specific
  'REFRIGERATION',
  'TRAILER_ELECTRICAL',
  'DOOR_MECHANISMS',
  'LANDING_GEAR',
  'INTERIOR',
  'FLOOR',
  'WALL',
  'ROOF',
]);

export type ServiceCategory = z.infer<typeof ServiceCategory>;
export type ServiceSubcategory = z.infer<typeof ServiceSubcategory>;

const categoryLabels: Record<ServiceCategory, string> = {
  MOBILE_SERVICES: 'Mobile Repair Services',
  SHOP_SERVICES: 'Shop-Based Services',
  SPECIALIZED_CENTERS: 'Specialized Service Centers',
  ENGINE_SYSTEMS: 'Engine Systems',
  DRIVETRAIN: 'Drivetrain',
  BRAKE_SYSTEMS: 'Brake Systems',
  ELECTRICAL_SYSTEMS: 'Electrical Systems',
  CHASSIS_SUSPENSION: 'Chassis & Suspension',
  AUXILIARY_SYSTEMS: 'Auxiliary Systems',
  TRAILER_SPECIFIC: 'Trailer Specific',
} as const;

const subcategoryLabels: Record<ServiceSubcategory, string> = {
  // Mobile Services
  EMERGENCY_MOBILE: '24/7 Emergency Mobile Repair',
  ONSITE_MAINTENANCE: 'Scheduled On-site Maintenance',
  MOBILE_DIAGNOSTIC: 'Mobile Diagnostic Services',
  JOBSITE_REPAIR: 'Job Site Repair Services',
  PREVENTIVE_MAINTENANCE: 'Preventive Maintenance Visits',
  MOBILE_WELDING: 'Mobile Welding Services',
  MOBILE_TIRE: 'Mobile Tire Services',
  MOBILE_HYDRAULIC: 'Mobile Hydraulic Services',

  // Shop Services
  FULL_TRUCK_REPAIR: 'Full-service Truck Repair',
  TRAILER_REPAIR: 'Trailer Repair Facility',
  BODY_SHOP: 'Body Shop Services',
  PAINT_SERVICES: 'Paint Services',
  MACHINE_SHOP: 'Machine Shop Services',
  FABRICATION: 'Fabrication Services',
  DYNO_TESTING: 'Dynamometer Testing',
  WARRANTY_CENTER: 'Warranty Repair Center',

  // Specialized Centers
  TRANSMISSION_SPECIALTY: 'Transmission Specialty Shop',
  ENGINE_REBUILD: 'Engine Rebuild Center',
  COLLISION_REPAIR: 'Collision Repair Center',
  ALT_FUEL_CENTER: 'Alternative Fuel System Center',
  BRAKE_SUSPENSION: 'Brake & Suspension Specialty',
  ELECTRICAL_SPECIALIST: 'Electrical System Specialist',
  HYDRAULIC_SPECIALIST: 'Hydraulic System Specialist',
  REFRIGERATION_SPECIALIST: 'Refrigeration Unit Specialist',

  // Engine Systems
  DIESEL_REPAIR: 'Diesel Engine Repair',
  ENGINE_DIAGNOSTICS: 'Engine Diagnostics',
  ENGINE_OVERHAUL: 'Engine Overhaul',
  FUEL_INJECTION: 'Fuel Injection Systems',
  TURBOCHARGER: 'Turbocharger Systems',
  EMISSIONS: 'Emissions Systems',
  ENGINE_BRAKE: 'Engine Brake Systems',
  ALT_FUEL_SYSTEMS: 'Alternative Fuel Systems',

  // Drivetrain
  MANUAL_TRANS: 'Manual Transmission',
  AUTO_TRANS: 'Automatic Transmission',
  DIFFERENTIAL: 'Differential Repair',
  CLUTCH: 'Clutch Systems',
  DRIVELINE: 'Driveline Components',
  PTO: 'PTO Systems',
  TRANSFER_CASE: 'Transfer Case',
  AXLE: 'Axle Repair',

  // Brake Systems
  AIR_BRAKES: 'Air Brake Systems',
  HYDRAULIC_BRAKES: 'Hydraulic Brakes',
  ABS: 'ABS Systems',
  BRAKE_COMPONENTS: 'Brake Component Replacement',
  AIR_SYSTEM: 'Air System Repair',
  FOUNDATION_BRAKE: 'Foundation Brake Repair',
  ELECTRONIC_BRAKE: 'Electronic Brake Controls',
  BRAKE_TESTING: 'Brake Testing & Certification',

  // Electrical Systems
  STARTING_CHARGING: 'Starting/Charging Systems',
  LIGHTING: 'Lighting Systems',
  CONTROL_MODULES: 'Electronic Control Modules',
  WIRING_HARNESS: 'Wiring Harness Repair',
  BATTERY: 'Battery Systems',
  ALTERNATOR: 'Alternator Repair',
  ELECTRONIC_DIAGNOSTICS: 'Electronic Diagnostics',
  TELEMATICS: 'Telematics Systems',

  // Chassis & Suspension
  ALIGNMENT: 'Alignment Services',
  SUSPENSION: 'Suspension Repair',
  FRAME: 'Frame Repair',
  STEERING: 'Steering Systems',
  FIFTH_WHEEL: 'Fifth Wheel Repair',
  AIR_SUSPENSION: 'Air Suspension Systems',
  WHEEL_END: 'Wheel End Repair',
  CHASSIS_MOD: 'Chassis Modification',

  // Auxiliary Systems
  HVAC: 'HVAC Systems',
  APU: 'APU Systems',
  CAB_COMFORT: 'Cab Comfort Systems',
  LIFT_GATE: 'Lift Gate Repair',
  HYDRAULIC: 'Hydraulic Systems',
  PTO_AUX: 'PTO Systems',
  WELDING: 'Welding Certification',
  FABRICATION_SKILLS: 'Fabrication Skills',

  // Trailer Specific
  REFRIGERATION: 'Refrigeration Units',
  TRAILER_ELECTRICAL: 'Trailer Electrical',
  DOOR_MECHANISMS: 'Door Mechanisms',
  LANDING_GEAR: 'Landing Gear',
  INTERIOR: 'Interior Repairs',
  FLOOR: 'Floor Repairs',
  WALL: 'Wall Repairs',
  ROOF: 'Roof Repairs',
} as const;

type ServiceCategoryMap = {
  [K in ServiceCategory]: {
    [S in ServiceSubcategory]?: string;
  };
};

export const serviceSubcategoriesMap: ServiceCategoryMap = {
  MOBILE_SERVICES: {
    EMERGENCY_MOBILE: subcategoryLabels.EMERGENCY_MOBILE,
    ONSITE_MAINTENANCE: subcategoryLabels.ONSITE_MAINTENANCE,
    MOBILE_DIAGNOSTIC: subcategoryLabels.MOBILE_DIAGNOSTIC,
    JOBSITE_REPAIR: subcategoryLabels.JOBSITE_REPAIR,
    PREVENTIVE_MAINTENANCE: subcategoryLabels.PREVENTIVE_MAINTENANCE,
    MOBILE_WELDING: subcategoryLabels.MOBILE_WELDING,
    MOBILE_TIRE: subcategoryLabels.MOBILE_TIRE,
    MOBILE_HYDRAULIC: subcategoryLabels.MOBILE_HYDRAULIC,
  },
  SHOP_SERVICES: {
    FULL_TRUCK_REPAIR: subcategoryLabels.FULL_TRUCK_REPAIR,
    TRAILER_REPAIR: subcategoryLabels.TRAILER_REPAIR,
    BODY_SHOP: subcategoryLabels.BODY_SHOP,
    PAINT_SERVICES: subcategoryLabels.PAINT_SERVICES,
    MACHINE_SHOP: subcategoryLabels.MACHINE_SHOP,
    FABRICATION: subcategoryLabels.FABRICATION,
    DYNO_TESTING: subcategoryLabels.DYNO_TESTING,
    WARRANTY_CENTER: subcategoryLabels.WARRANTY_CENTER,
  },
  SPECIALIZED_CENTERS: {
    TRANSMISSION_SPECIALTY: subcategoryLabels.TRANSMISSION_SPECIALTY,
    ENGINE_REBUILD: subcategoryLabels.ENGINE_REBUILD,
    COLLISION_REPAIR: subcategoryLabels.COLLISION_REPAIR,
    ALT_FUEL_CENTER: subcategoryLabels.ALT_FUEL_CENTER,
    BRAKE_SUSPENSION: subcategoryLabels.BRAKE_SUSPENSION,
    ELECTRICAL_SPECIALIST: subcategoryLabels.ELECTRICAL_SPECIALIST,
    HYDRAULIC_SPECIALIST: subcategoryLabels.HYDRAULIC_SPECIALIST,
    REFRIGERATION_SPECIALIST: subcategoryLabels.REFRIGERATION_SPECIALIST,
  },
  ENGINE_SYSTEMS: {
    DIESEL_REPAIR: subcategoryLabels.DIESEL_REPAIR,
    ENGINE_DIAGNOSTICS: subcategoryLabels.ENGINE_DIAGNOSTICS,
    ENGINE_OVERHAUL: subcategoryLabels.ENGINE_OVERHAUL,
    FUEL_INJECTION: subcategoryLabels.FUEL_INJECTION,
    TURBOCHARGER: subcategoryLabels.TURBOCHARGER,
    EMISSIONS: subcategoryLabels.EMISSIONS,
    ENGINE_BRAKE: subcategoryLabels.ENGINE_BRAKE,
    ALT_FUEL_SYSTEMS: subcategoryLabels.ALT_FUEL_SYSTEMS,
  },
  DRIVETRAIN: {
    MANUAL_TRANS: subcategoryLabels.MANUAL_TRANS,
    AUTO_TRANS: subcategoryLabels.AUTO_TRANS,
    DIFFERENTIAL: subcategoryLabels.DIFFERENTIAL,
    CLUTCH: subcategoryLabels.CLUTCH,
    DRIVELINE: subcategoryLabels.DRIVELINE,
    PTO: subcategoryLabels.PTO,
    TRANSFER_CASE: subcategoryLabels.TRANSFER_CASE,
    AXLE: subcategoryLabels.AXLE,
  },
  BRAKE_SYSTEMS: {
    AIR_BRAKES: subcategoryLabels.AIR_BRAKES,
    HYDRAULIC_BRAKES: subcategoryLabels.HYDRAULIC_BRAKES,
    ABS: subcategoryLabels.ABS,
    BRAKE_COMPONENTS: subcategoryLabels.BRAKE_COMPONENTS,
    AIR_SYSTEM: subcategoryLabels.AIR_SYSTEM,
    FOUNDATION_BRAKE: subcategoryLabels.FOUNDATION_BRAKE,
    ELECTRONIC_BRAKE: subcategoryLabels.ELECTRONIC_BRAKE,
    BRAKE_TESTING: subcategoryLabels.BRAKE_TESTING,
  },
  ELECTRICAL_SYSTEMS: {
    STARTING_CHARGING: subcategoryLabels.STARTING_CHARGING,
    LIGHTING: subcategoryLabels.LIGHTING,
    CONTROL_MODULES: subcategoryLabels.CONTROL_MODULES,
    WIRING_HARNESS: subcategoryLabels.WIRING_HARNESS,
    BATTERY: subcategoryLabels.BATTERY,
    ALTERNATOR: subcategoryLabels.ALTERNATOR,
    ELECTRONIC_DIAGNOSTICS: subcategoryLabels.ELECTRONIC_DIAGNOSTICS,
    TELEMATICS: subcategoryLabels.TELEMATICS,
  },
  CHASSIS_SUSPENSION: {
    ALIGNMENT: subcategoryLabels.ALIGNMENT,
    SUSPENSION: subcategoryLabels.SUSPENSION,
    FRAME: subcategoryLabels.FRAME,
    STEERING: subcategoryLabels.STEERING,
    FIFTH_WHEEL: subcategoryLabels.FIFTH_WHEEL,
    AIR_SUSPENSION: subcategoryLabels.AIR_SUSPENSION,
    WHEEL_END: subcategoryLabels.WHEEL_END,
    CHASSIS_MOD: subcategoryLabels.CHASSIS_MOD,
  },
  AUXILIARY_SYSTEMS: {
    HVAC: subcategoryLabels.HVAC,
    APU: subcategoryLabels.APU,
    CAB_COMFORT: subcategoryLabels.CAB_COMFORT,
    LIFT_GATE: subcategoryLabels.LIFT_GATE,
    HYDRAULIC: subcategoryLabels.HYDRAULIC,
    PTO_AUX: subcategoryLabels.PTO_AUX,
    WELDING: subcategoryLabels.WELDING,
    FABRICATION_SKILLS: subcategoryLabels.FABRICATION_SKILLS,
  },
  TRAILER_SPECIFIC: {
    REFRIGERATION: subcategoryLabels.REFRIGERATION,
    TRAILER_ELECTRICAL: subcategoryLabels.TRAILER_ELECTRICAL,
    DOOR_MECHANISMS: subcategoryLabels.DOOR_MECHANISMS,
    LANDING_GEAR: subcategoryLabels.LANDING_GEAR,
    INTERIOR: subcategoryLabels.INTERIOR,
    FLOOR: subcategoryLabels.FLOOR,
    WALL: subcategoryLabels.WALL,
    ROOF: subcategoryLabels.ROOF,
  },
} as const;

export const getCategoryLabel = (category: ServiceCategory): string =>
  categoryLabels[category];
export const getSubcategoryLabel = (subcategory: ServiceSubcategory): string =>
  subcategoryLabels[subcategory];

export const getSubcategoriesForCategory = (
  category: ServiceCategory
): Record<string, string> => {
  return serviceSubcategoriesMap[category] || {};
};
