import { useQuery } from 'convex/react';
import { useState } from 'react';
import { api } from 'src/convex/_generated/api';
import { Doc, Id } from 'src/convex/_generated/dataModel';

export function useGroupSearch(forSpecificCompanyId?: Id<'companies'>) {
  const [debouncedTerm, setDebouncedTerm] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<Doc<'groups'> | null>(
    null
  );

  // Get search results based on text
  const groups = useQuery(api.functions.companies.searchLocationsByName, {
    searchTerm: debouncedTerm,
    forSpecificCompanyId,
  });

  const returnedGroups: Doc<'groups'>[] = groups || [];

  return {
    debouncedTerm,
    setDebouncedTerm,
    groups: returnedGroups,
    selectedGroup,
    setSelectedGroup,
  };
}
