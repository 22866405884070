import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { useQuery } from 'convex/react';
import { useMemo, useState } from 'react';
import { Layer, Source, type LngLatBoundsLike } from 'react-map-gl';
import { api } from 'src/convex/_generated/api';
import { Doc } from 'src/convex/_generated/dataModel';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Logo } from 'src/minimal-theme/components/logo';
import {
  Map,
  MapControl,
  MapMarker,
  MapPopup,
} from 'src/minimal-theme/components/map';
import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';

const US_BOUNDS: LngLatBoundsLike = [
  [-124.848974, 24.396308], // Southwest coordinates
  [-66.885444, 49.384358], // Northeast coordinates
];

interface MapViewProps {
  data: {
    companies: Doc<'companies'>[];
    serviceLocations: Doc<'groups'>[];
    fleetLocations: Doc<'groups'>[];
    technicians: Doc<'users'>[];
    vehicles: Doc<'vehicles'>[];
  };
  mapStyle: string;
}

interface MapViewData {
  companies: Doc<'companies'>[];
  serviceLocations: Doc<'groups'>[];
  fleetLocations: Doc<'groups'>[];
  technicians: Doc<'users'>[];
  vehicles: Doc<'vehicles'>[];
}

// Type guard to check if an item has valid location data
function hasValidLocation(item: any): boolean {
  return (
    !!item?.location?.latitude &&
    !!item?.location?.longitude &&
    typeof item.location.latitude === 'number' &&
    typeof item.location.longitude === 'number'
  );
}

type LegendFilters = {
  companies: boolean;
  serviceLocations: boolean;
  fleetLocations: boolean;
  technicians: boolean;
  vehicles: boolean;
};

export function MapView({ data, mapStyle }: MapViewProps) {
  const [popupInfo, setPopupInfo] = useState<PopupInfo | null>(null);

  const me = useQuery(api.functions.users.getMe);

  // TODO: Not sure if it makes sense to show "companies" as many of them might not have an HQ address
  // We can have something like... filter locations by company, though
  // Select a dispatcher and see the locations they manage, etc.
  const [legendFilters, setLegendFilters] = useState<LegendFilters>({
    companies: false,
    serviceLocations: true,
    fleetLocations: true,
    technicians: true,
    vehicles: true,
  });

  const TYPE_COLORS: Record<
    keyof MapViewData,
    'primary' | 'success' | 'secondary' | 'info' | 'warning'
  > = {
    technicians: 'info',
    vehicles: 'success',
    companies: 'secondary',
    serviceLocations: 'primary',
    fleetLocations: 'warning',
  };

  const filteredData: MapViewData = {
    ...data,
    technicians: legendFilters.technicians ? [...data.technicians] : [],
    vehicles: legendFilters.vehicles ? [...data.vehicles] : [],
    companies: legendFilters.companies ? [...data.companies] : [],
    serviceLocations: legendFilters.serviceLocations
      ? [...data.serviceLocations]
      : [],
    fleetLocations: legendFilters.fleetLocations
      ? [...data.fleetLocations]
      : [],
  };

  const bounds = useMemo(() => {
    const allLocations = Object.values(filteredData)
      .flat()
      .filter(hasValidLocation);

    if (allLocations.length === 0) {
      return US_BOUNDS;
    }

    const lngs = allLocations.map(item => item.location!.longitude);
    const lats = allLocations.map(item => item.location!.latitude);

    return [
      [Math.min(...lngs) - 0.1, Math.min(...lats) - 0.1],
      [Math.max(...lngs) + 0.1, Math.max(...lats) + 0.1],
    ] as LngLatBoundsLike;
  }, [filteredData]);

  const serviceAreasData = useMemo(() => {
    if (
      !filteredData.serviceLocations?.length &&
      !filteredData.fleetLocations?.length
    )
      return null;

    const locations = [
      ...(filteredData.serviceLocations ?? []),
      ...(filteredData.fleetLocations ?? []),
    ].filter(hasValidLocation);

    const features = locations.map(item => {
      const radius = item.simpleServiceAreaByMilesOut || 25; // Default 25 mile radius
      const circlePoints = createCirclePoints(
        item.location!.longitude,
        item.location!.latitude,
        radius
      );

      return {
        type: 'Feature' as const,
        properties: {
          color: '#1976d2',
          radius: radius,
        },
        geometry: {
          type: 'Polygon' as const,
          coordinates: [circlePoints],
        },
      };
    });

    return {
      type: 'FeatureCollection' as const,
      features,
    };
  }, [filteredData]);

  console.log('popupInfo', popupInfo);

  return (
    <DashboardContent>
      <Card sx={{ height: '85vh' }}>
        <Map
          initialViewState={{
            bounds: bounds,
            fitBoundsOptions: { padding: 100 },
          }}
          mapStyle={mapStyle}
        >
          {serviceAreasData && (
            <Source type="geojson" data={serviceAreasData}>
              <Layer
                id="service-areas"
                type="fill"
                paint={{
                  'fill-color': ['get', 'color'],
                  'fill-opacity': 0.15,
                  'fill-outline-color': ['get', 'color'],
                }}
              />
              <Layer
                id="service-areas-outline"
                type="line"
                paint={{
                  'line-color': ['get', 'color'],
                  'line-opacity': 0.3,
                  'line-width': 1,
                }}
              />
            </Source>
          )}
          <MapControl />

          {(Object.entries(filteredData) as [keyof MapViewData, any[]][]).map(
            ([type, items]) =>
              items?.filter(hasValidLocation).map(item => (
                <MapMarker
                  key={`marker-${type}-${item._id}`}
                  color={type === 'serviceLocations' ? '' : TYPE_COLORS[type]}
                  latitude={item.location.latitude}
                  longitude={item.location.longitude}
                  onClick={event => {
                    event.originalEvent?.preventDefault();
                    event.originalEvent?.stopPropagation();
                    setPopupInfo({ item, type });
                  }}
                  logo={
                    (type === 'serviceLocations' && (
                      <Logo sx={{ height: 25 }} />
                    )) ||
                    (type === 'vehicles' && (
                      <Iconify
                        icon="mdi:truck"
                        color="white"
                        sx={{ height: 25 }}
                      />
                    )) ||
                    (type === 'fleetLocations' && (
                      <Iconify
                        icon="mdi:office-building-marker-outline"
                        color="white"
                        sx={{ height: 20 }}
                      />
                    ))
                  }
                >
                  {getMarkerLabel(type, item)}
                </MapMarker>
              ))
          )}

          {popupInfo && (
            <MapPopup
              latitude={popupInfo.item.location!.latitude}
              longitude={popupInfo.item.location!.longitude}
              onClose={() => setPopupInfo(null)}
              sx={{ top: '-37px', left: '-17px' }}
            >
              <Grid container sx={{ flexWrap: 'nowrap', gap: 2, pr: 3 }}>
                {popupInfo.type === 'technicians' ? (
                  <>
                    <Avatar
                      alt={(popupInfo.item as Doc<'users'>).clerkUser.firstName}
                      src={(popupInfo.item as Doc<'users'>).clerkUser.imageUrl}
                    />
                    <Typography variant="body2">
                      {(popupInfo.item as Doc<'users'>).clerkUser.fullName}
                    </Typography>
                  </>
                ) : (
                  <Box>
                    <Typography variant="body2" fontWeight="medium">
                      {popupInfo.type === 'companies' ||
                      popupInfo.type === 'serviceLocations' ||
                      popupInfo.type === 'fleetLocations'
                        ? (popupInfo.item as Doc<'companies' | 'groups'>).name
                        : ''}
                    </Typography>
                    {(popupInfo.type === 'serviceLocations' ||
                      popupInfo.type === 'fleetLocations') &&
                      (popupInfo.item as Doc<'groups'>)
                        .simpleServiceAreaByMilesOut && (
                        <Typography variant="caption" color="text.secondary">
                          Service Area:{' '}
                          {
                            (popupInfo.item as Doc<'groups'>)
                              .simpleServiceAreaByMilesOut
                          }{' '}
                          miles
                        </Typography>
                      )}
                    {popupInfo.item.location?.address && (
                      <Typography
                        variant="caption"
                        display="block"
                        color="text.secondary"
                      >
                        {popupInfo.item.location.address}
                      </Typography>
                    )}
                  </Box>
                )}
              </Grid>
            </MapPopup>
          )}
          <Card
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              mt: 3,
              mr: 3,
              // height: 300,
              width: 300,
            }}
          >
            <CardHeader title="Legend" />
            <CardContent>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {/* <FormControlLabel
                  label="Companies"
                  control={
                    <Checkbox
                      size="small"
                      checked={legendFilters.companies}
                      color="primary"
                    />
                  }
                /> */}
                {/* // TODO: Will have to change eventually when higher level organizations (companies) have users who can see all the locations */}
                {me?.clerkUser.isSuperAdmin && (
                  <FormControlLabel
                    label={`Service Locations (${filteredData.serviceLocations.length})`}
                    control={
                      <Checkbox
                        size="small"
                        checked={legendFilters.serviceLocations}
                        color={TYPE_COLORS.serviceLocations}
                        onClick={() =>
                          setLegendFilters(prev => ({
                            ...prev,
                            serviceLocations: !prev.serviceLocations,
                          }))
                        }
                      />
                    }
                  />
                )}
                {me?.clerkUser.isSuperAdmin && (
                  <FormControlLabel
                    label={`Fleet Locations (${filteredData.fleetLocations.length})`}
                    control={
                      <Checkbox
                        size="small"
                        checked={legendFilters.fleetLocations}
                        color={TYPE_COLORS.fleetLocations}
                        onClick={() =>
                          setLegendFilters(prev => ({
                            ...prev,
                            fleetLocations: !prev.fleetLocations,
                          }))
                        }
                      />
                    }
                  />
                )}
                {(me?.clerkUser.isSuperAdmin ||
                  me?.primaryRoleType === 'SERVICE_DISPATCHER') && (
                  <FormControlLabel
                    label={`Service Technicians (${filteredData.technicians.length})`}
                    control={
                      <Checkbox
                        size="small"
                        checked={legendFilters.technicians}
                        color={TYPE_COLORS.technicians}
                        onClick={() =>
                          setLegendFilters(prev => ({
                            ...prev,
                            technicians: !prev.technicians,
                          }))
                        }
                      />
                    }
                  />
                )}
                {(me?.clerkUser.isSuperAdmin ||
                  me?.primaryRoleType === 'FLEET_DISPATCHER') && (
                  <FormControlLabel
                    label={`Fleet Vehicles (${filteredData.vehicles.length})`}
                    control={
                      <Checkbox
                        size="small"
                        checked={legendFilters.vehicles}
                        color={TYPE_COLORS.vehicles}
                        onClick={() =>
                          setLegendFilters(prev => ({
                            ...prev,
                            vehicles: !prev.vehicles,
                          }))
                        }
                      />
                    }
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </Map>
      </Card>
    </DashboardContent>
  );
}

function getMarkerLabel(
  type: keyof MapViewData,
  item: Doc<'companies' | 'groups' | 'users' | 'vehicles'>
): string {
  if (type === 'technicians') {
    return (item as Doc<'users'>).clerkUser?.firstName?.[0] || 'U';
  }
  if (type === 'companies') {
    return (item as Doc<'companies'>).name?.[0] || 'C';
  }
  if (type === 'serviceLocations' || type === 'fleetLocations') {
    return (
      (item as Doc<'groups'>).name?.[0] ||
      (type === 'serviceLocations' ? 'S' : 'F')
    );
  }
  if (type === 'vehicles') {
    return 'V';
  }
  return '';
}

type PopupInfo = {
  item: Doc<'companies' | 'groups' | 'users' | 'vehicles'>;
  type: keyof MapViewData;
};

function createCirclePoints(
  centerLng: number,
  centerLat: number,
  radiusMiles: number
) {
  const points = [];
  const km = radiusMiles * 1.60934;
  const distanceX = km / (111.32 * Math.cos((centerLat * Math.PI) / 180));
  const distanceY = km / 110.574;

  const steps = 64;
  for (let i = 0; i < steps; i++) {
    const angle = (i / steps) * (2 * Math.PI);
    const dx = distanceX * Math.cos(angle);
    const dy = distanceY * Math.sin(angle);
    points.push([centerLng + dx, centerLat + dy]);
  }
  points.push(points[0]);

  return points;
}
