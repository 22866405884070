import { getManyFrom, getOneFrom } from 'convex-helpers/server/relationships';
import { zid } from 'convex-helpers/server/zod';
import { v } from 'convex/values';
import { z } from 'zod';
import { internal } from '../_generated/api';
import { Doc, Id } from '../_generated/dataModel';
import {
  internalQuery,
  MutationCtx,
  query,
  QueryCtx,
} from '../_generated/server';
import { inviteUserSchema, MyCompany } from '../schema/entities/companies';
import { MyPrimaryLocation } from '../schema/entities/groups/groups';
import { partialClerkUserSchema } from '../schema/entities/users';
import { MechanicStatus, mechanicStatus } from '../schema/enums/mechanicStatus';
import { userRole } from '../schema/enums/userRole';
import { locationUpdateInput } from '../types/locationSchema';
import { getCustomUserIdentity } from '../utils/getCustomUserIdentity';
import { getUserContext } from '../utils/getUserContext';
import { createConvexUserAndRoleHelper } from './helpers/userCreation';
import {
  zInternalMutation,
  zInternalQuery,
  zMutation,
  zQuery,
} from './helpers/zodHelpers';

/** Get user by Clerk use id (AKA "subject" on auth)  */
export const getUser = internalQuery({
  args: { subject: v.string() },
  async handler(ctx, args) {
    return await userQueryByClerkId(ctx, args.subject);
  },
});

export type EnhancedUser = Doc<'users'> & {
  roles: Doc<'roleDefinitions'>[];
  company: MyCompany;
  location: MyPrimaryLocation;
};

export const getMe = zQuery({
  args: {},
  async handler(ctx) {
    const { user, roles, company, primaryLocation } = await getUserContext(ctx);

    return {
      ...user,
      roles,
      company: {
        _id: company._id,
        name: company.name,
        isFullyVerified: company.isFullyVerified,
        isSignUpFormCompleted: company.isSignUpFormCompleted,
      },
      location: { _id: primaryLocation._id, name: primaryLocation.name },
    } as EnhancedUser;
  },
});

export const getUserById = zQuery({
  args: {
    id: zid('users'),
  },
  handler: async (ctx, { id }) => {
    await getUserContext(ctx);

    const u = await ctx.db.get(id);

    if (!u) {
      throw new Error(`User with id ${id} not found`);
    }
    return u;
  },
});

export const getUserByIdInternally = zInternalQuery({
  args: {
    id: zid('users'),
  },
  handler: async (ctx, { id }) => {
    const u = await ctx.db.get(id);

    if (!u) {
      throw new Error(`User with id ${id} not found`);
    }
    return u;
  },
});

export const getUsersOfRoleForLocation = zQuery({
  args: {
    roleType: userRole,
  },
  handler: async (ctx, { roleType }) => {
    const { user } = await getUserContext(ctx);

    if (!user) {
      throw new Error('User not authenticated');
    }

    return ctx.db
      .query('users')
      .withIndex('by_primaryLocationGroupId_and_primaryRoleType', q =>
        q
          .eq(
            'primaryLocationGroupId',
            user.impersonatingLocationId ?? user.primaryLocationGroupId
          )
          .eq('primaryRoleType', roleType)
      )
      .collect();
  },
});

export const getAllUsersForLocation = query({
  handler: async ctx => {
    const { user, primaryLocation } = await getUserContext(ctx);

    if (!user) {
      throw new Error('User not authenticated');
    }

    // TODO: Need extra layers of validation, not every user in a company should be able to view all info about all other users in the company
    // And also it's eventually going to be getting the users for a particular group ("location" group, etc.), especially for large multi-location companies
    return getManyFrom(
      ctx.db,
      'users',
      'by_primaryLocationGroupId',
      primaryLocation._id
    );
  },
});

export const getAllUsersForSuperAdmin = query({
  handler: async ctx => {
    const { user } = await getUserContext(ctx);

    if (!user || !user.clerkUser.isSuperAdmin) {
      throw new Error('User not authenticated');
    }

    return ctx.db
      .query('users')
      .filter(q =>
        q.or(
          q.eq(q.field('primaryRoleType'), 'TECHNICIAN'),
          q.eq(q.field('primaryRoleType'), 'FLEET_TECHNICIAN')
        )
      )
      .collect();
  },
});

export const updateUserFromClerkWebhook = zInternalMutation({
  args: {
    clerkUser: partialClerkUserSchema,
    hasVerifiedEmail: z.boolean(),
    hasVerifiedPhone: z.boolean(),
  },
  async handler(ctx, { clerkUser, ...restOfUser }) {
    const user = await getUser(ctx, { subject: clerkUser.id });

    if (user) {
      await ctx.db.patch(user._id, {
        clerkUser,
        ...restOfUser,
      });
    }
  },
});

// TODO: need to be very careful with this obviously... currently purely for invitation errors being thrown/user cleanup in that specific case
export const deleteUserByClerkId = zInternalMutation({
  args: {
    clerkUserId: z.string(),
  },
  async handler(ctx, { clerkUserId }) {
    const user = await getOneFrom(
      ctx.db,
      'users',
      'by_clerkId',
      clerkUserId,
      'clerkUser.id'
    );

    if (user) {
      await ctx.db.delete(user._id);
    } else {
      throw new Error('Could not find user by clerk id to delete');
    }
  },
});

async function userQueryByClerkId(ctx: QueryCtx, clerkUserId: string) {
  return await getOneFrom(
    ctx.db,
    'users',
    'by_clerkId',
    clerkUserId,
    'clerkUser.id'
  );
}

export type ConvexUserCreationResult = {
  userId: Id<'users'>;
};

export const createConvexUserAndRole = zInternalMutation({
  args: {
    input: inviteUserSchema,
    clerkId: z.string(),
  },
  handler: async (ctx, args): Promise<ConvexUserCreationResult> => {
    console.log('Starting Convex user creation:', {
      email: args.input.email,
      role: args.input.role,
    });

    const result = await createConvexUserAndRoleHelper(
      ctx.db,
      args.input,
      args.clerkId
    );

    console.log('Created user document:', result.userId);

    return result;
  },
});

export const updateTechnicianStatus = zInternalMutation({
  args: {
    technicianId: zid('users'),
    status: mechanicStatus,
  },
  handler: async (ctx, { technicianId, status }) => {
    await updateTechnicianStatusHelper(ctx, technicianId, status);
  },
});

export const updateTechnicianStatusHelper = async (
  ctx: MutationCtx,
  technicianId: Id<'users'>,
  status: MechanicStatus
) => {
  return ctx.db.patch(technicianId, { status });
};

export const updateTechnicianLocation = zMutation({
  args: locationUpdateInput.shape,
  handler: async (ctx, { latitude, longitude }) => {
    const { user, roles } = await getUserContext(ctx);
    if (!user) throw new Error('Not authenticated');

    const isTechnician = roles.some(
      r => r?.type === 'TECHNICIAN_PROVIDER' || r?.type === 'TECHNICIAN_FLEET'
    );

    if (!isTechnician) {
      throw new Error('Only technicians can update their location');
    }

    await ctx.db.patch(user._id, {
      location: { latitude, longitude, lastUpdated: new Date().toISOString() },
    });
    return true;
  },
});

// TODO: Move this/use it.. random example of ... how annoying conditional query building is... https://discord.com/channels/1019350475847499849/1312152405969993779
// type RequestTableInfo = {
//   document: Doc<'requests'>;
//   fieldPaths: string;
//   indexes: {
//     by_caseNumber: ['caseNumber', '_creationTime'];
//   };
//   searchIndexes: {
//     search: {
//       searchField: 'searchText';
//       filterFields: never;
//     };
//   };
//   vectorIndexes: Record<string, never>;
// };

// const preIndexQuery = ctx.db.query('requests');
// let postIndexQuery: Query<RequestTableInfo> = preIndexQuery;

// if (true) {
//   postIndexQuery = preIndexQuery.withIndex('by_caseNumber', q =>
//     q.eq('caseNumber', 'blah')
//   );
// }

// if (true) {
//   postIndexQuery = postIndexQuery.filter(q =>
//     q.neq(q.field('status'), 'DRAFT')
//   );
// }

// let orderedQuery: OrderedQuery<RequestTableInfo> = postIndexQuery;

// if (true) {
//   orderedQuery = postIndexQuery.order('desc');
// }

// const results = await orderedQuery.collect();

export const initSuperAdminUser = zMutation({
  handler: async (ctx, { latitude, longitude }) => {
    const identity = await getCustomUserIdentity(ctx);
    if (!identity) throw new Error('User not authenticated');

    const { isSuperAdmin, subject, email, givenName, familyName, phoneNumber } =
      identity;
    if (!isSuperAdmin) return { success: false, message: 'Not a superadmin' };

    if (!email || !givenName || !familyName || !phoneNumber) {
      return { success: false, message: 'Missing attributes in identity' };
    }

    const [adminCompany, adminPrimaryGroup] = await Promise.all([
      getOneFrom(ctx.db, 'companies', 'by_isSuperAdmin', true),
      getOneFrom(ctx.db, 'groups', 'by_isSuperAdmin', true),
    ]);

    if (!adminCompany || !adminPrimaryGroup) {
      throw Error('Admin company or group does not exist');
    }

    await ctx.runMutation(internal.functions.users.createConvexUserAndRole, {
      input: {
        email,
        phone: phoneNumber,
        firstName: givenName,
        lastName: familyName,
        role: 'SUPER_ADMIN',
        companyId: adminCompany._id,
        locationGroupId: adminPrimaryGroup._id,
      },
      clerkId: subject,
    });

    return { success: true, message: 'Created superadmin user' };
  },
});

export const checkSuperAdminInit = zQuery({
  handler: async ctx => {
    return isNewSuperAdmin(ctx);
  },
});

async function isNewSuperAdmin(ctx: QueryCtx) {
  const identity = await getCustomUserIdentity(ctx);
  if (!identity) return { needsSuperAdminInit: undefined };

  const { isSuperAdmin, subject } = identity;
  if (!isSuperAdmin) return { needsSuperAdminInit: false };

  const user = await getUser(ctx, { subject });
  return { needsSuperAdminInit: !user };
}
