import { getManyFrom } from 'convex-helpers/server/relationships';
import { Triggers } from 'convex-helpers/server/triggers';
import { DataModel } from '../_generated/dataModel';
import { generateSearchFields } from '../triggers/requests';

export const triggers = new Triggers<DataModel>();

triggers.register('requests', async (ctx, change) => {
  console.log('RUNNING REQUEST TRIGGER');
  if (!change.newDoc) return;
  const oldDoc = change.oldDoc;

  const { searchComponents, searchText } = await generateSearchFields(
    ctx,
    change.newDoc
  );

  // Only update if components changed
  if (
    !oldDoc ||
    JSON.stringify(searchComponents) !==
      JSON.stringify(oldDoc.searchComponents) ||
    searchText !== oldDoc.searchText
  ) {
    await ctx.innerDb.patch(change.id, {
      searchComponents,
      searchText,
    });
  }
});

triggers.register('users', async (ctx, change) => {
  if (change.operation === 'delete') {
    await Promise.all([
      // Delete from userRoles
      getManyFrom(ctx.db, 'userRoles', 'by_userId', change.id).then(roles =>
        Promise.all(roles.map(r => ctx.db.delete(r._id)))
      ),
      // Delete from userGroups
      getManyFrom(ctx.db, 'userGroups', 'by_userId', change.id).then(groups =>
        Promise.all(groups.map(g => ctx.db.delete(g._id)))
      ),
      // Delete any driverHistory
      getManyFrom(ctx.db, 'driverHistory', 'by_userId', change.id).then(
        history => Promise.all(history.map(h => ctx.db.delete(h._id)))
      ),
    ]);
  }
});
