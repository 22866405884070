// useReauthentication.js
import { useConvexAuth } from 'convex/react';
import { useEffect, useState } from 'react';

// TODO: I don't think this is working... I think they get redirected which isn't what we want...
/** Used to display the dialog version of SignInView if a user times out and needs to reauth */
export function useReauthentication() {
  const { isAuthenticated, isLoading } = useConvexAuth();
  const [showReauthDialog, setShowReauthDialog] = useState(false);
  const [wasAuthenticated, setWasAuthenticated] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      if (wasAuthenticated && !isAuthenticated) {
        // User was authenticated but now is not
        setShowReauthDialog(true);
      }
      setWasAuthenticated(isAuthenticated);
    }
  }, [isAuthenticated, isLoading, wasAuthenticated]);

  return {
    isLoading: false,
    showReauthDialog,
    closeReauthDialog: () => setShowReauthDialog(false),
  };
}
