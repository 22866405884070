import { createFileRoute, redirect } from '@tanstack/react-router';
// import { JwtSignInView } from 'src/minimal-theme/auth/view/jwt';

export const Route = createFileRoute('/')({
  beforeLoad: async ({ context: { isAuthenticated } }) => {
    if (isAuthenticated) {
      throw redirect({
        to: '/dashboard',
      });
    } else {
      throw redirect({
        to: '/sign-in',
      });
    }
  },
});
