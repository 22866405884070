import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useParams } from '@tanstack/react-router';
import { useMutation, useQuery } from 'convex/react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { api } from 'src/convex/_generated/api';
import { Id } from 'src/convex/_generated/dataModel';

interface RequestFindMechanicProps {
  open: boolean;
  onClose: () => void;
  inviteMechanic: () => void;
  onError?: (error: string) => void;
}

export const RequestFindMechanic = ({
  open,
  onClose,
  inviteMechanic,
  onError,
}: RequestFindMechanicProps) => {
  const params = useParams({ from: '/_auth/dashboard/requests/$requestId' });
  const requestId = params.requestId as Id<'requests'>;
  const [selectedMechanic, setSelectedMechanic] = useState<Id<'users'> | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  // Use "skip" string instead of null
  const mechanics = useQuery(
    api.functions.requests.getAvailableTechnicians,
    open ? { requestId } : 'skip'
  );

  const assignMechanic = useMutation(api.functions.requests.assignTechnician);

  // Reset selection when dialog closes
  useEffect(() => {
    if (!open) {
      setSelectedMechanic(null);
    }
  }, [open]);

  // Handle mechanics query error
  useEffect(() => {
    if (mechanics instanceof Error && open) {
      onError?.(mechanics.message || 'Failed to load technicians');
      onClose();
    }
  }, [mechanics, open, onError, onClose]);

  const handleAssignMechanic = async () => {
    if (!selectedMechanic) return;

    setIsLoading(true);
    try {
      const res = await assignMechanic({
        requestId: requestId,
        technicianId: selectedMechanic,
      });

      if (res.success) {
        toast.success('Mechanic assigned');
        onClose();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error('Failed to assign mechanic');
      onError?.(
        error instanceof Error ? error.message : 'Unknown error occurred'
      );
    } finally {
      setIsLoading(false);
    }
  };

  // TODO : currently if no technicians within service area, show all technicians

  const availableMechanics = mechanics?.techniciansWithDistance || [];
  const allMechanics = mechanics?.technicians || [];

  // Notify user if no technicians with distance are available
  const mechanicsToShow =
    availableMechanics.length > 0 ? availableMechanics : allMechanics;
  const showNotification = availableMechanics.length === 0;

  if (mechanics instanceof Error) {
    return null;
  }

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: 500 } }}
    >
      <DialogTitle>Find Mechanic</DialogTitle>

      <DialogContent>
        {showNotification && (
          <Alert severity="warning">
            No nearby mechanics available. Showing all mechanics.
          </Alert>
        )}
        {mechanics === undefined ? (
          <Alert severity="info">Loading available technicians...</Alert>
        ) : (
          <Autocomplete
            fullWidth
            options={mechanicsToShow}
            getOptionLabel={option =>
              `${option.clerkUser.firstName} ${option.clerkUser.lastName}`
            }
            value={
              mechanicsToShow.find(m => m._id === selectedMechanic) || null
            }
            onChange={(event, newValue) => {
              setSelectedMechanic(newValue?._id || null);
            }}
            renderInput={params => (
              <TextField {...params} label="Mechanic" required />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option._id}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Avatar src={option.clerkUser.imageUrl} />
                  {option.clerkUser.firstName} {option.clerkUser.lastName}{' '}
                  {'distance' in option &&
                    `(${(option.distance as number).toFixed(1)} miles away)`}
                </Box>
              </li>
            )}
            sx={{ mt: 1 }}
          />
        )}
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mt: 2, textAlign: 'center' }}
        >
          Can't find the mechanic you're looking for?
          <Link
            onClick={() => {
              onClose();
              inviteMechanic();
            }}
            sx={{
              cursor: 'pointer',
              textDecoration: 'none !important',
              ml: 1,
            }}
          >
            Invite a mechanic
          </Link>
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>

        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={!selectedMechanic || isLoading}
          loading={isLoading}
          onClick={handleAssignMechanic}
        >
          Assign
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
