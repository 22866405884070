import { createFileRoute } from '@tanstack/react-router';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute(
  '/_auth/dashboard/invoices/stripe-onboarding-complete'
)({
  beforeLoad: ({ context }) =>
    checkRouteAccess(context, routeAccessConfigs.dispatcherGeneral),
  component: () => <div>Hello /_auth/dashboard/invoices!</div>,
});
