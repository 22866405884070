import {
  createFileRoute,
  notFound,
  Outlet,
  redirect,
} from '@tanstack/react-router';

export const Route = createFileRoute('/_incomplete-sign-up')({
  beforeLoad: async ({ context: { isAuthenticated, user, me } }) => {
    if (!isAuthenticated) {
      throw redirect({
        to: '/sign-in',
        search: {
          redirect: location.href,
        },
      });
    } else if (user && me?.company) {
      const { companyOwner } = user.publicMetadata;

      const { isFullyVerified, isSignUpFormCompleted } = me.company;

      if (!companyOwner) {
        // TODO: Might need to make this a bit nicer...
        throw notFound();
      } else if (isSignUpFormCompleted && !isFullyVerified) {
        throw redirect({
          to: '/company-verification',
        });
      } else if (isFullyVerified) {
        throw redirect({
          to: '/dashboard',
        });
      }
    }
  },
  component: Outlet,
});
