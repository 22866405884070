import { api } from '@api';
import { createFileRoute } from '@tanstack/react-router';
import { Id } from 'src/convex/_generated/dataModel';
import Page from 'src/pages/dashboard/mechanic/edit';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/mechanics/$mechanicId')({
  beforeLoad: ({ context }) =>
    checkRouteAccess(context, routeAccessConfigs.serviceProviderOnly),
  loader: async ({ context, params }) => {
    return await context.convex.query(api.functions.users.getUserById, {
      id: params.mechanicId as Id<'users'>,
    });
  },
  component: Page,
});
