import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'src/config-global';
import { MechanicEditView } from 'src/sections/mechanic/view/mechanic-edit-view';
const metadata = { title: `Mechanic edit | Dashboard - ${CONFIG.appName}` };

export default function Page() {
  return (
    <>
      <Helmet>
        <title> {metadata.title}</title>
      </Helmet>
      <MechanicEditView />
    </>
  );
}
