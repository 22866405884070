import { useEffect, useState } from 'react';
import { Country } from 'react-phone-number-input';

export function usePreferredCountryCode() {
  const [countryCode, setCountryCode] = useState<Country>(() => {
    return (localStorage.getItem('countryCode') || 'US') as Country;
  });

  useEffect(() => {
    localStorage.setItem('countryCode', countryCode);
  }, [countryCode]);

  return [countryCode, setCountryCode] as const;
}
