import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { useMutation } from 'convex/react';
import { api } from 'src/convex/_generated/api';
import { Id } from 'src/convex/_generated/dataModel';
import { RepairDetails } from 'src/convex/schema/entities/requests/requests';
import { z } from 'zod';

const repairDetailsSchema = z.object({
  cause: z.string().min(2, 'Cause is required'),
  correction: z.string().min(2, 'Correction is required'),
  notes: z.string().optional(),
});

type RepairDetailsFormProps = {
  requestId: Id<'requests'>;
  currentRepairDetails?: RepairDetails;
  editable: boolean;
};
export const RepairDetailsForm = ({
  requestId,
  currentRepairDetails,
  editable,
}: RepairDetailsFormProps) => {
  const submitRepairDetails = useMutation(
    api.functions.requests.submitRepairDetails
  );

  const { Field, Subscribe, handleSubmit } = useForm({
    defaultValues: {
      cause: currentRepairDetails?.cause ?? '',
      correction: currentRepairDetails?.correction ?? '',
      wasATemporaryFix: !!currentRepairDetails?.wasATemporaryFix,
      notes: currentRepairDetails?.notes ?? '',
    },
    validatorAdapter: zodValidator(),
    validators: {
      onSubmit: repairDetailsSchema,
    },
    onSubmit: async ({ value }) => {
      try {
        await submitRepairDetails({
          requestId,
          ...value,
        });
      } catch (error) {
        console.error('Error submitting repair details:', error);
      }
    },
  });

  return (
    <Card>
      <CardHeader
        title="Repair Details"
        subheader={
          editable
            ? 'Fill in details about the repair work'
            : 'Technician will fill in details about the repair work'
        }
      />

      <CardContent>
        <form
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit();
          }}
        >
          <Stack spacing={3}>
            <Field
              name="cause"
              validatorAdapter={zodValidator()}
              validators={{
                onChange: z.string().min(1, { message: 'Cause is required' }),
              }}
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Cause of Repair"
                  disabled={!editable}
                  required
                  fullWidth
                  multiline
                  rows={3}
                  value={state.value}
                  error={state.meta.errors.length > 0}
                  helperText={state.meta.errors[0]}
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  placeholder="Describe what caused the issue"
                />
              )}
            />

            <Field
              name="correction"
              validatorAdapter={zodValidator()}
              validators={{
                onChange: z
                  .string()
                  .min(1, { message: 'Correction is required' }),
              }}
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Correction Applied"
                  required
                  fullWidth
                  disabled={!editable}
                  multiline
                  rows={3}
                  value={state.value}
                  error={state.meta.errors.length > 0}
                  helperText={state.meta.errors[0]}
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  placeholder="Describe what was done to fix the issue"
                />
              )}
            />

            <Field
              name="wasATemporaryFix"
              children={({ state, handleChange, handleBlur }) => (
                <FormControlLabel
                  label={'This repair was a temporary solution'}
                  disabled={!editable}
                  onBlur={handleBlur}
                  control={
                    <Checkbox
                      size="small"
                      checked={state.value}
                      onChange={(e, checked) => handleChange(checked)}
                    />
                  }
                />
              )}
            />

            <Field
              name="notes"
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Additional Notes"
                  fullWidth
                  disabled={!editable}
                  multiline
                  rows={3}
                  value={state.value}
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  placeholder="Any additional notes or observations (optional)"
                />
              )}
            />

            {editable && (
              <Box display="flex" justifyContent="flex-end">
                <Subscribe
                  selector={state => [state.canSubmit, state.isSubmitting]}
                  children={([canSubmit, isSubmitting]) => (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={!canSubmit}
                    >
                      Save Repair Details
                    </LoadingButton>
                  )}
                />
              </Box>
            )}
          </Stack>
        </form>
      </CardContent>
    </Card>
  );
};
