import { getManyFrom, getOneFrom } from 'convex-helpers/server/relationships';
import { internal } from '../_generated/api';
import { Id } from '../_generated/dataModel';
import { MutationCtx, query, QueryCtx } from '../_generated/server';
import {
  assignVehicleToDriverSchema,
  createVehicleInput,
  getVehicleByIdInput,
  unassignVehicleFromDriverSchema,
  updateVehicleInput,
} from '../schema/entities/vehicles';
import {
  VehicleLocationUpdateInput,
  vehicleLocationUpdateInput,
} from '../types/locationSchema';
import { getUserContext } from '../utils/getUserContext';
import { zInternalMutation, zMutation, zQuery } from './helpers/zodHelpers';

interface VehicleData {
  vin: string;
  unitNumber: string;
  [key: string]: any;
}

interface InsertResult {
  success: boolean;
  vehicle?: VehicleData;
  error?: Error;
  vin?: string;
}

// Create initial vehicle entries in the database
// export const createVehicleInitMutation = zInternalMutation({
//   args: { vehiclesInput: createVehicleInput },
//   handler: async (
//     ctx,
//     { vehiclesInput: { vehicles } }
//   ): Promise<{
//     status: boolean;
//     message: string;
//     successfulInserts: any[];
//     failedInserts: any[];
//   }> => {
//     try {
//       console.log('initimutation');
//       const insertResults = await Promise.all(
//         vehicles.map(async vehicleData => {
//           console.log('vehicleData', vehicleData);
//           try {
//             // Check for an existing vehicle with the same VIN and companyId
//             const existingVehicle = await getOneFrom(
//               ctx.db,
//               'vehicles',
//               'by_vin',
//               vehicleData.vin,
//               'vin'
//             );

//             if (existingVehicle) {
//               throw new Error(
//                 `Vehicle with VIN ${vehicleData.vin} already exists.`
//               );
//             }

//             // Proceed with insertion if no duplicate is found
//             const insertedVehicleId = await ctx.db.insert('vehicles', {
//               ...vehicleData,
//               companyId: vehicleData.companyId as Id<'companies'>,
//               updatedById: vehicleData.updatedById as Id<'users'>,
//               addedById: vehicleData.addedById as Id<'users'>,
//               // TODO: We probably want to let them set these on creation? Bit trickier with bulk...
//               status: vehicleData.status,
//               typeStatus: 'FLEET_OWNED',
//             });

//             const insertedVehicle = await ctx.db.get(insertedVehicleId);

//             return { success: true, vehicle: insertedVehicle };
//           } catch (error) {
//             return { success: false, error, vin: vehicleData.vin };
//           }
//         })
//       );

//       // Separate successful and failed inserts
//       const successfulInserts = insertResults
//         .filter(result => result.success)
//         .map(result => result.vehicle);

//       const failedInserts = insertResults
//         .filter(result => !result.success)
//         .map(result => ({
//           vin: result.vin,
//           error:
//             (result.error as Error)?.message ?? 'An unknown error occurred',
//         }));

//       return {
//         status: true,
//         message: 'Vehicles initialized successfully',
//         successfulInserts,
//         failedInserts,
//       };
//     } catch (error: any) {
//       console.error('Error in createVehicleInitMutation:', error);
//       return {
//         status: false,
//         message: error.message ?? 'An error occurred',
//         successfulInserts: [],
//         failedInserts: [],
//       };
//     }
//   },
// });

type VehicleResult = {
  vin: string;
  vehicleId?: Id<'vehicles'>;
  scheduleId?: Id<'_scheduled_functions'>;
  error?: string;
};
export const createVehicleMutation = zMutation({
  args: { vehiclesInput: createVehicleInput },
  handler: async (
    ctx,
    { vehiclesInput: { vehicles } }
  ): Promise<{
    success: boolean;
    message: string;
    results: VehicleResult[];
  }> => {
    const { user, company, primaryLocation } = await getUserContext(ctx);

    if (!user) {
      throw new Error('User not authenticated');
    }

    const results: VehicleResult[] = [];

    for (const vehicle of vehicles) {
      try {
        // Check for existing vehicle
        const existingVehicle = await getOneFrom(
          ctx.db,
          'vehicles',
          'by_vin',
          vehicle.vin,
          'vin'
        );

        if (existingVehicle) {
          results.push({
            vin: vehicle.vin,
            error: `Vehicle with VIN ${vehicle.vin} already exists`,
          });
          continue;
        }

        // Prepare vehicle data with context
        const vehicleData = {
          ...vehicle,
          companyId: company._id,
          addedById: user._id,
          updatedById: user._id,
          primaryLocationGroupId: primaryLocation._id,
          status: vehicle.status,
        };

        const vehicleId = await ctx.db.insert('vehicles', {
          ...vehicleData,
          typeStatus: 'FLEET_OWNED',
        });

        const scheduleId = await ctx.scheduler.runAfter(
          0,
          internal.actions.addVehicles.getVehicleData,
          {
            vin: vehicle.vin,
            vehicleId,
          }
        );

        results.push({
          vin: vehicle.vin,
          vehicleId,
          scheduleId,
        });
      } catch (error) {
        results.push({
          vin: vehicle.vin,
          error:
            error instanceof Error ? error.message : 'Unknown error occurred',
        });
      }
    }

    const successCount = results.filter(r => !r.error).length;
    const failureCount = results.filter(r => r.error).length;

    return {
      success: successCount > 0,
      message: `${successCount} vehicles created, ${failureCount} failed. VIN decoding in progress.`,
      results, // Frontend can use the scheduleIds to monitor VIN decoding progress
    };
  },
});

export const getVehiclesForLocation = query({
  handler: async ctx => {
    const { user, primaryLocation } = await getUserContext(ctx);

    if (!user) {
      throw new Error('User not authenticated');
    }

    const vehicles = await getManyFrom(
      ctx.db,
      'vehicles',
      'by_primaryLocationGroupId',
      primaryLocation._id
    );

    return vehicles;
  },
});

export const getAllVehiclesForAdmin = query({
  handler: async ctx => {
    const { user } = await getUserContext(ctx);

    if (!user || !user.clerkUser.isSuperAdmin) {
      throw new Error('User not authenticated');
    }

    return ctx.db.query('vehicles').collect();
  },
});

export const updateVehicleMutation = zMutation({
  args: updateVehicleInput.shape,
  handler: async (ctx, { vehicleId, ...updates }) => {
    const { user, company } = await getUserContext(ctx);
    if (!user) {
      throw new Error('User not authenticated');
    }

    const vehicle = await ctx.db.get(vehicleId);
    if (!vehicle) {
      throw new Error(`Vehicle with ID ${vehicleId} not found`);
    }

    if (vehicle.companyId !== company._id) {
      throw new Error('Vehicle does not belong to your company');
    }

    const updatedVehicle = await ctx.db.patch(vehicleId, updates);

    return {
      status: true,
      message: 'Vehicle updated successfully',
      vehicle: updatedVehicle,
    };
  },
});

export const updateVehicleLocation = zMutation({
  args: vehicleLocationUpdateInput.shape,
  handler: async (ctx, { vehicleId, location }) => {
    // Get user context and verify authentication
    const { user, company } = await getUserContext(ctx);

    const companyId = company._id;

    // Get vehicle and verify company ownership
    const vehicle = await ctx.db.get(vehicleId);
    if (!vehicle) {
      throw new Error('Vehicle not found');
    }
    if (vehicle.companyId !== companyId) {
      throw new Error('Vehicle does not belong to your company');
    }

    // Verify user is actively assigned to this vehicle
    const activeAssignment = await ctx.db
      .query('driverHistory')
      .withIndex('by_vehicle_user_status', q =>
        q
          .eq('vehicleId', vehicleId)
          .eq('userId', user._id)
          .eq('status', 'ACTIVE')
      )
      .first();

    if (!activeAssignment) {
      throw new Error('User is not currently assigned to this vehicle');
    }

    await updateDriverAndVehicleLocation(
      ctx,
      { vehicleId, location },
      user._id
    );

    return {
      success: true,
      message: 'Vehicle location updated successfully',
    };
  },
});

export const updateDriverAndVehicleLocation = (
  ctx: MutationCtx,
  vehicleLocationUpdate: VehicleLocationUpdateInput,
  driverId: Id<'users'>
) => {
  return Promise.all([
    ctx.db.patch(vehicleLocationUpdate.vehicleId, {
      location: {
        ...vehicleLocationUpdate.location,
        lastUpdated: new Date().toISOString(),
      },
      updatedAt: new Date().toISOString(),
      updatedById: driverId,
    }),
    ctx.db.patch(driverId, {
      location: {
        ...vehicleLocationUpdate.location,
        lastUpdated: new Date().toISOString(),
      },
      updatedAt: new Date().toISOString(),
    }),
  ]);
};

export const updateVehicleMutationInternal = zInternalMutation({
  args: updateVehicleInput.shape,
  handler: async (ctx, { vehicleId, ...updates }) => {
    const vehicle = await ctx.db.get(vehicleId);
    if (!vehicle) {
      throw new Error(`Vehicle with ID ${vehicleId} not found`);
    }

    await ctx.db.patch(vehicleId, updates);

    return {
      status: true,
      message: 'Vehicle updated successfully',
    };
  },
});

export const getVehicleById = zQuery({
  args: getVehicleByIdInput.shape,
  handler: async (ctx, { vehicleId }) => {
    const { user, company } = await getUserContext(ctx);

    const companyId = company._id;

    if (!user) {
      throw new Error('User not authenticated');
    }

    const vehicle = await ctx.db.get(vehicleId);

    if (!vehicle) {
      throw new Error(`Vehicle with ID ${vehicleId} not found`);
    }

    // TODO: removing this for now, but we should probably add it back in
    // if (vehicle.companyId !== companyId) {
    //   throw new Error('Vehicle does not belong to your company');
    // }

    return vehicle;
  },
});

export const deleteVehicleMutation = zMutation({
  args: getVehicleByIdInput.shape,
  handler: async (ctx, { vehicleId }) => {
    const { user, company } = await getUserContext(ctx);

    if (!user) {
      throw new Error('User not authenticated');
    }

    const vehicle = await ctx.db.get(vehicleId);

    if (!vehicle) {
      throw new Error(`Vehicle with ID ${vehicleId} not found`);
    }

    if (vehicle.companyId !== company._id) {
      throw new Error('Vehicle does not belong to your company');
    }

    await ctx.db.delete(vehicleId);

    return {
      status: true,
      message: 'Vehicle deleted successfully',
    };
  },
});

// todo: add photos to vehicle mutation

export const assignVehicleToDriver = zMutation({
  args: assignVehicleToDriverSchema.shape,
  async handler(ctx, { driverId, vehicleId }) {
    const { user, company } = await getUserContext(ctx);

    if (!user) {
      throw new Error('User not authenticated');
    }

    // Validate driver and vehicle existence and ownership
    const [driver, vehicle] = await Promise.all([
      ctx.db.get(driverId),
      ctx.db.get(vehicleId),
    ]);

    if (!driver) {
      throw new Error('Driver not found');
    }

    if (!vehicle) {
      throw new Error('Vehicle not found');
    }

    if (driver.companyId !== company._id) {
      throw new Error('Driver does not belong to your company');
    }

    if (vehicle.companyId !== company._id) {
      throw new Error('Vehicle does not belong to your company');
    }

    // Check if the driver is already assigned to the same vehicle with status
    const activeAssignment = await ctx.db
      .query('driverHistory')
      .withIndex('by_vehicle_user_status', q =>
        q
          .eq('vehicleId', vehicleId)
          .eq('userId', driverId)
          .eq('status', 'ACTIVE')
      )
      .first();

    if (activeAssignment) {
      throw new Error('Driver is already assigned to this vehicle');
    }

    // Create a new driver assignment in the `driverHistory` table
    await ctx.db.insert('driverHistory', {
      userId: driverId,
      vehicleId: vehicleId,
      started: new Date().toISOString(),
      status: 'ACTIVE',
      createdBy: user._id,
      updatedBy: user._id,
    });

    // Notify the driver of the new assignment
    ctx.scheduler.runAfter(
      0,
      internal.actions.sendEmail.sendEmailUsingLocalTemplates,
      {
        to: driver.clerkUser.primaryEmailAddress?.emailAddress ?? '',
        emailType: 'NOTIFICATION',
        data: {
          userName: driver.clerkUser.firstName ?? '',
          subject: 'You Have Been Assigned A Vehicle',
          additionalContext: `You have been assigned a vehicle in the myMechanic App (Unit: ${vehicle.unitNumber})`,
          alternateRelativeUrl: '/',
        },
      }
    );

    return {
      success: true,
      message: 'Truck assigned to driver successfully',
    };
  },
});

export const unassignVehicleFromDriver = zMutation({
  args: unassignVehicleFromDriverSchema.shape,
  async handler(ctx, { vehicleId, driverIds }) {
    const { user, company } = await getUserContext(ctx);

    if (!user) {
      throw new Error('User not authenticated');
    }

    // Check if the vehicle exists
    const vehicle = await ctx.db.get(vehicleId);
    if (!vehicle) {
      throw new Error('Vehicle not found');
    }

    if (vehicle.companyId !== company._id) {
      throw new Error('Vehicle does not belong to your company');
    }

    // Find active assignments for the specified drivers
    const activeAssignments = await Promise.all(
      driverIds.map(driverId =>
        ctx.db
          .query('driverHistory')
          .withIndex('by_vehicle_user_status', q =>
            q
              .eq('vehicleId', vehicleId)
              .eq('userId', driverId)
              .eq('status', 'ACTIVE')
          )
          .first()
      )
    );

    const validAssignments = activeAssignments.filter(
      (assignment): assignment is NonNullable<typeof assignment> =>
        assignment !== null
    );

    if (validAssignments.length === 0) {
      throw new Error('No active assignments found for the specified drivers');
    }

    // End assignments for specified drivers
    await Promise.all(
      validAssignments.map(async assignment => {
        await ctx.db.patch(assignment._id, {
          status: 'INACTIVE',
          stopped: new Date().toISOString(),
          updatedBy: user._id,
        });

        // Fetch the driver details and send notification
        const driver = await ctx.db.get(assignment.userId);
        if (driver) {
          ctx.scheduler.runAfter(
            0,
            internal.actions.sendEmail.sendEmailUsingLocalTemplates,
            {
              to: driver.clerkUser.primaryEmailAddress?.emailAddress ?? '',
              emailType: 'NOTIFICATION',
              data: {
                userName: driver.clerkUser.firstName ?? '',
                subject: 'You Have Been Unassigned From A Vehicle',
                additionalContext: `You have been unassigned from a vehicle in the myMechanic App (Unit: ${vehicle.unitNumber})`,
                alternateRelativeUrl: '/',
              },
            }
          );
        }
      })
    );

    return {
      success: true,
      message: `Truck unassigned from ${validAssignments.length} driver(s) successfully`,
    };
  },
});

async function getOperationalStatus(ctx: QueryCtx, vehicleId: Id<'vehicles'>) {
  const vehicle = await ctx.db.get(vehicleId);
  if (!vehicle) return null;

  // If vehicle is explicitly marked as inactive, that takes precedence
  if (vehicle.status !== 'ACTIVE') {
    return {
      isOperational: false,
      status: vehicle.status,
      reason: `Vehicle is ${vehicle.status?.toLowerCase()}`,
    };
  }

  // Check for active requests
  const activeRequest = await ctx.db
    .query('requests')
    .withIndex('by_vehicleId_and_status', q =>
      q.eq('vehicleId', vehicleId).eq('status', 'ACTIVE')
    )
    .first();

  if (activeRequest) {
    return {
      isOperational: false,
      status: 'IN_SERVICE',
      reason: 'Vehicle has active service request',
      requestId: activeRequest._id,
    };
  }

  return {
    isOperational: true,
    status: 'OPERATIONAL',
    reason: 'No active service requests',
  };
}

/**
 * Gets all vehicles that are currently non-operational
 * Useful for fleet managers to see vehicles requiring attention
 */
async function getNonOperationalVehicles(
  ctx: QueryCtx,
  companyId: Id<'companies'>
) {
  // First get vehicles marked as non-active
  const nonActiveVehicles = await ctx.db
    .query('vehicles')
    .withIndex('by_companyId', q => q.eq('companyId', companyId))
    .filter(q => q.neq(q.field('status'), 'ACTIVE'))
    .collect();

  // Then get vehicles with active requests
  const vehiclesWithRequests = await ctx.db
    .query('requests')
    .withIndex('by_requesterCompanyId_and_status', q =>
      q.eq('requesterCompanyId', companyId).eq('status', 'ACTIVE')
    )
    .collect();

  // Combine and deduplicate
  const nonOperationalIds = new Set([
    ...nonActiveVehicles.map(v => v._id),
    ...vehiclesWithRequests
      .map(r => r.vehicleId)
      .filter((id): id is Id<'vehicles'> => id !== undefined),
  ]);

  // Get full vehicle details for each
  const statuses = await Promise.all(
    Array.from(nonOperationalIds).map(id => getOperationalStatus(ctx, id))
  );

  return statuses.filter((s): s is NonNullable<typeof s> => s !== null);
}

// Helper functions for common vehicle location operations
export const vehicleLocationHelpers = {
  /**
   * Gets a vehicle's current primary location (HOME_TERMINAL)
   */
  async getCurrentHomeTerminal(ctx: QueryCtx, vehicleId: Id<'vehicles'>) {
    return await ctx.db
      .query('vehicleLocationHistory')
      .withIndex('by_vehicleId', q => q.eq('vehicleId', vehicleId))
      .filter(q =>
        q.and(
          q.eq(q.field('assignmentType'), 'HOME_TERMINAL'),
          q.eq(q.field('endedAt'), undefined)
        )
      )
      .first();
  },

  /**
   * Gets all current locations for a vehicle (including temporary assignments)
   */
  async getAllCurrentLocations(ctx: QueryCtx, vehicleId: Id<'vehicles'>) {
    return await ctx.db
      .query('vehicleLocationHistory')
      .withIndex('by_vehicleId', q => q.eq('vehicleId', vehicleId))
      .filter(q => q.eq(q.field('endedAt'), undefined))
      .collect();
  },

  /**
   * Gets all vehicles currently assigned to a location
   */
  async getLocationVehicles(ctx: QueryCtx, locationGroupId: Id<'groups'>) {
    return await ctx.db
      .query('vehicleLocationHistory')
      .withIndex('by_locationGroupId', q =>
        q.eq('locationGroupId', locationGroupId)
      )
      .filter(q => q.eq(q.field('endedAt'), undefined))
      .collect();
  },

  /**
   * Updates a vehicle's home terminal
   */
  async updateHomeTerminal(
    ctx: MutationCtx,
    vehicleId: Id<'vehicles'>,
    newLocationGroupId: Id<'groups'>,
    companyId: Id<'companies'>,
    userId: Id<'users'>
  ) {
    // End current home terminal assignment if exists
    const currentHome = await this.getCurrentHomeTerminal(ctx, vehicleId);
    if (currentHome) {
      await ctx.db.patch(currentHome._id, {
        endedAt: new Date().toISOString(),
      });
    }

    // Create new home terminal assignment
    await ctx.db.insert('vehicleLocationHistory', {
      vehicleId,
      locationGroupId: newLocationGroupId,
      locationCompanyId: companyId,
      startedAt: new Date().toISOString(),
      assignmentType: 'HOME_TERMINAL',
      assignedById: userId,
      companyId,
    });
  },

  /**
   * Creates a temporary location assignment
   */
  async createTemporaryAssignment(
    ctx: MutationCtx,
    vehicleId: Id<'vehicles'>,
    locationGroupId: Id<'groups'>,
    companyId: Id<'companies'>,
    userId: Id<'users'>,
    expectedDuration: {
      startDate: string;
      endDate: string;
      reason: string;
    }
  ) {
    await ctx.db.insert('vehicleLocationHistory', {
      vehicleId,
      locationGroupId,
      locationCompanyId: companyId,
      startedAt: new Date().toISOString(),
      assignmentType: 'TEMPORARY',
      expectedDuration,
      assignedById: userId,
      companyId,
    });
  },

  /**
   * Records a borrowed vehicle arrangement
   */
  async recordBorrowedVehicle(
    ctx: MutationCtx,
    vehicleId: Id<'vehicles'>,
    borrowedFromGroupId: Id<'groups'>,
    borrowedFromCompanyId: Id<'companies'>,
    borrowingGroupId: Id<'groups'>,
    borrowingCompanyId: Id<'companies'>,
    userId: Id<'users'>,
    agreement: {
      agreementNumber: string;
      startDate: string;
      endDate: string;
      contactName: string;
      contactPhone?: string;
      contactEmail?: string;
      notes?: string;
    }
  ) {
    // Record at the lending location
    await ctx.db.insert('vehicleLocationHistory', {
      vehicleId,
      locationGroupId: borrowedFromGroupId,
      locationCompanyId: borrowedFromCompanyId,
      assignmentType: 'BORROWED_FROM',
      startedAt: new Date().toISOString(),
      agreementDetails: agreement,
      assignedById: userId,
      companyId: borrowedFromCompanyId,
    });

    // Record at the borrowing location
    await ctx.db.insert('vehicleLocationHistory', {
      vehicleId,
      locationGroupId: borrowingGroupId,
      locationCompanyId: borrowingCompanyId,
      originatingLocationGroupId: borrowedFromGroupId,
      originatingCompanyId: borrowedFromCompanyId,
      assignmentType: 'BORROWED_BY',
      startedAt: new Date().toISOString(),
      agreementDetails: agreement,
      assignedById: userId,
      companyId: borrowingCompanyId,
    });
  },
};
