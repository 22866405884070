import { createFileRoute } from '@tanstack/react-router';
import { api } from 'src/convex/_generated/api';
import { Id } from 'src/convex/_generated/dataModel';
import Page from 'src/pages/dashboard/company/edit';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/companies/$companyId')({
  beforeLoad: ({ context }) =>
    checkRouteAccess(context, routeAccessConfigs.onlySuperAdmin),
  loader: async ({ context, params }) => {
    return await context.convex.query(api.functions.companies.getCompany, {
      companyId: params.companyId as Id<'companies'>,
    });
  },
  component: Page,
});
