import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Box,
  Card,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { useLoaderData } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { useMutation } from 'convex/react';
import { api } from 'src/convex/_generated/api';
import { vehicleSchema } from 'src/convex/schema/entities/vehicles';
import { vehicleType } from 'src/convex/schema/enums/vehicleType';
import { toast } from 'src/minimal-theme/components/snackbar';
import { z } from 'zod';

export type NewTruckSchemaType = z.infer<typeof vehicleSchema>;

const vehicleTypeLabels: Record<string, string> = {
  TRUCK: 'Truck',
  TRAILER: 'Trailer',
  TOW_TRUCK: 'Tow Truck',
};

const makerOptions = [
  'Freightliner',
  'Peterbilt',
  'Kenworth',
  'Volvo',
  'Mack',
  'International',
  'Western Star',
];

export const TruckNewEditForm = () => {
  const vehicle = (() => {
    try {
      return useLoaderData({
        from: '/_auth/dashboard/trucks/$truckId',
      });
    } catch {
      return null;
    }
  })();

  const createVehicle = useMutation(
    api.functions.vehicles.createVehicleMutation
  );

  const updateVehicle = useMutation(
    api.functions.vehicles.updateVehicleMutation
  );

  // const drivers = useQuery(api.functions.users.getUsersOfRoleForLocation, {
  //   roleType: 'DRIVER_FLEET',
  // });

  const { Field, Subscribe, handleSubmit, reset } = useForm({
    defaultValues: {
      vin: vehicle?.vin || '',
      type: (vehicle?.type as 'TRUCK' | 'TRAILER' | 'TOW_TRUCK') || '',
      maker: vehicle?.maker || '',
      model: vehicle?.model || '',
      modelYear: vehicle?.modelYear || '',
      color: vehicle?.color || '',
      unitNumber: vehicle?.unitNumber || '',
      status: vehicle?.status,
      // assignedDriverId: vehicle?.userId || null,
    },
    onSubmit: async ({ value }) => {
      try {
        if (vehicle) {
          // Update existing vehicle
          const result = await updateVehicle({
            ...value,
            vehicleId: vehicle._id,
          });

          if (!result.status) {
            toast.error(result.message);
            return;
          }

          toast.success('Truck updated successfully');
        } else {
          // Create new vehicle
          const result = await createVehicle({
            vehiclesInput: {
              vehicles: [value],
            },
          });

          console.log('result', result);

          if (result.success) {
            toast.success('Truck created successfully');
            reset();
          } else {
            toast.error(result.message);
          }
        }
      } catch (error) {
        console.error('Failed to save vehicle:', error);
        toast.error('Failed to save vehicle. Please try again.');
      }
    },
  });

  return (
    <Card>
      <CardHeader
        title={vehicle ? 'Edit Truck' : 'Create Truck'}
        subheader={
          vehicle
            ? 'Edit the details of the truck'
            : 'Enter the details of the truck'
        }
        sx={{ mb: 3 }}
      />
      <Divider />
      <Stack spacing={3} sx={{ p: 3 }}>
        <form
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit();
          }}
          style={{ width: '100%' }}
        >
          <Box
            columnGap={2}
            rowGap={3}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
            }}
          >
            <Field
              name="vin"
              validatorAdapter={zodValidator()}
              validators={{
                onChange: z
                  .string()
                  .length(17, { message: 'VIN must be 17 characters long' }),
              }}
            >
              {({ state, handleChange, handleBlur }) => (
                <TextField
                  label="VIN"
                  placeholder="12345678901234567"
                  variant="outlined"
                  required
                  fullWidth
                  value={state.value}
                  helperText={state.meta.errors[0]}
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  type="text"
                />
              )}
            </Field>
            <Field
              name="type"
              validatorAdapter={zodValidator()}
              validators={{
                onChange: vehicleType,
              }}
            >
              {({ state, handleChange, handleBlur }) => (
                <FormControl fullWidth error={!!state.meta.errors[0]}>
                  <InputLabel id="type-label">Truck Type *</InputLabel>
                  <Select
                    required
                    labelId="type-label"
                    label="Truck Type"
                    value={state.value}
                    onChange={e =>
                      handleChange(
                        e.target.value as 'TRUCK' | 'TRAILER' | 'TOW_TRUCK'
                      )
                    }
                    onBlur={handleBlur}
                  >
                    {vehicleType.options.map(type => (
                      <MenuItem key={type} value={type}>
                        {vehicleTypeLabels[type] || type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Field>
            <Field
              name="maker"
              validatorAdapter={zodValidator()}
              validators={{
                onChange: z.string(),
              }}
            >
              {({ state, handleChange, handleBlur }) => (
                <FormControl fullWidth error={!!state.meta.errors[0]}>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    options={makerOptions}
                    getOptionLabel={option => option}
                    value={state.value}
                    onChange={(event, newValue) => {
                      handleChange(newValue || '');
                    }}
                    inputValue={state.value}
                    onInputChange={(event, newInputValue) => {
                      handleChange(newInputValue || '');
                    }}
                    renderInput={params => (
                      <TextField {...params} label="Maker" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </FormControl>
              )}
            </Field>
            <Field
              name="model"
              validatorAdapter={zodValidator()}
              validators={{
                onChange: z.string(),
              }}
            >
              {({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Model"
                  variant="outlined"
                  fullWidth
                  value={state.value}
                  helperText={state.meta.errors[0]}
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  type="text"
                />
              )}
            </Field>

            <Field
              name="modelYear"
              validatorAdapter={zodValidator()}
              validators={{
                onChange: z.string(),
              }}
            >
              {({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Model Year"
                  variant="outlined"
                  fullWidth
                  value={state.value}
                  helperText={state.meta.errors[0]}
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  type="text"
                />
              )}
            </Field>

            <Field
              name="color"
              validatorAdapter={zodValidator()}
              validators={{
                onChange: z.string(),
              }}
            >
              {({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Color"
                  variant="outlined"
                  fullWidth
                  value={state.value}
                  helperText={state.meta.errors[0]}
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  type="text"
                />
              )}
            </Field>
            <Field
              name="unitNumber"
              validatorAdapter={zodValidator()}
              validators={{
                onChange: z.string(),
              }}
            >
              {({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Unit Number"
                  variant="outlined"
                  fullWidth
                  value={state.value}
                  helperText={state.meta.errors[0]}
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  type="text"
                />
              )}
            </Field>
          </Box>
          <Field name="status">
            {({ state, handleChange, handleBlur }) => (
              <FormControlLabel
                sx={{ mt: 2 }}
                control={
                  <Switch
                    id="status"
                    checked={state.value === 'OUT_OF_SERVICE'}
                    onChange={e =>
                      handleChange(
                        e.target.checked ? 'OUT_OF_SERVICE' : 'ACTIVE'
                      )
                    }
                  />
                }
                label="Out of Service"
              />
            )}
          </Field>

          <Stack alignItems="flex-end" sx={{ mt: 3 }}>
            <Subscribe
              selector={state => [
                state.canSubmit,
                state.isSubmitting,
                state.isDirty,
              ]}
              children={([canSubmit, isSubmitting, isDirty]) => {
                return (
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!canSubmit || !isDirty}
                  >
                    {vehicle ? 'Save' : 'Create'}
                  </LoadingButton>
                );
              }}
            />
          </Stack>
        </form>
      </Stack>
    </Card>
  );
};
