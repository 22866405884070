import { m } from 'framer-motion';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { Box } from '@mui/material';
import { PageNotFoundIllustration } from 'src/minimal-theme/assets/illustrations';
import {
  MotionContainer,
  varBounce,
} from 'src/minimal-theme/components/animate';
import { RouterLink } from 'src/minimal-theme/components/routes/components';
import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';

// ----------------------------------------------------------------------

export function NotFoundView() {
  return (
    <DashboardContent>
      <Container component={MotionContainer}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Sorry, page not found!
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
            mistyped the URL? Be sure to check your spelling.
          </Typography>
        </m.div>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <m.div variants={varBounce().in}>
            <PageNotFoundIllustration sx={{ my: { xs: 5, sm: 10 } }} />
          </m.div>

          <Button
            component={RouterLink}
            href="/"
            size="large"
            variant="contained"
          >
            Go to home
          </Button>
        </Box>
      </Container>
    </DashboardContent>
  );
}
