import { api } from '@api';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Fab,
  FormControl,
  IconButton,
  Stack,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { useLoaderData, useParams } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { useMutation, useQuery } from 'convex/react';
import { useEffect, useState } from 'react';
import { Doc, Id } from 'src/convex/_generated/dataModel';
import { isLocationRecentlyUpdated } from 'src/convex/functions/requests';
import { CustomTabs } from 'src/minimal-theme/components/custom-tabs';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Image } from 'src/minimal-theme/components/image';
import { useLocationTracking } from 'src/minimal-theme/hooks/useLocationTracking';
import { RequestChat } from 'src/sections/chat/view/RequestChat';
import { RequestHistory } from 'src/sections/chat/view/RequestHistory';
import { AddPhotosDialog } from 'src/sections/request/AddPhotosDialog';
import { RepairDetailsForm } from 'src/sections/request/RepairDetailsForm';
import { RequestParticipants } from 'src/sections/request/view/request-participants';
import { RequestStepInformation } from 'src/sections/request/view/request-step-information';
import { getRequestStatusColor } from 'src/utils/helper';
import { z } from 'zod';
import { RequestMap } from './request-map';
import { RequestWorkflowSteps } from './request-workflow-steps';

export const REQUEST_TABS = {
  main: 'main',
  photos: 'photos',
  chat: 'chat',
  history: 'history',
} as const;

type RequestTabs = (typeof REQUEST_TABS)[keyof typeof REQUEST_TABS];

export const RequestViewForm = () => {
  const params = useParams({ from: '/_auth/dashboard/requests/$requestId' });

  const [currentTab, setCurrentTab] = useState<RequestTabs>(REQUEST_TABS.main);
  const [addPhotoDialogOpen, setIsAddPhotoDialogOpen] =
    useState<boolean>(false);

  const user = useQuery(api.functions.users.getMe);

  useLocationTracking();

  const loaderData = useLoaderData({
    from: '/_auth/dashboard/requests/$requestId',
  });

  const realTimeRequest = useQuery(api.functions.requests.getRequest, {
    requestId: params.requestId as Id<'requests'>,
  });

  // Use loader data while query is loading, then use real-time data
  const request = realTimeRequest ?? loaderData;

  const images = useQuery(api.functions.requestImages.getRequestPhotos, {
    requestId: request._id,
  });

  const confirmedImages = images ? images.filter(i => !!i.confirmed) : [];
  const myUnconfirmedImages = images
    ? images.filter(i => !i.confirmed && i.createdById === user?._id)
    : [];

  const slides = confirmedImages.map(c => ({ src: c.url }));

  // TODO: @Andrii maybe you can figure this one out?  It works but the styling is all messed up, so they must have a lightbox... container or something somewhere I just can't find it
  // const lightbox = useLightBox(slides);

  const userIsFleetDriver = user?.primaryRoleType === 'DRIVER_FLEET';

  const userIsFleetDispatcher =
    user?.primaryRoleType === 'FLEET_DISPATCHER' ||
    user?.primaryRoleType === 'FLEET_DISPATCH_SUPERVISOR';

  const [selectedDriver, setSelectedDriver] = useState<Doc<'users'> | null>(
    userIsFleetDriver ? user : null
  );

  const [initialActiveChecked, setInitialActiveChecked] = useState(false);

  const [isEditingRequestDetails, setIsEditingRequestDetails] = useState(
    request.status === 'DRAFT'
  );

  // For when transitioning from draft to active initially
  useEffect(() => {
    if (request.status === 'ACTIVE' && !initialActiveChecked) {
      setInitialActiveChecked(true);
      setIsEditingRequestDetails(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [request.status, initialActiveChecked]);

  const currentVehicles = useQuery(
    api.functions.driverHistory.getCurrentVehicleAssignments,
    userIsFleetDriver
      ? {
          userId: user?._id as Id<'users'>,
        }
      : 'skip'
  );

  const vehicles = useQuery(api.functions.vehicles.getVehiclesForLocation);

  const drivers = useQuery(api.functions.users.getUsersOfRoleForLocation, {
    roleType: 'DRIVER_FLEET',
  });

  const submitRequest = useMutation(api.functions.requests.submitRequest);

  const { Field, Subscribe, handleSubmit, useStore, reset } = useForm({
    defaultValues: {
      vehicleId: request.vehicleId as Id<'vehicles'> | null,
      description: request.description || '',
      activeDriverId: selectedDriver?._id || request.activeDriverId || null,
      address: request.address || '',
    },
    // validatorAdapter: zodValidator(),
    // validators: {
    //   onSubmit: newRequestSchema,
    // },
    onSubmit: async ({ value }) => {
      try {
        if (!value.vehicleId || !value.activeDriverId) return;

        await submitRequest({
          input: {
            requestId: request?._id as Id<'requests'>,
            ...value,
            vehicleId: value.vehicleId,
            activeDriverId: value.activeDriverId,
            address: value.address,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        });
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    },
  });

  const selectedVehicleId = useStore(s => s.values.vehicleId);

  const driverVehicle = useQuery(
    api.functions.vehicles.getVehicleById,
    selectedVehicleId ? { vehicleId: selectedVehicleId } : 'skip'
  );

  // Special additional condition for drivers submitting... they must provide a valid recent location before submitting
  const canDriverSubmit =
    !!driverVehicle && isLocationRecentlyUpdated(driverVehicle);

  console.log(`driverVehicle: ${driverVehicle}`);
  console.log(`RECENTLY UPDATED: ${isLocationRecentlyUpdated(driverVehicle)}`);

  if (!request) {
    return null;
  }

  // TODO: I don't think vehicle/driver should be editable for a request? Shouldn't it be kind of locked in? Discussion needed
  let showRequestDetailsEditButton = false;
  let showLocationDetailsEditButton = false;

  const requestLocked =
    request.status === 'COMPLETED' || request.status === 'CANCELLED';
  if (userIsFleetDispatcher && request.status !== 'DRAFT' && !requestLocked) {
    showRequestDetailsEditButton = true;
    showLocationDetailsEditButton = true;
  }

  const content = (
    <Box display="flex" flexDirection="column" gap={3}>
      {/* <Button
        component={RouterLink}
        href={'/dashboard/requests'}
        startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
        sx={{ mr: 'auto' }}
      >
        Back
      </Button> */}

      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="h4">Case # {request.caseNumber}</Typography>
        <Chip
          label={request.status}
          color={getRequestStatusColor(request.status)}
        />
      </Box>

      <RequestStepInformation />

      <Box
        display="flex"
        gap={2}
        flexDirection={{ xs: 'column', md: 'row' }}
        maxWidth="lg"
      >
        <Box
          width={{
            xs: '100%',
            md: request ? '70%' : '100%',
            lg: request ? '60%' : '100%',
          }}
          display="flex"
          gap={2}
          flexDirection="column"
        >
          <CustomTabs
            sx={{ borderRadius: 1 }}
            value={currentTab}
            onChange={(e, val) => setCurrentTab(val)}
          >
            <Tab
              icon={<Iconify icon="solar:bill-list-bold-duotone" />}
              label="Details"
              value={REQUEST_TABS.main}
            />
            {request.status !== 'DRAFT' && (
              <Tab
                icon={<Iconify icon="solar:chat-round-call-bold-duotone" />}
                label="Chat"
                value={REQUEST_TABS.chat}
              />
            )}
            <Tab
              icon={<Iconify icon="solar:camera-minimalistic-bold-duotone" />}
              label="Photos"
              value={REQUEST_TABS.photos}
            />
            <Tab
              icon={<Iconify icon="solar:sort-by-time-bold-duotone" />}
              label="History"
              value={REQUEST_TABS.history}
            />
          </CustomTabs>

          {currentTab === REQUEST_TABS.main && (
            <>
              {(request.currentStepType === 'TECHNICIAN_STARTED_WORK' ||
                request.currentStepType === 'COMPLETION_VERIFICATION' ||
                request.currentStepType === 'COMPLETED') &&
                user && (
                  <RepairDetailsForm
                    requestId={request._id}
                    currentRepairDetails={request.repairDetails}
                    editable={
                      request.currentAssignedToId === user._id &&
                      request.currentStepType === 'TECHNICIAN_STARTED_WORK'
                    }
                  />
                )}

              <Card>
                <CardHeader
                  title="Request Details"
                  subheader={'Enter the details of the request'}
                  action={
                    showRequestDetailsEditButton && (
                      <IconButton
                        onClick={() =>
                          setIsEditingRequestDetails(!isEditingRequestDetails)
                        }
                        disabled={!userIsFleetDispatcher}
                      >
                        <Iconify icon="solar:pen-bold" />
                      </IconButton>
                    )
                  }
                />

                <Stack spacing={3} sx={{ p: 3 }}>
                  <Box columnGap={2} rowGap={3} display="grid">
                    <FormControl>
                      <Field
                        name="vehicleId"
                        validatorAdapter={zodValidator()}
                        validators={{
                          onChange: z
                            .string()
                            .min(1, { message: 'Please select a vehicle' }),
                        }}
                        children={({ state, handleChange }) => (
                          <Autocomplete
                            disabled={!isEditingRequestDetails}
                            fullWidth
                            options={currentVehicles || vehicles || []}
                            getOptionLabel={option =>
                              `${option?.maker} ${option?.model} ${option?.modelYear} / Unit ${option?.unitNumber}`
                            }
                            value={
                              (currentVehicles || vehicles)?.find(
                                v => v?._id === state.value
                              ) ||
                              request.vehicle ||
                              null
                            }
                            isOptionEqualToValue={(option, value) =>
                              option?._id === value?._id
                            }
                            onChange={(event, newValue) => {
                              handleChange(newValue?._id || null);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Vehicle"
                                required
                                error={state.meta.errors.length > 0}
                                helperText={state.meta.errors[0]}
                              />
                            )}
                            renderOption={(props, option) => (
                              <li {...props} key={option?._id}>
                                {option?.maker} {option?.model}{' '}
                                {option?.modelYear} / Unit {option?.unitNumber}
                              </li>
                            )}
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl>
                      <Field
                        name="activeDriverId"
                        validatorAdapter={zodValidator()}
                        validators={{
                          onChange: z
                            .string()
                            .min(1, { message: 'Please select a driver' }),
                        }}
                        children={({ state, handleChange }) => (
                          <Autocomplete
                            disabled={!isEditingRequestDetails}
                            fullWidth
                            options={drivers || []}
                            getOptionLabel={option =>
                              `${option.clerkUser.firstName} ${option.clerkUser.lastName}`
                            }
                            value={
                              selectedDriver ||
                              request.activeDriver ||
                              drivers?.find(d => d._id === state.value) ||
                              null
                            }
                            isOptionEqualToValue={(option, value) =>
                              option?._id === value?._id
                            }
                            onChange={(event, newValue) => {
                              setSelectedDriver(newValue || null);
                              handleChange(newValue?._id || null);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Driver"
                                required
                                error={state.meta.errors.length > 0}
                                helperText={state.meta.errors[0]}
                              />
                            )}
                            renderOption={(props, option) => (
                              <li {...props} key={option._id}>
                                {option.clerkUser.firstName}{' '}
                                {option.clerkUser.lastName}
                              </li>
                            )}
                          />
                        )}
                      />
                    </FormControl>

                    <Field
                      name="description"
                      validatorAdapter={zodValidator()}
                      validators={{
                        onChange: z
                          .string()
                          .min(1, { message: 'Please provide a description' }),
                      }}
                      children={({ state, handleChange, handleBlur }) => (
                        <TextField
                          disabled={!isEditingRequestDetails}
                          id="problem-description"
                          label="Request Complaint"
                          placeholder="Please provide a detailed description of the complaint"
                          variant="outlined"
                          required
                          fullWidth
                          multiline
                          rows={3}
                          value={state.value}
                          error={state.meta.errors.length > 0}
                          helperText={state.meta.errors[0]}
                          onChange={e => handleChange(e.target.value)}
                          onBlur={handleBlur}
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Card>
              <Card>
                <CardHeader
                  title="Request Location"
                  subheader={
                    request.status === 'DRAFT' && userIsFleetDispatcher
                      ? 'Enter the location of the request (optional)'
                      : 'Request location'
                  }
                  action={
                    showLocationDetailsEditButton && (
                      <IconButton
                        onClick={() =>
                          setIsEditingRequestDetails(!isEditingRequestDetails)
                        }
                        disabled={!userIsFleetDispatcher}
                      >
                        <Iconify icon="solar:pen-bold" />
                      </IconButton>
                    )
                  }
                />

                <Stack spacing={3} sx={{ p: 3 }}>
                  <Box columnGap={2} rowGap={3} display="grid">
                    <Field
                      name="address"
                      validatorAdapter={zodValidator()}
                      children={({ state, handleChange, handleBlur }) => (
                        <RequestMap
                          address={state.value}
                          handleChange={handleChange}
                          disabled={!isEditingRequestDetails}
                          editing={setIsEditingRequestDetails}
                          error={state.meta.errors.length > 0}
                          helperText={String(state.meta.errors[0] || '')}
                          draftSelectedVehicleId={selectedVehicleId}
                        />
                      )}
                    />
                  </Box>
                </Stack>
              </Card>
              {request.status === 'DRAFT' && (
                <Box width="100%" display="flex" justifyContent="center">
                  <Subscribe
                    selector={state => [state.canSubmit, state.isSubmitting]}
                    children={([canSubmit, isSubmitting]) => {
                      return (
                        <LoadingButton
                          type="submit"
                          color="primary"
                          variant="contained"
                          size="large"
                          loading={isSubmitting}
                          disabled={
                            !canSubmit ||
                            (userIsFleetDriver && !canDriverSubmit)
                          }
                          onClick={() => handleSubmit()}
                        >
                          Submit Request
                        </LoadingButton>
                      );
                    }}
                  />
                </Box>
              )}
            </>
          )}

          {currentTab === REQUEST_TABS.chat && <RequestChat />}

          {currentTab === REQUEST_TABS.photos && (
            <Card>
              <CardHeader
                title="Request Photos"
                subheader={'Add or view request photos'}
                action={
                  <Tooltip title="Add photos">
                    <Fab
                      color="primary"
                      size="medium"
                      onClick={() => setIsAddPhotoDialogOpen(true)}
                      aria-label="Add photos"
                    >
                      <Iconify icon="material-symbols:photo-camera" />
                    </Fab>
                  </Tooltip>
                }
              />
              <CardContent>
                {confirmedImages.map(i => (
                  <Image
                    key={i._id}
                    //   alt={slide.src}
                    src={i.url}
                    //   ratio="1/1"
                    // onClick={() => lightbox.onOpen(i.url)}
                    sx={{
                      borderRadius: 2,
                      cursor: 'pointer',
                      transition: theme => theme.transitions.create('opacity'),
                      '&:hover': { opacity: 0.8 },
                      mb: 2,
                    }}
                  />
                ))}
                {/* <Lightbox
                  slides={slides}
                  open={lightbox.open}
                  close={lightbox.onClose}
                  index={lightbox.selected}
                /> */}
              </CardContent>
              <AddPhotosDialog
                open={addPhotoDialogOpen}
                onClose={() => setIsAddPhotoDialogOpen(false)}
                requestId={request._id}
                unConfirmedImagesByUser={myUnconfirmedImages}
              />
            </Card>
          )}
          {currentTab === REQUEST_TABS.history && <RequestHistory />}
        </Box>

        {request.status !== 'DRAFT' && (
          <Box
            width={{ xs: '100%', md: '30%', lg: '40%' }}
            display="flex"
            flexDirection="column"
            gap={2}
          >
            <RequestParticipants />
            <RequestWorkflowSteps
              currentStep={request.currentStepType ?? 'INTAKE'}
            />
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <>
      {request.status === 'DRAFT' ? (
        <form
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit();
          }}
        >
          {content}
        </form>
      ) : (
        content
      )}
    </>
  );
};
