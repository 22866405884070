import { zid } from 'convex-helpers/server/zod';
import { z } from 'zod';
import { Id } from '../_generated/dataModel';
import { QueryCtx } from '../_generated/server';
import { physicalLocationType } from '../schema/entities/groups/groups';

export const locationSchema = z.object({
  latitude: z.number(),
  longitude: z.number(),
  // Original address as entered
  address: z.string().optional(),
  zip: z.string().optional(),
  lastUpdated: z.string(),
});

export const restrictiveLocationSchema = locationSchema.extend({
  address: z.string(),
});

export const locationReferencesSchema = z.object({
  cityId: zid('cities').optional(),
  stateId: zid('states').optional(),
  countryId: zid('countries').optional(),
});

export const vehicleLocationUpdateInput = z.object({
  vehicleId: zid('vehicles'),
  location: z.object({
    latitude: z.number(),
    longitude: z.number(),
    address: z.string().optional(),
    zip: z.string().optional(),
  }),
});

export type VehicleLocationUpdateInput = z.infer<
  typeof vehicleLocationUpdateInput
>;

export const locationUpdateInput = z.object({
  latitude: z.number(),
  longitude: z.number(),
});

export const locationHelpers = {
  async findPotentialDuplicates(
    ctx: QueryCtx,
    cityId: Id<'cities'>,
    address: string
  ) {
    return await ctx.db
      .query('groups')
      .filter(q =>
        q.and(
          q.eq(q.field('cityId'), cityId),
          q.neq(q.field('location.address'), undefined)
        )
      )
      .collect();
  },

  async getLocationsByCity(
    ctx: QueryCtx,
    cityId: Id<'cities'>,
    options?: {
      locationType?: z.infer<typeof physicalLocationType>;
    }
  ) {
    let query = ctx.db
      .query('groups')
      .withIndex('by_cityId', q => q.eq('cityId', cityId));

    if (options?.locationType) {
      query = query.filter(q =>
        q.eq(q.field('locationType'), options.locationType)
      );
    }

    return await query.collect();
  },
};
