import { createFileRoute } from '@tanstack/react-router';
import Page from 'src/pages/dashboard/driver/list';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';
export const Route = createFileRoute('/_auth/dashboard/drivers/')({
  beforeLoad: ({ context }) =>
    checkRouteAccess(context, routeAccessConfigs.fleetOnly),
  component: Page,
});
