import { createFileRoute, linkOptions, redirect } from '@tanstack/react-router';
export const Route = createFileRoute('/_auth/dashboard/')({
  beforeLoad: ({ context: { user } }) => {
    if (user?.publicMetadata.isSuperAdmin) {
      throw redirect({
        to: '/dashboard/companies',
      });
    }

    throw redirect({
      to: '/dashboard/requests',
    });
  },
});

export const dashboardIndexLinkOptions = linkOptions({
  to: '/dashboard',
});
