import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'src/config-global';
import { MechanicCreateView } from 'src/sections/mechanic/view/mechanic-create-view';

const metadata = {
  title: `Create a new mechanic | Dashboard - ${CONFIG.appName}`,
};

export default function Page() {
  return (
    <>
      <Helmet>
        <title> {metadata.title}</title>
      </Helmet>

      <MechanicCreateView />
    </>
  );
}
