import { api } from '@api';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import { useQuery } from 'convex/react';
import { useCallback } from 'react';
import { toast } from 'sonner';
import { ConfirmDialog } from 'src/minimal-theme/components/custom-dialog';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { RouterLink } from 'src/minimal-theme/components/routes/components/router-link';
import { Scrollbar } from 'src/minimal-theme/components/scrollbar';
import {
  emptyRows,
  getComparator,
  rowInPage,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/minimal-theme/components/table';
import { useBoolean } from 'src/minimal-theme/hooks/use-boolean';
import { useSetState } from 'src/minimal-theme/hooks/use-set-state';
import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { varAlpha } from 'src/minimal-theme/theme/styles';
import { TruckTableFilters } from 'src/types/truck';
import { TruckTableFiltersResult } from '../truck-table-filters-result';
import { TruckTableRow } from '../truck-table-row';
import { TruckTableToolbar } from '../truck-table-toolbar';

const STATUS_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'isBrokenDown', label: 'Broken Down' },
];

const TABLE_HEAD = [
  { id: 'vin', label: 'VIN' },
  { id: 'maker', label: 'Maker' },
  { id: 'model', label: 'Model' },
  { id: 'type', label: 'Type' },
  { id: 'modelYear', label: 'Model Year' },
  { id: 'unitNumber', label: 'Unit Number' },
  { id: 'isBrokenDown', label: 'Status' },
  { id: 'assignedTo', label: 'Assigned To' },
  { id: '', label: 'Actions', width: 88 },
];

export function TruckListView() {
  const table = useTable({
    defaultOrderBy: 'isBrokenDown',
    defaultOrder: 'desc',
  });

  const confirm = useBoolean();

  const tableData = useQuery(api.functions.vehicles.getVehiclesForLocation);

  const filters = useSetState<TruckTableFilters>({
    vin: '',
    maker: '',
    model: '',
    type: '',
    unitNumber: '',
    modelYear: '',
    isBrokenDown: false,
  });

  const dataFiltered = applyFilter({
    inputData: tableData ?? [],
    comparator: getComparator(table.order, table.orderBy),
    filters: filters.state,
  });

  const dataInPage = rowInPage(dataFiltered, table.page, table.rowsPerPage);

  const canReset =
    !!filters.state.maker || filters.state.isBrokenDown !== false;

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleDeleteRow = useCallback((id: string) => {
    console.log('delete', id);
    toast.success('Truck deleted successfully!');
  }, []);

  const handleDeleteRows = useCallback(() => {
    console.log('delete rows');
  }, []);

  const handleEditRow = useCallback((vin: string) => {
    console.log('edit', vin);
  }, []);

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      table.onResetPage();
      filters.setState({ isBrokenDown: newValue === 'isBrokenDown' });
    },
    [filters, table]
  );

  if (!tableData) return null;

  return (
    <>
      <DashboardContent>
        <Button
          component={RouterLink}
          href={'/dashboard/trucks/new'}
          color="primary"
          variant="contained"
          startIcon={<Iconify icon="mingcute:add-line" />}
          sx={{ width: 'fit-content', mb: 2, ml: 'auto' }}
        >
          Add Truck
        </Button>
        <Card>
          <Tabs
            value={filters.state.isBrokenDown ? 'isBrokenDown' : 'all'}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: theme =>
                `inset 0 -2px 0 0 ${varAlpha(theme.vars.palette.grey['500Channel'], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map(tab => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                // icon={
                //   <Label
                //     variant={
                //       (tab.value === 'all' && !filters.state.isBrokenDown) ||
                //       (tab.value === 'isBrokenDown' &&
                //         filters.state.isBrokenDown)
                //         ? 'filled'
                //         : 'soft'
                //     }
                //     color={tab.value === 'isBrokenDown' ? 'error' : 'default'}
                //   >
                //     {tab.value === 'all'
                //       ? tableData.length
                //       : tableData.filter(
                //           truck =>
                //             truck.isBrokenDown ===
                //             (tab.value === 'isBrokenDown')
                //         ).length}
                //   </Label>
                // }
              />
            ))}
          </Tabs>

          <TruckTableToolbar
            filters={filters}
            onResetPage={table.onResetPage}
          />

          {canReset && (
            <TruckTableFiltersResult
              filters={filters}
              totalResults={dataFiltered.length}
              onResetPage={table.onResetPage}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <Box sx={{ position: 'relative' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={dataFiltered.length}
              onSelectAllRows={checked =>
                table.onSelectAllRows(
                  checked,
                  dataFiltered.map(row => row.vin)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table
                size={table.dense ? 'small' : 'medium'}
                sx={{ minWidth: 960 }}
              >
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataFiltered.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={checked =>
                    table.onSelectAllRows(
                      checked,
                      dataFiltered.map(row => row.vin)
                    )
                  }
                />

                <TableBody>
                  {dataFiltered
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map(row => (
                      <TruckTableRow
                        key={row.vin}
                        row={row}
                        selected={table.selected.includes(row.vin)}
                        onSelectRow={() => table.onSelectRow(row.vin)}
                        onDeleteRow={() => handleDeleteRow(row.vin)}
                        //   onEditRow={() => handleEditRow(row.vin)}
                      />
                    ))}

                  <TableEmptyRows
                    height={table.dense ? 56 : 56 + 20}
                    emptyRows={emptyRows(
                      table.page,
                      table.rowsPerPage,
                      dataFiltered.length
                    )}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </Box>

          <TablePaginationCustom
            page={table.page}
            dense={table.dense}
            count={dataFiltered.length}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onChangeDense={table.onChangeDense}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>

        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title="Delete"
          content={
            <>
              Are you sure want to delete{' '}
              <strong> {table.selected.length} </strong> items?
            </>
          }
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleDeleteRows();
                confirm.onFalse();
              }}
            >
              Delete
            </Button>
          }
        />
      </DashboardContent>
    </>
  );
}

type ApplyFilterProps = {
  inputData: any[];
  filters: TruckTableFilters;
  comparator: (a: any, b: any) => number;
};

function applyFilter({ inputData, comparator, filters }: ApplyFilterProps) {
  const { maker, model, type, unitNumber, modelYear, isBrokenDown } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map(el => el[0]);

  if (maker) {
    inputData = inputData.filter(
      truck => truck.maker?.toLowerCase().indexOf(maker.toLowerCase()) !== -1
    );
  }

  if (isBrokenDown !== false) {
    inputData = inputData.filter(truck => truck.isBrokenDown === isBrokenDown);
  }

  return inputData;
}
