import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';

import { DispatcherNewEditForm } from '../dispatcher-new-edit-form';

export function DispatcherEditView() {
  return (
    <DashboardContent>
      <DispatcherNewEditForm />
    </DashboardContent>
  );
}
