import { Button, Link, MenuList, Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useRouter } from '@tanstack/react-router';
import {
  CustomPopover,
  usePopover,
} from 'src/minimal-theme/components/custom-popover';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { MuiTypeSafeLink } from 'src/minimal-theme/components/routes/components/mui-link';
import { useBoolean } from 'src/minimal-theme/hooks/use-boolean';
import type { User } from 'src/types/user';

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: User;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export function DispatcherTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { _id, clerkUser, status } = row;

  const confirm = useBoolean();

  const popover = usePopover();

  const router = useRouter();

  const handleViewDetails = () => {
    router.navigate({
      to: '/dashboard/dispatchers/$dispatcherId',
      params: { dispatcherId: _id },
    });
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell>
          <MuiTypeSafeLink
            to="/dashboard/dispatchers/$dispatcherId"
            params={{ dispatcherId: _id }}
          >
            {clerkUser.firstName} {clerkUser.lastName}
          </MuiTypeSafeLink>
        </TableCell>

        <TableCell>
          <Link href={`tel:${clerkUser.primaryPhoneNumber?.phoneNumber}`}>
            {clerkUser.primaryPhoneNumber?.phoneNumber}
          </Link>
        </TableCell>

        <TableCell>
          <Link href={`mailto:${clerkUser.primaryEmailAddress?.emailAddress}`}>
            {clerkUser.primaryEmailAddress?.emailAddress}
          </Link>
        </TableCell>

        <TableCell align="right">
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              color="primary"
              startIcon={<Iconify icon="material-symbols:chat" />}
            >
              Chat
            </Button>
            <IconButton
              color={popover.open ? 'primary' : 'default'}
              onClick={popover.onOpen}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        slotProps={{ arrow: { placement: 'right-top' } }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete
          </MenuItem>

          <MenuItem
            component={MuiTypeSafeLink}
            to="/dashboard/dispatchers/$dispatcherId"
            params={{ dispatcherId: _id }}
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'inherit' }}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
        </MenuList>
      </CustomPopover>
    </>
  );
}
