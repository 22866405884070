import type { SxProps, Theme } from '@mui/material/styles';
import { useCallback } from 'react';
import type { UseSetStateReturn } from 'src/minimal-theme/hooks/use-set-state';
import { TruckTableFilters } from 'src/types/truck';

import Chip from '@mui/material/Chip';

import {
  chipProps,
  FiltersBlock,
  FiltersResult,
} from '../../minimal-theme/components/filters-result';

// ----------------------------------------------------------------------

type Props = {
  totalResults: number;
  sx?: SxProps<Theme>;
  onResetPage: () => void;
  filters: UseSetStateReturn<TruckTableFilters>;
};

export function TruckTableFiltersResult({
  filters,
  onResetPage,
  totalResults,
  sx,
}: Props) {
  const handleRemoveKeyword = useCallback(() => {
    onResetPage();
    filters.setState({ isBrokenDown: false });
  }, [filters, onResetPage]);

  const handleRemoveStatus = useCallback(() => {
    onResetPage();
    filters.setState({ isBrokenDown: false });
  }, [filters, onResetPage]);

  const handleReset = useCallback(() => {
    onResetPage();
    filters.onResetState();
  }, [filters, onResetPage]);

  return (
    <FiltersResult totalResults={totalResults} onReset={handleReset} sx={sx}>
      <FiltersBlock
        label="Status:"
        isShow={filters.state.isBrokenDown !== false}
      >
        <Chip
          {...chipProps}
          label="Broken Down"
          onDelete={handleRemoveStatus}
          sx={{ textTransform: 'capitalize' }}
        />
      </FiltersBlock>

      <FiltersBlock label="Keyword:" isShow={!!filters.state.maker}>
        <Chip
          {...chipProps}
          label={filters.state.maker}
          onDelete={handleRemoveKeyword}
        />
      </FiltersBlock>
    </FiltersResult>
  );
}
