import { z } from 'zod';

export const userRole = z.enum([
  // Dispatch roles
  'FLEET_DISPATCHER', // 24/7 first point of contact
  'FLEET_DISPATCH_SUPERVISOR', // Oversees dispatch team

  'SERVICE_DISPATCHER', // 24/7 first point of contact
  'SERVICE_DISPATCH_SUPERVISOR', // Oversees dispatch team

  // Fleet company roles
  'DRIVER_FLEET', // Driver working for a fleet
  'FLEET_MANAGER', // Manages fleet drivers
  'MAINTENANCE_MANAGER', // Oversees maintenance & repairs
  'OPERATIONS_MANAGER', // Supervises fleet managers
  'FLEET_ADMIN', // Fleet company admin

  // Service provider roles
  'TECHNICIAN_FLEET', // In-house fleet mechanic
  'TECHNICIAN_PROVIDER', // Repair shop mechanic
  'SERVICE_MANAGER', // Manages repair team
  'SERVICE_ADMIN', // Service company admin

  // Location Managers ("owners" at the location level)
  'FLEET_LOCATION_MANAGER',
  'SERVICE_LOCATION_MANAGER',
  'SERVICE_DISPATCH_LOCATION_MANAGER',
  'FLEET_DISPATCH_LOCATION_MANAGER',

  // Independent roles
  'DRIVER', // Independent owner-operator
  'TOW_OPERATOR', // Towing service provider
  'TECHNICIAN', // Independent mechanic

  // System Admin Roles
  'SUPER_ADMIN',
  'EXECUTIVE_ADMIN',
]);

export const USER_ROLE_DISPLAY: Record<UserRole, string> = {
  // Dispatch roles
  FLEET_DISPATCHER: 'Fleet Dispatcher',
  FLEET_DISPATCH_SUPERVISOR: 'Fleet Dispatch Supervisor',
  SERVICE_DISPATCHER: 'Service Dispatcher',
  SERVICE_DISPATCH_SUPERVISOR: 'Service Dispatch Supervisor',

  // Fleet company roles
  DRIVER_FLEET: 'Fleet Driver',
  FLEET_MANAGER: 'Fleet Manager',
  MAINTENANCE_MANAGER: 'Maintenance Manager',
  OPERATIONS_MANAGER: 'Operations Manager',
  FLEET_ADMIN: 'Fleet Administrator',

  // Service provider roles
  TECHNICIAN_FLEET: 'Fleet Technician',
  TECHNICIAN_PROVIDER: 'Service Provider Technician',
  SERVICE_MANAGER: 'Service Manager',
  SERVICE_ADMIN: 'Service Administrator',

  // Location Managers
  FLEET_LOCATION_MANAGER: 'Fleet Location Manager',
  SERVICE_LOCATION_MANAGER: 'Service Location Manager',
  SERVICE_DISPATCH_LOCATION_MANAGER: 'Service Dispatch Location Manager',
  FLEET_DISPATCH_LOCATION_MANAGER: 'Fleet Dispatch Location Manager',

  // Independent roles
  DRIVER: 'Independent Driver',
  TOW_OPERATOR: 'Tow Operator',
  TECHNICIAN: 'Independent Technician',

  SUPER_ADMIN: 'Super Admin',
  EXECUTIVE_ADMIN: 'Executive Admin',
};

export const getUserRoleDisplay = (role?: UserRole) =>
  role ? USER_ROLE_DISPLAY[role] : 'N/A';

export type UserRole = z.infer<typeof userRole>;
// TODO: I feel like there are more distinctions here...
// mobile mechanics could be working alone or for a repair shop...
// they might also JUST work exclusively at the repair shop and not be mobile

// check if the role is a service provider
export const roleIsATopFleetManager = (role: UserRole) => {
  const rolesList: UserRole[] = [
    userRole.enum.FLEET_MANAGER,
    userRole.enum.FLEET_ADMIN,
    userRole.enum.OPERATIONS_MANAGER,
  ];
  return rolesList.includes(role);
};

export const serviceProviderRolesList: UserRole[] = [
  userRole.enum.SERVICE_ADMIN,
  userRole.enum.SERVICE_MANAGER,
  userRole.enum.SERVICE_LOCATION_MANAGER,
  userRole.enum.SERVICE_DISPATCHER,
  userRole.enum.SERVICE_DISPATCH_LOCATION_MANAGER,
  userRole.enum.SERVICE_DISPATCH_SUPERVISOR,
  userRole.enum.TECHNICIAN_PROVIDER,
  userRole.enum.TECHNICIAN,
];

export const roseIsServiceProvider = (role: UserRole) => {
  return serviceProviderRolesList.includes(role);
};
