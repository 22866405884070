import { api } from '@api';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  debounce,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useForm } from '@tanstack/react-form';
import { useParams } from '@tanstack/react-router';
import { useMutation, useQuery } from 'convex/react';
import { useEffect, useMemo, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input/input';
import { toast } from 'sonner';
import { CONFIG } from 'src/config-global';
import { Id } from 'src/convex/_generated/dataModel';
import { PhoneInput } from 'src/minimal-theme/components/phone-input';
import { schemaHelper } from 'src/minimal-theme/utils/schema-helper';
import zod from 'zod';

export type NewLocationSchemaType = zod.infer<typeof NewLocationSchema>;

export const NewLocationSchema = zod.object({
  email: zod
    .string()
    .email('Invalid email address')
    .optional()
    .or(zod.literal('')),
  phone: schemaHelper.phoneNumber({ isValidPhoneNumber }),
  companyName: zod
    .string()
    .min(2, 'Company name must be at least 2 characters'),
  firstName: zod.string().min(2, 'First name must be at least 2 characters'),
  lastName: zod.string().min(2, 'Last name must be at least 2 characters'),
});

export function InviteLocationViaRequestDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const params = useParams({ from: '/_auth/dashboard/requests/$requestId' });

  const createLocation = useMutation(api.functions.invitations.inviteUser);

  const [companyHQCoordinates, setCompanyHQCoordinates] = useState<
    [number, number] | null
  >(null);

  const [locationCoordinates, setLocationCoordinates] = useState<
    [number, number] | null
  >(null);

  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);
  const [companyHqInputValue, setCompanyHqInputValue] = useState('');
  const [locationInputValue, setLocationInputValue] = useState('');

  const [scheduledInvitationId, setScheduledInvitationId] =
    useState<Id<'_scheduled_functions'> | null>(null);

  const scheduledInvitation = useQuery(
    api.functions.schedule.getScheduledMessage,
    scheduledInvitationId ? { id: scheduledInvitationId } : 'skip'
  );

  useEffect(() => {
    if (scheduledInvitation) {
      if (scheduledInvitation.state.kind === 'success') {
        toast.dismiss();
        toast.success('Invitation sent successfully');
        onClose();
      } else if (
        scheduledInvitation.state.kind === 'failed' ||
        scheduledInvitation.state.kind === 'canceled'
      ) {
        toast.dismiss();
        toast.error('Something went wrong when inviting');
      }
    }
  }, [scheduledInvitation]);

  // Debounced search function
  const searchAddress = useMemo(
    () =>
      debounce(async (query: string) => {
        if (!query) return;

        try {
          const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
              query
            )}.json?access_token=${CONFIG.mapboxApiKey}&types=address`
          );
          const data = await response.json();
          setSearchResults(
            data.features.map((feature: any) => ({
              place_name: feature.place_name,
              center: feature.center,
            }))
          );
        } catch (error) {
          console.error('Search error:', error);
          setSearchResults([]);
        }
      }),
    []
  );

  // Memoize the geocoding function to prevent recreating on every render
  const geocodeAddress = useMemo(
    () => async (query: string) => {
      if (!query) return null;

      try {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            query
          )}.json?access_token=${CONFIG.mapboxApiKey}&types=address`
        );
        const data = await response.json();
        return data.features?.[0] || null;
      } catch (error) {
        console.error('Geocoding error:', error);
        return null;
      }
    },
    []
  );

  const { Field, Subscribe, handleSubmit, reset } = useForm({
    defaultValues: {
      companyName: '',
      locationName: '',
      locationAddress: '',
      locationLatitude: 0,
      locationLongitude: 0,
      companyHqAddress: '',
      companyHqLatitude: 0,
      companyHqLongitude: 0,
      locationType: '',
      description: '',
      companyContactPhone: '',
      companyContactEmail: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      role: '',
      requestId: params.requestId,
      skipInvitationEmail: false,
    },
    onSubmit: async ({ value }) => {
      try {
        const res = await createLocation({
          inviteInfo: {
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            phone: value.phone,
            role: 'SERVICE_DISPATCHER',
            requestId: value.requestId as any,
            skipInvitationEmail: value.skipInvitationEmail,
          },
          locationInfo: {
            companyName: value.companyName,
            locationName: value.locationName,
            locationAddress: value.locationAddress,
            locationLatitude: locationCoordinates?.[0] || 0,
            locationLongitude: locationCoordinates?.[1] || 0,
            companyHqAddress: value.companyHqAddress,
            companyHqLatitude: companyHQCoordinates?.[0] || 0,
            companyHqLongitude: companyHQCoordinates?.[1] || 0,
            locationType: 'REPAIR_SHOP',
            description: value.description,
            companyContactPhone: value.phone,
            companyContactEmail: value.email,
            locationContactInfo: {
              phone: value.phone,
              email: value.email,
              dispatchPhone: value.phone,
              emergencyPhone: value.phone,
            },
          },
        });
        if (res.success) {
          toast.loading(res.message);
          if (res.scheduleId) {
            setScheduledInvitationId(res.scheduleId);
          }

          // onClose();
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        console.error('Failed to save location:', error);
        toast.error('Failed to save location. Please try again.');
      }
    },
  });

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: 'md' } }}
    >
      <DialogTitle>Invite Service Provider / Location</DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Divider />
        <Stack spacing={3} sx={{ p: 3 }}>
          <form
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit();
            }}
            style={{ width: '100%' }}
          >
            <Typography variant="h6">Company Details</Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{ my: 2 }}
            >
              <Field
                name="companyName"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Parent Company Name"
                    variant="outlined"
                    autoFocus
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="companyHqAddress"
                children={({ state, handleChange, handleBlur }) => (
                  <Autocomplete
                    options={searchResults}
                    value={state.value ? { place_name: state.value } : null}
                    getOptionLabel={option => option?.place_name || ''}
                    inputValue={companyHqInputValue}
                    onInputChange={(_event, newValue) => {
                      setCompanyHqInputValue(newValue);
                      searchAddress(newValue);
                    }}
                    onChange={async (_event, newValue) => {
                      handleChange(newValue?.place_name || '');
                      const geocoded = await geocodeAddress(
                        newValue?.place_name || ''
                      );
                      setCompanyHQCoordinates(geocoded?.center || null);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.place_name === value?.place_name
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Address"
                        variant="outlined"
                        fullWidth
                        error={error}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: error ? 'error.main' : undefined,
                            },
                          },
                          '& .MuiInputLabel-root': {
                            color: error ? 'error.main' : undefined,
                          },
                          '& .MuiInputBase-input': {
                            color: error ? 'error.main' : undefined,
                          },
                        }}
                      />
                    )}
                  />
                )}
              />
            </Box>
            <Typography variant="h6">Location Details</Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{ my: 2 }}
            >
              <Field
                name="locationName"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Location Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="locationAddress"
                children={({ state, handleChange, handleBlur }) => (
                  <Autocomplete
                    options={searchResults}
                    value={state.value ? { place_name: state.value } : null}
                    getOptionLabel={option => option?.place_name || ''}
                    inputValue={locationInputValue}
                    onInputChange={(_event, newValue) => {
                      setLocationInputValue(newValue);
                      searchAddress(newValue);
                    }}
                    onChange={async (_event, newValue) => {
                      handleChange(newValue?.place_name || '');
                      const geocoded = await geocodeAddress(
                        newValue?.place_name || ''
                      );
                      setLocationCoordinates(geocoded?.center || null);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.place_name === value?.place_name
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Address"
                        variant="outlined"
                        fullWidth
                        error={error}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: error ? 'error.main' : undefined,
                            },
                          },
                          '& .MuiInputLabel-root': {
                            color: error ? 'error.main' : undefined,
                          },
                          '& .MuiInputBase-input': {
                            color: error ? 'error.main' : undefined,
                          },
                        }}
                      />
                    )}
                  />
                )}
              />
              {/* <Field
                name="locationType"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    select
                    label="Location Type"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value || ''}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  >
                    {physicalLocationType.options.map(type => (
                      <MenuItem key={type} value={type}>
                        {getPhysicalLocationTypeDisplay(type)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              /> */}
              <Field
                name="description"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={3}
                    fullWidth
                    value={state.value}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
            </Box>
            <Typography variant="h6">Contact Details</Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{ my: 2 }}
            >
              <Field
                name="firstName"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
              <Field
                name="lastName"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
              <Field
                name="phone"
                children={({ state, handleChange, handleBlur }) => (
                  <PhoneInput
                    required
                    label="Phone Number"
                    fullWidth
                    value={state.value}
                    onChange={newValue => handleChange(newValue || '')}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
              <Field
                name="email"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={state.value}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
              {/* <Field
                name="role"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    select
                    label="Role"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value || ''}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  >
                    {serviceProviderRolesList.map(role => (
                      <MenuItem key={role} value={role}>
                        {getUserRoleDisplay(role)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              /> */}
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <Subscribe
                selector={state => [state.canSubmit, state.isSubmitting]}
                children={([canSubmit, isSubmitting]) => {
                  return (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={!canSubmit}
                    >
                      Invite location
                    </LoadingButton>
                  );
                }}
              />
            </Stack>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
