import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/sign-out')({
  preload: false,
  loader: ({ context: { isAuthenticated, clerk } }) => {
    if (isAuthenticated) {
      clerk?.signOut();
    }
  },
});
