import { useMutation, useQuery } from 'convex/react';
import { useEffect, useState } from 'react';
import { api } from 'src/convex/_generated/api';

// Singleton state management
class LocationTrackingService {
  private static instance: LocationTrackingService;
  private watchId: number | null = null;
  private activeSubscribers = 0;
  private updateLocation: any; // Convex mutation
  private status: {
    isTracking: boolean;
    lastUpdate: Date | null;
    error: string | null;
  } = {
    isTracking: false,
    lastUpdate: null,
    error: null,
  };
  private statusListeners: Set<(status: typeof this.status) => void> =
    new Set();

  private constructor() {}

  static getInstance(): LocationTrackingService {
    if (!this.instance) {
      this.instance = new LocationTrackingService();
    }
    return this.instance;
  }

  setMutation(mutation: any) {
    this.updateLocation = mutation;
  }

  subscribe(listener: (status: typeof this.status) => void) {
    this.statusListeners.add(listener);
    this.activeSubscribers++;

    if (this.activeSubscribers === 1) {
      this.startTracking();
      console.log('STARTED TRACKING LOCATION');
    }

    return () => {
      this.statusListeners.delete(listener);
      this.activeSubscribers--;

      if (this.activeSubscribers === 0) {
        this.stopTracking();
        console.log('STOPPED TRACKING LOCATION');
      }
    };
  }

  private updateStatus(newStatus: Partial<typeof this.status>) {
    this.status = { ...this.status, ...newStatus };
    this.statusListeners.forEach(listener => listener(this.status));
  }

  private async startTracking() {
    if (!navigator.geolocation || this.watchId !== null) return;

    try {
      this.watchId = navigator.geolocation.watchPosition(
        async position => {
          try {
            console.log('UPDATING LOCATION');
            await this.updateLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            console.log('UPDATED LOCATION');
            this.updateStatus({
              isTracking: true,
              lastUpdate: new Date(),
              error: null,
            });
          } catch (error) {
            this.updateStatus({
              error: 'Failed to update location',
            });
          }
        },
        error => {
          this.updateStatus({
            error: error.message,
          });
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } catch (error) {
      this.updateStatus({
        error: 'Failed to start location tracking',
      });
    }
  }

  private stopTracking() {
    if (this.watchId !== null) {
      navigator.geolocation.clearWatch(this.watchId);
      this.watchId = null;
      this.updateStatus({
        isTracking: false,
        lastUpdate: null,
        error: null,
      });
    }
  }
}

// React hook to use the singleton service
export const useLocationTracking = () => {
  const updateLocation = useMutation(
    api.functions.users.updateTechnicianLocation
  );

  const me = useQuery(api.functions.users.getMe);

  const isTechnician = me?.roles.find(
    r =>
      r.type === 'TECHNICIAN' ||
      r.type === 'TECHNICIAN_FLEET' ||
      r.type === 'TECHNICIAN_PROVIDER'
  );

  const myAssignedActiveRequests = useQuery(
    api.functions.requests.getAssignedRequests,
    isTechnician ? {} : 'skip'
  );

  const [status, setStatus] = useState({
    isTracking: false,
    lastUpdate: null as Date | null,
    error: null as string | null,
  });

  useEffect(() => {
    // Only track location if technician has active request
    if (!myAssignedActiveRequests || myAssignedActiveRequests.length === 0)
      return;

    const service = LocationTrackingService.getInstance();
    service.setMutation(updateLocation);

    return service.subscribe(setStatus);
  }, [myAssignedActiveRequests, updateLocation]);

  return status;
};
