// import { defaultSettings } from 'src/minimal-theme/components/settings';

import { defaultSettings } from '../components/settings';

// ----------------------------------------------------------------------

export const schemeConfig = {
  modeStorageKey: 'theme-mode',
  defaultMode: defaultSettings.colorScheme,
};
