import { zid, zodToConvex } from 'convex-helpers/server/zod';
import { defineTable } from 'convex/server';
import { z } from 'zod';
import { clerkVerificationStatusSchema } from '../../types/clerkVerificationStatus';
import {
  locationReferencesSchema,
  locationSchema,
} from '../../types/locationSchema';
import { phoneSchema } from '../../zodHelpers/phoneSchema';
import { currencyCodeSchema } from '../enums/currencyTypes';
import { mechanicStatus } from '../enums/mechanicStatus';
import { userRole } from '../enums/userRole';
export const emailAddressSchema = z
  .string()
  .email({ message: 'Please provide a valid email address' });
export const partialClerkVerificationSchema = z.object({
  status: clerkVerificationStatusSchema,
});

export const partialClerkEmailAddressSchema = z.object({
  emailAddress: emailAddressSchema,
  verification: partialClerkVerificationSchema.optional(),
});

export const partialClerkPhoneNumberSchema = z.object({
  phoneNumber: phoneSchema,
  verification: partialClerkVerificationSchema.optional(),
});

export const userNamesSchema = z.object({
  firstName: z.string().min(2, { message: 'First name is required' }),
  lastName: z.string().min(2, { message: 'Last name is required' }),
});

export const userSignUpSchema = userNamesSchema.extend({
  email: emailAddressSchema,
  phone: phoneSchema,
});

export const otherNameComponentsSchema = z.object({
  fullName: z.string().min(5), // 2 + 2 + space
});

export const partialClerkUserSchema = z
  .object({
    id: z.string(),
    hasImage: z.boolean().optional(),
    imageUrl: z.string(),
    primaryEmailAddress: partialClerkEmailAddressSchema.optional(),
    isSuperAdmin: z.boolean().optional(),
    companyOwner: z.boolean().optional(),
    primaryPhoneNumber: partialClerkPhoneNumberSchema.optional(),
    lastSignInAt: z.number().optional(),
  })
  .merge(userNamesSchema)
  .merge(otherNameComponentsSchema);

export const userSchema = z
  .object({
    clerkUser: partialClerkUserSchema,
    hasVerifiedEmail: z.boolean().optional(),
    hasVerifiedPhone: z.boolean().optional(),
    about: z.string().optional(),

    rate: z.number().optional(),
    rateCurrencyType: currencyCodeSchema.optional(),

    location: locationSchema.optional(),
    // TODO: RF - figure out what this is
    pushToken: z.string().optional(),
    arePushNotificationsEnabled: z.boolean().optional(),
    // TODO: RF - does this mean total completed or total active assigned (not completed?? do we want to track both?)
    jobCount: z.number().int().default(0).optional(),

    status: mechanicStatus.optional(),
    averageRating: z.number().int().default(0).optional(),
    activityRadius: z.number().int().default(10).optional(),

    primaryRoleId: zid('roleDefinitions').optional(), // Denormalized primary role
    primaryRoleType: userRole.optional(), // Cached role type for even faster lookups

    impersonatingCompanyId: zid('companies').optional(),
    impersonatingLocationId: zid('groups').optional(),

    createdByUserDuringRequest: z.boolean().optional(),
    createdFromRequestId: zid('requests').optional(),

    invitedByUser: zid('users').optional(),
    invitedByLocation: zid('groups').optional(),
    invitedByCompany: zid('companies').optional(),

    // TODO: RF - separate presence entity
    // isOnline: z.boolean().optional(),
    // lastSeen: z.string().optional(),
    updatedAt: z.string().optional(),
    // TODO: RF - let folks know changed isActive state to just be an deletedAt "soft delete" time stamp
    deletedAt: z.string().optional(),

    companyId: zid('companies').optional(),

    // Users should only belong to a single primaryLocationGroup
    primaryLocationGroupId: zid('groups').optional(),
    // Dispatch could potentially cover multiple groups ... requests created in these groups build their feed
    /// this might not be used right away... will it be an override for the one set on "groups" or a denormalized version of that?
    dispatchCoverageGroupIds: z.array(zid('groups')).optional(),
    // TODO: For wiring up when single dispatch users are assigned to specific technicians
    dispatchForIndividualTechnicians: z.array(zid('users')).optional(),
  })
  .merge(locationReferencesSchema);

export const users = defineTable(zodToConvex(userSchema).fields)
  .index('by_clerkId', ['clerkUser.id'])
  .index('by_primaryEmail', ['clerkUser.primaryEmailAddress.emailAddress'])
  .index('by_primaryPhone', ['clerkUser.primaryPhoneNumber.phoneNumber'])
  .index('by_companyId', ['companyId'])
  .index('by_createdByUserDuringRequest', ['createdByUserDuringRequest'])
  .index('by_companyId_and_primaryRoleType', ['companyId', 'primaryRoleType'])
  .index('by_primaryLocationGroupId', ['primaryLocationGroupId'])
  .index('by_primaryLocationGroupId_and_primaryRoleType', [
    'primaryLocationGroupId',
    'primaryRoleType',
  ])
  .index('by_companyId_and_status', ['companyId', 'status']);
