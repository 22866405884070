import { redirect } from '@tanstack/react-router';
import { UserRole } from 'src/convex/schema/enums/userRole';
import { RouterContext } from 'src/routes/__root';

type AccessConfig = {
  allowSuperAdmin?: boolean;
  allowImpersonation?: boolean;
  allowedRoles?: UserRole[];
  allowAllRoles?: boolean;
  requireFleetOnly?: boolean;
  requireServiceProviderOnly?: boolean;
  redirectTo?: string;
};

export const checkRouteAccess = (
  context: RouterContext,
  config: AccessConfig = {}
) => {
  const {
    allowSuperAdmin = false,
    allowImpersonation = true,
    allowedRoles = [],
    requireFleetOnly = false,
    requireServiceProviderOnly = false,
    allowAllRoles = false,
    redirectTo = '/dashboard',
  } = config;

  const { user, me, impersonatingFleet, impersonatingServiceProvider } =
    context;

  // Handle super admin access
  const isSuperAdmin = user?.publicMetadata.isSuperAdmin;
  const isImpersonating = impersonatingFleet || impersonatingServiceProvider;

  // For superadmin-only routes (like the onlySuperAdmin config),
  // immediately redirect if user is not a superadmin
  if (allowSuperAdmin && !allowAllRoles && allowedRoles.length === 0) {
    if (!isSuperAdmin) {
      throw redirect({ to: redirectTo });
    }
    return;
  }

  if (isSuperAdmin) {
    if (!allowSuperAdmin) {
      throw redirect({ to: redirectTo });
    }

    if (!allowImpersonation && isImpersonating) {
      throw redirect({ to: redirectTo });
    }

    // Handle fleet/SP specific restrictions
    if (requireFleetOnly && !impersonatingFleet) {
      throw redirect({ to: redirectTo });
    }
    if (requireServiceProviderOnly && !impersonatingServiceProvider) {
      throw redirect({ to: redirectTo });
    }
    return;
  }

  if (allowAllRoles) {
    return;
  }

  // Handle role-based access
  if (
    allowedRoles.length > 0 &&
    !allowedRoles.includes(me?.primaryRoleType as UserRole)
  ) {
    throw redirect({ to: redirectTo });
  }

  // Handle fleet/SP specific access for non-super-admins
  if (!isSuperAdmin) {
    if (requireFleetOnly && me?.primaryRoleType !== 'FLEET_DISPATCHER') {
      throw redirect({ to: redirectTo });
    }
    if (
      requireServiceProviderOnly &&
      me?.primaryRoleType !== 'SERVICE_DISPATCHER'
    ) {
      throw redirect({ to: redirectTo });
    }
  }
};

type RouteConfigs = {
  allRolesExceptSuperAdmin: AccessConfig;
  dispatcherGeneral: AccessConfig;
  fleetOnly: AccessConfig;
  serviceProviderOnly: AccessConfig;
  noSuperAdmin: AccessConfig;
  onlySuperAdmin: AccessConfig;
};

export const routeAccessConfigs: RouteConfigs = {
  allRolesExceptSuperAdmin: {
    allowAllRoles: true,
  },
  dispatcherGeneral: {
    allowSuperAdmin: true,
    allowImpersonation: true,
    allowedRoles: ['FLEET_DISPATCHER', 'SERVICE_DISPATCHER'],
  },
  fleetOnly: {
    allowSuperAdmin: true,
    allowImpersonation: true,
    requireFleetOnly: true,
    allowedRoles: ['FLEET_DISPATCHER'],
  },
  serviceProviderOnly: {
    allowSuperAdmin: true,
    allowImpersonation: true,
    requireServiceProviderOnly: true,
    allowedRoles: ['SERVICE_DISPATCHER'],
  },
  noSuperAdmin: {
    allowSuperAdmin: false,
    allowedRoles: ['FLEET_DISPATCHER', 'SERVICE_DISPATCHER'],
  },
  onlySuperAdmin: {
    allowSuperAdmin: true,
  },
};
