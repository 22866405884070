import { Suspense } from 'react';

import { DashboardLayout } from 'src/minimal-theme/layouts/dashboard';

import { Outlet } from '@tanstack/react-router';
import { LoadingScreen } from 'src/minimal-theme/components/loading-screen';

export function AuthenticatedRoot() {
  return (
    <DashboardLayout>
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
    </DashboardLayout>
  );
}
