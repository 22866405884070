import { createFileRoute } from '@tanstack/react-router';
import Page from 'src/pages/dashboard/truck/new';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/trucks/new')({
  beforeLoad: ({ context }) =>
    checkRouteAccess(context, routeAccessConfigs.fleetOnly),
  component: Page,
});
