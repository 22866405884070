import { Box, Button, DialogActions, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import * as Sentry from '@sentry/react';
import { useConvexAuth, useQuery } from 'convex/react';
import { AnyARecord } from 'dns';
import { useState } from 'react';
import { api } from 'src/convex/_generated/api';
import { Iconify } from 'src/minimal-theme/components/iconify';

type ErrorFallbackProps = {
  error: AnyARecord;
  componentStack?: string;
  resetError?: () => void;
  eventId?: string;
};

export function GlobalErrorFallbackDialog({
  error,
  componentStack,
  resetError,
  eventId,
}: ErrorFallbackProps) {
  const [open, setOpen] = useState<boolean>(true);

  const { isAuthenticated, isLoading } = useConvexAuth();

  const user = useQuery(
    api.functions.users.getMe,
    isAuthenticated ? {} : 'skip'
  );

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleReportError = () => {
    setOpen(false);
    if (eventId) {
      Sentry.showReportDialog({
        eventId,
        // Customize the crash report modal
        title: 'Something Went Wrong',
        subtitle:
          "Our team has been notified. If you'd like to help, tell us what happened:",
        subtitle2: '', // Remove second subtitle
        labelName: 'Name',
        labelEmail: 'Email',
        labelComments: 'What happened?',
        labelClose: 'Close',
        labelSubmit: 'Submit Report',
        errorGeneric:
          'An error occurred while submitting your report. Please try again.',
        errorFormEntry:
          'Some fields were invalid. Please correct the errors and try again.',
        successMessage: 'Your feedback has been sent. Thank you!',
        onLoad: () => {
          // TODO: This doesn't work for some reason?
          return {
            name: user?.clerkUser.fullName ?? '',
            email: user?.clerkUser.primaryEmailAddress?.emailAddress,
          };
        },
      });
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <DialogContent sx={{ py: 5, px: 3 }}>
        <Typography variant="h3">Something went wrong</Typography>

        <Typography color="text.secondary">
          {error.toString() || 'An unexpected error occurred'}
        </Typography>

        {process.env.NODE_ENV === 'development' && componentStack && (
          <Box
            component="pre"
            sx={{
              p: 2,
              bgcolor: 'background.neutral',
              borderRadius: 1,
              overflowX: 'auto',
              typography: 'caption',
            }}
          >
            {componentStack}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {/* {resetError && (
            <Button variant="contained" onClick={resetError}>
              Try Again
            </Button>
          )} */}
        {eventId && (
          <Button
            color="info"
            variant="outlined"
            startIcon={<Iconify icon="eva:message-circle-fill" />}
            onClick={handleReportError}
          >
            Help Us By Adding Feedback
          </Button>
        )}
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:refresh-fill" />}
          onClick={handleRefresh}
        >
          Refresh Page
        </Button>
      </DialogActions>
    </Dialog>
  );
}
