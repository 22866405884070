import { Doc } from '../_generated/dataModel';
import { internalMutation, MutationCtx } from '../_generated/server';
import { RequestSearchComponent } from '../schema/entities/requests/requests';

// To update existing requests
export const updateExistingRequestSearchFields = internalMutation({
  handler: async ctx => {
    const requestsToUpdate = await ctx.db.query('requests').collect();

    for (const request of requestsToUpdate) {
      const { searchComponents, searchText } = await generateSearchFields(
        ctx,
        request
      );

      await ctx.db.patch(request._id, {
        searchComponents,
        searchText,
      });
    }
  },
});

export async function generateSearchFields(
  ctx: MutationCtx,
  doc: Doc<'requests'>
) {
  const searchComponents: RequestSearchComponent[] = [];
  const searchTexts = [];

  // Always add case number
  searchComponents.push({
    type: 'CASE_NUMBER',
    text: doc.caseNumber,
  });

  searchComponents.push({
    type: 'ADDRESS',
    text: doc.address ?? '',
  });

  searchComponents.push({
    type: 'DESCRIPTION',
    text: doc.description ?? '',
  });

  searchTexts.push(doc.caseNumber);

  // Fetch all related entities in parallel
  const [driver, dispatcher, serviceDispatcher, technician, vehicle] =
    await Promise.all([
      doc.activeDriverId ? ctx.db.get(doc.activeDriverId) : null,
      doc.activeFleetDispatcherId
        ? ctx.db.get(doc.activeFleetDispatcherId)
        : null,
      doc.activeServiceDispatcherId
        ? ctx.db.get(doc.activeServiceDispatcherId)
        : null,
      doc.activeTechnicianId ? ctx.db.get(doc.activeTechnicianId) : null,
      doc.vehicleId ? ctx.db.get(doc.vehicleId) : null,
    ]);

  if (driver) {
    searchComponents.push({
      type: 'DRIVER_NAME',
      text: driver.clerkUser.fullName,
      sourceId: driver._id,
    });
    searchTexts.push(driver.clerkUser.fullName);
  }

  if (dispatcher) {
    searchComponents.push({
      type: 'FLEET_DISPATCHER_NAME',
      text: dispatcher.clerkUser.fullName,
      sourceId: dispatcher._id,
    });
    searchTexts.push(dispatcher.clerkUser.fullName);
  }

  if (serviceDispatcher) {
    searchComponents.push({
      type: 'SERVICE_DISPATCHER_NAME',
      text: serviceDispatcher.clerkUser.fullName,
      sourceId: serviceDispatcher._id,
    });
    searchTexts.push(serviceDispatcher.clerkUser.fullName);
  }

  if (technician) {
    searchComponents.push({
      type: 'TECHNICIAN_NAME',
      text: technician.clerkUser.fullName,
      sourceId: technician._id,
    });
    searchTexts.push(technician.clerkUser.fullName);
  }

  if (vehicle) {
    if (vehicle.vin) {
      searchComponents.push({
        type: 'VEHICLE_VIN',
        text: vehicle.vin,
        sourceId: vehicle._id,
      });
      searchTexts.push(vehicle.vin);
    }

    if (vehicle.maker) {
      searchComponents.push({
        type: 'VEHICLE_MAKER',
        text: vehicle.maker,
        sourceId: vehicle._id,
      });
      searchTexts.push(vehicle.maker);
    }

    if (vehicle.model) {
      searchComponents.push({
        type: 'VEHICLE_MODEL',
        text: vehicle.model,
        sourceId: vehicle._id,
      });
      searchTexts.push(vehicle.model);
    }

    if (vehicle.unitNumber) {
      searchComponents.push({
        type: 'VEHICLE_UNIT_NUMBER',
        text: vehicle.unitNumber,
        sourceId: vehicle._id,
      });
      searchTexts.push(vehicle.unitNumber);
    }
  }

  return {
    searchComponents,
    searchText: searchTexts.join(' ').toLowerCase().trim(),
  };
}
