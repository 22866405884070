import { DashboardContent } from '../../../minimal-theme/layouts/dashboard';

import { DispatcherNewEditForm } from '../dispatcher-new-edit-form';

export function DispatcherCreateView() {
  return (
    <DashboardContent>
      <DispatcherNewEditForm />
    </DashboardContent>
  );
}
