import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { MechanicNewEditForm } from '../mechanic-new-edit-form';

export function MechanicEditView() {
  return (
    <DashboardContent>
      <MechanicNewEditForm />
    </DashboardContent>
  );
}
