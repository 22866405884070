import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { styled } from '@mui/material/styles';
import { useForm } from '@tanstack/react-form';
import { useState } from 'react';
import { FormHead } from 'src/components/form/form-head';
import { companyType } from 'src/convex/schema/enums/companyType';
import {
  ServiceCategory,
  ServiceSubcategory,
  getCategoryLabel,
  serviceSubcategoriesMap,
} from 'src/convex/schema/enums/serviceCategories';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Logo } from 'src/minimal-theme/components/logo';
import { CenteredLayout } from 'src/minimal-theme/layouts/centered';
import { z } from 'zod';

type SubcategoryOption = {
  category: z.infer<typeof ServiceCategory>;
  subcategory: z.infer<typeof ServiceSubcategory>;
  label: string;
};

const getSubcategoryOptions = (): SubcategoryOption[] => {
  const options: SubcategoryOption[] = [];

  Object.entries(serviceSubcategoriesMap).forEach(
    ([category, subcategories]) => {
      Object.entries(subcategories).forEach(([subcategory, label]) => {
        options.push({
          category: category as z.infer<typeof ServiceCategory>,
          subcategory: subcategory as z.infer<typeof ServiceSubcategory>,
          label: label,
        });
      });
    }
  );

  return options;
};

// Move this outside the component to ensure the options list remains stable
const SUBCATEGORY_OPTIONS = getSubcategoryOptions();

export function SignUpFinalView() {
  const [activeStep, setActiveStep] = useState(0);
  const { Field, Subscribe, handleSubmit } = useForm({
    defaultValues: {
      companyType: undefined as z.infer<typeof companyType> | undefined,
      address: '',
      companyServices: [] as SubcategoryOption[],
    },
    onSubmit: async ({ value }) => {},
  });

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const STEPS = [
    {
      label: 'What category best describes your business?',
      description: 'Please select an option below',
    },
    {
      label: 'What services does your company offer?',
      description: 'Please select an option below',
    },
    {
      label: 'Company Address',
      description: 'Enter your company address',
    },
    {
      label: 'Please Check Your Email',
      description: 'We sent a link you can use to log in!',
    },
  ];

  const QontoStepIconRoot = styled('div')<{
    ownerState: {
      active?: boolean;
    };
  }>(({ theme, ownerState }) => ({
    height: 22,
    display: 'flex',
    alignItems: 'center',
    color: theme.vars.palette.text.disabled,
    ...(ownerState.active && { color: theme.vars.palette.primary.main }),
    '& .QontoStepIcon-completedIcon': {
      zIndex: 1,
      fontSize: 18,
      color: theme.vars.palette.primary.main,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }));

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    // Force completed state for last step when activeStep is 3
    const isCompleted = completed || (props.icon === 4 && activeStep === 3);

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {isCompleted ? (
          <Iconify
            icon="eva:checkmark-fill"
            className="QontoStepIcon-completedIcon"
            width={24}
            height={24}
          />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.vars.palette.primary.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.vars.palette.primary.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderRadius: 1,
      borderTopWidth: 3,
      borderColor: theme.vars.palette.divider,
    },
  }));

  const renderForm = (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
      }}
      style={{ width: '100%' }}
    >
      <Stack spacing={2}>
        {activeStep === 0 && (
          <>
            <Field
              name="companyType"
              children={({ state, handleChange, handleBlur }) => (
                <Box display="flex" gap={2} justifyContent="center">
                  <Button
                    size="large"
                    variant="outlined"
                    color={state.value === 'FLEET' ? 'primary' : 'inherit'}
                    onClick={() => handleChange('FLEET')}
                    sx={{ width: '50%' }}
                  >
                    Fleet
                  </Button>
                  <Button
                    size="large"
                    variant="outlined"
                    color={
                      state.value === 'REPAIR_SHOP' ? 'primary' : 'inherit'
                    }
                    onClick={() => handleChange('REPAIR_SHOP')}
                    sx={{ width: '50%' }}
                  >
                    Service Provider
                  </Button>
                </Box>
              )}
            />
          </>
        )}

        {activeStep === 1 && (
          <>
            <Field
              name="companyServices"
              children={({ state, handleChange }) => (
                <Box>
                  {Object.entries(serviceSubcategoriesMap).map(
                    ([category, subcategories]) => (
                      <Box key={category} sx={{ mb: 3 }}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            mb: 1,
                            fontWeight: 'bold',
                            color: 'primary.main',
                          }}
                        >
                          {getCategoryLabel(
                            category as z.infer<typeof ServiceCategory>
                          )}
                        </Typography>

                        <Stack spacing={1}>
                          {Object.entries(subcategories).map(
                            ([subcategory, label]) => {
                              const option: SubcategoryOption = {
                                category: category as z.infer<
                                  typeof ServiceCategory
                                >,
                                subcategory: subcategory as z.infer<
                                  typeof ServiceSubcategory
                                >,
                                label,
                              };

                              const isSelected = (state.value || []).some(
                                selected =>
                                  selected.category === option.category &&
                                  selected.subcategory === option.subcategory
                              );

                              return (
                                <FormControlLabel
                                  key={subcategory}
                                  control={
                                    <Checkbox
                                      checked={isSelected}
                                      onChange={e => {
                                        const newValue = isSelected
                                          ? (state.value || []).filter(
                                              item =>
                                                item.category !==
                                                  option.category ||
                                                item.subcategory !==
                                                  option.subcategory
                                            )
                                          : [...(state.value || []), option];
                                        handleChange(newValue);
                                      }}
                                    />
                                  }
                                  label={label}
                                  sx={{
                                    mx: 0,
                                    '&:hover': {
                                      bgcolor: 'action.hover',
                                    },
                                    borderRadius: 1,
                                    px: 1,
                                  }}
                                />
                              );
                            }
                          )}
                        </Stack>
                      </Box>
                    )
                  )}
                </Box>
              )}
            />
          </>
        )}

        {activeStep === 0 && (
          <Subscribe
            selector={state => [state.canSubmit, state.isTouched]}
            children={([canSubmit, isTouched]) => (
              <>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={handleNext}
                  disabled={!canSubmit || !isTouched}
                >
                  Next
                </Button>
              </>
            )}
          />
        )}

        {/* {activeStep === 1 && (
          <Button variant="contained" size="large" onClick={handleBack}>
            Back
          </Button>
        )} */}

        {activeStep === 1 && (
          <Subscribe
            selector={state => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => {
              return (
                <LoadingButton
                  size="large"
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!canSubmit}
                >
                  Sign Up
                </LoadingButton>
              );
            }}
          />
        )}
      </Stack>
    </form>
  );

  return (
    <CenteredLayout>
      <Logo sx={{ mx: 'auto', width: '64px', height: '64px' }} />

      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
        sx={{ mb: 3 }}
      >
        {STEPS.map((_, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
          </Step>
        ))}
      </Stepper>
      <FormHead
        title={STEPS[activeStep].label}
        description={STEPS[activeStep].description}
      />

      {renderForm}
    </CenteredLayout>
  );
}
