import { api } from '@api';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  CardHeader,
  debounce,
  Divider,
  MenuItem,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useForm } from '@tanstack/react-form';
import { useParams } from '@tanstack/react-router';
import { useMutation, useQuery } from 'convex/react';
import { useMemo, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input/input';
import { toast } from 'sonner';
import { CONFIG } from 'src/config-global';
import { Id } from 'src/convex/_generated/dataModel';
import {
  CompanyType,
  companyType,
  getCompanyTypeDisplay,
} from 'src/convex/schema/enums/companyType';
import { PhoneInput } from 'src/minimal-theme/components/phone-input';
import { schemaHelper } from 'src/minimal-theme/utils/schema-helper';
import zod from 'zod';

export type NewVendorSchemaType = zod.infer<typeof NewVendorSchema>;

export const NewVendorSchema = zod.object({
  email: zod
    .string()
    .email('Invalid email address')
    .optional()
    .or(zod.literal('')),
  phone: schemaHelper.phoneNumber({ isValidPhoneNumber }),
  companyName: zod
    .string()
    .min(2, 'Company name must be at least 2 characters'),
});

export function CompanyNewEditForm() {
  const params = useParams({ strict: false });

  const currentCompany = useQuery(
    api.functions.companies.getCompany,
    params.companyId
      ? {
          companyId: params.companyId as Id<'companies'>,
        }
      : 'skip'
  );

  const createCompany = useMutation(api.functions.companies.createCompany);
  const editCompany = useMutation(api.functions.companies.editCompanyInfo);

  const companyTypeList = companyType.options;

  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [coordinates, setCoordinates] = useState<{
    long: number;
    lat: number;
  } | null>(null);

  // Debounced search function
  const searchAddress = useMemo(
    () =>
      debounce(async (query: string) => {
        if (!query) return;

        try {
          const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
              query
            )}.json?access_token=${CONFIG.mapboxApiKey}&types=address`
          );
          const data = await response.json();
          setSearchResults(
            data.features.map((feature: any) => ({
              place_name: feature.place_name,
              center: feature.center,
            }))
          );
        } catch (error) {
          console.error('Search error:', error);
          setSearchResults([]);
        }
      }),
    []
  );

  // Memoize the geocoding function to prevent recreating on every render
  const geocodeAddress = useMemo(
    () => async (query: string) => {
      if (!query) return null;

      try {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            query
          )}.json?access_token=${CONFIG.mapboxApiKey}&types=address`
        );
        const data = await response.json();
        return data.features?.[0] || null;
      } catch (error) {
        console.error('Geocoding error:', error);
        return null;
      }
    },
    []
  );

  const { Field, Subscribe, handleSubmit, reset } = useForm({
    defaultValues: {
      name: currentCompany?.name || '',
      companyType: currentCompany?.companyType || '',
      ein: currentCompany?.ein || '',
      mcNo: currentCompany?.mcNo || '',
      about: currentCompany?.about || '',
      latitude: currentCompany?.location?.latitude || 0,
      longitude: currentCompany?.location?.longitude || 0,
      address: currentCompany?.location?.address || '',
      zip: currentCompany?.location?.zip || '',
      contactEmail: currentCompany?.contactEmail || '',
      contactPhoneNo: currentCompany?.contactPhoneNo || '',
    },
    onSubmit: async ({ value }) => {
      try {
        if (currentCompany) {
          const result = await editCompany({
            companyId: currentCompany._id,
            name: value.name,
            ein: value.ein,
            mcNo: value.mcNo,
            about: value.about,
            location: {
              latitude: coordinates?.lat ?? 0,
              longitude: coordinates?.long ?? 0,
              address: value.address,
              lastUpdated: new Date().toISOString(),
            },
            contactEmail: value.contactEmail,
            contactPhoneNo: value.contactPhoneNo,
          });
          if (result.success) {
            toast.success(result.message);
            reset();
          } else {
            toast.error(result.message);
          }
        } else {
          const result = await createCompany({
            name: value.name,
            companyType:
              value.companyType as (typeof companyType.options)[number],
            ein: value.ein,
            mcNo: value.mcNo,
            about: value.about,
            location: {
              latitude: coordinates?.lat ?? 0,
              longitude: coordinates?.long ?? 0,
              address: value.address,
              lastUpdated: new Date().toISOString(),
            },
            contactEmail: value.contactEmail,
            contactPhoneNo: value.contactPhoneNo,
          });
          if (result.success) {
            toast.success(result.message);
            reset();
          } else {
            toast.error(result.message);
          }
        }
      } catch (error) {
        console.error('Failed to save company:', error);
        toast.error('Failed to save company. Please try again.');
      }
    },
  });

  return (
    <>
      <Card>
        <CardHeader
          title={currentCompany ? 'Edit Company' : 'Create Company'}
          subheader={
            currentCompany
              ? 'Edit the details of the company'
              : "Enter the details of the company you'd like to invite"
          }
          sx={{ mb: 3 }}
        />
        <Divider />
        <Stack spacing={3} sx={{ p: 3 }}>
          <form
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit();
            }}
            style={{ width: '100%' }}
          >
            <Typography variant="h6">Company Details</Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{ my: 2 }}
            >
              <Field
                name="name"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Company Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="companyType"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    disabled={!!currentCompany}
                    select
                    label="Company Type"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    onChange={e =>
                      handleChange(e.target.value as typeof state.value)
                    }
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  >
                    {companyTypeList
                      .filter(t => t !== 'SYSTEM_ADMIN' && t !== 'TOWING')
                      .map(type => (
                        <MenuItem key={type} value={type}>
                          {getCompanyTypeDisplay(type as CompanyType)}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />
              <Field
                name="ein"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="EIN"
                    variant="outlined"
                    fullWidth
                    value={state.value}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
              <Field
                name="mcNo"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="MC No"
                    variant="outlined"
                    fullWidth
                    value={state.value}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
              <Field
                name="address"
                children={({ state, handleChange, handleBlur }) => (
                  <Autocomplete
                    options={searchResults}
                    getOptionLabel={option => option?.place_name || ''}
                    isOptionEqualToValue={(option, value) =>
                      option.place_name === value.place_name
                    }
                    inputValue={state.value || ''}
                    value={state.value ? { place_name: state.value } : null}
                    onInputChange={(_event, newValue) => {
                      setSearchResults([]);
                      handleChange(newValue);
                      searchAddress(newValue);
                    }}
                    onChange={async (_event, newValue) => {
                      if (typeof newValue === 'string') {
                        handleChange(newValue);
                      } else {
                        handleChange(newValue?.place_name || '');

                        const geocoded = await geocodeAddress(
                          newValue?.place_name || ''
                        );

                        // TODO: create a reusable/testable function for this... and find the typings... cuz it was backwards I think
                        if (geocoded?.center) {
                          const [long, lat] = geocoded.center;
                          setCoordinates({ long, lat });
                        } else {
                          setCoordinates(null);
                        }
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Address"
                        required
                        error={state.meta.errors.length > 0}
                        helperText={state.meta.errors[0]}
                      />
                    )}
                  />
                )}
              />
              <Field
                name="about"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    multiline
                    label="About"
                    variant="outlined"
                    fullWidth
                    value={state.value}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
            </Box>

            <Typography variant="h6">Contact Details</Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{ my: 2 }}
            >
              <Field
                name="contactEmail"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Contact Email"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="contactPhoneNo"
                children={({ state, handleChange, handleBlur }) => (
                  <PhoneInput
                    required
                    label="Contact Phone Number"
                    fullWidth
                    value={state.value}
                    onChange={newValue => handleChange(newValue || '')}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <Subscribe
                selector={state => [state.canSubmit, state.isSubmitting]}
                children={([canSubmit, isSubmitting]) => {
                  return (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={!canSubmit}
                    >
                      {!currentCompany ? 'Invite company' : 'Save changes'}
                    </LoadingButton>
                  );
                }}
              />
            </Stack>
          </form>
        </Stack>
      </Card>
    </>
  );
}
