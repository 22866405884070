import { api } from '@api';
import { Alert, Box, Tooltip } from '@mui/material';
import { useMutation, useQuery } from 'convex/react';

import LoadingButton from '@mui/lab/LoadingButton';
import { useParams } from '@tanstack/react-router';
import { useState } from 'react';
import { CONFIG } from 'src/config-global';
import { Id } from 'src/convex/_generated/dataModel';
import { ServiceProviderResult } from 'src/convex/functions/providers';
import {
  getStepTypeDisplay,
  repairDetailsSchema,
} from 'src/convex/schema/entities/requests/requests';
import { toast } from 'src/minimal-theme/components/snackbar';
import { useLocationTracking } from 'src/minimal-theme/hooks/useLocationTracking';
import { InviteLocationViaRequestDialog } from 'src/sections/location/location-new-request';
import { InviteUserViaRequestDialog } from 'src/sections/request/view/InviteUserViaRequestDialog';
import { getRequestStatusColor } from 'src/utils/helper';
import { RequestFindMechanic } from './request-find-mechanic';
import { RequestFindServiceProvider } from './request-find-service-provider';
import { InviteMechanicViaRequestDialog } from './request-invite-mechanic-request-dialog';

export type InvitingUser = {
  dialogOpen: boolean;
  selectedServiceProvider: ServiceProviderResult;
};

export const RequestStepInformation = () => {
  const params = useParams({ from: '/_auth/dashboard/requests/$requestId' });

  const request = useQuery(api.functions.requests.getRequest, {
    requestId: params.requestId as Id<'requests'>,
  });

  const me = useQuery(api.functions.users.getMe);

  const claimFleetDispatchRequest = useMutation(
    api.functions.requests.claimFleetDispatchRequest
  );

  const driverConfirmLocationAndSendBackToDispatch = useMutation(
    api.functions.requests.driverConfirmLocationAndSendBackToDispatch
  );
  const claimServiceDispatchRequest = useMutation(
    api.functions.requests.claimServiceDispatchRequest
  );
  const acceptTechnicianAssignment = useMutation(
    api.functions.requests.acceptTechnicianAssignment
  );

  const startWorkOnRequest = useMutation(
    api.functions.requests.startWorkOnRequest
  );

  const completeWorkOnRequest = useMutation(
    api.functions.requests.completeWorkOnRequest
  );

  const declineRequestAssignmentAsServiceDispatcher = useMutation(
    api.functions.requests.declineRequestAssignmentAsServiceDispatcher
  );

  const technicianArrivedAtLocation = useMutation(
    api.functions.requests.technicianArrivedAtLocation
  );

  const verifyRequestCompletion = useMutation(
    api.functions.requests.verifyRequestCompletion
  );

  const [error, setError] = useState<string | null>(null);
  const [isClaiming, setIsClaiming] = useState<boolean>(false);
  const [isAssigningMechanic, setIsAssigningMechanic] =
    useState<boolean>(false);
  const [isFindingAServiceProvider, setIsFindingAServiceProvider] =
    useState<boolean>(false);
  const [isStartingWork, setIsStartingWork] = useState<boolean>(false);
  const [isTechnicianArrived, setIsTechnicianArrived] =
    useState<boolean>(false);
  const [isCompletingWork, setIsCompletingWork] = useState<boolean>(false);
  const [isDeclining, setIsDeclining] = useState<boolean>(false);
  const [isVerifyingCompletion, setIsVerifyingCompletion] =
    useState<boolean>(false);
  const [isInvitingLocation, setIsInvitingLocation] = useState<boolean>(false);
  const [isInvitingMechanic, setIsInvitingMechanic] = useState<boolean>(false);
  const [userInvitation, setUserInvitation] = useState<InvitingUser | null>(
    null
  );
  const [isSharingLocation, setIsSharingLocation] = useState<boolean>();

  const isTracking = useLocationTracking();

  if (!request || !me) {
    return null;
  }

  const roleType = me.primaryRoleType;
  const isAssignedToMe = request.currentAssignedToId === me._id;
  const isFleetDispatcher = me.roles.find(r => r?.type === 'FLEET_DISPATCHER');
  const isServiceProviderDispatcher = me.roles.find(
    r => r?.type === 'SERVICE_DISPATCHER'
  );
  const isServiceProviderMechanic = me.roles.find(
    r => r?.type === 'TECHNICIAN_PROVIDER'
  );

  const isQueuedForFleetDispatch =
    request.currentStepState === 'QUEUED' &&
    request.currentStepType === 'DISPATCH_TRIAGE';

  const isQueuedForServiceProviderDispatch =
    request.currentStepState === 'QUEUED' &&
    request.currentStepType === 'WITH_SERVICE_PROVIDER_DISPATCH';

  const isQueuedForServiceProviderMechanic =
    request.currentStepState === 'ASSIGNED' &&
    request.currentStepType === 'TECHNICIAN_ASSIGNED';

  const driverMustConfirmLocation =
    request.currentStepState === 'ASSIGNED' &&
    request.currentStepType === 'DRIVER_CONFIRM_LOCATION' &&
    isAssignedToMe;

  const needToFindAServiceProvider =
    isAssignedToMe &&
    isFleetDispatcher &&
    request.currentStepState === 'ASSIGNED' &&
    request.currentStepType === 'DISPATCH_TRIAGE';

  const needToFindAMechanic =
    isAssignedToMe &&
    isServiceProviderDispatcher &&
    request.currentStepState === 'ASSIGNED' &&
    request.currentStepType === 'WITH_SERVICE_PROVIDER_DISPATCH';

  const isTechnicianAccepted =
    request.currentStepType === 'TECHNICIAN_ACCEPTED' &&
    isServiceProviderMechanic;

  const isTechnicianArrivedOnSite =
    request.currentStepType === 'TECHNICIAN_ARRIVED' &&
    isServiceProviderMechanic;

  const isTechnicianStartedWork =
    request.currentStepType === 'TECHNICIAN_STARTED_WORK' &&
    isServiceProviderMechanic;

  const isCompletionVerification =
    request.currentStepType === 'COMPLETION_VERIFICATION' && isFleetDispatcher;

  const isShowAcceptButton =
    (isFleetDispatcher && isQueuedForFleetDispatch) ||
    (isServiceProviderDispatcher && isQueuedForServiceProviderDispatch) ||
    (isServiceProviderMechanic && isQueuedForServiceProviderMechanic);

  const isShowDeclineButton =
    isServiceProviderDispatcher && isQueuedForServiceProviderDispatch;

  let todoText = '';

  if (isQueuedForFleetDispatch || isQueuedForServiceProviderDispatch) {
    todoText = 'Waiting for dispatcher to accept request';
  }

  // ? 'Waiting for dispatcher to accept request'
  // : `Current Step: ${request.currentStepType}`;

  if (needToFindAServiceProvider) {
    todoText = `${request.fleetDispatcher?.clerkUser.fullName} is locating an appropriate service provider`;
  }

  if (needToFindAMechanic) {
    todoText = `${request.serviceDispatcher?.clerkUser.fullName} is locating an appropriate mechanic`;
  }

  const reverseGeocode = async (latitude: number, longitude: number) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${CONFIG.mapboxApiKey}&types=address`
      );
      const data = await response.json();
      return data.features?.[0] || null;
    } catch (error) {
      console.error('Reverse geocoding error:', error);
      return null;
    }
  };

  const handleShareLocationAndAssignToDispatch = async () => {
    setIsSharingLocation(true);
    if (!navigator.geolocation) {
      alert('Geolocation is not supported by your browser');
      return;
    }

    try {
      const position = await new Promise<GeolocationPosition>(
        (resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        }
      );

      const { latitude, longitude } = position.coords;
      const feature = await reverseGeocode(latitude, longitude);

      if (!feature) {
        alert('Could not find address for this location');
        return;
      }

      await driverConfirmLocationAndSendBackToDispatch({
        location: { longitude, latitude, address: feature.place_name },
        requestId: request._id,
      });
    } catch (error) {
      console.error('Error getting location:', error);
      alert('Error getting your location. Please try again.');
    } finally {
      setIsSharingLocation(false);
    }
  };

  const handleClaimRequest = async () => {
    setIsClaiming(true);
    try {
      let result;
      if (roleType === 'FLEET_DISPATCHER') {
        result = await claimFleetDispatchRequest({ requestId: request._id });
      } else if (roleType === 'SERVICE_DISPATCHER') {
        result = await claimServiceDispatchRequest({ requestId: request._id });
      } else if (roleType === 'TECHNICIAN_PROVIDER') {
        result = await acceptTechnicianAssignment({ requestId: request._id });
      } else {
        result = await claimFleetDispatchRequest({ requestId: request._id });
      }

      if (result.success) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } finally {
      setIsClaiming(false);
    }
  };

  const handleDeclineRequest = async () => {
    setIsDeclining(true);
    try {
      let result;
      if (roleType === 'SERVICE_DISPATCHER') {
        result = await declineRequestAssignmentAsServiceDispatcher({
          requestId: request._id,
        });
      }
      // else if (roleType === 'TECHNICIAN_PROVIDER') {
      //   result = await acceptTechnicianAssignment({ requestId: request._id });
      // }
      else {
        setIsDeclining(false);
        return;
      }

      if (result.success) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } finally {
      setIsDeclining(false);
    }
  };

  const handleFindAServiceProvider = () => {
    setIsFindingAServiceProvider(true);
  };

  const handleFindAMechanic = () => {
    setError(null);
    setIsAssigningMechanic(true);
  };

  const handleTechnicianArrived = async () => {
    setIsTechnicianArrived(true);
    const response = await technicianArrivedAtLocation({
      requestId: request._id,
    });
    if (response.success) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    setIsTechnicianArrived(false);
  };

  const handleStartWork = async () => {
    setIsStartingWork(true);
    const response = await startWorkOnRequest({ requestId: request._id });
    if (response.success) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    setIsStartingWork(false);
  };

  const handleCompleteWork = async () => {
    setIsCompletingWork(true);
    const response = await completeWorkOnRequest({ requestId: request._id });
    if (response.success) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    setIsCompletingWork(false);
  };

  const handleVerifyCompletion = async () => {
    setIsVerifyingCompletion(true);
    const response = await verifyRequestCompletion({ requestId: request._id });
    if (response.success) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    setIsVerifyingCompletion(false);
  };

  const validateRepairDetails = () => {
    if (!request.repairDetails) {
      return 'Must submit repair details before completing work';
    }

    const result = repairDetailsSchema.safeParse(request.repairDetails);
    if (!result.success) {
      // Get the first error message
      const errorMessage =
        result.error.errors[0]?.message || 'Invalid repair details';
      return errorMessage;
    }

    return null;
  };

  const validRepairDetails = validateRepairDetails();

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
        <Box
          width={{ xs: '100%', md: request ? '70%' : '100%' }}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          {request.currentStepType && (
            <Alert
              variant="outlined"
              severity={
                getRequestStatusColor(request.status) as
                  | 'success'
                  | 'warning'
                  | 'info'
                  | 'error'
              }
            >
              {getStepTypeDisplay(request.currentStepType)}
              {todoText && ` - ${todoText}`}
            </Alert>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {isShowAcceptButton && (
              <LoadingButton
                type="submit"
                color="primary"
                size="large"
                variant="contained"
                loading={isClaiming}
                onClick={handleClaimRequest}
                sx={{ width: 'fit-content', alignSelf: 'center' }}
              >
                Accept Request
              </LoadingButton>
            )}
            {isShowDeclineButton && (
              <LoadingButton
                type="submit"
                color="error"
                size="large"
                variant="contained"
                loading={isDeclining}
                onClick={handleDeclineRequest}
                sx={{ width: 'fit-content', alignSelf: 'center', ml: 2 }}
              >
                Decline Request
              </LoadingButton>
            )}
          </Box>

          {driverMustConfirmLocation && (
            <LoadingButton
              type="submit"
              color="primary"
              size="large"
              variant="contained"
              loading={isSharingLocation}
              onClick={handleShareLocationAndAssignToDispatch}
              sx={{ width: 'fit-content', alignSelf: 'center' }}
            >
              Share Location
            </LoadingButton>
          )}

          {needToFindAServiceProvider && (
            <LoadingButton
              type="submit"
              color="primary"
              size="large"
              variant="contained"
              disabled={isFindingAServiceProvider}
              onClick={handleFindAServiceProvider}
              sx={{ width: 'fit-content', alignSelf: 'center' }}
            >
              Find Service Provider
            </LoadingButton>
          )}

          {needToFindAMechanic && (
            <LoadingButton
              type="submit"
              color="primary"
              size="large"
              variant="contained"
              disabled={isAssigningMechanic}
              onClick={handleFindAMechanic}
              sx={{ width: 'fit-content', alignSelf: 'center' }}
            >
              Find Mechanic
            </LoadingButton>
          )}

          {isTechnicianAccepted && isTracking && (
            <>
              <Alert severity="info">Location tracking is active</Alert>
              <LoadingButton
                type="submit"
                color="primary"
                size="large"
                variant="contained"
                loading={isTechnicianArrived}
                disabled={isTechnicianArrived}
                onClick={handleTechnicianArrived}
                sx={{ width: 'fit-content', alignSelf: 'center' }}
              >
                Arrived At Location
              </LoadingButton>
            </>
          )}

          {isTechnicianArrivedOnSite && (
            <LoadingButton
              type="submit"
              color="primary"
              size="large"
              variant="contained"
              onClick={handleStartWork}
              sx={{ width: 'fit-content', alignSelf: 'center' }}
            >
              Start Work
            </LoadingButton>
          )}

          {isTechnicianStartedWork && (
            <Tooltip title={validRepairDetails || ''} placement="top">
              <Box sx={{ width: 'fit-content', alignSelf: 'center' }}>
                <LoadingButton
                  type="submit"
                  color="primary"
                  size="large"
                  variant="contained"
                  loading={isCompletingWork}
                  disabled={isCompletingWork || !!validRepairDetails}
                  onClick={handleCompleteWork}
                >
                  Complete Work
                </LoadingButton>
              </Box>
            </Tooltip>
          )}

          {isCompletionVerification && (
            <LoadingButton
              type="submit"
              color="primary"
              size="large"
              variant="contained"
              onClick={handleVerifyCompletion}
              sx={{ width: 'fit-content', alignSelf: 'center' }}
            >
              Verify Completion
            </LoadingButton>
          )}

          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}

          <RequestFindServiceProvider
            open={isFindingAServiceProvider}
            needToFindAServiceProvider={needToFindAServiceProvider}
            onClose={() => setIsFindingAServiceProvider(false)}
            inviteLocation={() => setIsInvitingLocation(true)}
            setUserInvitation={setUserInvitation}
          />

          <InviteLocationViaRequestDialog
            open={isInvitingLocation}
            onClose={() => setIsInvitingLocation(false)}
          />

          <InviteMechanicViaRequestDialog
            open={isInvitingMechanic}
            onClose={() => setIsInvitingMechanic(false)}
          />

          {!!userInvitation?.selectedServiceProvider && (
            <InviteUserViaRequestDialog
              open={!!userInvitation?.dialogOpen}
              onClose={() => setUserInvitation(null)}
              selectedServiceProvider={userInvitation.selectedServiceProvider}
            />
          )}

          <RequestFindMechanic
            open={isAssigningMechanic}
            inviteMechanic={() => setIsInvitingMechanic(true)}
            onClose={() => {
              setIsAssigningMechanic(false);
              setError(null);
            }}
            onError={errorMessage => {
              setError(errorMessage);
              setIsAssigningMechanic(false);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
