import { Link, LinkProps } from '@tanstack/react-router';
import { forwardRef } from 'react';
import { FileRoutesByFullPath } from 'src/routeTree.gen';

// ----------------------------------------------------------------------

type ValidPaths = keyof FileRoutesByFullPath;

interface RouterLinkProps extends Omit<LinkProps, 'to'> {
  href: ValidPaths;
}

export const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  ({ href, ...other }, ref) => <Link ref={ref} to={href} {...other} />
);

RouterLink.displayName = 'RouterLink';
