import LoadingButton from '@mui/lab/LoadingButton';
import { CardHeader, FormControlLabel, Switch } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useForm } from '@tanstack/react-form';
import { useLoaderData } from '@tanstack/react-router';
import { useConvexAuth, useMutation, useQuery } from 'convex/react';
import { useRef } from 'react';
import { toast } from 'sonner';
import { api } from 'src/convex/_generated/api';
import { PhoneInput } from 'src/minimal-theme/components/phone-input';

export function DispatcherNewEditForm() {
  const selectedDispatcher = (() => {
    try {
      return useLoaderData({
        from: '/_auth/dashboard/dispatchers/$dispatcherId',
      });
    } catch {
      return null;
    }
  })();

  const { isAuthenticated } = useConvexAuth();

  const me = useQuery(api.functions.users.getMe, isAuthenticated ? {} : 'skip');

  const company = useQuery(
    api.functions.companies.getCompany,
    me?.companyId ? { companyId: me.companyId } : 'skip'
  );

  const inviteDispatcher = useMutation(api.functions.invitations.inviteUser);
  const phoneRef = useRef<HTMLInputElement>(null);

  const { Field, Subscribe, handleSubmit, reset } = useForm({
    defaultValues: {
      firstName: selectedDispatcher?.clerkUser.firstName ?? '',
      lastName: selectedDispatcher?.clerkUser.lastName ?? '',
      phone:
        selectedDispatcher?.clerkUser.primaryPhoneNumber?.phoneNumber ?? '',
      email:
        selectedDispatcher?.clerkUser.primaryEmailAddress?.emailAddress ?? '',
      skipInvitationEmail: true,
    },

    onSubmit: async ({ value }) => {
      const res = await inviteDispatcher({
        inviteInfo: {
          ...value,
          role:
            company?.companyType === 'REPAIR_SHOP'
              ? 'SERVICE_DISPATCHER'
              : 'FLEET_DISPATCHER',
        },
      });

      console.log('res', res);

      if (res.success) {
        toast.success(res.message);
        reset();
      } else {
        toast.error(res.message);
      }
    },
  });

  return (
    <>
      <Card>
        <CardHeader
          title={!selectedDispatcher ? 'Add Dispatcher' : 'Edit Dispatcher'}
          subheader={selectedDispatcher?.clerkUser.fullName}
          sx={{ mb: 3 }}
        />
        {/* <Divider /> */}
        <Stack spacing={3} sx={{ p: 3 }}>
          <form
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit();
            }}
            style={{ width: '100%' }}
          >
            {/* <Box sx={{ mb: 5 }}>
            <Field
              name="imageUrl"
              children={({ state, ...other }) => (
                <UploadAvatar
                  value={state.value}
                  maxSize={3145728}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 3,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.disabled',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of {fData(3145728)}
                    </Typography>
                  }
                  {...other}
                />
              )}
            />
          </Box> */}
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <Field
                name="firstName"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    autoFocus
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="lastName"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="phone"
                // validatorAdapter={zodValidator()}
                // validators={{
                //   onChange: phoneSchema,
                // }}
                children={({ state, handleChange, handleBlur }) => (
                  <PhoneInput
                    ref={phoneRef}
                    required
                    placeholder="(123) 456-7890"
                    fullWidth
                    value={state.value}
                    onChange={newValue => handleChange(newValue || '')}
                    onBlur={handleBlur}
                    helperText={state.meta.errors[0]}
                    label="Phone Number"
                  />
                )}
              />
              <Field
                name="email"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field name="skipInvitationEmail">
                {({ state, handleChange, handleBlur }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        id="skipInvitationEmail"
                        checked={state.value}
                        onChange={e => handleChange(e.target.checked)}
                      />
                    }
                    label="Skip Invitation Email"
                  />
                )}
              </Field>
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <Subscribe
                selector={state => [state.canSubmit, state.isSubmitting]}
                children={([canSubmit, isSubmitting]) => {
                  return (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={!canSubmit}
                    >
                      {!selectedDispatcher ? 'Add Dispatcher' : 'Save changes'}
                    </LoadingButton>
                  );
                }}
              />
            </Stack>
          </form>
        </Stack>
      </Card>
    </>
  );
}
