import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'src/config-global';
import { RequestView } from 'src/sections/request/view';
// import { RequestDetailsView } from 'src/sections/request/view';

const metadata = { title: `Request Details | Dashboard - ${CONFIG.appName}` };

export default function Page() {
  return (
    <>
      <Helmet>
        <title> {metadata.title}</title>
      </Helmet>

      <RequestView />

      {/* <RequestDetailsView /> */}
    </>
  );
}
