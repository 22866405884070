import { createFileRoute, redirect } from '@tanstack/react-router';
import Page from 'src/pages/sign-up/sign-up';
import { z } from 'zod';

const signUpSearchSchema = z.object({
  notSignedUp: z.boolean().optional(),
});

export const Route = createFileRoute('/sign-up')({
  validateSearch: search => signUpSearchSchema.parse(search),
  beforeLoad: async ({ context: { isAuthenticated } }) => {
    // TODO: Eventually will add this back
    throw redirect({ to: '/sign-in' });
    if (isAuthenticated) {
      throw redirect({ to: '/dashboard/requests' });
    }
  },
  component: Page,
});
