import { useQuery } from 'convex/react';
import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'src/config-global';
import { api } from 'src/convex/_generated/api';
import { Doc } from 'src/convex/_generated/dataModel';
import { MapView } from 'src/sections/map/view/map-view';

const metadata = { title: `Map | Dashboard - ${CONFIG.appName}` };

export default function Page() {
  const THEMES = {
    streets: 'mapbox://styles/mapbox/streets-v11',
    outdoors: 'mapbox://styles/mapbox/outdoors-v11',
    light: 'mapbox://styles/mapbox/light-v10',
    dark: 'mapbox://styles/mapbox/dark-v10',
    satellite: 'mapbox://styles/mapbox/satellite-v9',
    satelliteStreets: 'mapbox://styles/mapbox/satellite-streets-v11',
  };

  const me = useQuery(api.functions.users.getMe);

  const users = useQuery(api.functions.users.getAllUsersForLocation);
  const vehicles = useQuery(api.functions.vehicles.getVehiclesForLocation);

  // TODO: For admin we'll have to trim this down in some way, just scaffolding for now
  const allUsers = useQuery(
    api.functions.users.getAllUsersForSuperAdmin,
    me && me.clerkUser.isSuperAdmin ? {} : 'skip'
  );
  const allVehicles = useQuery(
    api.functions.vehicles.getAllVehiclesForAdmin,
    me && me.clerkUser.isSuperAdmin ? {} : 'skip'
  );

  let actualUsers: Doc<'users'>[] | undefined = users;
  let actualVehicles: Doc<'vehicles'>[] | undefined = vehicles;

  if (me?.clerkUser.isSuperAdmin) {
    actualUsers = allUsers;
    actualVehicles = allVehicles;
  }

  const technicians =
    actualUsers?.filter(
      u =>
        u.primaryRoleType === 'TECHNICIAN' ||
        u.primaryRoleType === 'TECHNICIAN_FLEET'
    ) ?? [];

  // TODO: if admin, change things a bit more

  const companies = useQuery(
    api.functions.companies.getCompanies,
    me && me.clerkUser.isSuperAdmin ? {} : 'skip'
  );

  const locations = useQuery(
    api.functions.companies.getLocations,
    me && me.clerkUser.isSuperAdmin ? {} : 'skip'
  );

  const fleetLocations = locations?.filter(
    l => l.locationType === 'FLEET_TERMINAL' || l.locationType === 'FLEET_YARD'
  );

  const serviceLocations = locations?.filter(
    l =>
      l.locationType === 'REPAIR_SHOP' ||
      l.locationType === 'MOBILE_REPAIR_BASE'
  );

  return (
    <>
      <Helmet>
        <title> {metadata.title}</title>
      </Helmet>

      <MapView
        data={{
          technicians: technicians ?? [],
          vehicles: actualVehicles ?? [],
          companies: companies ?? [],
          fleetLocations: fleetLocations ?? [],
          serviceLocations: serviceLocations ?? [],
        }}
        mapStyle={THEMES.light}
      />
    </>
  );
}
