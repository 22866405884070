import { m } from 'framer-motion';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { ForbiddenIllustration } from 'src/minimal-theme/assets/illustrations';
import { SimpleLayout } from 'src/minimal-theme/layouts/simple';

import {
  MotionContainer,
  varBounce,
} from 'src/minimal-theme/components/animate';
import { MuiTypeSafeLink } from 'src/minimal-theme/components/routes/components/mui-link';

// ----------------------------------------------------------------------

export function View403() {
  return (
    <SimpleLayout content={{ compact: true }}>
      <Container component={MotionContainer}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            No permission
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            The page you’re trying to access has restricted access. Please refer
            to your system administrator.
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ my: { xs: 5, sm: 10 } }} />
        </m.div>

        <Button
          component={MuiTypeSafeLink}
          to="/dashboard"
          size="large"
          variant="contained"
        >
          Go to home
        </Button>
      </Container>
    </SimpleLayout>
  );
}
