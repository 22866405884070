import { api } from '@api';
import { Badge } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { useQuery } from 'convex/react';
import { ConfirmDialog } from 'src/minimal-theme/components/custom-dialog';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { MuiTypeSafeLink } from 'src/minimal-theme/components/routes/components/mui-link';
import {
  CustomPopover,
  usePopover,
} from '../../minimal-theme/components/custom-popover';
import { useBoolean } from '../../minimal-theme/hooks/use-boolean';
import { DriverQuickAssignForm } from './driver-quick-assign-form';

type DriverTableRowProps = {
  row: any;
  selected: boolean;
  onEditRow: () => void;
  onSelectRow: () => void;
  onDeleteRow: () => void;
};

export function DriverTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: DriverTableRowProps) {
  const confirm = useBoolean();

  const popover = usePopover();

  const quickAssign = useBoolean();

  const currentVehicles = useQuery(
    api.functions.driverHistory.getCurrentVehicleAssignments,
    {
      userId: row._id,
    }
  );

  // TODO: @Andrii - I did this just to get it to build, we'll need to handle for multiple vehicles for driver
  const currentVehicle = currentVehicles ? currentVehicles[0] : undefined;

  return (
    <>
      <TableRow hover selected={selected} aria-checked={selected} tabIndex={-1}>
        <TableCell padding="checkbox">
          <Checkbox id={row._id} checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell>
          <Stack spacing={2} direction="row" alignItems="center">
            <Badge
              // variant={getBadgeVariant(row.status)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              sx={{ mr: 2 }}
            >
              <Avatar alt={row.clerkUser.firstName} src={row.imageUrl}>
                {row.clerkUser.firstName[0]}
              </Avatar>
            </Badge>

            <Stack
              sx={{
                typography: 'body2',
                flex: '1 1 auto',
                alignItems: 'flex-start',
              }}
            >
              <MuiTypeSafeLink
                to="/dashboard/drivers/$driverId"
                params={{ driverId: row._id }}
                color="inherit"
                sx={{ cursor: 'pointer' }}
              >
                {row.clerkUser.firstName} {row.clerkUser.lastName}
              </MuiTypeSafeLink>
              <Link
                href={`mailto:${row.clerkUser.primaryEmailAddress.emailAddress}`}
                sx={{ color: 'text.disabled' }}
                underline="hover"
              >
                {row.clerkUser.primaryEmailAddress.emailAddress}
              </Link>
            </Stack>
          </Stack>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Link
            href={`tel:${row.clerkUser.primaryPhoneNumber.phoneNumber}`}
            color="inherit"
            underline="hover"
          >
            {row.clerkUser.primaryPhoneNumber.phoneNumber}
          </Link>
        </TableCell>

        <TableCell>
          {currentVehicle ? (
            <MuiTypeSafeLink
              to="/dashboard/trucks/$truckId"
              params={{ truckId: currentVehicle?._id }}
              color="inherit"
              sx={{ cursor: 'pointer' }}
            >
              {`${currentVehicle.maker} ${currentVehicle.model} ${currentVehicle.modelYear} / Unit : ${currentVehicle.unitNumber}`}
            </MuiTypeSafeLink>
          ) : (
            'N/A'
          )}
        </TableCell>

        {/** Use status ony for service provider's technicians, commenting out for now */}

        {/* <TableCell>
          <Label variant="soft" color={getStatusColor(row.status)}>
            {getStatusLabel(row.status)}
          </Label>
        </TableCell> */}

        <TableCell>
          <Stack direction="row" alignItems="center">
            <Tooltip
              title={currentVehicle ? 'Unassign' : 'Assign'}
              placement="top"
              arrow
            >
              <IconButton
                color={quickAssign.value ? 'inherit' : 'default'}
                onClick={quickAssign.onTrue}
              >
                <Iconify
                  icon={
                    currentVehicle
                      ? 'mdi:truck-minus-outline'
                      : 'mdi:truck-plus-outline'
                  }
                />
              </IconButton>
            </Tooltip>

            <IconButton
              color={popover.open ? 'inherit' : 'default'}
              onClick={popover.onOpen}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      <DriverQuickAssignForm
        driverId={row._id}
        open={quickAssign.value}
        onClose={quickAssign.onFalse}
        currentVehicle={currentVehicle}
      />

      <CustomPopover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        slotProps={{ arrow: { placement: 'right-top' } }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete
          </MenuItem>

          <MenuItem
            component={MuiTypeSafeLink}
            to="/dashboard/drivers/$driverId"
            params={{ driverId: row._id }}
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'inherit' }}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
        </MenuList>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
