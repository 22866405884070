import type { MapProps, MapRef } from 'react-map-gl';

import { forwardRef } from 'react';
import MapGL from 'react-map-gl';

import { CONFIG } from 'src/config-global';

// ----------------------------------------------------------------------

export const Map = forwardRef<MapRef, MapProps>(({ ...other }, ref) => (
  <MapGL
    ref={ref}
    mapboxAccessToken={CONFIG.mapboxApiKey}
    {...(other as any)}
  />
));
