import { z } from 'zod';

const viteEnvironmentSchema = z.object({
  VITE_CONVEX_URL: z.string().optional(),
  VITE_CLERK_PUBLISHABLE_KEY: z.string(),
  VITE_SENTRY_DSN: z.string(),
  VITE_SENTRY_ORG: z.string(),
  VITE_SENTRY_PROJECT: z.string(),
  VITE_MAPBOX_API_KEY: z.string(),
});

export type ViteEnvironment = z.infer<typeof viteEnvironmentSchema>;

const viteModeSchema = z.enum(['development', 'preview', 'production']);
export const viteMode = viteModeSchema.parse(import.meta.env.MODE);

let viteEnvironment: ViteEnvironment;

try {
  viteEnvironment = viteEnvironmentSchema.parse(import.meta.env);
} catch (error) {
  if (error instanceof z.ZodError) {
    console.error(
      'Invalid Vite environment variables, check with a colleague/ensure you have the latest set up as reflected in Vercel:',
      error.errors
    );
  } else {
    console.error(
      'Unexpected error during Vite environment validation:',
      error
    );
  }
  throw Error;
}

export { viteEnvironment };
