import { getOneFrom } from 'convex-helpers/server/relationships';
import { Id } from '../../_generated/dataModel';
import { DatabaseWriter } from '../../_generated/server';
import { InviteUserInput } from '../../schema/entities/companies';

export type UserCreationResult = {
  clerkId: string;
  userId: Id<'users'>;
};

/**
 * Creates both Clerk and Convex user with proper role
 */
export async function createConvexUserAndRoleHelper(
  db: DatabaseWriter,
  input: InviteUserInput,
  clerkId: string
) {
  // Create Convex user first
  const userId = await db.insert('users', {
    companyId: input.companyId,
    invitedByUser: input.invitedByUserId,
    invitedByCompany: input.invitedByCompanyId,
    invitedByLocation: input.invitedByLocationId,
    createdByUserDuringRequest: !!input.requestId,
    createdFromRequestId: input.requestId,
    status: 'AVAILABLE',
    clerkUser: {
      id: clerkId,
      isSuperAdmin: input.role === 'SUPER_ADMIN',
      firstName: input.firstName,
      lastName: input.lastName,
      fullName: `${input.firstName} ${input.lastName}`,
      primaryEmailAddress: {
        emailAddress: input.email,
      },
      primaryPhoneNumber: {
        phoneNumber: input.phone,
      },
      imageUrl: '',
    },
  });

  const [roleDefinition, locationGroup] = await Promise.all([
    getOneFrom(db, 'roleDefinitions', 'by_type', input.role),
    db.get(input.locationGroupId),
  ]);

  if (!roleDefinition) {
    await db.delete(userId);
    throw new Error(`Role definition not found for ${input.role}`);
  }

  if (!locationGroup) {
    await db.delete(userId);
    throw new Error(`Location group not found for ${input.locationGroupId}`);
  }

  await Promise.all([
    db.insert('userGroups', {
      userId: userId,
      groupId: input.locationGroupId,
    }),
    db.insert('userRoles', {
      userId,
      roleDefinitionId: roleDefinition._id,
      status: 'active',
      companyId: input.companyId,
    }),
    db.patch(userId, {
      primaryRoleId: roleDefinition._id,
      primaryRoleType: roleDefinition.type,
      primaryLocationGroupId: input.locationGroupId,
    }),
  ]);

  return { userId };
}
