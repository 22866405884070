import { zid, zodToConvex } from 'convex-helpers/server/zod';
import { defineTable } from 'convex/server';
import { customAlphabet } from 'nanoid';
import { z } from 'zod';
import { paymentMethod } from '../../enums/paymentMethod';
import { requestStatusType } from '../../enums/requestStatusType';

export const searchComponentSchema = z.object({
  type: z.enum([
    'CASE_NUMBER',
    'ADDRESS',
    'DESCRIPTION',
    'DRIVER_NAME',
    'FLEET_DISPATCHER_NAME',
    'SERVICE_DISPATCHER_NAME',
    'TECHNICIAN_NAME',
    'VEHICLE_VIN',
    'VEHICLE_MAKER',
    'VEHICLE_MODEL',
    'VEHICLE_UNIT_NUMBER',
  ]),
  text: z.string(),
  sourceId: zid('users').or(zid('vehicles')).optional(),
});

export type RequestSearchComponent = z.infer<typeof searchComponentSchema>;

export const caseNumberSchema = z
  .string()
  .regex(/^\d{6}-\d{7}$/, 'Invalid case number format')
  .describe('Case number in format YYMMDD-NNNNNNN');

export const stepState = z.enum([
  'UNASSIGNED', // No one assigned yet
  'ASSIGNED', // Someone specific is assigned
  'QUEUED', // Waiting in a dispatch/service queue
  'COMPLETED', // Step is done
  'CANCELED', // Step was canceled
]);

// Define service phase to track which group is actively handling the request
export const servicePhase = z.enum([
  'FLEET_DISPATCH', // Initial fleet company dispatch handling
  'TOW_DISPATCH', // Tow company dispatch handling
  'TOW_SERVICE', // Active tow service
  'MECHANIC_DISPATCH', // Service provider dispatch handling
  'MECHANIC_SERVICE', // Active mechanical service
  'VERIFICATION',
  'COMPLETED',
]);

export const stepType = z.enum([
  'INTAKE',
  'DRIVER_CONFIRM_LOCATION',
  'DISPATCH_TRIAGE',
  'WITH_SERVICE_PROVIDER_DISPATCH',
  'TECHNICIAN_ASSIGNED',
  'TECHNICIAN_ACCEPTED',
  'TECHNICIAN_ARRIVED',
  'TECHNICIAN_STARTED_WORK',
  'TECHNICIAN_COMPLETED_WORK',
  'COMPLETION_VERIFICATION',
  'COMPLETED',
]);

export type StepType = z.infer<typeof stepType>;

export const STEP_DISPLAY: Record<StepType, string> = {
  INTAKE: 'Request Created',
  DRIVER_CONFIRM_LOCATION: 'Driver Confirming Location',
  DISPATCH_TRIAGE: 'With Dispatch',
  WITH_SERVICE_PROVIDER_DISPATCH: 'Awaiting Service Provider',
  TECHNICIAN_ASSIGNED: 'Technician Assigned',
  TECHNICIAN_ACCEPTED: 'Technician En Route',
  TECHNICIAN_ARRIVED: 'Technician Onsite',
  TECHNICIAN_STARTED_WORK: 'Technician Started Work',
  TECHNICIAN_COMPLETED_WORK: 'Technician Has Completed Work',
  COMPLETION_VERIFICATION: 'Awaiting Verification of Completion of the request',
  COMPLETED: 'Request Completed',
};

export const getStepTypeDisplay = (role?: StepType) =>
  role ? STEP_DISPLAY[role] : 'N/A';

export const repairDetailsSchema = z.object({
  cause: z.string(),
  correction: z.string(),
  wasATemporaryFix: z.boolean().optional(),
  notes: z.string().optional(),
  completedAt: z.string(), // When these details were submitted
  technicianId: zid('users'), // Who submitted them
});

export type RepairDetails = z.infer<typeof repairDetailsSchema>;

export const submitRepairDetailsInput = repairDetailsSchema
  .omit({
    completedAt: true,
    technicianId: true,
  })
  .extend({ requestId: zid('requests'), wasATemporaryFix: z.boolean() });

export const requestSchema = z.object({
  // workflowDefinitionId: zid('workflowDefinitions'),
  status: requestStatusType,
  // currentStepIndex: z.number(),

  currentPhase: servicePhase.optional(),
  currentStepType: stepType.optional(),
  currentStepState: stepState.optional(),

  caseNumber: caseNumberSchema,

  description: z.string().optional(),

  // Location
  address: z.string().optional(), // To trap the initial location of accident in time... separate from where the vehicle is now, etc. can be used to geneerate heatmap of breakdown locations,
  longitude: z.number().optional(),
  latitude: z.number().optional(),

  // Group history - tracks all groups involved
  driverGroupId: zid('groups').optional(),
  fleetDispatchGroupId: zid('groups').optional(), // Fleet dispatch group
  towDispatchGroupId: zid('groups').optional(), // Tow dispatcher group
  towServiceGroupId: zid('groups').optional(), // Tow service group
  mechanicDispatchGroupId: zid('groups').optional(), // Mechanic dispatcher group
  mechanicServiceGroupId: zid('groups').optional(), // Mechanic service group

  // Current assignment
  currentAssignedToId: zid('users').optional(),
  currentRequiredRoleId: zid('roleDefinitions').optional(), // Role required for current step if it's queued, awaiting claim

  // Current active participants by role (for real-time coordination)
  activeDriverId: zid('users').optional(),
  activeFleetDispatcherId: zid('users').optional(),
  activeServiceDispatcherId: zid('users').optional(),
  activeTechnicianId: zid('users').optional(),
  activeTowOperatorId: zid('users').optional(),

  // For technician when they have started their work
  repairDetails: repairDetailsSchema.optional(),

  // Companies involved
  requesterCompanyId: zid('companies'),
  serviceProviderCompanyId: zid('companies').optional(),
  towingCompanyId: zid('companies').optional(),

  // For full text search/client side search in the meantime
  searchText: z.string().optional(),
  searchComponents: z.array(searchComponentSchema).optional(),

  // In cases where dispatcher is creating on behalf of driver, and we require driver location ... determines the order of steps (confirm drivers location will show up and come before dispatch triage in these cases)
  requiresVehicleLocation: z.boolean().optional(),

  // Timing
  currentStepStartedAt: z.string().optional(),
  createdAt: z.string(),
  completedAt: z.string().optional(),
  deletedAt: z.string().optional(),

  estimatedTimeOfArrival: z.string().optional(),

  driverRequiresTow: z.boolean().optional(),

  // TODO: RF ... would be more work but might be useful if this wasn't just a string, but pulling from a managed list fo relevant tools
  tools: z.string().optional(),

  // TODO: when workflows are figured out, this will apply to a step, not the whole request
  mapGeneratedEta: z.string().optional(),
  // TODO: RF - should probably not apply to the whole request but on the specific action/service being charged
  paymentMethod: paymentMethod.optional(),

  // Simple external reference - optional string that fleets can use to track their internal number
  fleetReferenceNumber: z.string().optional(),

  // TODO: I think get rid of these??
  externalReferenceNumber: z.string().optional(),
  nationalAccountNumber: z.string().optional(),

  notes: z.string().optional(),
  timezone: z.string().optional(), // TODO RF: Still need to think about this, a single tz set on the request might not be best? If it was always the tz of the broken down driver sure

  // Relationships
  createdById: zid('users'),

  vehicleId: zid('vehicles').optional(), // Optionally initially for creating draft

  // Services the fleet believes are needed
  servicesRequiredByFleet: z.array(zid('services')).optional(),
  // Services the technician deems necessary after inspection
  servicesDeemedNecessaryByTech: z.array(zid('services')).optional(),
  // Services actually performed
  servicesPerformed: z.array(zid('services')).optional(),
});

export type ServiceRequest = z.infer<typeof requestSchema>;
export const requests = defineTable(zodToConvex(requestSchema).fields)
  // Core status and phase indexes
  .index('by_currentPhase_and_status', ['currentPhase', 'status'])
  .index('by_currentStepState_and_status', ['currentStepState', 'status'])

  // Group indexes - consolidate with status
  .index('by_driverGroupId_and_status', ['driverGroupId', 'status'])
  .index('by_fleetDispatchGroupId_and_status', [
    'fleetDispatchGroupId',
    'status',
  ])
  .index('by_mechanicDispatchGroupId_and_status', [
    'mechanicDispatchGroupId',
    'status',
  ])
  .index('by_mechanicServiceGroupId_and_status', [
    'mechanicServiceGroupId',
    'status',
  ])

  // Active participant indexes - all include status
  .index('by_activeDriverId_and_status', ['activeDriverId', 'status'])
  .index('by_activeFleetDispatcherId_and_status', [
    'activeFleetDispatcherId',
    'status',
  ])
  .index('by_activeServiceDispatcherId_and_status', [
    'activeServiceDispatcherId',
    'status',
  ])
  .index('by_activeTechnicianId_and_status', ['activeTechnicianId', 'status'])
  .index('by_activeTowOperatorId_and_status', ['activeTowOperatorId', 'status'])

  // Queue indexes (critical for dispatch functionality)
  .index('by_fleetQueue', [
    'currentRequiredRoleId',
    'fleetDispatchGroupId',
    'status',
    'currentStepState',
  ])
  .index('by_serviceQueue', [
    'currentRequiredRoleId',
    'mechanicDispatchGroupId',
    'status',
    'currentStepState',
  ])

  // Assignment indexes
  .index('by_currentAssignedToId_and_status', ['currentAssignedToId', 'status'])

  // Company indexes
  .index('by_requesterCompanyId_and_status', ['requesterCompanyId', 'status'])
  .index('by_serviceProviderCompanyId_and_status', [
    'serviceProviderCompanyId',
    'status',
  ])
  .index('by_towingCompanyId_and_status', ['towingCompanyId', 'status'])

  // Creation and management indexes
  .index('by_createdById_and_status', ['createdById', 'status'])
  .index('by_caseNumber', ['caseNumber'])
  .index('by_vehicleId_and_status', ['vehicleId', 'status'])
  .searchIndex('search', {
    searchField: 'searchText',
  });

const generateNumericId = customAlphabet('0123456789', 7);

export function generateCaseNumber(): string {
  const now = new Date();
  const datePart = now.toISOString().slice(2, 10).replace(/-/g, ''); // YYMMDD
  const randomPart = generateNumericId();

  return `${datePart}-${randomPart}`;
}
