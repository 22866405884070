import { Helmet } from 'react-helmet-async';

import { CONFIG } from 'src/config-global';

import { CssBaseline } from '@mui/material';
import { MotionLazy } from 'src/minimal-theme/components/animate/motion-lazy';
import { NotFoundView } from 'src/minimal-theme/sections/error';
import { ThemeProvider } from 'src/minimal-theme/theme/theme-provider';

// ----------------------------------------------------------------------

const metadata = { title: `404 page not found! | Error - ${CONFIG.appName}` };

export function NotFoundPage() {
  return (
    <ThemeProvider>
      <MotionLazy>
        <CssBaseline />
        <Helmet>
          <title> {metadata.title}</title>
        </Helmet>

        <NotFoundView />
      </MotionLazy>
    </ThemeProvider>
  );
}
